<section tiptapDraggable [ngClass]="{ editable: isEditable, selected: isEditable && isSelected }">
    <span> Zoom Meeting Link: {{ zoomMeeting?.topic || 'No Meeting Selected' }} </span>
    <ui-tiptap-zoom-link
        [showCopyLinkButton]="showCopyLinkButton"
        [showJoinButton]="showJoinButton"
        [showJoinUrl]="showJoinUrl"
        [showCopyPasswordButton]="showCopyPasswordButton"
        [joinUrl]="joinUrl"
        [password]="password"
        [alignment]="alignment"
    ></ui-tiptap-zoom-link>
    <span class="hint" *ngIf="!showJoinButton && !showJoinUrl && !showCopyLinkButton">
        You've chosen to hide all buttons. This is not recommended.
    </span>
</section>

import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { Meeting, Webinar } from '@app/data/models';
import { DestroyService } from '@app/shared/services';
import { Store } from '@ngxs/store';
import { AngularNodeViewComponent, NgxTiptapModule } from 'ngx-tiptap';

import { ZoomState } from '../../state/zoom.state';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'admin-tiptap-guest-link-container',
    templateUrl: './tiptap-guest-link-container.component.html',
    styleUrls: ['./tiptap-guest-link-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
    imports: [
        NgxTiptapModule,

        NgClass,

        MatFormField,
        MatInput,
        MatIconButton,
        MatSuffix,
        MatIcon
    ],
})
export class TiptapGuestLinkContainerComponent extends AngularNodeViewComponent implements OnInit {
    joinUrl = 'https://zoom.us/j/1234567890?pwd=abc123';

    get zoomId(): number {
        return this.node.attrs['zoomId'];
    }

    get isEditable() {
        return this.editor.isEditable;
    }

    get isSelected() {
        return this.selected;
    }

    get alignment() {
        return this.node.attrs['textAlign'];
    }

    zoomMeeting: Webinar | Meeting | null = null;

    constructor(private cd: ChangeDetectorRef, private store: Store) {
        super();
    }

    ngOnInit(): void {
        this.editor.on('transaction', ({ editor, transaction }) => {
            this.updateZoomMeeting();
            this.cd.detectChanges();
        });

        this.editor.on('selectionUpdate', () => {
            this.updateZoomMeeting();
            this.cd.detectChanges();
        });
    }

    updateZoomMeeting() {
        const webinars = this.store.selectSnapshot(ZoomState.getWebinars());
        const meetings = this.store.selectSnapshot(ZoomState.getMeetings());

        this.zoomMeeting = (webinars.find(w => w.id === this.zoomId) || meetings.find(m => m.id === this.zoomId)) || null;
    }
}

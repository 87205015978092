<div [formGroup]="form" class="field" fxLayout="row nowrap" fxLayoutGap="10px">
    <mat-form-field fxFlex appearance="outline" class="time-picker">
        <mat-label>{{ label }}</mat-label>
        <input matInput formControlName="datetime" [placeholder]="placeholder" />
        <mat-hint>{{ hint }}</mat-hint>

        <input type="hidden" [value]="valueAsDate" [matDatepicker]="datePicker" (dateChange)="onDateChange($event)" />

        <mat-datepicker-toggle [disabled]="disabled" matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <button mat-icon-button *ngIf="showTime" matSuffix [disabled]="disabled" (click)="timePicker.open()">
            <mat-icon>av_timer</mat-icon>
        </button>
        <ngx-material-timepicker #timePicker [defaultTime]="valueAsTime" [format]="12" (timeSet)="onTimeChange($event)">
        </ngx-material-timepicker>
        <button
            *ngIf="showTimeZone"
            [disabled]="disabled"
            class="timezone-toggle"
            mat-icon-button
            matSuffix
            [matMenuTriggerFor]="timeZoneMenu"
        >
            <mat-icon [ngClass]="{ 'airplane-mode': DEFAULT_TIME_ZONE !== form.get('timeZone')?.value }">flight</mat-icon>
        </button>

        <mat-error *ngIf="datetime.invalid">
            <ui-error-display [error]="datetime.errors"></ui-error-display>
        </mat-error>
    </mat-form-field>
</div>

<mat-menu class="time-zones" #timeZoneMenu="matMenu">
    <button
        [ngClass]="{ selected: tz.utc[0] === form.get('timeZone')?.value }"
        mat-menu-item
        (click)="onTimeZoneChange(tz)"
        *ngFor="let tz of timeZones"
    >
        {{ tz.text }}
    </button>
</mat-menu>

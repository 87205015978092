import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/util';

@Pipe({
    name: 'expires',
    standalone: true,
})
export class ExpiresPipe implements PipeTransform {
    transform(value: number | Date): string {
        if (isNullOrUndefined(value)) {
            return '';
        }

        let minutes = 0;

        if (value instanceof Number) {
            minutes = value as number;
        } else if (value instanceof Date) {
            const now = new Date();
            minutes = ((value as Date).getTime() - now.getTime()) / 1000 / 60;
        }

        if (minutes < 60) {
            return `${Math.floor(minutes)} minutes remaining`;
        }

        if (minutes < 1440) {
            const hours = Math.floor(minutes / 60);
            minutes = Math.floor(minutes % 60);

            return `${hours} hours ${minutes} minutes remaining`;
        }

        const days = Math.floor(minutes / 60 / 24);

        return `${days} days remaining`;
    }
}

import { Environment } from '@app/shared/models';

export const environment: Environment = {
    appId: 'admin',
    production: true,
    instrumentationKey: '',
    searchIndexQueueName: 'searchindex',

    appUrl: location.origin,

    cdnUrl: 'https://cdn.shareablelink.online',
    rapidApiUrl: 'https://api.shareablelink.online/func-api-auth',
    serverUrl: `https://api.shareablelink.online`,
    proxyServerUrl: `https://api.shareablelink.online`,
    fpEndpointUrl: `https://shareablelink.online`,
    fpjsToken: 'icVKtNvtbIY3JkgZyj6w',

    activityReportInSec: 60,
    inactivityTimeoutInSec: 604800, //7 days
    debug: false,
    autoUpdate: true,
    insightsEnabled: true,

    blobStorageUrl: '',
    privateContainerName: 'production-private',
    publicContainerName: 'production-public',

    sourceStorageSasUri: 'https://storageazureshare.blob.core.windows.net/',
    destinationStorageSasUri: 'https://shareablelink.blob.core.windows.net/',

    buildVersion: '1.0.89',
    buildDate: '2025-01-25T19:21:39+00:00',
    buildCommit: '6c381f082c89e2593f8eab8c91592a0b3cdba9a8'
};

@if (joinUrl) {
  <div [ngStyle]="getStyling()" class="player">
    @if (showJoinUrl) {
      <div class="link-wrapper" matRipple>
        <span class="link">
          <a target="_blank" rel="nofollow" [href]="joinUrl">{{ joinUrl }}</a>
        </span>
      </div>
    }
    @if (password && showJoinUrl) {
      <span matRipple class="password" (click)="onCopyPasswordToClipboard()"
        >Passcode: {{ password }}</span
        >
      }
      @if (showJoinButton) {
        <button mat-raised-button color="primary" (click)="onJoinMeeting()">
          @if (isJoiningMeeting) {
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
            }Join Now<mat-icon
            >video_call</mat-icon
            >
          </button>
        }
        @if (showCopyLinkButton) {
          <button class="nowrap" mat-raised-button (click)="onCopyLinkToClipboard()">
            <mat-icon>content_copy</mat-icon>
            Copy Link
          </button>
        }
        @if (password && showCopyPasswordButton) {
          <button class="nowrap" mat-raised-button (click)="onCopyPasswordToClipboard()">
            <mat-icon>content_copy</mat-icon>
            Copy Passcode
          </button>
        }
      </div>
    }

import { AudioBinary, EncodingJob, MediaAsset } from '@app/data/models';
import { MediaType } from '@app/shared/enums';
import { IAction } from '@app/shared/models';

const ns = '[ManageMedia]';

export class NormalizeWebmAudio {
    static readonly type = `${ns} Normalize Audio Recording`;
    constructor(public file: File) {}
}

export class NormalizeWebmAudioSuccess {
    static readonly type = `${ns} Normalize Audio Recording Success`;
    constructor(public binary: AudioBinary) {}
}

export class NormalizeWebmAudioFailure {
    static readonly type = `${ns} Normalize Audio Recording Failure`;
    constructor(public error: any) {}
}

export class ClearAudioRecording {
    static readonly type = `${ns} Clear Audio Recording`;
}

export class JobError {
    static readonly type = `${ns} Job Error`;
    constructor(public job: any) {}
}

export class LoadMediaAssets {
    static readonly type = `${ns} Load Media Assets`;
    constructor(public rowKeys: string[] = []) {}
}

export class LoadMediaAssetsFailure {
    static readonly type = `${ns} Load Media Assets Failure`;
    constructor(public error: any) {}
}

export class EnsureLoadMediaAssets {
    static readonly type = `${ns} Ensure Load Media Assets`;
}

export class RemoveMediaAsset {
    static readonly type = `${ns} Remove Media Asset`;
    constructor(public mediaAssetIds: string[]) {}
}

export class UpdateMediaAsset {
    static readonly type = `${ns} Update Media Asset`;
    constructor(public asset: MediaAsset) {}
}

export class DeleteMediaAssetConfirmation {
    static readonly type = `${ns} Delete Media Asset Confirmation`;
    constructor(public asset: MediaAsset) {}
}

export class RenameMediaAsset {
    static readonly type = `${ns} Rename Media Asset`;
    constructor(public asset: MediaAsset) {}
}

export class DownloadMediaAsset {
    static readonly type = `${ns} Download Media Asset`;
    constructor(public asset: MediaAsset) {}
}

export class DownloadMediaAssetBinary {
    static readonly type = `${ns} Download Media Asset Binary`;
    constructor(public assetName: string, public asset: MediaAsset) {}
}

export class DownloadMediaAssetBinaryFailure {
    static readonly type = `${ns} Download Media Asset Binary Failure`;
    constructor(public error: any) {}
}

export class DeleteMediaAsset {
    static readonly type = `${ns} Delete Media Asset`;
    constructor(public asset: MediaAsset) {}
}

export class DeleteMediaAssetSuccess {
    static readonly type = `${ns} Delete Media Asset Success`;
}

export class DeleteMediaAssetFailure {
    static readonly type = `${ns} Delete Media Asset Failure`;
    constructor(public error: any) {}
}

export class AddErrors {
    static readonly type = `${ns} Add Errors`;
    constructor(public action: IAction, public error: any) {}
}

export class ClearErrors {
    static readonly type = `${ns} Clear Errors`;
    constructor(public actions?: IAction | IAction[]) {}
}

export class RecordingFailure {
    static readonly type = `${ns} Recording Error`;
    constructor(public error: any) {}
}

export class CreateMediaAsset {
    static readonly type = `${ns} Create Media Asset`;
    constructor(public asset: string, public mediaType: MediaType, public clientId: string) {}
}

export class CreateMediaAssetSuccess {
    static readonly type = `${ns} Create Media Asset Success`;
    constructor(public asset: MediaAsset) {}
}

export class CreateMediaAssetFailure {
    static readonly type = `${ns} Create Media Asset Failure`;
    constructor(public error: any) {}
}

export class CancelAssetEncoding {
    static readonly type = `${ns} Cancel Asset Encoding`;
    constructor(public mediaAssetId: string) {}
}

export class LoadEncodingJobs {
    static readonly type = `${ns} Load Encoding Jobs`;
}

export class LoadEncodingJobsSuccess {
    static readonly type = `${ns} Load Encoding Jobs Success`;
    constructor(public previous: EncodingJob[], public current: EncodingJob[]) {}
}

export class LoadEncodingJobsFailure {
    static readonly type = `${ns} Load Encoding Jobs Failure`;
    constructor(public error: any) {}
}

export class AcknowledgeEncodingJob {
    static readonly type = `${ns} Acknowledge Encoding Job`;
    constructor(public job: EncodingJob) {}
}

export class AcknowledgeEncodingJobFailure {
    static readonly type = `${ns} Acknowledge Encoding Job Failure`;
    constructor(public error: any) {}
}

export class CancelEncodingJob {
    static readonly type = `${ns} Cancel Encoding Job`;
    constructor(public job: EncodingJob) {}
}

export class CancelEncodingJobFailure {
    static readonly type = `${ns} Cancel Encoding Job Failure`;
    constructor(public error: any) {}
}

export class SendToEncodingQueue {
    static readonly type = `${ns} Send To Encoding Queue`;
    constructor(public model: MediaAsset) {}
}

export class SendToEncodingQueueFailure {
    static readonly type = `${ns} Send To Encoding Queue Failure`;
    constructor(public error: any) {}
}

export class AddPlaceHolderJob {
    static readonly type = `${ns} Add Place Holder Job`;
    constructor(public job: EncodingJob) {}
}

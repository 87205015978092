@if (isDownloading) {
  <div>
    <mat-progress-spinner [value]="downloadProgress"></mat-progress-spinner>
    {{ loadedBytes | size }} / {{ totalBytes | size }}
  </div>
}

@if (videoSrc) {
  <video [ngClass]="cssClassName" controls controlsList="nodownload" preload="none">
    <source [src]="videoSrc | safeUrl" />
    Your browser does not support the video tag.
  </video>
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { ErrorDisplayComponent } from '../../../../../ui/src/lib/components/error-display/error-display.component';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './extend-session-dialog.component.html',
    styleUrls: ['./extend-session-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatHint,
        NgIf,
        MatError,
        ErrorDisplayComponent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ],
})
export class ExtendSessionDialogComponent {
    get ctrls() {
        return this.form.controls;
    }

    form = this.fb.group({
        days: [7, [Validators.required, Validators.min(-90), Validators.max(90)]],
    });

    constructor(private fb: FormBuilder) {}
}

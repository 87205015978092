import { Routes } from '@angular/router';

import { ProtectedGuard } from '@app/admin/guards/protected.guard';
import { ChecklistLinksResolver } from '@app/admin/resolvers/checklist-links.resolver';
import { CreateNodeResolver } from '@app/admin/resolvers/create-node.resolver';
import { DefaultResolver } from '@app/admin/resolvers/default.resolver';
import { EditChecklistLinkTemplateResolver } from '@app/admin/resolvers/edit-checklist-link-template.resolver';
import { EditChecklistLinkResolver } from '@app/admin/resolvers/edit-checklist-link.resolver';
import { EditChecklistTemplateResolver } from '@app/admin/resolvers/edit-checklist-template.resolver';
import { EditEventLinkResolver } from '@app/admin/resolvers/edit-event-link.resolver';
import { EditFormLinkTemplateResolver } from '@app/admin/resolvers/edit-form-link-template.resolver';
import { EditFormLinkResolver } from '@app/admin/resolvers/edit-form-link.resolver';
import { EditPreambleTemplateResolver } from '@app/admin/resolvers/edit-preamble-template.resolver';
import { EditShareableLinkTemplateResolver } from '@app/admin/resolvers/edit-shareable-link-template.resolver';
import { EditShareableLinkResolver } from '@app/admin/resolvers/edit-shareable-link.resolver';
import { EventLinksResolver } from '@app/admin/resolvers/event-links.resolver';
import { FormLinksResolver } from '@app/admin/resolvers/form-links.resolver';
import { HomeResolver } from '@app/admin/resolvers/home.resolver';
import { LinkRequestsResolver } from '@app/admin/resolvers/link-requests.resolver';
import { LoginResolver } from '@app/admin/resolvers/login.resolver';
import { PreambleTemplatesResolver } from '@app/admin/resolvers/preamble-templates.resolver';
import { RootResolver } from '@app/admin/resolvers/root.resolver';
import { ShareableLinksResolver } from '@app/admin/resolvers/shareable-links.resolver';
import { ViewArchivedChecklistLinkResolver } from '@app/admin/resolvers/view-archived-checklist-link.resolver';
import { ViewArchivedEventLinkResolver } from '@app/admin/resolvers/view-archived-event-link.resolver';
import { ViewArchivedFormLinkResolver } from '@app/admin/resolvers/view-archived-form-link.resolver';
import { ViewArchivedLinkResolver } from '@app/admin/resolvers/view-archived-link.resolver';
import { ViewNodeResolver } from '@app/admin/resolvers/view-node.resolver';
import { FormLinkType, FormSectionType } from '@app/shared/enums';

const formLinksProfile = [
    FormSectionType.Page,
    FormSectionType.Text,
    FormSectionType.Question,
    FormSectionType.File,
    FormSectionType.Media,
];

const bookingLinksProfile = [
    FormSectionType.Page,
    FormSectionType.Text,
    FormSectionType.Question,
    FormSectionType.File,
    FormSectionType.Booking,
];

const eventLinksProfile = [
    FormSectionType.Page,
    FormSectionType.Text,
    FormSectionType.Question,
    FormSectionType.File,
    FormSectionType.Event,
    FormSectionType.MeetingRegistration,
    FormSectionType.WebinarRegistration,
];

export const ROUTES: Routes = [
    {
        path: '',
        canActivate: [ProtectedGuard],
        data: {
            title: '',
        },
        loadChildren: () => {
            return [
                {
                    path: 'home',
                    loadComponent: () =>
                        import('../../../../libs/admin/src/lib/containers/home-container/home-container.component').then(
                            m => m.HomeContainerComponent,
                        ),
                    resolve: {
                        data: RootResolver,
                    },
                    loadChildren: () => {
                        return [
                            {
                                path: '',
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/node-list-container/node-list-container.component'
                                    ).then(m => m.NodeListContainerComponent),
                                data: {
                                    title: 'Home',
                                },
                                resolve: {
                                    data: HomeResolver,
                                },
                            },
                            {
                                path: 'profile',
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/profile-container/profile-container.component'
                                    ).then(m => m.ProfileContainerComponent),
                                data: {
                                    title: 'Profile',
                                },
                                resolve: {
                                    data: DefaultResolver,
                                },
                            },
                            {
                                path: 'link-requests',
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/link-request-container/link-request-container.component'
                                    ).then(m => m.LinkRequestContainerComponent),
                                data: {
                                    title: 'Link Requests',
                                },
                                resolve: {
                                    data: LinkRequestsResolver,
                                },
                            },
                            {
                                path: 'search',
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/search-links-container/search-links-container.component'
                                    ).then(m => m.SearchLinksContainerComponent),
                                data: {
                                    title: 'Search',
                                },
                            },
                            {
                                path: 'shareable-links',
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/shareable-links-list-container/shareable-links-list-container.component'
                                    ).then(m => m.ShareableLinksListContainerComponent),
                                data: {
                                    title: 'Shareable Links',
                                },
                                resolve: {
                                    data: ShareableLinksResolver,
                                },
                            },
                            {
                                path: 'shareable-links/:linkId',
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-shareable-link-container/edit-shareable-link-container.component'
                                    ).then(m => m.EditShareableLinkContainerComponent),
                                data: {
                                    title: 'Edit Shareable Link',
                                },
                                resolve: {
                                    data: EditShareableLinkResolver,
                                },
                            },
                            {
                                path: 'shareable-links/archived/:linkId',
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/view-archived-link-container/view-archived-link-container.component'
                                    ).then(m => m.ViewArchivedLinkContainerComponent),
                                data: {
                                    title: 'View Archived Link',
                                },
                                resolve: {
                                    data: ViewArchivedLinkResolver,
                                },
                            },
                            {
                                path: 'shareable-links/template/:linkId',
                                // component: EditShareableLinkTemplateContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-shareable-link-template-container/edit-shareable-link-template-container.component'
                                    ).then(m => m.EditShareableLinkTemplateContainerComponent),
                                data: {
                                    title: 'Edit Shareable Link Template',
                                },
                                resolve: {
                                    data: EditShareableLinkTemplateResolver,
                                },
                            },
                            {
                                path: 'form-links',
                                // component: FormLinksListContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/form-links-list-container/form-links-list-container.component'
                                    ).then(m => m.FormLinksListContainerComponent),
                                data: {
                                    title: 'Form Links',
                                    isPollLink: false,
                                    linkType: FormLinkType.Form,
                                },
                                resolve: {
                                    data: FormLinksResolver,
                                },
                            },
                            {
                                path: 'form-links/:linkId',
                                // component: EditFormLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-form-link-container/edit-form-link-container.component'
                                    ).then(m => m.EditFormLinkContainerComponent),
                                data: {
                                    title: 'Edit Form Link',
                                    linkType: FormLinkType.Form,
                                    formProfile: formLinksProfile,
                                },
                                resolve: {
                                    data: EditFormLinkResolver,
                                },
                            },
                            {
                                path: 'form-links/template/:linkId',
                                // component: EditFormLinkTemplateContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-form-link-template-container/edit-form-link-template-container.component'
                                    ).then(m => m.EditFormLinkTemplateContainerComponent),
                                data: {
                                    title: 'Edit Form Link Template',
                                    linkType: FormLinkType.Form,
                                    formProfile: formLinksProfile,
                                },
                                resolve: {
                                    data: EditFormLinkTemplateResolver,
                                },
                            },
                            {
                                path: 'form-links/archived/:linkId',
                                // component: ViewArchivedFormLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/view-archived-form-link-container/view-archived-form-link-container.component'
                                    ).then(m => m.ViewArchivedFormLinkContainerComponent),
                                data: {
                                    title: 'View Archived Form Link',
                                    linkType: FormLinkType.Form,
                                },
                                resolve: {
                                    data: ViewArchivedFormLinkResolver,
                                },
                            },
                            {
                                path: 'poll-links',
                                // component: FormLinksListContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/form-links-list-container/form-links-list-container.component'
                                    ).then(m => m.FormLinksListContainerComponent),
                                data: {
                                    title: 'Poll Links',
                                    linkType: FormLinkType.Poll,
                                },
                                resolve: {
                                    data: FormLinksResolver,
                                },
                            },
                            {
                                path: 'poll-links/:linkId',
                                // component: EditFormLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-form-link-container/edit-form-link-container.component'
                                    ).then(m => m.EditFormLinkContainerComponent),
                                data: {
                                    title: 'Edit Poll Link',
                                    linkType: FormLinkType.Poll,
                                    formProfile: formLinksProfile,
                                },
                                resolve: {
                                    data: EditFormLinkResolver,
                                },
                            },
                            {
                                path: 'poll-links/archived/:linkId',
                                // component: ViewArchivedFormLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/view-archived-form-link-container/view-archived-form-link-container.component'
                                    ).then(m => m.ViewArchivedFormLinkContainerComponent),
                                data: {
                                    title: 'View Archived Poll Link',
                                    linkType: FormLinkType.Poll,
                                },
                                resolve: {
                                    data: ViewArchivedFormLinkResolver,
                                },
                            },
                            {
                                path: 'booking-links',
                                // component: FormLinksListContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/form-links-list-container/form-links-list-container.component'
                                    ).then(m => m.FormLinksListContainerComponent),
                                data: {
                                    title: 'Booking Links',
                                    linkType: FormLinkType.Booking,
                                },
                                resolve: {
                                    data: FormLinksResolver,
                                },
                            },
                            {
                                path: 'booking-links/:linkId',
                                // component: EditFormLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-form-link-container/edit-form-link-container.component'
                                    ).then(m => m.EditFormLinkContainerComponent),
                                data: {
                                    title: 'Edit Booking Link',
                                    linkType: FormLinkType.Booking,
                                    formProfile: bookingLinksProfile,
                                },
                                resolve: {
                                    data: EditFormLinkResolver,
                                },
                            },
                            {
                                path: 'booking-links/archived/:linkId',
                                // component: ViewArchivedFormLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/view-archived-form-link-container/view-archived-form-link-container.component'
                                    ).then(m => m.ViewArchivedFormLinkContainerComponent),
                                data: {
                                    title: 'View Archived Booking Link',
                                    linkType: FormLinkType.Booking,
                                },
                                resolve: {
                                    data: ViewArchivedFormLinkResolver,
                                },
                            },
                            {
                                path: 'event-links',
                                // component: EventLinksListContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/event-links-list-container/event-links-list-container.component'
                                    ).then(m => m.EventLinksListContainerComponent),
                                data: {
                                    title: 'Links',
                                },
                                resolve: {
                                    data: EventLinksResolver,
                                },
                            },
                            {
                                path: 'event-links/:linkId',
                                // component: EditEventLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-event-link-container/edit-event-link-container.component'
                                    ).then(m => m.EditEventLinkContainerComponent),
                                data: {
                                    title: 'Events',
                                    formProfile: eventLinksProfile,
                                },
                                resolve: {
                                    data: EditEventLinkResolver,
                                },
                            },
                            {
                                path: 'event-links/archived/:linkId',
                                // component: ViewArchivedEventLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/view-archived-event-link-container/view-archived-event-link-container.component'
                                    ).then(m => m.ViewArchivedEventLinkContainerComponent),
                                data: {
                                    title: 'View Archived Forms',
                                },
                                resolve: {
                                    data: ViewArchivedEventLinkResolver,
                                },
                            },
                            {
                                path: 'checklist-links',
                                // component: ChecklistListContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/checklist-list-container/checklist-list-container.component'
                                    ).then(m => m.ChecklistListContainerComponent),
                                data: {
                                    title: 'Checklist Links',
                                },
                                resolve: {
                                    data: ChecklistLinksResolver,
                                },
                            },
                            {
                                path: 'checklist-links/:linkId',
                                // component: EditChecklistLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-checklist-link-container/edit-checklist-link-container.component'
                                    ).then(m => m.EditChecklistLinkContainerComponent),
                                data: {
                                    title: 'Edit Checklist',
                                },
                                resolve: {
                                    data: EditChecklistLinkResolver,
                                },
                            },
                            {
                                path: 'checklist-links/template/:linkId',
                                // component: EditChecklistLinkTemplateContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-checklist-link-template-container/edit-checklist-link-template-container.component'
                                    ).then(m => m.EditChecklistLinkTemplateContainerComponent),
                                data: {
                                    title: 'Edit Checklist Template',
                                },
                                resolve: {
                                    data: EditChecklistLinkTemplateResolver,
                                },
                            },
                            {
                                path: 'checklist-links/archived/:linkId',
                                // component: ViewArchivedChecklistLinkContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/view-archived-checklist-link-container/view-archived-checklist-link-container.component'
                                    ).then(m => m.ViewArchivedChecklistLinkContainerComponent),
                                data: {
                                    title: 'View Archived Checklists',
                                },
                                resolve: {
                                    data: ViewArchivedChecklistLinkResolver,
                                },
                            },
                            {
                                path: 'checklist/template/:linkId',
                                // component: EditChecklistTemplateContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-checklist-template-container/edit-checklist-template-container.component'
                                    ).then(m => m.EditChecklistTemplateContainerComponent),
                                data: {
                                    title: 'Edit Checklist Template',
                                },
                                resolve: {
                                    data: EditChecklistTemplateResolver,
                                },
                            },
                            {
                                path: 'preamble-templates',
                                // component: PreambleListContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/preamble-list-container/preamble-list-container.component'
                                    ).then(m => m.PreambleListContainerComponent),
                                data: {
                                    title: 'Preamble Templates',
                                },
                                resolve: {
                                    data: PreambleTemplatesResolver,
                                },
                            },
                            {
                                path: 'preamble-templates/:linkId',
                                // component: EditPreambleTemplateContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/edit-preamble-template-container/edit-preamble-template-container.component'
                                    ).then(m => m.EditPreambleTemplateContainerComponent),
                                data: {
                                    title: 'Edit Preamble Template',
                                },
                                resolve: {
                                    data: EditPreambleTemplateResolver,
                                },
                            },
                            {
                                path: 'add/:nodeType',
                                // component: CreateNodeContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/create-node-container/create-node-container.component'
                                    ).then(m => m.CreateNodeContainerComponent),
                                resolve: {
                                    title: CreateNodeResolver,
                                },
                            },
                            {
                                path: ':nodeId/edit',
                                // component: ViewNodeContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/view-node-container/view-node-container.component'
                                    ).then(m => m.ViewNodeContainerComponent),
                                resolve: {
                                    title: ViewNodeResolver,
                                },
                            },
                            {
                                path: ':nodeId',
                                // component: ViewNodeContainerComponent,
                                loadComponent: () =>
                                    import(
                                        '../../../../libs/admin/src/lib/containers/view-node-container/view-node-container.component'
                                    ).then(m => m.ViewNodeContainerComponent),
                                resolve: {
                                    title: ViewNodeResolver,
                                },
                            },
                        ];
                    },
                },
            ];
        },
    },
    {
        path: 'login/:organization-url',
        loadComponent: () =>
            import('../../../../libs/admin/src/lib/containers/login-container/login-container.component').then(
                m => m.LoginContainerComponent,
            ),
        canActivate: [LoginResolver],
        data: {
            title: 'Login',
        },
    },
    {
        path: 'calendly',
        loadComponent: () => import('./calendly.component').then(m => m.CalendlyComponent),
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];
// @NgModule({
//     imports: [RouterModule.forRoot(ROUTES)],
//     exports: [RouterModule],
// })
// export class AppRoutingModule {}

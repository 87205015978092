<section class="container ngx-toastr toast-info" [ngClass]="{ 'toast-info': !isError, 'toast-error': isError }">
    <div class="title" [attr.title]="title"><mat-icon>movie</mat-icon>{{ title }}</div>
    <div class="message" [attr.title]="message">{{ message }}</div>
    <section class="message" *ngIf="state && !isCompleted">
        <div *ngFor="let video of state.videos">
            {{ video.meta.width }}x{{ video.meta.height }}
            {{ video.status }}
            {{ video.percent / 100 | percent }}
        </div>
    </section>
    <mat-progress-bar
        *ngIf="!isCancelling && progress > 0"
        mode="determinate"
        color="accent"
        [value]="progress"
    ></mat-progress-bar>
    <div class="actions">
        <!-- <button *ngIf="!isCompleted && !isError" [disabled]="!isCancelEnabled" mat-flat-button color="warn" (click)="onCancel()">Cancel</button> -->
        <button mat-flat-button *ngIf="isError" (click)="onCopyErrorMessage()">Copy</button>
        <button mat-flat-button [color]="isError ? '' : 'primary'" (click)="onCompleted()">Dismiss</button>
    </div>
</section>

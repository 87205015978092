import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    ActiveLinkSummary,
    DeleteResult,
    GsVideoSummary,
    LinkSessionSummary,
    PaymentModel,
    QueryResult,
    SaveLinkModel,
    SearchOptionsModel,
    ShareableLinkModel,
} from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { LinkType } from '@app/shared/enums';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable({
    providedIn: 'root',
})
export class ManageShareableLinksService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    getAllLinks(): Observable<ShareableLinkModel[]> {
        return this.http
            .get<ServerResult<ShareableLinkModel[]>>(`${this.env.serverUrl}/manage/links`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getArchivedLink(rowkey: string): Observable<ShareableLinkModel> {
        return this.http
            .post<ServerResult<ShareableLinkModel>>(`${this.env.serverUrl}/manage/links/archived`, { rowkey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getLinkSessions(rowKey: string): Observable<LinkSessionSummary[]> {
        return this.http
            .post<ServerResult<LinkSessionSummary[]>>(`${this.env.serverUrl}/manage/links/sessions`, {
                rowKey,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    extendSessions(rowKeys: string[], days: number): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/extend`, {
                rowKeys,
                days,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getGuidedSequenceSummariesForLink(rowKey: string): Observable<GsVideoSummary[]> {
        return this.http
            .post<ServerResult<GsVideoSummary[]>>(`${this.env.serverUrl}/manage/links/guided-sequence-summary`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveLink(model: SaveLinkModel): Observable<ShareableLinkModel> {
        return this.http
            .post<ServerResult<ShareableLinkModel>>(`${this.env.serverUrl}/manage/links/save`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    archiveLinks(links: ShareableLinkModel[]): Observable<DeleteResult> {
        return this.http
            .post<ServerResult<DeleteResult>>(
                `${this.env.serverUrl}/manage/links/archive`,
                links.map(m => m.rowKey),
            )
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteLinks(links: ShareableLinkModel[]): Observable<DeleteResult> {
        return this.http
            .request<ServerResult<DeleteResult>>('DELETE', `${this.env.serverUrl}/manage/links/content`, {
                body: links.map(m => m.rowKey),
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    activateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/activate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    syncActiveSessions(rowKey: string): Observable<ShareableLinkModel> {
        return this.http
            .post<ServerResult<ShareableLinkModel>>(`${this.env.serverUrl}/manage/sessions/sync`, {
                rowKeys: [rowKey],
                linkType: LinkType.Shareable,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    updateActiveSessionCount(rowKey: string): Observable<ShareableLinkModel> {
        return this.http
            .post<ServerResult<ShareableLinkModel>>(`${this.env.serverUrl}/manage/links/sessions/update-count`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deactivateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/deactivate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    approveSession(rowKey: string, formSubmissionRowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/approve`, {
                rowKey,
                formSubmissionRowKeys,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getLinksSummary(): Observable<ActiveLinkSummary> {
        return this.http
            .get<ServerResult<ActiveLinkSummary>>(`${this.env.serverUrl}/manage/links/summary`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteSession(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/delete`, rowKeys)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    mergeSessions(linkId: string, primary: string, rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/merge`, { rowKeys, primary, linkId })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    searchArchivedLinks(model: SearchOptionsModel): Observable<QueryResult<ShareableLinkModel>> {
        return this.http
            .post<ServerResult<QueryResult<ShareableLinkModel>>>(`${this.env.serverUrl}/manage/links/search-archived`, model)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getTags(): Observable<string[]> {
        return this.http
            .get<ServerResult<string[]>>(`${this.env.serverUrl}/manage/links/tags`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteTemplates(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/template/delete`, rowKeys)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    extendLinkExpirationDate(rowKeys: string[], days: number): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/extend`, {
                rowKeys,
                days,
                linkType: LinkType.Shareable,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinkPayments(rowKey: string): Observable<PaymentModel[]> {
        return this.http
            .post<ServerResult<PaymentModel[]>>(`${this.env.serverUrl}/manage/link/payments`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}

import { UpdatePasswordModel } from '@app/data/models';

export class SaveProfile {
    static readonly type = '[Profile] Save Profile';
}
export class SaveProfileSuccess {
    static readonly type = '[Profile] Save Profile Success';
}
export class SaveProfileFailure {
    static readonly type = '[Profile] Save Profile Failure';
    constructor(public error: any) {}
}
export class UpdatePassword {
    static readonly type = '[Profile] Save Password';
    constructor(public model: UpdatePasswordModel) {}
}
export class UpdatePasswordSuccess {
    static readonly type = '[Profile] Save Password Success';
}
export class UpdatePasswordFailure {
    static readonly type = '[Profile] Save Password Failure';
    constructor(public error: any) {}
}

<h1 mat-dialog-title>Confirm delete content</h1>
<mat-dialog-content [formGroup]="form">
    <p>
        Are you certain you want to delete <i>{{ name }}</i
        >?
    </p>
    <mat-checkbox class="example-margin" *ngIf="isFolder" formControlName="includeChildren">
        Include sub-folders/files
    </mat-checkbox>
    <mat-checkbox class="example-margin" *ngIf="isMediaAsset" formControlName="includeMediaAsset">
        Permanently delete the associated {{ data.type === NodeType.Video ? 'video' : 'audio' }} file
    </mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="warn" [disabled]="form.invalid" [mat-dialog-close]="form.value">Delete</button>
</mat-dialog-actions>

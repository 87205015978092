@if(!isEnumerable() && hasError()){
<span class="message" [ngClass]="{ 'is-critical': error()?.isCritical, icons: showIcons }">
    {{ message() }}
</span>
} @else { @for (e of getMessage(error()); track $index) {
<div class="message" [ngClass]="{ 'is-critical': error()?.isCritical, icons: showIcons }">
    {{ e }}
</div>
} } @if(isValidationError()){
<ul>
    @for (error of validationResults(); track $index) {
    <li>
        {{ error }}
    </li>
    }
</ul>
}

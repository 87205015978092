import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseNode } from '@app/data/models';

import { NodeType } from '@app/shared/enums';


@Component({
    selector: 'ui-breadcrumb',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./breadcrumb.component.html`,
    styleUrls: [`./breadcrumb.component.scss`],
    standalone: true,
    imports: [],
})
export class BreadcrumbComponent implements OnChanges {
    breadcrumb: BaseNode[] = [];

    @Input() path: string | null = null;
    @Input() nodes: BaseNode[] | null = [];

    @Output() readonly selectNode: EventEmitter<BaseNode | null> = new EventEmitter();

    buildBreadcrumb() {
        if (!this.nodes || this.nodes.length === 0) {
            return;
        }

        const nodes = this.nodes?.slice(); //clone the array
        const nodeId = this.path?.split('.').pop();
        let node: BaseNode | null | undefined = nodes.find(n => n.rowKey === nodeId);

        this.breadcrumb = nodes
            .reduce(
                (acc: BaseNode[], curr: BaseNode, ix: number, parent: BaseNode[]) => {
                    if (node && node.path) {
                        node = parent.find(n => n.rowKey === node?.parentId);
                        if (node) {
                            acc.push(node);
                        }
                    }
                    return acc;
                },
                node && node.type === NodeType.Node ? [node] : [],
            )
            .reverse();
    }

    onSelect(node: BaseNode | null) {
        this.selectNode.emit(node);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['path']) {
            this.buildBreadcrumb();
        }
    }
}

import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnChanges } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle, MatCardTitleGroup } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
} from '@angular/material/table';

import { GuestRegistration, LinkModel, LinkSessionSummary, RegistrantInfo } from '@app/data/models';

import { ElapsedTimePipe } from '../../../../../shared/src/lib/pipes/elapsed-time.pipe';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';

interface RowViewModel {
    name: string;
    isGuest: boolean;
    hasGuests: boolean;
    registrantsInfo: RegistrantInfo[];
}

@Component({
    selector: 'ui-form-submissions-export',
    templateUrl: './form-submissions-export.component.html',
    styleUrls: ['./form-submissions-export.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CdkOverlayOrigin,

        NgClass,

        MatCard,
        MatCardHeader,
        MatCardTitleGroup,
        MatCardTitle,
        MatCardContent,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        MatIcon,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,

        ElapsedTimePipe,
        FormatDatePipe,
    ],
})
export class FormSubmissionsExportComponent implements OnChanges {
    readonly link = input<LinkModel | null>(null);
    readonly category = input<string | null>(null);
    readonly sessions = input<LinkSessionSummary[] | null>(null);

    dataSource: RowViewModel[] = [];
    displayedColumns = ['icon', 'joinTime', 'leaveTime', 'durationInSeconds'];

    ngOnChanges(): void {
        const sessions = this.sessions() || [];
        const data = sessions.sort((a, b) => a.name.localeCompare(b.name));
        this.dataSource = data.reduce((acc, current: any) => {
            acc.push({
                name: current.name,
                registrantsInfo: current.registrantsInfo || [],
                isGuest: false,
                hasGuests: (current.guestRegistrations || []).length > 0
            });

            if (current.guestRegistrations) {
                current.guestRegistrations.forEach((guest: GuestRegistration) => {
                    acc.push({
                        name: `${guest.firstName} ${guest.lastName}`,
                        registrantsInfo: guest.registrantsInfo,
                        isGuest: true,
                        hasGuests: false
                    } as RowViewModel);
                });
            }

            return acc;
        }, [] as RowViewModel[]);
    }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { catchError, finalize, mergeMap, take, tap } from 'rxjs/operators';

import { CoreActions, NotificationActions } from '@app/data/actions';
import {
    EditShareableLinkFormModel,
    FormState,
    LinkSessionSummary,
    SearchOptionsModel,
    ShareableLinkModel,
} from '@app/data/models';
import { RouterState } from '@app/data/state/router.state';
import { LinkExpiration, LinkInactivityMode, LinkType, TimerUnit, VideoOption } from '@app/shared/enums';
import { isErrorModel } from '@app/shared/models';
import { isDate, isDst, isString, parseTimeZoneFromOffset, toDateTimeString } from '@app/shared/util';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Action, Actions, createSelector, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';
import { Timezone } from 'timezones.json';

import { ManageRequestActions } from '../actions';
import { DeleteLinkSessionsConfirmationDialogComponent } from '../dialogs/delete-link-session-confirmation/delete-link-session-confirmation-dialog.component';
import { ManageLinkRequestsService } from '../services/manage-link-requests.service';
import { MergeLinkSessionsConfirmationDialogComponent } from '../dialogs/merge-link-sessions-confirmation/merge-link-sessions-confirmation-dialog.component';

export interface StateModel {
    sessions: LinkSessionSummary[];
    isSearching: boolean;
    isLoading: boolean;
    isSaving: boolean;
    errors: any;
    model: ShareableLinkModel | null;
    form: FormState<EditShareableLinkFormModel>;
    questions: FormState<Record<string, any>>;
    searchForm: FormState<SearchOptionsModel>;
}

@State<StateModel>({
    name: 'manageRequests',
    defaults: {
        model: null,
        sessions: [],
        isSearching: false,
        isLoading: false,
        isSaving: false,
        errors: null,
        form: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        questions: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        searchForm: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class ManageRequestsState {
    readonly format: string = 'MMM DD YYYY @ hh:mmA';

    static isSearching() {
        return createSelector([ManageRequestsState], (state: StateModel) => state.isSearching);
    }

    static getErrors() {
        return createSelector([ManageRequestsState], (state: StateModel) => state.errors);
    }

    static getSessions() {
        return createSelector([ManageRequestsState], (state: StateModel) => state.sessions);
    }

    static getLinkModel() {
        return createSelector([ManageRequestsState], (state: StateModel) => state.model);
    }

    static getForm() {
        return createSelector([ManageRequestsState], (state: StateModel) => state.form);
    }

    static getQuestionsForm() {
        return createSelector([ManageRequestsState], (state: StateModel) => state.questions);
    }

    static isValid() {
        return createSelector([ManageRequestsState], (state: StateModel) => state.form.status === 'VALID');
    }

    static isSaving() {
        return createSelector([ManageRequestsState], (state: StateModel) => state.isSaving);
    }

    constructor(
        private store: Store,
        private actions: Actions,
        private matDialog: MatDialog,
        private manageRequestsService: ManageLinkRequestsService,
    ) {}

    @Action(ManageRequestActions.Save)
    save({ patchState, dispatch }: StateContext<StateModel>) {
        patchState({
            isSaving: true,
            errors: null,
        });

        const model = this.store.selectSnapshot(ManageRequestsState.getForm()).model as EditShareableLinkFormModel;

        // const questions = [];
        // const questions = (model as any).hasRegistration
        //     ? this.store.selectSnapshot(ManageRequestsState.getQuestionsForm()).model?.questions
        //     : [];

        return this.manageRequestsService
            .saveLink({
                ...model,
                linkExpiration: LinkExpiration.None,
                startDate: null,
                endDate: null,
                timer: null,
                timerEndDate: null,
                timerUnit: TimerUnit.Minutes,
                linkInactivityMode: LinkInactivityMode.Default,

                questions: [],
                registrationEndDate: null,
                timeZone: 'America/Edmonton',
                videoSettings: {
                    elapsedTimeInSeconds: 0,
                    isLiveStream: false,
                    startTime: VideoOption.None,
                    autoplay: false,
                    showWatermark: true,
                    showBackgroundWatermark: true,
                    enableWaitingRoom: false,
                    waitingRoomOffsetInSeconds: 0,
                    showControls: false,
                    showPlayButton: false,
                    showFullscreenButton: false,
                    showProgress: false,
                    allowScrubbing: false,
                    showPlaybackSpeed: false,
                    resumePlayback: false,
                    allowListeners: false,
                    countDownAssetId: null,
                },
            })
            .pipe(
                mergeMap(link => {
                    patchState({
                        model: link,
                    });

                    return dispatch([
                        new ManageRequestActions.SearchLinkSessions(),
                        new NotificationActions.Success('Content link has been successfully saved'),
                    ]);
                }),
                catchError(err => dispatch(new ManageRequestActions.SaveFailure(err))),
                finalize(() =>
                    patchState({
                        isSaving: false,
                    }),
                ),
            );
    }

    @Action(CoreActions.Refresh)
    autoRefresh({ dispatch }: StateContext<StateModel>): void {
        const route = this.store.selectSnapshot(RouterState.selectCurrentRoute());
        if (!route) return;

        const url = route.url?.map(s => s.path).join('/') || '';

        if (url.includes('link-requests')) {
            dispatch([new ManageRequestActions.UpdateLinkApprovalCount()]);
        }
    }

    @Action(ManageRequestActions.UpdateLinkApprovalCount)
    updateLinkApprovalCount({ patchState, dispatch }: StateContext<StateModel>) {
        return this.manageRequestsService.getLinkRequestModel().pipe(
            tap(model => {
                const currentModel = this.store.selectSnapshot(ManageRequestsState.getLinkModel()) as ShareableLinkModel;
                patchState({
                    model: {
                        ...currentModel,
                        approvalCount: model.approvalCount,
                    },
                });
                dispatch(new ManageRequestActions.UpdateLinkApprovalCountSuccess());
            }),
            catchError(err => this.store.dispatch(new ManageRequestActions.UpdateLinkApprovalCountFailure(err))),
        );
    }

    @Action(UpdateFormValue)
    updateFormValue(ctx: StateContext<StateModel>, action: UpdateFormValue): void {
        if (action.payload.path === 'manageRequests.filters') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }
    }

    @Action(ManageRequestActions.EnsureLoadLinks)
    ensureLoad(
        { getState, dispatch }: StateContext<StateModel>,
        action: ManageRequestActions.EnsureLoadLinks,
    ): Observable<any> | void {
        const { sessions, model } = getState();

        if (!model || action.force) {
            return dispatch(new ManageRequestActions.LoadLinkModel()).pipe(
                mergeMap(() => dispatch(new ManageRequestActions.SearchLinkSessions())),
            );
        }

        if (!sessions || sessions.length === 0) {
            return dispatch(new ManageRequestActions.SearchLinkSessions());
        }
    }

    @Action(ManageRequestActions.LoadLinkModel)
    loadLinkModel({ getState, patchState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { model } = getState();

        patchState({ isLoading: true });

        return this.manageRequestsService.getLinkRequestModel().pipe(
            tap(model => {
                patchState({
                    model,
                });
                dispatch(new ManageRequestActions.LoadLinkModelSuccess());
            }),
            catchError(err => this.store.dispatch(new ManageRequestActions.LoadLinkModelFailure(err))),
            finalize(() => {
                patchState({ isLoading: false });
            }),
        );
    }

    @Action(ManageRequestActions.SearchLinkSessions)
    searchLinkSessions({ getState, patchState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { isSearching, searchForm, model } = getState();
        const { rowKey } = model as ShareableLinkModel;

        if (!searchForm) {
            return;
        }

        if (isSearching) {
            return this.actions.pipe(ofActionDispatched(ManageRequestActions.SearchLinkSessionsComplete), take(1));
        }

        patchState({ isSearching: true });

        // const { model: searchModel } = searchForm;

        return (
            this.manageRequestsService
                // .searchLinkSessions(model.rowKey, searchModel || { filter: '', limit: 25, offset: 0, tags: [], total: null })
                .getAllContentLinkSessions(rowKey as string)
                .pipe(
                    tap(sessions => {
                        patchState({
                            sessions,
                        });
                        dispatch(new ManageRequestActions.SearchLinkSessionsSuccess());
                    }),
                    catchError(err => this.store.dispatch(new ManageRequestActions.SearchLinkSessionsFailure(err))),
                    finalize(() => {
                        patchState({ isSearching: false });
                        dispatch(new ManageRequestActions.SearchLinkSessionsComplete());
                    }),
                )
        );
    }

    @Action(ManageRequestActions.ConfirmDeleteSession)
    confirmDeleteSession(ctx: StateContext<StateModel>, action: ManageRequestActions.ConfirmDeleteSession) {
        return this.matDialog
            .open(DeleteLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: action.rowKeys,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageRequestActions.DeleteSession(action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageRequestActions.DeleteSession)
    deleteSession(ctx: StateContext<StateModel>, action: ManageRequestActions.DeleteSession) {
        ctx.patchState({
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageRequestsState.getLinkModel()) as ShareableLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageRequestsService.deleteSession(action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageRequestActions.SearchLinkSessions(),
                    new ManageRequestActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Deleted Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageRequestActions.DeleteSessionFailure(err))),
        );
    }

    @Action(ManageRequestActions.ConfirmMergeSession)
    confirmMergeSessions(ctx: StateContext<StateModel>, action: ManageRequestActions.ConfirmMergeSession) {
        const allSessions = this.store.selectSnapshot(ManageRequestsState.getSessions());
        const sessions: LinkSessionSummary[] = [];

        action.rowKeys.forEach(rowKey => {
            const session = allSessions.find(s => s.rowKey === rowKey);
            if (session) {
                sessions.push(session);
            }
        });

        return this.matDialog
            .open(MergeLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    linkType: LinkType.Shareable,
                    sessions,
                },
            })
            .afterClosed()
            .pipe(
                tap((primary: LinkSessionSummary) => {
                    if (primary) {
                        this.store.dispatch(new ManageRequestActions.MergeSessions(primary.rowKey, action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageRequestActions.MergeSessions)
    mergeSessions(ctx: StateContext<StateModel>, action: ManageRequestActions.MergeSessions) {
        ctx.patchState({
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageRequestsState.getLinkModel()) as ShareableLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageRequestsService.mergeSessions(rowKey, action.primaryRowKey, action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageRequestActions.SearchLinkSessions(),
                    new ManageRequestActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submissions Merged Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageRequestActions.MergeSessionsFailure(err))),
        );
    }

    @Action(ManageRequestActions.ApproveSessions)
    approveSessions(
        { getState, patchState, dispatch }: StateContext<StateModel>,
        action: ManageRequestActions.ApproveSessions,
    ) {
        const { model } = getState();
        const { rowKey } = model as ShareableLinkModel;

        patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageRequestsService.approveSession(rowKey as string, action.rowKeys).pipe(
            mergeMap(() => {
                const actions = [
                    new ManageRequestActions.SearchLinkSessions(),
                    new ManageRequestActions.UpdateSessionCount(rowKey as string),
                    new NotificationActions.Success('Submission(s) Approved'),
                ];

                return dispatch(actions);
            }),
            catchError(err => dispatch(new ManageRequestActions.ApproveSessionsFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageRequestActions.UpdateSessionCount)
    updateSessionCount(ctx: StateContext<StateModel>, action: ManageRequestActions.UpdateSessionCount) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageRequestsService.updateActiveSessionCount(action.rowKey).pipe(
            tap(result => {
                const link = this.store.selectSnapshot(ManageRequestsState.getLinkModel()) as ShareableLinkModel;

                ctx.patchState({
                    model: {
                        ...link,
                        lateRegistrationCount: result.lateRegistrationCount,
                        activeSubmissionCount: result.activeSubmissionCount,
                        approvalCount: result.approvalCount,
                    },
                });
            }),
            catchError(err => this.store.dispatch(new ManageRequestActions.UpdateSessionCountFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageRequestActions.ActivateSession)
    activateSession(ctx: StateContext<StateModel>, action: ManageRequestActions.ActivateSession) {
        ctx.patchState({
            errors: null,
        });

        return this.manageRequestsService.activateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageRequestActions.SearchLinkSessions(),
                    new ManageRequestActions.UpdateSessionCount('linkrequest'),
                    new NotificationActions.Success('Submission Activated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageRequestActions.ActivateSessionFailure(err))),
        );
    }

    @Action(ManageRequestActions.DeactivateSession)
    deactivateSession(ctx: StateContext<StateModel>, action: ManageRequestActions.DeactivateSession) {
        ctx.patchState({
            errors: null,
        });

        return this.manageRequestsService.deactivateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageRequestActions.SearchLinkSessions(),
                    new ManageRequestActions.UpdateSessionCount('linkrequest'),
                    new NotificationActions.Success('Submission Deactivated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageRequestActions.DeactivateSessionFailure(err))),
        );
    }

    @Action([
        ManageRequestActions.ActivateSessionFailure,
        ManageRequestActions.SearchLinkSessionsFailure,
        ManageRequestActions.DeleteSessionFailure,
        ManageRequestActions.MergeSessionsFailure,
        ManageRequestActions.DeactivateSessionFailure,
        ManageRequestActions.SaveFailure,
    ])
    handleFailures(ctx: StateContext<StateModel>, action: ManageRequestsFailureTypes): Observable<any> | void {
        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof ManageRequestActions.SearchLinkSessionsFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Loading Links', action.error));
            case action instanceof ManageRequestActions.DeactivateSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deactivating Session(s)', action.error),
                );
            case action instanceof ManageRequestActions.DeleteSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deleting Link Session(s)', action.error),
                );
            case action instanceof ManageRequestActions.MergeSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Merging Link Sessions', action.error),
                );
            case action instanceof ManageRequestActions.SaveFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Saving Link', action.error));
            default:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error', action.error));
        }
    }

    getOffset(timezone: Timezone, date: Date): number {
        if (timezone.isdst && isDst(date)) {
            return timezone.offset + (timezone.offset < 0 ? -1 : 1);
        }

        return timezone.offset;
    }

    getUtcDateString(date: string | Date, timeZone: Timezone): string {
        if (isString(date)) {
            return `${date.replace(' ', 'T')}${parseTimeZoneFromOffset(
                this.getOffset(timeZone, new Date(date.replace(' ', 'T'))),
            )}`;
        }

        if (isDate(date)) {
            return `${toDateTimeString(date)}${parseTimeZoneFromOffset(this.getOffset(timeZone, date))}`;
        }

        throw new Error('Invalid type');
    }

    getUtcDate(date: string | Date, timeZone: Timezone): Date {
        return new Date(this.getUtcDateString(date, timeZone));
    }
}

type ManageRequestsFailureTypes =
    | ManageRequestActions.ActivateSessionFailure
    | ManageRequestActions.SearchLinkSessionsFailure
    | ManageRequestActions.DeleteSessionFailure
    | ManageRequestActions.MergeSessionsFailure
    | ManageRequestActions.DeactivateSessionFailure
    | ManageRequestActions.SaveFailure;

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SessionGroup } from '@app/data/models';
import { MatActionList, MatListItem, MatListItemAvatar, MatListItemLine } from '@angular/material/list';
import { NgClass } from '@angular/common';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { SrcUnloadDirective } from '../../../../../shared/src/lib/directives/src-unload.directive';
import { MatIcon } from '@angular/material/icon';
import { MatBadge } from '@angular/material/badge';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';

@Component({
    selector: 'ui-session-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './session-list.component.html',
    styleUrls: ['./session-list.component.scss'],
    standalone: true,
    imports: [
    MatActionList,
    MatListItem,
    ExtendedModule,
    NgClass,
    MatListItemAvatar,
    SrcUnloadDirective,
    MatIcon,
    MatListItemLine,
    MatBadge,
    FormatDatePipe
],
})
export class SessionListComponent {
    @Input() hasLinkStarted = false;
    @Input() sessionGroups: SessionGroup[] = [];

    @Output() readonly sessionGroupSelected: EventEmitter<SessionGroup> = new EventEmitter();

    onSessionGroupSelected(session: SessionGroup) {
        this.sessionGroupSelected.emit(session);
    }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { DropMediaModel } from '@app/data/models';
import { MediaNodeType } from '@app/shared/enums';
import { randomId } from '@app/shared/util';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FileSizePipe } from '../../../../../shared/src/lib/pipes/file-size.pipe';

@Component({
    selector: 'admin-drop-media',
    templateUrl: './drop-media.component.html',
    styleUrls: ['./drop-media.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        NgIf,
        MatSelect,
        ReactiveFormsModule,
        FormsModule,
        NgFor,
        MatOption,
        MatMiniFabButton,
        MatTooltip,
        MatIcon,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        ExtendedModule,
        NgClass,
        FileSizePipe,
    ],
})
export class DropMediaComponent implements OnChanges {
    MediaNodeType: typeof MediaNodeType = MediaNodeType;

    displayedColumns: string[] = ['name', 'fileSize', 'nodeType', 'actions'];
    dataSource: DropMediaModel[] = [];

    get validMediaFiles(): DropMediaModel[] {
        return this.dataSource.filter(model => Object.keys(model.warnings).length === 0);
    }

    @Input() mediaFiles: File[] | null = [];
    @Input() isPublic: boolean | null = false;

    trackByFn(index: number, item: DropMediaModel) {
        return item.id;
    }

    updateDataSource() {
        this.dataSource =
            this.mediaFiles?.map((file, index) => {
                const model = {
                    id: randomId(6),
                    file,
                    isVideo: file.type.includes('video'),
                    isAudio: file.type.includes('audio'),
                    isImage: file.type.includes('image'),
                    nodeType: this.getDefaultNodeType(file),
                    fileSize: file.size,
                    isPublic: this.isPublic,
                    nodeTypeOptions: [],
                    warnings: {},
                } as DropMediaModel;

                if (model.isAudio) {
                    model.nodeTypeOptions = [MediaNodeType.Audio];
                } else if (model.isVideo) {
                    model.nodeTypeOptions = [MediaNodeType.Video];
                } else if (model.isImage) {
                    model.nodeTypeOptions = [MediaNodeType.Image];
                }

                if (!this.isPublic && !model.isImage) {
                    model.nodeTypeOptions.push(MediaNodeType.MediaAsset);
                }

                model.warnings = this.getWarnings(model);
                return model;
            }) || [];
    }

    getMediaNodeTypeLabel(type: MediaNodeType): string {
        switch (type) {
            case MediaNodeType.Image:
                return 'Browser Embedded Image';
            case MediaNodeType.Audio:
                return 'Browser Embedded Audio';
            case MediaNodeType.Video:
                return 'Browser Embedded Video';
            default:
                return 'Saved Media';
        }
    }

    onNodeTypeChange(event$: MatSelectChange, model: DropMediaModel) {
        model.nodeType = event$.value;
        model.warnings = this.getWarnings(model);
    }

    getDefaultNodeType(file: File): MediaNodeType {
        if (file.type.includes('image')) {
            return MediaNodeType.Image;
        }

        if (file.type.includes('audio')) {
            return MediaNodeType.Audio;
        }

        return !this.isPublic ? MediaNodeType.MediaAsset : MediaNodeType.Video;
    }

    getWarnings(model: DropMediaModel): Record<string, string> {
        const warnings: Record<string, string> = {};
        const oneHundredMegaBytes = 100000000;

        const supportedAudioFileTypes = [
            'audio/mp3',
            'audio/mpeg',
            'audio/wav',
            'audio/ogg',
            'audio/webm',
            // 'audio/midi',
            // 'audio/x-midi',
            // 'audio/x-wav',
            // 'audio/x-aiff',
            // 'audio/x-pn-realaudio',
        ];

        const supportedVideoFileTypes = [
            'video/mp4',
            'video/webm',
            'video/ogg',
            // 'video/quicktime',
            // 'video/x-msvideo',
            // 'video/x-ms-wmv',
            // 'video/x-flv',
            // 'video/3gpp',
            // 'video/3gpp2',
            // 'video/x-matroska',
            // 'video/x-ms-asf',
            // 'video/x-ms-wmv',
            // 'video/x-msvideo',
            // 'video/x-flv',
            // 'video/3gpp',
            // 'video/3gpp2',
            // 'video/x-matroska',
            // 'video/x-ms-asf',
        ];

        if (model.nodeType !== MediaNodeType.MediaAsset && model.file.size > oneHundredMegaBytes) {
            warnings['fileSize'] = 'File size is greater than 100MB';
        }

        if (model.nodeType === MediaNodeType.Audio && !supportedAudioFileTypes.includes(model.file.type)) {
            warnings['audio'] = 'Audio file is not supported. Only mp3, wav, and ogg are supported.';
        }

        if (model.nodeType === MediaNodeType.Video && !supportedVideoFileTypes.includes(model.file.type)) {
            warnings['video'] = 'Video file is not supported. Only mp4, webm, and ogg are supported.';
        }

        return warnings;
    }

    onRemove(model: DropMediaModel) {
        this.dataSource = this.dataSource.filter(item => item !== model);
    }

    getRowClasses(model: DropMediaModel): string[] {
        const classes = [];

        if (model.warnings['fileSize']) {
            classes.push('file-size-warning');
        }

        if (model.warnings['audio']) {
            classes.push('audio-warning');
        }

        if (model.warnings['video']) {
            classes.push('video-warning');
        }

        return classes;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['mediaFiles']) {
            this.updateDataSource();
        }
    }
}

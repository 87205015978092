import { FormLinkModel as ManageFormsActions } from '@app/data/models';
import { Timezone } from 'timezones.json';

export class SearchLinkSessions {
    static readonly type = '[Link Request] Search Link Sessions';
}
export class SearchLinkSessionsSuccess {
    static readonly type = '[Link Request] Search Link Sessions Success';
}
export class SearchLinkSessionsFailure {
    static readonly type = '[Link Request] Search Link Sessions Failure';
    constructor(public error: any) {}
}
export class SearchLinkSessionsComplete {
    static readonly type = '[Link Request] Search Link Sessions Complete';
}

export class UpdateLinkApprovalCount {
    static readonly type = '[Link Request] Update Link Approval Count';
}

export class UpdateLinkApprovalCountSuccess {
    static readonly type = '[Link Request] Update Link Approval Count Success';
}

export class UpdateLinkApprovalCountFailure {
    static readonly type = '[Link Request] Update Link Approval Count Failure';
    constructor(public error: any) {}
}

export class EnsureLoadLinks {
    static readonly type = '[Link Request] Ensure Load';
    constructor(public force: boolean = false) {}
}

export class LoadLinkModel {
    static readonly type = '[Link Request] Load Link Model';
}

export class LoadLinkModelSuccess {
    static readonly type = '[Link Request] Load Link Model Success';
}

export class LoadLinkModelFailure {
    static readonly type = '[Link Request] Load Link Model Failure';
    constructor(public error: any) {}
}

export class Save {
    static readonly type = '[Link Request] Save Link Request';
}

export class SaveLinkRequestSuccess {
    static readonly type = '[Link Request] Save Link Request Success';
}

export class SaveFailure {
    static readonly type = '[Link Request] Save Link Request Failure';
    constructor(public error: any) {}
}
export class ApproveSessions {
    static readonly type = '[Link Request] Approve Sessions';
    constructor(public rowKeys: string[]) {}
}

export class ApproveSessionsFailure {
    static readonly type = '[Link Request] Approve Sessions Failure';
    constructor(public error: any) {}
}

export class UpdateSessionCount {
    static readonly type = '[Link Request] Update Session Count';
    constructor(public rowKey: string) {}
}

export class UpdateSessionCountFailure {
    static readonly type = '[Link Request] Update Session Count Failure';
    constructor(public error: any) {}
}

export class Error {
    static readonly type = '[Link Request] Error';
    constructor(public error: any, public source: any) {}
}

export class ActivateSession {
    static readonly type = '[Link Request] Activate Session';
    constructor(public rowKey: string) {}
}

export class ActivateSessionFailure {
    static readonly type = '[Link Request] Activate Session Failure';
    constructor(public error: any) {}
}

export class DeactivateSession {
    static readonly type = '[Link Request] Deactivate Session';
    constructor(public rowKey: string) {}
}

export class DeactivateSessionFailure {
    static readonly type = '[Link Request] Deactivate Session Failure';
    constructor(public error: any) {}
}

export class ConfirmMergeSession {
    static readonly type = '[Link Request] Confirm Merge Sessions';
    constructor(public rowKeys: string[]) {}
}

export class MergeSessions {
    static readonly type = '[Link Request] Merge Sessions';
    constructor(public primaryRowKey: string, public rowKeys: string[]) {}
}

export class MergeSessionsFailure {
    static readonly type = '[Link Request] Merge Sessions Failure';
    constructor(public error: any) {}
}

export class ConfirmDeleteSession {
    static readonly type = '[Link Request] Confirm Delete Session';
    constructor(public rowKeys: string[]) {}
}

export class DeleteSession {
    static readonly type = '[Link Request] Delete Session';
    constructor(public rowKeys: string[]) {}
}

export class DeleteSessionFailure {
    static readonly type = '[Link Request] Delete Session Failure';
    constructor(public error: any) {}
}

export class OpenLink {
    static readonly type = '[Link Request] Open Link';
    constructor(public link: ManageFormsActions) {}
}

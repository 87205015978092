@if (event) {
  <mat-list>
    @if (isActive) {
      <mat-list-item>
        <mat-icon matListItemIcon>event</mat-icon>
        <div matListItemTitle>{{ event.eventName }}</div>
        <div matListItemLine>Event will begin at</div>
        <div matListItemLine class="text">
          <b>{{ formatDate(event.startTime) }}</b>
        </div>
        <div matListItemLine>Event ends at</div>
        <div matListItemLine>
          <b>{{ formatDate(event.endTime) }}</b>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      @switch (event.location?.type) {
        @case ('zoom') {
          <mat-list-item>
            <mat-icon matListItemIcon>near_me</mat-icon>
            <div matListItemTitle>Zoom Meeting</div>
            @if (event.location?.join_Url; as url) {
              <div matListItemLine>
                <a [href]="url" rel="nofollow" target="_blank">{{ url }}</a>
              </div>
            }
          </mat-list-item>
        }
        @case ('outbound_call') {
          <mat-list-item>
            <mat-icon matListItemIcon>near_me</mat-icon>
            <div matListItemTitle>Outbound Call</div>
            <div matListItemLine>{{ event.location?.location }}</div>
          </mat-list-item>
        }
        @case ('inbound_call') {
          <mat-list-item>
            <mat-icon matListItemIcon>near_me</mat-icon>
            <div matListItemTitle>Inbound Call</div>
            <div matListItemLine>{{ event.location?.location }}</div>
          </mat-list-item>
        }
        @case ('physical') {
          <mat-list-item>
            <mat-icon matListItemIcon>near_me</mat-icon>
            <div matListItemTitle>In-person</div>
            <div matListItemLine>{{ event.location?.location }}</div>
          </mat-list-item>
        }
        @case ('ask_invitee') {
          <mat-list-item>
            <mat-icon matListItemIcon>near_me</mat-icon>
            <div matListItemTitle>Custom</div>
            <div matListItemLine>{{ event.location?.location }}</div>
          </mat-list-item>
        }
        @case ('custom') {
          <mat-list-item>
            <mat-icon matListItemIcon>near_me</mat-icon>
            <div matListItemTitle>Custom</div>
            <div matListItemLine>{{ event.location?.location }}</div>
          </mat-list-item>
        }
      }
      @for (invitee of event.invitees; track invitee) {
        <mat-divider></mat-divider>
        <mat-list-item>
          <mat-icon matListItemIcon>person</mat-icon>
          <div matListItemTitle>{{ invitee.name || invitee.firstName + ' ' + invitee.lastName }}</div>
          <div matListItemLine>{{ invitee.email }}</div>
        </mat-list-item>
        @if (invitee.questionsAndAnswers | any) {
          <mat-divider></mat-divider>
          <mat-list-item>
            <mat-icon matListItemIcon>help</mat-icon>
            <div matListItemTitle>Questions & Answers</div>
          </mat-list-item>
          @for (result of invitee.questionsAndAnswers; track result) {
            <mat-list-item>
              <mat-icon matListItemIcon></mat-icon>
              <div matListItemTitle>{{ result.question }}</div>
              <div matListItemLine>{{ result.answer }}</div>
              <!-- <div matListItem>&nbsp;</div> -->
            </mat-list-item>
          }
        }
      }
    }
    @if (isCancelled) {
      <mat-list-item>
        <mat-icon matListItemIcon color="warn">event_busy</mat-icon>
        <div matListItemTitle>Event has been cancelled by {{ event.cancellation?.canceledBy }}</div>
        <div matListItemLine>{{ event.cancellation?.reason }}</div>
      </mat-list-item>
    }
  </mat-list>
} @else {
  <mat-spinner></mat-spinner>
}


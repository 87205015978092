import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { Node, NoteModel, SaveNoteModel, TaskModel } from '@app/data/models';
import { Store } from '@ngxs/store';

import { NoteActions } from '../../actions';
import { HomeState,  } from '../../state/home.state';
import { Navigate } from '@ngxs/router-plugin';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTabGroup, MatTab, MatTabLabel } from '@angular/material/tabs';
import { NotesListComponent } from '../../components/notes-list/notes-list.component';
import { NotesComponent } from '../../components/notes/notes.component';
import { TasksComponent } from '../../components/tasks/tasks.component';
import { NotesState } from '@app/admin/state/notes.state';

@Component({
    selector: 'admin-notes-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./notes-container.component.html`,
    styleUrls: [`./notes-container.component.scss`],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '40vh', minHeight: '40vh' })),
            state('expanded', style({ height: '60vh' })),
            transition('expanded <=> collapsed', animate('225ms ease-in-out')),
        ]),
    ],
    standalone: true,
    imports: [
        FlexModule,
        NgIf,
        MatProgressSpinner,
        MatIconButton,
        MatIcon,
        MatTabGroup,
        MatTab,
        MatTabLabel,
        NotesListComponent,
        NotesComponent,
        TasksComponent,
        AsyncPipe,
    ],
})
export class NotesContainerComponent implements OnInit {
    isExpanded = true;

    nodes$: Observable<Node[] | null> = this.store.select(HomeState.getNodes());
    model$: Observable<NoteModel | null> = this.store.select(NotesState.getNote());
    isSaving$ = this.store.select(NotesState.isSaving());
    notes$: Observable<NoteModel[] | null> = this.store.select(NotesState.getNotes());
    tasks$: Observable<TaskModel[] | null> = this.store.select(NotesState.getTasks());
    isLoading$ = this.store.select(NotesState.isLoading());
    isLoadingNotes$ = this.store.select(NotesState.isLoadingNotes());

    constructor(private store: Store) {}

    onAdd(): void {
        this.store.dispatch(new NoteActions.AddNote());
    }

    onPin(note: NoteModel): void {
        this.store.dispatch(new NoteActions.PinNote(note));
    }

    onNodeSelected(node: Node) {
        this.store.dispatch(new Navigate(['/home', node.rowKey]));
    }

    onDelete(note: NoteModel): void {
        this.store.dispatch(new NoteActions.ConfirmDeleteNote(note));
    }

    onSelectionChange(note: NoteModel): void {
        this.store.dispatch(new NoteActions.SelectNote(note));
    }

    onSave(saveModel: SaveNoteModel): void {
        this.store.dispatch(new NoteActions.SaveNote(saveModel));
    }

    onClose(): void {
        this.store.dispatch(new NoteActions.Hide());
    }

    onSaveTasks(tasks: TaskModel[]) {
        this.store.dispatch(new NoteActions.SaveTasks(tasks));
    }

    ngOnInit(): void {
        this.store.dispatch(new NoteActions.EnsureLoad());
    }
}

<mat-card>
  <mat-card-content>
    @if (showTitle) {
      <ui-rich-text-view [content]="title"></ui-rich-text-view>
    }
    <div>
      <div [ngClass]="{ readonly: readonly }">
        @switch (type) {
          @case (FormQuestionType.ShortAnswer) {
            <ng-container [ngTemplateOutlet]="short"></ng-container>
          }
          @case (FormQuestionType.LongAnswer) {
            <ng-container [ngTemplateOutlet]="long"></ng-container>
          }
          @case (FormQuestionType.MultipleChoice) {
            <ng-container
              [ngTemplateOutlet]="multiple"
            ></ng-container>
          }
          @case (FormQuestionType.Checkbox) {
            <ng-container [ngTemplateOutlet]="checkboxes"></ng-container>
          }
          @case (FormQuestionType.Dropdown) {
            <ng-container [ngTemplateOutlet]="dropdown"></ng-container>
          }
          @case (FormQuestionType.Scale) {
            <ng-container [ngTemplateOutlet]="scale"></ng-container>
          }
          @case (FormQuestionType.Date) {
            <ng-container [ngTemplateOutlet]="date"></ng-container>
          }
          @case (FormQuestionType.Matrix) {
            <ng-container [ngTemplateOutlet]="matrix"></ng-container>
          }
          @case (FormQuestionType.MatrixCheckbox) {
            <ng-container
              [ngTemplateOutlet]="matrixCheckbox"
            ></ng-container>
          }
        }
      </div>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #short>
  <div [formGroup]="form">
    <mat-form-field appearance="outline" class="full-width">
      <input formControlName="value" [ngClass]="{ hidden: print }" matInput />
      @if (print) {
        <div>{{ form.get('value')?.value }}</div>
      }
      @if (form.get('value')?.hasError('required')) {
        <mat-error>Required</mat-error>
      }
    </mat-form-field>
  </div>
</ng-template>

<ng-template #long>
  <div [formGroup]="form">
    <mat-form-field appearance="outline" class="full-width">
      <textarea formControlName="value" [ngClass]="{ hidden: print }" matInput></textarea>
      @if (print) {
        <div>{{ form.get('value')?.value }}</div>
      }
      @if (form.get('value')?.hasError('required')) {
        <mat-error>Required</mat-error>
      }
    </mat-form-field>
  </div>
</ng-template>

<ng-template #multiple>
  <div [formGroup]="form">
    <mat-radio-group formControlName="value">
      @for (option of options; track option) {
        <div class="option">
          <mat-radio-button [value]="option['id']">
            {{ option['text'] }}
          </mat-radio-button>
        </div>
      }
      @if (showOtherOption) {
        <div class="option">
          <mat-radio-button>
            <mat-form-field appearance="outline">
              <mat-label>Other</mat-label>
              <input matInput formControlName="other" [ngClass]="{ hidden: print }" />
              @if (print) {
                <div>{{ form.get('other')?.value }}</div>
              }
              @if (form.get('other')?.hasError('required')) {
                <mat-error>Required</mat-error>
              }
              @if (form.get('other')?.hasError('minlength')) {
                <mat-error>Min length is 15 characters</mat-error>
              }
            </mat-form-field>
          </mat-radio-button>
        </div>
      }
      @if (form.get('value')?.hasError('required')) {
        <mat-error class="radio-required">Required</mat-error>
      }
    </mat-radio-group>
  </div>
</ng-template>

<ng-template #matrix>
  <div [formGroup]="form">
    <div class="matrix-row">
      <div class="label">&nbsp;</div>
      <div class="matrix">
        @for (col of model?.matrix?.columns; track col) {
          <div class="matrix-value">
            {{ col.text }}
          </div>
        }
      </div>
    </div>
    @for (row of model?.matrix?.rows; track row; let ix = $index) {
      <div formArrayName="value">
        <div class="matrix-row">
          <div class="label">{{ row.text }}</div>
          <mat-radio-group class="matrix" [formControlName]="ix">
            @for (col of model?.matrix?.columns; track col) {
              <mat-radio-button class="matrix-value" [value]="col.id">
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
      </div>
    }
    @if (form.get('value')?.hasError('required')) {
      <mat-error>Required</mat-error>
    }
  </div>
</ng-template>

<ng-template #matrixCheckbox>
  <div [formGroup]="form">
    <div class="matrix-row">
      <div class="label">&nbsp;</div>
      <div class="matrix">
        @for (col of model?.matrix?.columns; track col) {
          <div class="matrix-value">
            {{ col.text }}
          </div>
        }
      </div>
    </div>
    @for (row of model?.matrix?.rows; track row; let rowIx = $index) {
      <div formArrayName="value">
        <div class="matrix-row" [formGroupName]="rowIx">
          <div class="label">{{ row.text }}</div>
          <div class="matrix">
            @for (col of model?.matrix?.columns; track col; let colIx = $index) {
              <div [formGroupName]="colIx">
                <mat-checkbox
                  class="matrix-value"
                  formControlName="checked"
                  [value]="getMatrixCtrl(rowIx, colIx).value.id"
                  [checked]="getMatrixCtrl(rowIx, colIx).value.checked"
                  (change)="onCheckChanged()"
                ></mat-checkbox>
              </div>
            }
          </div>
        </div>
      </div>
    }
    @if (form.get('value')?.hasError('required')) {
      <mat-error>Required</mat-error>
    }
  </div>
</ng-template>

<ng-template #checkboxes>
  <div class="q-checkboxes" [formGroup]="form">
    @for (ctrl of optionsCtrl.controls; track ctrl; let ix = $index) {
      <div formArrayName="options" class="option">
        <div [formGroupName]="ix">
          <mat-checkbox
            formControlName="checked"
            [value]="ctrl.value.id"
            [checked]="ctrl.value.checked"
            (change)="onCheckChanged(ctrl, ix)"
            >{{ ctrl.value.text }}</mat-checkbox
            >
          </div>
        </div>
      }
      @if (showOtherOption) {
        <div class="option other">
          <mat-checkbox
            #otherCheckbox
            (change)="onOtherValueChange($event)"
            [checked]="isOtherValueChecked"
          ></mat-checkbox>
          <mat-form-field appearance="outline">
            <mat-label>Other</mat-label>
            <input matInput formControlName="other" [ngClass]="{ hidden: print }" />
            @if (print) {
              <div>{{ form.get('other')?.value }}</div>
            }
            @if (form.get('other')?.hasError('required')) {
              <mat-error>Required</mat-error>
            }
            @if (form.get('other')?.hasError('minlength')) {
              <mat-error>Min length is 15 characters</mat-error>
            }
          </mat-form-field>
        </div>
      }
    </div>
  </ng-template>

  <ng-template #dropdown>
    <div [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-select formControlName="value" [ngClass]="{ hidden: print }">
          @for (option of options; track option; let ix = $index) {
            <mat-option [value]="option['id']">
              {{ option['text'] }}
            </mat-option>
          }
        </mat-select>
        @if (print) {
          <div>{{ getPrintValue() }}</div>
        }
        @if (form.get('value')?.hasError('required')) {
          <mat-error>Required</mat-error>
        }
      </mat-form-field>
    </div>
  </ng-template>

  <ng-template #scale>
    <div class="scale" [formGroup]="form">
      <div class="label" fxLayout="row nowrap">
        <div fxFlex="0 0 auto">{{ minLabel }}</div>
        <div fxFlex="1 1"></div>
        <div fxFlex="0 0 auto">{{ maxLabel }}</div>
      </div>
      <!-- <mat-slider formControlName="value" [tickInterval]="1" [min]="min" [max]="max" step="1"></mat-slider> -->
      <mat-radio-group formControlName="value">
        <div fxLayout="row nowrap" fxLayoutAlign="space-between">
          @for (option of options; track option) {
            <mat-radio-button fxFlex="0 0 auto" [value]="option['id']">
              {{ option['text'] }}
            </mat-radio-button>
          }
        </div>
      </mat-radio-group>
    </div>
  </ng-template>

  <ng-template #date>
    <div [formGroup]="form">
      <mat-form-field appearance="outline">
        <input
          formControlName="value"
          [matDatepicker]="datePicker"
          [min]="minDateValue"
          matInput
          [ngClass]="{ hidden: print }"
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
          @if (print) {
            <div>{{ getPrintValue() }}</div>
          }
          @if (form.get('value')?.hasError('required')) {
            <mat-error>Required</mat-error>
          }
        </mat-form-field>
      </div>
    </ng-template>

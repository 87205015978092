<h1 mat-dialog-title>Confirm delete note</h1>
<mat-dialog-content>
    <p>
        Are you certain you want to delete <i>{{ name }}</i
        >?
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="warn" [mat-dialog-close]="true">Delete</button>
</mat-dialog-actions>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h2 matDialogTitle><mat-icon>update</mat-icon>Scheduled Maintenance In Progress</h2>
        <mat-dialog-content> Please check back in a few hours. </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
                padding-right: 10px;
            }
        `,
    ],
    standalone: true,
    imports: [MatDialogTitle, MatIcon, MatDialogContent],
})
export class DenyServiceUnavailableDialogComponent {}

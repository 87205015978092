import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';

import { Observable, Subject } from 'rxjs';
import { debounceTime, map, skip, startWith, take, takeUntil, tap } from 'rxjs/operators';

import { ShareableLinkModel, QueryResult, SearchOptionsModel } from '@app/data/models';
import { SHARED_DATE_REGEX } from '@app/shared/constants';
import { DayOfWeek, LinkExpiration, LinkInactivityMode, LinkStatus, RegistrationMode, TimerUnit } from '@app/shared/enums';
import { DEFAULT_TIME_ZONE, getFormSubmissionResetDate, getTimeZoneByIanaTimeZone, isDate, isDst, isNullOrEmpty } from '@app/shared/util';
import { Store } from '@ngxs/store';
import timezones, { Timezone } from 'timezones.json';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgxsFormDirective } from '@ngxs/form-plugin';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgFor, NgIf, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { LoadingButtonComponent } from '../../../../../ui/src/lib/components/loading-button/loading-button.component';
import { ErrorDisplayComponent } from '../../../../../ui/src/lib/components/error-display/error-display.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatBadge } from '@angular/material/badge';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTooltip } from '@angular/material/tooltip';
import { AnyPipe } from '../../../../../shared/src/lib/pipes/any.pipe';
import { ExpiresPipe } from '../../../../../shared/src/lib/pipes/expires.pipe';
import { TimerUnitPipe } from '../../../../../shared/src/lib/pipes/timer-unit.pipe';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';

const DEFAULT_PAGE_SIZE = 25;
interface ContentLinkModelViewModel extends ShareableLinkModel {
    isActivated: boolean;
    isStale: boolean;
    isExpired: boolean;
    hasStarted: boolean;
    hasDates: boolean;
    hasTimer: boolean;
    displayAs: DisplayAs;
    offset: number;
    expires: Date;
    displayTimezone: Timezone;
    resetDisplay: string;
    resetStartDate: Date;
    // displayExpirationDate: Date;
}

enum DisplayAs {
    None,
    StartDate,
    Timer,
    RegistrationCutOff,
    RegistrationReset,
}

const DEBOUNCE_IN_MS = 200;

@Component({
    selector: 'admin-archived-link-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./archived-link-list.component.html`,
    styleUrls: [`./archived-link-list.component.scss`],
    standalone: true,
    imports: [
        FlexModule,
        NgxsFormDirective,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatAutocompleteTrigger,
        MatAutocomplete,
        NgFor,
        MatOption,
        MatSelect,
        MatChipListbox,
        MatChipOption,
        MatIcon,
        MatChipRemove,
        LoadingButtonComponent,
        NgIf,
        ErrorDisplayComponent,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatMenu,
        MatMenuItem,
        MatCheckbox,
        MatIconButton,
        MatMenuTrigger,
        MatCellDef,
        MatCell,
        RouterLink,
        MatBadge,
        ExtendedModule,
        NgClass,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        MatTooltip,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        AnyPipe,
        ExpiresPipe,
        TimerUnitPipe,
        FormatDatePipe,
        AsyncPipe,
    ],
})
export class ArchivedLinkListComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    readonly today = new Date();
    readonly format = 'MMM DD YYYY @ hh:mmA';

    private isAlive$: Subject<void> = new Subject();

    get pageSize() {
        return this.searchOptions?.limit || DEFAULT_PAGE_SIZE;
    }

    get pageIndex() {
        if (this.searchOptions) {
            return this.searchOptions?.offset / this.pageSize;
        }

        return 0;
    }

    dataSource: MatTableDataSource<ContentLinkModelViewModel>;

    DisplayAs: typeof DisplayAs = DisplayAs;
    TimerUnit: typeof TimerUnit = TimerUnit;
    LinkInactivityMode: typeof LinkInactivityMode = LinkInactivityMode;

    displayTags: string[] = [];
    filteredTags$: Observable<string[]>;
    keyup$: Subject<void> = new Subject();
    selection: SelectionModel<ContentLinkModelViewModel> = new SelectionModel<ContentLinkModelViewModel>(true, []);

    @ViewChild(MatAutocompleteTrigger, { static: true }) autoTrigger: MatAutocompleteTrigger;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    get total() {
        return this.queryResult?.total;
    }

    get isAutoDisabled() {
        return this.form && this.form.controls['filter'].value ? !this.form.controls['filter'].value.startsWith('#') : true;
    }

    @Input() error: any;
    @Input() isLoading: boolean | null = false;
    @Input() queryResult: QueryResult<ShareableLinkModel> | null = null;
    @Input() searchOptions: SearchOptionsModel | null = null;
    @Input() filter: string | null = null;
    @Input() tags: string[] | null = [];

    @Output() readonly copyLink: EventEmitter<ShareableLinkModel> = new EventEmitter();
    @Output() readonly view: EventEmitter<ShareableLinkModel> = new EventEmitter();
    @Output() readonly delete: EventEmitter<ShareableLinkModel[]> = new EventEmitter();
    @Output() readonly statusChange: EventEmitter<{ linkIds: string[]; status: LinkStatus }> = new EventEmitter();
    @Output() readonly refresh: EventEmitter<SearchOptionsModel> = new EventEmitter();

    form: FormGroup = this.fb.group({
        filter: new FormControl(),
        tags: new FormControl([]),
        displayedColumns: new FormControl(['select', 'description', 'details', 'expires', 'archived', 'actions']),
    });

    constructor(private fb: FormBuilder, private store: Store) {}

    columns: { key: string; value: string }[] = [
        { key: 'Id', value: 'index' },
        { key: 'Description', value: 'description' },
        { key: 'Details', value: 'details' },
        { key: 'Expires', value: 'expires' },
        { key: 'Archived', value: 'archived' },
        { key: 'Timestamp', value: 'timestamp' },
    ];
    _displayedColumns: string[] = ['select', 'description', 'details', 'expires', 'archived', 'actions'];

    get displayedColumns(): string[] {
        return this._displayedColumns;
    }

    get currentOffset() {
        return isDst() ? -7 : -6;
    }

    onFilterClick() {
        if (!this.autoTrigger.panelOpen) {
            this.autoTrigger.openPanel();
        }
    }

    onRefresh(): void {
        this.paginator.firstPage();
        this.queryResult = null;

        const options = {
            ...this.form.value,
            offset: 0,
            limit: this.pageSize,
            total: null,
        };

        this.refresh.emit(options);
    }

    updateDisplayedColumns(event: MatSelectChange) {
        this._displayedColumns = event.value || [];

        if (!this._displayedColumns.includes('actions')) {
            this._displayedColumns.push('actions');
        }

        if (!this._displayedColumns.includes('select')) {
            this._displayedColumns = ['select'].concat(this._displayedColumns);
        }
    }

    onUpdateFilter() {
        if (
            !this.autoTrigger.panelOpen &&
            this.autoTrigger.autocomplete &&
            this.form.controls['filter'].value?.length >= 1
        ) {
            this.autoTrigger.openPanel();
        }

        this.keyup$.next();
    }

    onSelectAllChange(event: MatCheckboxChange): void {
        const pageSize = this.paginator.pageSize;
        const pageIndex = this.paginator.pageIndex;
        const recordsToSkip = Math.max(0, pageIndex * pageSize);
        const records = this.dataSource.filteredData.slice(recordsToSkip, recordsToSkip + pageSize);

        records.forEach(r => {
            if (event.checked) {
                this.selection.select(r);
            } else {
                this.selection.deselect(r);
            }
        });
    }

    areAllSelected(): boolean {
        if (!this.paginator || this.dataSource.filteredData.length === 0) {
            return false;
        }

        return this.dataSource.filteredData.every(r => this.selection.isSelected(r));
    }

    areAnySelected(): boolean {
        if (!this.paginator || this.dataSource.filteredData.length === 0) {
            return false;
        }

        return this.dataSource.filteredData.some(r => this.selection.isSelected(r)) && !this.areAllSelected();
    }

    updateDataSource() {
        let items: ContentLinkModelViewModel[] = [];
        this.selection.clear(); // remove any old selected values.

        if (this.queryResult && this.queryResult?.items.length > 0) {
            items = this.queryResult?.items.map(link => {
                const hasDates = isDate(link.startDate) && isDate(link.endDate);
                const hasTimer = link.timer && link.timer > 0;

                const displayTimezone = getTimeZoneByIanaTimeZone(link.timeZone || DEFAULT_TIME_ZONE);

                const activationDate = link.activationDate ? link.activationDate : null;
                const isActivated = activationDate !== null;
                const now = new Date();
                const linkExpiration = link.linkExpiration;
                const hasRegistrationCutoff = isDate(link.registrationEndDate);
                const registrationEndDate = hasRegistrationCutoff ? (link.registrationEndDate as Date) : null;

                let isExpired = false;
                let displayAs = DisplayAs.StartDate;
                let expires: Date | null = null;
                let resetStartDate: Date | null = null;
                let resetDisplay = null;

                if (hasRegistrationCutoff && now < (registrationEndDate as Date)) {
                    displayAs = DisplayAs.RegistrationCutOff;
                } else if (link.registrationMode !== RegistrationMode.Default) {
                    displayAs = DisplayAs.RegistrationReset;
                    resetStartDate = getFormSubmissionResetDate(link, true);
                    switch (link.registrationMode) {
                        case RegistrationMode.Daily:
                            resetDisplay = 'Resets Daily';
                            break;
                        case RegistrationMode.Weekly:
                            resetDisplay = `Resets ${DayOfWeek[link.dayOfWeek]} Weekly`;
                            break;
                        case RegistrationMode.Monthly:
                            resetDisplay = `Resets Monthly`;
                            break;
                    }
                } else if (linkExpiration === LinkExpiration.Date) {
                    if (isActivated || link.startDate) {
                        displayAs = DisplayAs.StartDate;
                    } else {
                        displayAs = DisplayAs.None;
                    }
                    expires = link.endDate as Date;
                } else if (linkExpiration === LinkExpiration.Timer) {
                    displayAs = DisplayAs.Timer;
                    expires = link.timerEndDate ? new Date(link.timerEndDate) : null;
                } else {
                    displayAs = DisplayAs.None;
                }

                if (linkExpiration === LinkExpiration.Timer && activationDate) {
                    let timer: Date | null = null;

                    switch (link.timerUnit) {
                        case TimerUnit.Months:
                            timer = new Date(activationDate);
                            timer.setDate(activationDate.getDate() + (link.timer as number) * 30);
                            break;
                        case TimerUnit.Weeks:
                            timer = new Date(activationDate);
                            timer.setDate(activationDate.getDate() + (link.timer as number) * 7);
                            break;
                        case TimerUnit.Days:
                            timer = new Date(activationDate);
                            timer.setDate(activationDate.getDate() + (link.timer as number));
                            break;
                        case TimerUnit.Hours:
                            timer = new Date(activationDate);
                            timer.setHours(activationDate.getHours() + (link.timer as number));
                            break;
                        default:
                            timer = new Date(activationDate);
                            timer.setMinutes(activationDate.getMinutes() + (link.timer as number));
                            break;
                    }

                    if (expires === null || timer < expires) {
                        expires = timer;
                    }
                }

                if (expires && now > expires) {
                    isExpired = true;
                }

                return {
                    ...link,
                    // displayExpirationDate,
                    isActivated,
                    isExpired,
                    hasDates,
                    hasTimer,
                    displayAs,
                    expires,
                    resetStartDate,
                    resetDisplay,
                    displayTimezone,
                } as ContentLinkModelViewModel;
            }); //clone
        }

        this.dataSource = new MatTableDataSource(items);
    }

    filterTags(filter: string): string[] {
        if (!this.tags) return [];

        if (!filter) {
            return this.tags.filter(
                tag => !this.displayTags.map(s => s.toLocaleLowerCase()).includes(tag.toLocaleLowerCase()),
            );
        }

        return this.tags.filter(tag => tag.toLocaleLowerCase().startsWith(filter));
    }

    selectTag(event: MatAutocompleteSelectedEvent): void {
        this.displayTags.push(event.option.viewValue);
        this.form.controls['filter'].setValue(null);
        this.form.controls['tags'].setValue(this.displayTags.slice());
    }

    removeTag(tag: string): void {
        const index = this.displayTags.indexOf(tag);

        if (index >= 0) {
            this.displayTags.splice(index, 1);
        }

        this.form.controls['tags'].setValue(this.displayTags.slice());
    }

    onView(model: ContentLinkModelViewModel): void {
        this.view.emit(model);
    }

    updatePage(page: PageEvent) {
        const { pageIndex, pageSize } = page;

        const options = {
            ...this.form.value,
            offset: pageIndex * pageSize,
            limit: pageSize,
        };

        this.refresh.emit(options);
    }

    onPage(event: PageEvent) {
        this.updatePage(event);
    }

    onDeleteSelected(): void {
        if (!this.selection.isEmpty()) {
            this.delete.emit(this.selection.selected);
        }
    }

    onCopyLink(model: ContentLinkModelViewModel): void {
        this.copyLink.emit(model);
    }

    onDelete(model: ContentLinkModelViewModel): void {
        this.delete.emit([model]);
    }

    ngOnInit() {
        this.form.controls['filter'].valueChanges
            .pipe(
                takeUntil(this.isAlive$),
                take(1),
                tap(v => this.onUpdateFilter()),
            )
            .subscribe();

        this.filteredTags$ = this.form.controls['filter'].valueChanges.pipe(
            takeUntil(this.isAlive$),
            startWith(null),
            map(tag => this.filterTags(tag)),
        );

        this.keyup$
            .pipe(
                takeUntil(this.isAlive$),
                skip(1), // skip the initial value because it's being set by ngxs form plugin
                debounceTime(DEBOUNCE_IN_MS),
                tap(() => {
                    this.paginator.firstPage();
                    const options = {
                        ...this.form.value,
                        limit: this.pageSize,
                        total: null,
                        offset: 0,
                    };
                    this.refresh.emit(options);
                }),
            )
            .subscribe();
    }

    ngAfterViewInit() {
        this.updateDisplayedColumns({ value: this.form.controls['displayedColumns'].value } as any);
        this.displayTags = (this.form.controls['tags'].value || []).slice();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['queryResult']) {
            this.updateDataSource();
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}

<mat-card>
  @if (showTitle) {
    <mat-card-header>
      <mat-card-title>
        {{ title }}
      </mat-card-title>
    </mat-card-header>
  }
  <mat-card-content>
    <ui-rich-text-view [content]="model?.description"></ui-rich-text-view>
    @if (hasBooked || scheduledEvent) {
      <p>You're meeting has been scheduled</p>
    } @else {
      @for (id of ids; track id) {
        <div [attr.id]="id" class="calendly"></div>
      }
    }
  </mat-card-content>
</mat-card>

<ng-template #calendly>
  @for (id of ids; track id) {
    <div [attr.id]="id" class="calendly"></div>
  }
</ng-template>

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';

import { NoteActions } from '../actions';

export const HomeResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
) => {
    return store.dispatch(new NoteActions.EnsureLoad());
};

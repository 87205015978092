import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { finalize, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageChecklistActions } from '../actions';
import { ManageChecklistsState } from '../state/manage-checklists.state';

export const ViewArchivedChecklistLinkResolver: ResolveFn<Observable<void | Observable<void>>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
) => {
        const rowKey = route.params?.['linkId'];

        return store
            .dispatch([
                new CoreActions.UpdateGlobalLoading(true),
                new ManageChecklistActions.LoadArchivedLink(rowKey),
                new ManageChecklistActions.LoadArchivedLinkSessions(rowKey),
            ])
            .pipe(
                take(1),
                withLatestFrom(store.select(ManageChecklistsState.getArchivedLink())),
                mergeMap(([, link]) => {

                    if (!link) {
                        return of(void 0);
                    }

                    if (link.checklistTemplateIds.length > 0) {
                        store.dispatch([
                            ...link.checklistTemplateIds.map(
                                id => new ManageChecklistActions.LoadChecklist(`${rowKey}.${id}`),
                            ),
                        ]);
                    }

                    return of(void 0);
                }),
                finalize(() => store.dispatch([new CoreActions.UpdateGlobalLoading(false)])),
            );
    };

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    AdminRegistrant,
    DeleteResult,
    EventLinkModel,
    EventLinkTemplateModel,
    GuestRegistration,
    LinkSessionSummary,
    Participant,
    PaymentModel,
    QueryResult,
    SaveEventLinkModel,
    SaveEventLinkTemplateModel,
    SearchOptionsModel,
    Submission,
} from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { LinkType } from '@app/shared/enums';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable({
    providedIn: 'root',
})
export class ManageEventLinksService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    getAllLinks(): Observable<EventLinkModel[]> {
        return this.http
            .get<ServerResult<EventLinkModel[]>>(`${this.env.serverUrl}/manage/events`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getArchivedLink(rowkey: string): Observable<EventLinkModel> {
        return this.http
            .post<ServerResult<EventLinkModel>>(`${this.env.serverUrl}/manage/events/archived`, { rowkey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllTemplates(): Observable<EventLinkTemplateModel[]> {
        return this.http
            .get<ServerResult<EventLinkTemplateModel[]>>(`${this.env.serverUrl}/manage/events/templates`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveLink(model: SaveEventLinkModel): Observable<ServerResult<EventLinkModel>> {
        return this.http
            .post<ServerResult<EventLinkModel>>(`${this.env.serverUrl}/manage/events`, model, {})
            .pipe(verifyServerResult(), errorHandler());
    }

    saveTemplate(model: SaveEventLinkTemplateModel): Observable<EventLinkTemplateModel> {
        return this.http
            .post<ServerResult<EventLinkTemplateModel>>(`${this.env.serverUrl}/manage/events/template`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinkSessions(rowKey: string): Observable<LinkSessionSummary[]> {
        return this.http
            .post<ServerResult<LinkSessionSummary[]>>(`${this.env.serverUrl}/manage/events/sessions`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getLinkRegistrants(rowKey: string): Observable<AdminRegistrant[]> {
        return this.http
            .post<ServerResult<AdminRegistrant[]>>(`${this.env.serverUrl}/manage/events/registrants`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getGuestRegistrations(rowKey: string) {
        return this.http
            .post<ServerResult<GuestRegistration[]>>(`${this.env.serverUrl}/manage/events/guest-registrations`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinkSubmissions(rowKey: string): Observable<Submission[]> {
        return this.http
            .post<ServerResult<Submission[]>>(`${this.env.serverUrl}/manage/events/submissions`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinkPayments(rowKey: string): Observable<PaymentModel[]> {
        return this.http
            .post<ServerResult<PaymentModel[]>>(`${this.env.serverUrl}/manage/link/payments`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    activateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/activate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    syncActiveSessions(rowKey: string): Observable<EventLinkModel> {
        return this.http
            .post<ServerResult<EventLinkModel>>(`${this.env.serverUrl}/manage/sessions/sync`, {
                rowKeys: [rowKey],
                linkType: LinkType.Event,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    updateLinkCounts(rowKey: string): Observable<EventLinkModel> {
        return this.http
            .post<ServerResult<EventLinkModel>>(`${this.env.serverUrl}/manage/events/sessions/update-count`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deactivateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/events/sessions/deactivate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    archiveLinks(links: EventLinkModel[]): Observable<DeleteResult> {
        return this.http
            .post<ServerResult<DeleteResult>>(
                `${this.env.serverUrl}/manage/events/archive`,
                links.map(m => m.rowKey),
            )
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteLinks(links: EventLinkModel[]): Observable<DeleteResult> {
        return this.http
            .request<ServerResult<DeleteResult>>('DELETE', `${this.env.serverUrl}/manage/events`, {
                body: links.map(m => m.rowKey),
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteTemplates(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/events/template/delete`, JSON.stringify(rowKeys))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    approveSession(rowKey: string, formSubmissionRowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/events/sessions/approve`, {
                rowKey,
                formSubmissionRowKeys,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteSessions(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/events/sessions/delete`, rowKeys)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    mergeSessions(linkId: string, primary: string, rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/events/sessions/merge`, { rowKeys, primary, linkId })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    searchArchivedLinks(model: SearchOptionsModel): Observable<QueryResult<EventLinkModel>> {
        return this.http
            .post<ServerResult<QueryResult<EventLinkModel>>>(`${this.env.serverUrl}/manage/events/search-archived`, model)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    completeSubmission(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/events/submission/complete`, {
                rowKey,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    acknowledgeSubmissions(formSubmissionRowKeys: string[], rowKey: string): Observable<LinkSessionSummary[]> {
        return this.http
            .post<ServerResult<LinkSessionSummary[]>>(`${this.env.serverUrl}/manage/events/submission/acknowledge`, {
                rowKey,
                formSubmissionRowKeys,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    acknowledgeInfluencerNotification(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/events/influencer/acknowledge`, JSON.stringify(rowKeys))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getParticipants(rowKey: string): Observable<Participant[]> {
        return this.http
            .post<ServerResult<Participant[]>>(`${this.env.serverUrl}/manage/link/participants`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    syncParticipants(rowKey: string): Observable<Participant[]> {
        return this.http
            .post<ServerResult<Participant[]>>(`${this.env.serverUrl}/manage/link/participants/sync`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}

import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';

export type FontForegroundOptions = {
    types: string[];
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        fontForeground: {
            /**
             * Set the font Foreground
             */
            setFontForeground: (colour: string) => ReturnType;
            /**
             * Unset the font Foreground
             */
            unsetFontForeground: () => ReturnType;
        };
    }
}

export const FontForeground = Extension.create<FontForegroundOptions>({
    name: 'fontForeground',

    addOptions() {
        return {
            types: ['textStyle'],
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontForeground: {
                        default: null,
                        renderHTML: attributes => {
                            if (!attributes['fontForeground']) {
                                return {};
                            }

                            return {
                                style: `background-color: ${attributes['fontForeground']}`,
                            };
                        },
                        parseHTML: element => element.style.backgroundColor.replace(/['"]+/g, ''),
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setFontForeground:
                fontForeground =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontForeground }).run();
                },
            unsetFontForeground:
                () =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontForeground: null }).removeEmptyTextStyle().run();
                },
        };
    },
});

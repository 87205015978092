<section
    fxLayout="row wrap"
    fxLayout.gt-xs="row nowrap"
    fxLayoutAlign="start start"
    [formGroup]="form"
    ngxsForm="manageLinks.searchArchivedLinks"
>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxFlex>
        <mat-form-field fxFlex="1 1 100%" fxFlex.gt-sm="1 1 30%" appearance="outline">
            <mat-label>Filter</mat-label>
            <input
                formControlName="filter"
                matInput
                autocomplete="off"
                [matAutocomplete]="auto"
                (keyup)="onUpdateFilter()"
                (click)="onFilterClick()"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
                <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
                    {{ tag }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field fxFlex="1 1 100%" fxFlex.gt-sm="1 1 30%" appearance="outline">
            <mat-select
                multiple="true"
                formControlName="displayedColumns"
                (selectionChange)="updateDisplayedColumns($event)"
            >
                <mat-option *ngFor="let column of columns" [value]="column.value">
                    {{ column.key }}
                </mat-option>
            </mat-select>
            <mat-label> Display Columns </mat-label>
        </mat-form-field>
        <mat-chip-listbox fxFlex="1 1 100%">
            <mat-chip-option
                *ngFor="let tag of displayTags"
                [removable]="true"
                [selectable]="false"
                (removed)="removeTag(tag)"
            >
                {{ tag }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
    <div fxFlex="1 1 100%" fxFlex.gt-xs="0 0 150px">
        <ui-loading-button [isLoading]="isLoading" (click)="onRefresh()"></ui-loading-button>
    </div>
    <div fxFlex>&nbsp;</div>
</section>
<ui-error-display *ngIf="error" [error]="error"></ui-error-display>
<div class="container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-menu #actions="matMenu">
                    <button mat-menu-item (click)="onDeleteSelected()">
                        <mat-icon color="warn">delete</mat-icon>
                        Permanently Delete ({{ selection.selected.length }})
                    </button>
                </mat-menu>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="onSelectAllChange($event)"
                    [checked]="areAllSelected()"
                    [indeterminate]="areAnySelected()"
                >
                </mat-checkbox>
                <button *ngIf="selection.hasValue()" mat-icon-button [matMenuTriggerFor]="actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </th>
            <td mat-cell *matCellDef="let data">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(data) : null"
                    [checked]="selection.isSelected(data)"
                >
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let data">
                {{ data.rowKey }}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let data">
                <div>
                    <a
                        class="pointer active-badge"
                        (click)="onView(data)"
                        matBadgePosition="before"
                        matBadgeOverlap="false"
                        [routerLink]="['/home/shareable-links/archived', data.rowKey]"
                        [queryParams]="{ t: 'content' }"
                        [matBadge]="data.activeSubmissionCount || '-'"
                        [ngClass]="{ active: data.isActivated, empty: data.activeSubmissionCount === 0 }"
                    >
                        {{ data.requestedBy ? '(' + data.requestedBy + ')' : '' }}
                        {{ data.url }}
                    </a>
                    <div class="meta-badges">
                        <span
                            *ngIf="data.approvalCount > 0"
                            matBadgePosition="after"
                            matBadgeOverlap="false"
                            [matBadge]="data.approvalCount"
                            (click)="onView(data)"
                            class="badge pending-badge"
                            >&nbsp;
                        </span>
                        <span
                            *ngIf="data.lateRegistrationCount > 0"
                            matBadgePosition="after"
                            matBadgeOverlap="false"
                            [matBadge]="data.lateRegistrationCount"
                            class="badge inactive-badge"
                        >
                            &nbsp;
                        </span>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let data">
                <time> Create Date {{ data.createdDate | formatDate }} MST </time>
                <ng-container *ngIf="data.isActivated" [ngSwitch]="data.displayAs">
                    <time class="active" *ngSwitchCase="DisplayAs.RegistrationReset">
                        {{ data.resetDisplay }} ({{ data.resetStartDate | formatDate }})
                        <span *ngIf="!data.isExpired">{{ data.expires | expires }}</span>
                    </time>
                    <time class="active" *ngSwitchCase="DisplayAs.Timer">
                        {{ data.linkInactivityMode === LinkInactivityMode.Link ? 'Link Timer' : 'User Timer'}} ({{ data.timer }}{{ data.timerUnit | timerUnit }})
                        <span *ngIf="!data.isExpired">{{ data.expires | expires }}</span>
                    </time>
                    <time class="pending" *ngSwitchCase="DisplayAs.RegistrationCutOff">
                        Cut-off {{ data.registrationEndDate | formatDate }}
                    </time>
                    <time class="active" *ngSwitchDefault>
                        Start Date {{ data.startDate || data.createdDate | formatDate }} MST
                    </time>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="expires">
            <th mat-header-cell *matHeaderCellDef>Expires</th>
            <td mat-cell *matCellDef="let data">
                <time>
                    {{ data.linkExpirationDate | formatDate }}
                </time>
            </td>
        </ng-container>

        <ng-container matColumnDef="archived">
            <th mat-header-cell *matHeaderCellDef>Archived</th>
            <td mat-cell *matCellDef="let data">
                <time>
                    {{ data.archivedDate | formatDate }}
                </time>
            </td>
        </ng-container>

        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Timestamp</th>
            <td mat-cell *matCellDef="let data">
                <time>
                    {{ data.timestamp | formatDate }}
                </time>
            </td>
        </ng-container>

        <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef>Tags</th>
            <td mat-cell *matCellDef="let model">
                <mat-chip-listbox *ngIf="model.tags | any">
                    <mat-chip-option *ngFor="let tag of model.tags" [removable]="false" [selectable]="false">
                        {{ tag }}
                    </mat-chip-option>
                </mat-chip-listbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let data">
                <mat-menu #actions="matMenu">
                    <button mat-menu-item (click)="onView(data)">
                        <mat-icon>edit</mat-icon>
                        View
                    </button>
                    <button mat-menu-item (click)="onCopyLink(data)">
                        <mat-icon>content_copy</mat-icon>
                        Duplicate
                    </button>
                    <button mat-menu-item (click)="onDelete(data)">
                        <mat-icon color="warn">delete</mat-icon>
                        Permanently Delete
                    </button>
                </mat-menu>
                <button mat-icon-button matTooltip="Duplicate link" (click)="onCopyLink(data)">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
                inactive: row.status === 0 || row.isExpired,
                expired: row.isExpired,
                'not-activated': !row.isLinkActivated,
                archived: row.isDeleted
            }"
        ></tr>
    </table>
    <mat-paginator
        #paginator
        (page)="onPage($event)"
        [length]="total"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[25, 50, 100]"
    >
    </mat-paginator>
</div>

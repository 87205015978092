import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageEventActions } from '../actions';

export const ViewArchivedEventLinkResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
) => {
    const rowKey = route.params?.['linkId'];

    store.dispatch(new CoreActions.UpdateGlobalLoading(true));
    return store.dispatch([new ManageEventActions.LoadArchivedLink(rowKey)]).pipe(
        take(1),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};

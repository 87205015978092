import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { filter, finalize, mergeMap, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageEventActions, ManageTemplateActions, ZoomActions } from '../actions';
import { ManageEventsState } from '../state/manage-events.state';

export const EditEventLinkResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    const { linkId } = route.params;

    store.dispatch([
        new CoreActions.UpdateGlobalLoading(true),
        new ManageEventActions.EnsureLoadLinks(),
        new ZoomActions.EnsureLoadWebinars(),
        new ZoomActions.EnsureLoadMeetings(),
        new ZoomActions.EnsureLoadWebinarTemplates(),
        new ZoomActions.EnsureLoadMeetingTemplates(),
        new ManageTemplateActions.EnsureLoadPreambleTemplates(),
    ]);

    return store.select(ManageEventsState.hasLoadedLinks()).pipe(
        filter(loaded => loaded === true),
        take(1),
        mergeMap(() => store.dispatch(new ManageEventActions.LoadLink(linkId))),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};

import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { MediaPlayerActions } from '@app/data/actions';
import { MediaPlayerState } from '@app/data/state/media-player.state';
import { MiniPlayerState } from '@app/shared/enums';
import { Store } from '@ngxs/store';

import { MiniPlayerComponent } from '../../components/mini-player/mini-player.component';

@Component({
    selector: 'shared-mini-player-container',
    templateUrl: './mini-player-container.component.html',
    styleUrls: ['./mini-player-container.component.scss'],
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        MiniPlayerComponent,
        AsyncPipe,
    ],
})
export class MiniPlayerContainerComponent {
    MiniPlayerState: typeof MiniPlayerState = MiniPlayerState;
    state: MiniPlayerState = MiniPlayerState.Hidden;

    isPlaying$ = this.store.select(MediaPlayerState.isPlaying());
    title$ = this.store.select(MediaPlayerState.getTitle());
    duration$ = this.store.select(MediaPlayerState.getDuration());
    position$ = this.store.select(MediaPlayerState.getPostition());
    canSeek$ = this.store.select(MediaPlayerState.canSeek());
    canPlay$ = this.store.select(MediaPlayerState.canPlay());
    isLoading$ = this.store.select(MediaPlayerState.isLoading());
    playbackRate$ = this.store.select(MediaPlayerState.getPlaybackRate());

    constructor(private store: Store) {}

    onPlay() {
        this.store.dispatch(new MediaPlayerActions.PlayMedia());
    }

    onPause() {
        this.store.dispatch(new MediaPlayerActions.PauseMedia());
    }

    onSeek(amount: number) {
        this.store.dispatch(new MediaPlayerActions.SeekMedia(amount));
    }

    onPlaybackRate(rate: number) {
        this.store.dispatch(new MediaPlayerActions.PlaybackRate(rate));
    }

    onChangeMediaPlayerState(state: MiniPlayerState) {
        this.store.dispatch(new MediaPlayerActions.SetMiniPlayerVisibility(state));
    }
}

@if (src) {
  @switch (display) {
    @case ('inline') {
      <div class="player" [ngStyle]="getStyling()">
        <div class="title">{{ title }}</div>
        @if (!hasInteracted) {
          <mat-icon class="play-button" matRipple (click)="onPlayMedia()">{{
            isPlayingMedia ? 'pause' : 'play_arrow'
          }}</mat-icon>
        }
        @if (hasInteracted) {
          <div
            matRipple
            (click)="onPlayMedia()"
            class="music-bars play-button"
            [ngClass]="{ paused: !isPlayingMedia }"
            >
            <span></span>
            <span></span>
            <span></span>
          </div>
        }
      </div>
    }
    @default {
      <div [ngStyle]="getStyling()">
        <audio controls controlsList="nodownload" preload="none">Your browser does not support the audio tag.</audio>
      </div>
    }
  }
}

@if (isLoading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

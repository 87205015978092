import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injector } from '@angular/core';

import { filter, map, tap } from 'rxjs';

import { Node } from '@tiptap/core';

import { Environment, ServerResult } from '../models';
import { APP_ENVIRONMENT } from '../tokens';
import { findNodePosition } from './drop-image';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        file: {
            /**
             * Add an image
             */
            setFile: (options: { src: string; title: string }) => ReturnType;
            uploadFile: (options: {
                src: File;
                title: string;
                progressFn: <T>(response: HttpEvent<T>, isComplete: boolean) => void;
            }) => ReturnType;
        };
    }
}

export const createFileExtension = (injector: Injector) => {
    const http = injector.get(HttpClient);
    const env = injector.get(APP_ENVIRONMENT) as Environment;

    return Node.create({
        name: 'file',
        inline: true,
        group: 'inline',
        selectable: true,
        priority: 100,
        draggable: true,
        defaultOptions: {},

        addAttributes: () => ({
            href: {},
            title: { default: null },
            download: { default: null },
        }),

        parseHTML: () => [
            {
                tag: 'a[download][href]',
                getAttrs: dom => {
                    if (typeof dom === 'string') return {};
                    const element = dom as HTMLAnchorElement;

                    return {
                        href: element.getAttribute('href'),
                        download: element.getAttribute('title'),
                        title: element.getAttribute('title'),
                    };
                },
            },
        ],
        renderHTML: ({ node, HTMLAttributes }) => {
            const { href, title } = node.attrs;

            HTMLAttributes['download'] = title;
            HTMLAttributes['title'] = title;
            HTMLAttributes['target'] = '_blank';
            HTMLAttributes['rel'] = 'noopener noreferrer nofollow';
            HTMLAttributes['href'] = href;
            HTMLAttributes['class'] = href === '#' ? 'uploading' : '';
            HTMLAttributes['data-upload'] = href === '#' ? '0%' : '';

            return ['a', HTMLAttributes, title];
        },

        addCommands() {
            const result = (attrs: any) => (state: any, dispatch: (p: any) => any) => {
                const { selection } = state;
                const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
                const node = this.type.create(attrs);
                const transaction = state.tr.insert(position, node);
                dispatch(transaction);
            };

            return {
                setFile:
                    options =>
                    ({ commands, tr, dispatch }) => {
                        // return commands.insertContent({
                        //     type: this.name,
                        //     attrs: options,
                        // });

                        const { selection } = tr;
                        const node = this.type.create(options);

                        if (dispatch) {
                            tr.replaceRangeWith(selection.from, selection.to, node);
                        }

                        return true;
                    },
                uploadFile:
                    options =>
                    ({ tr, view, editor }) => {
                        const { selection } = tr;
                        const node = this.type.create({
                            href: '#',
                            title: options.title,
                        });

                        tr.insert(selection.from, node);
                        const formData = new FormData();

                        formData.append('isPublic', false.toString());
                        formData.append('data', options.src);

                        http.post<ServerResult<string>>(env.serverUrl + '/file/public/upload', formData, {
                            reportProgress: true,
                            observe: 'events',
                            responseType: 'json',
                        })
                            .pipe(
                                map((response) => {
                                    if (response.type === HttpEventType.Response) {
                                        options.progressFn(response, true);
                                        return response.body?.data;
                                    } else if (response.type === HttpEventType.UploadProgress) {
                                        options.progressFn(response, response.loaded / (response?.total || 0) === 1);

                                    }

                                    return '';
                                }),
                                filter(src => src !== ''),
                                tap(url => {
                                    const from = findNodePosition(editor.state.doc, node);
                                    view.dispatch(view.state.tr.setNodeAttribute(from, 'href', url));
                                    view.dispatch(view.state.tr.setNodeAttribute(from, 'class', null));
                                }),
                            )
                            .subscribe();

                        return true;
                    },
                ...result,
            };
        },
    });
};

import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { BaseNode, GsVideoSummary } from '@app/data/models';
import { NodeType } from '@app/shared/enums';
import { NodeTreeComponent } from '../node-tree/node-tree.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { NodeColourPipe } from '../../../../../shared/src/lib/pipes/node-colour.pipe';
import { NodeTypeIconPipe } from '../../../../../shared/src/lib/pipes/node-type-icon.pipe';

@Component({
    selector: 'ui-session-gs-summary',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './session-gs-summary.component.html',
    styleUrls: ['./session-gs-summary.component.scss'],
    standalone: true,
    imports: [
    NodeTreeComponent,
    FlexModule,
    ExtendedModule,
    NgClass,
    MatIcon,
    NodeColourPipe,
    NodeTypeIconPipe
],
})
export class SessionGsSummaryComponent {
    @ViewChild('nodeTemplate', { static: true }) nodeTemplateRef: TemplateRef<any>;

    @Input() summary: GsVideoSummary | null = null;
    @Input() nodes: BaseNode[] | null = null;

    isDisabled(summary: GsVideoSummary, node: BaseNode) {
        if (node.type === NodeType.Audio || node.type === NodeType.Video) {
            return (
                summary !== null &&
                !summary.completedNodeIds.includes(node.rowKey) &&
                !summary.activeNodeIds.includes(node.rowKey)
            );
        }

        return false;
    }

    isComplete(summary: GsVideoSummary, node: BaseNode) {
        if (node.type === NodeType.Audio || node.type === NodeType.Video) {
            return summary !== null && summary.completedNodeIds.includes(node.rowKey);
        }

        return false;
    }
}

<section>
  @for (page of pages; track page; let ix = $index; let isFirstPage = $first) {
    <div class="divider">
      @if (!isFirstPage) {
        <mat-divider> </mat-divider>
      }
      <mat-icon [fxHide]="isFirstPage">unfold_more</mat-icon>
      Page {{ ix + 1 }} of {{ pages?.length }}
    </div>
    <ui-form-section [model]="page"></ui-form-section>
    @if (page.children | any) {
      <div fxLayout="column nowrap" fxLayoutAlign="center" class="sections">
        @for (child of page.children; track child; let ix = $index; let isFirst = $first) {
          <div fxFlex="0 0 100%">
            <div class="divider"></div>
            @switch (child.sectionType) {
              @case (FormSectionType.Text) {
                <ui-form-section-text
                  class="section"
                  [model]="child"
                ></ui-form-section-text>
              }
              @case (FormSectionType.Question) {
                <mat-card>
                  <mat-card-header>
                    <mat-card-title [innerHTML]="child.title | safeHtml"></mat-card-title>
                    <mat-card-subtitle>{{ getTotalSubmissionsForSection(child) }} Submissions</mat-card-subtitle>
                  </mat-card-header>
                  <mat-card-content>
                    @switch (child.type) {
                      @case (FormQuestionType.Matrix) {
                        <ng-container
                          *ngTemplateOutlet="matrix; context: { section: child, rows: child?.matrix?.rows }"
                          >
                        </ng-container>
                      }
                      @case (FormQuestionType.MatrixCheckbox) {
                        <ng-container
                          *ngTemplateOutlet="matrix; context: { section: child, rows: child?.matrix?.rows }"
                          >
                        </ng-container>
                      }
                      @default {
                        <ui-form-section-chart
                          [section]="child"
                          [submissions]="submissions"
                          [sessions]="sessions"
                          [includeDraftResponses]="includeDraftResponses"
                          (showDetails)="onShowDetails($event)"
                        ></ui-form-section-chart>
                      }
                    }
                  </mat-card-content>
                </mat-card>
              }
            }
          </div>
        }
      </div>
    }
  }
</section>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  [cdkConnectedOverlayOrigin]="_overlayOrigin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayPositions]="_positions"
  [cdkConnectedOverlayWidth]="_overlayWidth"
  (backdropClick)="close()"
  (attach)="_onAttached()"
  (detach)="close()"
  >
  <div #panel role="listbox" tabindex="-1" class="mat-mdc-select-panel mdc-menu-surface mdc-menu-surface--open">
    <ui-form-chart-tooltip
      (selectItem)="onSelectItem($event)"
      (copyToClipboard)="onCopyToClipboard($event)"
      [link]="link"
      [pages]="pages"
      [webinars]="webinars"
      [meetings]="meetings"
      [category]="tooltipLabel"
      [sessions]="$any(tooltipSessions)"
    ></ui-form-chart-tooltip>
  </div>
</ng-template>

<ng-template #matrix let-section="section" let-rows="rows">
  @for (row of rows; track row; let ix = $index) {
    <h3>{{ rows[ix].text }}</h3>
    <ui-form-section-chart
      [matrixRowIndex]="ix"
      [section]="section"
      [submissions]="submissions"
      [includeDraftResponses]="includeDraftResponses"
      >
    </ui-form-section-chart>
  }
</ng-template>

import { AsyncPipe, VERSION as ngVersion } from '@angular/common';
import { ApplicationRef, Component, inject } from '@angular/core';
import { VERSION as ngMaterialVersion } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { distinctUntilChanged } from 'rxjs';

import { AuthState } from '@app/data/state/auth.state';
import { CoreState } from '@app/data/state/core.state';
import { BuildInfoComponent, ConnectionStatusComponent } from '@app/shared/components';
import { GlobalLoadingComponent } from '@app/ui/components';
import { Store } from '@ngxs/store';
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, SubTitle, Title, Tooltip } from 'chart.js';

import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterModule,

        AsyncPipe,

        GlobalLoadingComponent,
        BuildInfoComponent,
        ConnectionStatusComponent,
    ],
})
export class AppComponent {
    store = inject(Store);

    appRef = inject(ApplicationRef);
    matIconRegistry = inject(MatIconRegistry);
    domSanitizer = inject(DomSanitizer);

    isLoading$ = this.store.select(CoreState.isLoading());
    isConnected$ = this.store.select(CoreState.isConnected()).pipe(distinctUntilChanged());
    isAuthenticated$ = this.store.select(AuthState.isAuthenticated());

    constructor() {
        console.log('@angular/core', ngVersion.full);
        console.log('@angular/material', ngMaterialVersion.full);
        console.log(`Build ${environment.buildVersion}-${environment.buildCommit}`);
        console.log(`Build Date`, new Date(environment.buildDate as string));
        Chart.register(
            // PieController,
            BarController,
            // ArcElement,
            CategoryScale,
            LinearScale,
            BarElement,
            Legend,
            Title,
            Tooltip,
            SubTitle,
        );
        this.matIconRegistry.addSvgIcon(
            'zoom_logo',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                'https://cdn.shareablelink.online/production-public/apps/admin/assets/zoom-logo.svg',
            ),
        );
    }
}

import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTree, MatTreeNodeDef, MatTreeNode, MatTreeNodePadding, MatTreeNodeToggle } from '@angular/material/tree';

import { Node } from '@app/data/models';
import { NodeStatus, NodeType, VideoOption } from '@app/shared/enums';
import { containsPath, sortNodes } from '@app/shared/util';
import { NgIf, NgClass } from '@angular/common';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIcon } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatIconButton } from '@angular/material/button';
import { NodeColourPipe } from '../../../../../shared/src/lib/pipes/node-colour.pipe';
import { NodeTypeIconPipe } from '../../../../../shared/src/lib/pipes/node-type-icon.pipe';

interface TreeNode {
    id: string;
    name: string;
    selected: boolean;
    data: Node;
    parentId: string;
    children: TreeNode[];
}

interface FlatNode {
    expandable: boolean;
    data: Node;
    name: string;
    level: number;
    selected: boolean;
    id: string;
}

@Component({
    selector: 'admin-select-content',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./select-content.component.html`,
    styleUrls: [`./select-content.component.scss`],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectContentComponent,
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        BreadcrumbComponent,
        MatTree,
        MatTreeNodeDef,
        MatTreeNode,
        MatTreeNodePadding,
        FlexModule,
        MatCheckbox,
        MatIcon,
        ExtendedModule,
        NgClass,
        MatIconButton,
        MatTreeNodeToggle,
        NodeColourPipe,
        NodeTypeIconPipe,
    ],
})
export class SelectContentComponent implements ControlValueAccessor, OnChanges {
    NodeType: typeof NodeType = NodeType;
    NodeVideoOption: typeof VideoOption = VideoOption;
    NodeStatus: typeof NodeStatus = NodeStatus;

    touched = false;
    disabled = false;

    path: string | null = null;
    selectedNodeIds: string[] = [];
    checklistSelection: SelectionModel<Node> = new SelectionModel<Node>(true /* multiple */);
    nodeDataSource: MatTreeFlatDataSource<TreeNode, FlatNode>;
    treeControl: FlatTreeControl<FlatNode> = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable,
    );
    treeFlattener = new MatTreeFlattener(
        this.transformer.bind(this) as (node: TreeNode, level: number) => FlatNode,
        this.getLevel,
        node => node.expandable,
        node => node.children,
    );

    @Input() showNodeOptions = true;
    @Input() nodes: Node[] | null = [];
    @Input() nodeOptions: { associatedNodeIds: string[] } | null = null;

    @Output() readonly nodeSelected: EventEmitter<Node> = new EventEmitter();
    @Output() readonly nodeOptionsChange: EventEmitter<{ associatedNodeIds: string[] }> = new EventEmitter();

    onTouched = () => {};

    onChange = (value: any) => {};

    writeValue(obj: any): void {
        this.selectedNodeIds = [...(obj || [])];
        this.updateSelectedNodes(this.selectedNodeIds); // cheap clone
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    leafSelectionToggle(node: FlatNode): void {
        this.markAsTouched();

        this.checklistSelection.toggle(node.data);
        this.checkAllParentsSelection(node.data);
        this.onChange(this.checklistSelection.selected.map(n => n.rowKey));
    }

    onAssociateLinkToFolder(event: MatCheckboxChange, model: FlatNode) {
        const nodeOptions = this.nodeOptions || { associatedNodeIds: [] };
        nodeOptions.associatedNodeIds.push(model.id);

        if (!event.checked) {
            nodeOptions.associatedNodeIds = nodeOptions.associatedNodeIds.filter(n => n !== model.id);
        }

        this.nodeOptionsChange.emit({
            ...nodeOptions,
        });
    }

    isFolderAssociatedWithLink(node: FlatNode) {
        const nodeOptions = this.nodeOptions || { associatedNodeIds: [] };
        return nodeOptions.associatedNodeIds.includes(node.id);
    }

    nodeSelectionToggle(node: FlatNode): void {
        this.markAsTouched();
        this.checklistSelection.toggle(node.data);
        const descendants = this.treeControl.getDescendants(node).map(n => n.data);
        const isSelected = this.checklistSelection.isSelected(node.data);
        isSelected ? this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);

        if (!isSelected) {
            const nodeOptions = this.nodeOptions || { associatedNodeIds: [] };
            nodeOptions.associatedNodeIds = nodeOptions.associatedNodeIds.filter(n => n !== node.id);

            this.nodeOptionsChange.emit({
                ...nodeOptions,
            });
        }

        // Force update for the parent
        descendants.forEach(child => this.checklistSelection.isSelected(child));
        this.checkAllParentsSelection(node.data);
        this.onChange(this.checklistSelection.selected.map(n => n.rowKey));
        //this.selectedNodesChange.emit(this.checklistSelection.selected.map(n => n));
    }

    descendantsAllSelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.length > 0 && descendants.every(child => this.checklistSelection.isSelected(child.data));
    }

    checkAllParentsSelection(node: Node): void {
        let parent: Node | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    checkRootNodeSelection(node: Node): void {
        if (!this.nodes) return;

        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.nodes.filter(n => (n.path || '').includes(node.rowKey));
        const descAllSelected = descendants.every(child => this.checklistSelection.isSelected(child));
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    descendantsPartiallySelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child.data));
        return result && !this.descendantsAllSelected(node);
    }

    /* Get the parent node of a node */
    getParentNode(node: Node): Node | null {
        return this.nodes?.find(n => n.rowKey === node.parentId) || null;
    }

    updateNodeDataSource(path: string | null = null) {
        const expandedNodeIds: string[] = [];
        const nodes: TreeNode[] = (this.nodes as Node[])
            .filter(n => path === null || containsPath(n.path, path))
            .sort(sortNodes)
            .map(
                node =>
                    ({
                        name: node.title,
                        id: node.rowKey,
                        parentId: node.parentId,
                        isFavourite: false,
                        selected: false,
                        children: [],
                        data: node,
                    } as TreeNode),
            )
            .reduce((acc: TreeNode[], node: TreeNode, ix: number, arr: TreeNode[]) => {
                let parent = arr.find(n => n.id === node.parentId);

                if (parent) {
                    parent.children.push(node);
                } else {
                    acc.push(node);
                }

                if (this.selectedNodeIds && this.selectedNodeIds.includes(node.id)) {
                    while (parent) {
                        expandedNodeIds.push(parent.id);
                        parent = arr.find(n => n.id === parent?.parentId);
                    }
                }

                return acc;
            }, [] as TreeNode[]);

        this.nodeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, []);
        this.nodeDataSource.data = nodes;

        this.treeControl.expansionModel.select(
            ...this.treeControl.dataNodes.filter(n => expandedNodeIds.some(id => id === n.id)),
        );
    }

    updateSelectedNodes(nodeIds: string[]): void {
        this.checklistSelection.clear();
        this.nodes?.filter(n => nodeIds.includes(n.rowKey)).forEach(n => this.checklistSelection.select(n));
    }

    onNodeSelected(node: Node) {
        this.markAsTouched();
        this.nodeSelected.emit(node);
    }

    setRoot(node: Node | null): void {
        if (node && node.type !== NodeType.Node) {
            return;
        }

        this.path = node ? (node.path ? `${node.path}.${node.rowKey}` : node.rowKey) : null;
        this.updateNodeDataSource(this.path);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['nodes']) {
            this.updateNodeDataSource(this.path);
        }
    }

    hasChild(_: number, node: FlatNode): boolean {
        return node.expandable;
    }

    isExpanded(node: FlatNode): boolean {
        return this.treeControl.isExpanded(node);
    }

    private getLevel(node: FlatNode): number {
        return node.level;
    }

    private transformer(node: TreeNode, level: number): FlatNode {
        return {
            level,
            expandable: node.children.length > 0,
            name: node.name,
            data: node.data,
            id: node.id,
            selected: node.selected,
        };
    }
}

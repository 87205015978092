import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { catchError, finalize, forkJoin, map, mergeMap, Observable, tap } from 'rxjs';

import { NotificationActions } from '@app/data/actions';
import {
    ChecklistLinkTemplateModel,
    ChecklistTemplate,
    ConfirmArchiveResult,
    EditFormLinkModel,
    EditShareableLinkFormModel,
    FormLinkTemplateModel,
    FormSection,
    FormState,
    FormSubmissionMessage,
    PreambleTemplate,
    ShareableLinkTemplateModel,
} from '@app/data/models';
import { handleNotFoundError, transformResponseToText } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { RouterState } from '@app/data/state/router.state';
import {
    BlobContainer,
    DayOfWeek,
    FormLinkType,
    FormQuestionType,
    FormSectionType,
    LinkExpiration,
    LinkInactivityMode,
    LinkStatus,
    LinkType,
    PaymentProvider,
    PublicAccessMode,
    RegistrationMode,
    TimerUnit,
    VideoOption,
} from '@app/shared/enums';
import { DEFAULT_TIME_ZONE, generatePassword, randomId } from '@app/shared/util';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';

import { AdminActions, ManageTemplateActions } from '../actions';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog.component';
import { ManageTemplatesService } from '../services/manage-templates.service';

interface EditShareableLinkTemplateFormModel extends EditShareableLinkFormModel {
    templateName: string;
    isTemplatePublic: boolean;
    checklist: string | null;
}

interface EditFormLinkTemplateFormModel extends EditFormLinkModel {
    templateName: string;
    isTemplatePublic: boolean;
    checklist: string | null;
}

export interface StateModel {
    isLoading: boolean;
    isSaving: boolean;

    copy:
        | PreambleTemplate
        | ShareableLinkTemplateModel
        | ChecklistLinkTemplateModel
        | FormLinkTemplateModel
        | ChecklistTemplate
        | null;

    isSavingChecklist: boolean;
    isLoadingChecklist: boolean;
    checklist: string | null;
    formSubmissionMessages: FormSubmissionMessage[];

    isLoadingChecklistTemplates: boolean;
    hasLoadedChecklistTemplates: boolean;
    checklistTemplates: ChecklistTemplate[];

    isSavingPreamble: boolean;
    isLoadingPreamble: boolean;
    isLoadingPreambleForLink: boolean;
    isLoadingPreambleTemplates: boolean;
    hasLoadedPreambleTemplates: boolean;
    preambleTemplates: PreambleTemplate[];

    preambleTemplate: string | null;

    isLoadingSharableLinkTemplates: boolean;
    hasLoadedShareableLinkTemplates: boolean;
    shareableLinkTemplates: ShareableLinkTemplateModel[];

    isLoadingFormLinkTemplates: boolean;
    hasLoadedFormLinkTemplates: boolean;
    formLinkTemplates: FormLinkTemplateModel[];

    isLoadingChecklistLinkTemplates: boolean;
    hasLoadedChecklistLinkTemplates: boolean;
    checklistLinkTemplates: ChecklistLinkTemplateModel[];

    formTemplateHeader: FormState<{ templateName: string; isTemplatePublic: boolean; canRequestLink: boolean }>;
    formShareableLinkTemplate: FormState<EditShareableLinkTemplateFormModel>;
    formFormLinkTemplate: FormState<EditFormLinkTemplateFormModel>;
    formChecklistLinkTemplate: FormState<ChecklistLinkTemplateModel>;
    formChecklistTemplate: FormState<ChecklistTemplate>;
    formCustomLinkPreambleTemplate: FormState<PreambleTemplate>;
    formPreambleTemplate: FormState<PreambleTemplate>;

    errors: any;
}

@State<StateModel>({
    name: 'manageTemplates',
    defaults: {
        isLoading: false,
        isSaving: false,

        copy: null,

        isSavingChecklist: false,
        isLoadingChecklist: false,
        checklist: null,
        formSubmissionMessages: [],

        isLoadingChecklistTemplates: false,
        hasLoadedChecklistTemplates: false,
        checklistTemplates: [],

        isSavingPreamble: false,
        isLoadingPreamble: false,
        isLoadingPreambleForLink: false,
        isLoadingPreambleTemplates: false,
        hasLoadedPreambleTemplates: false,
        preambleTemplates: [],

        preambleTemplate: null,

        isLoadingSharableLinkTemplates: false,
        hasLoadedShareableLinkTemplates: false,
        shareableLinkTemplates: [],

        isLoadingFormLinkTemplates: false,
        hasLoadedFormLinkTemplates: false,
        formLinkTemplates: [],

        isLoadingChecklistLinkTemplates: false,
        hasLoadedChecklistLinkTemplates: false,
        checklistLinkTemplates: [],

        formTemplateHeader: {
            model: null,
            dirty: false,
            status: '',
            errors: null,
        },
        formShareableLinkTemplate: {
            model: null,
            dirty: false,
            status: '',
            errors: null,
        },
        formFormLinkTemplate: {
            model: null,
            dirty: false,
            status: '',
            errors: null,
        },
        formChecklistLinkTemplate: {
            model: null,
            dirty: false,
            status: '',
            errors: null,
        },
        formChecklistTemplate: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        formCustomLinkPreambleTemplate: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        formPreambleTemplate: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },

        errors: null,
    },
})
@Injectable({
    providedIn: 'root',
})
export class ManageTemplatesState {
    static isLoading() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.isLoading);
    }

    static isLoadingTemplates() {
        return createSelector(
            [ManageTemplatesState],
            (state: StateModel) =>
                state.isLoadingChecklistLinkTemplates ||
                state.isLoadingChecklistTemplates ||
                state.isLoadingFormLinkTemplates ||
                state.isLoadingSharableLinkTemplates ||
                state.isLoadingPreambleTemplates,
        );
    }

    static isSaving() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.isSaving);
    }

    static hasLoadedShareableLinkTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.hasLoadedShareableLinkTemplates);
    }

    static hasLoadedFormLinkTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.hasLoadedFormLinkTemplates);
    }

    static getChecklist() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.checklist);
    }

    static getChecklistTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.checklistTemplates);
    }

    static getPreambleTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.preambleTemplates);
    }

    static getPreambleTemplateForLink() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.preambleTemplate);
    }

    static hasLoadedChecklistTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.hasLoadedChecklistTemplates);
    }

    static hasLoadedPreambleTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.hasLoadedPreambleTemplates);
    }

    static hasLoadedChecklistLinkTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.hasLoadedChecklistLinkTemplates);
    }

    static getShareableLinkTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.shareableLinkTemplates);
    }

    static getFormLinkTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.formLinkTemplates);
    }

    static getChecklistLinkTemplates() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.checklistLinkTemplates);
    }

    static getShareableLinkTemplateForm() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.formShareableLinkTemplate);
    }

    static getFormLinkTemplateForm() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.formFormLinkTemplate);
    }

    static getChecklistLinkTemplateForm() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.formChecklistLinkTemplate);
    }

    static getChecklistTemplateForm() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.formChecklistTemplate);
    }

    static getPreambleTemplateForm() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.formPreambleTemplate);
    }

    static getTemplateHeaderForm() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.formTemplateHeader);
    }

    static getLinkId() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId);
    }

    static isNewLink() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId === 'new');
    }

    static getCopyLink() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.copy);
    }

    static getErrors() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.errors);
    }

    static getFormSubmissionMessages() {
        return createSelector([ManageTemplatesState], (state: StateModel) => state.formSubmissionMessages);
    }

    static getChecklistTemplateModel() {
        return createSelector(
            [
                ManageTemplatesState.getChecklistTemplates(),
                ManageTemplatesState.getCopyLink(),
                RouterState.selectRouteParam('linkId'),
                ManageTemplatesState.isNewLink(),
            ],
            (
                templates: ChecklistTemplate[],
                copy: ChecklistTemplate,
                linkId: string,
                isNew: boolean,
            ): ChecklistTemplate | null => {
                if (isNew) {
                    if (copy) {
                        return {
                            ...copy,
                            rowKey: null,
                            name: '',
                        } as ChecklistTemplate;
                    }

                    return {
                        rowKey: null,
                        content: '',
                        createdDate: new Date(),
                        name: '',
                        hasLoaded: true,
                    } as ChecklistTemplate;
                }

                const link = templates.find(l => l.rowKey === linkId) || null;

                if (link) {
                    return { ...link };
                }

                return null;
            },
        );
    }

    static getPreambleTemplateModel() {
        return createSelector(
            [
                ManageTemplatesState.getPreambleTemplates(),
                ManageTemplatesState.getCopyLink(),
                RouterState.selectRouteParam('linkId'),
                ManageTemplatesState.isNewLink(),
            ],
            (
                templates: PreambleTemplate[],
                copy: PreambleTemplate,
                linkId: string,
                isNew: boolean,
            ): PreambleTemplate | null => {
                if (isNew) {
                    if (copy) {
                        return {
                            ...copy,
                            rowKey: null,
                            name: '',
                        } as PreambleTemplate;
                    }

                    return {
                        rowKey: null,
                        content: '',
                        createdDate: new Date(),
                        name: '',
                        hasLoaded: true,
                    } as PreambleTemplate;
                }

                const link = templates.find(l => l.rowKey === linkId) || null;

                if (link) {
                    return { ...link };
                }

                return null;
            },
        );
    }

    static getShareableLinkTemplateModel() {
        return createSelector(
            [
                ManageTemplatesState.getShareableLinkTemplates(),
                ManageTemplatesState.getCopyLink(),
                RouterState.selectRouteParam('linkId'),
                ManageTemplatesState.isNewLink(),
                ManageTemplatesState.getChecklist(),
            ],
            (
                links: ShareableLinkTemplateModel[],
                copy: ShareableLinkTemplateModel,
                linkId: string,
                isNew: boolean,
                template: string,
            ) => {
                if (isNew) {
                    if (copy) {
                        return {
                            ...copy,
                            rowKey: null,
                            isDeleted: false,
                            activationDate: null,
                            createdDate: new Date(),
                            status: LinkStatus.Active,
                            activeSubmissionCount: 0,
                            checklist: template,
                        };
                    }
                    return {
                        rowKey: null,
                        description: '',
                        nodeIds: [],
                        videoSettings: {
                            allowListeners: false,
                            autoplay: false,
                            elapsedTimeInSeconds: 0,
                            enableWaitingRoom: false,
                            waitingRoomOffsetInSeconds: 60,
                            showWatermark: true,
                            showBackgroundWatermark: true,
                            showControls: true,
                            showPlayButton: true,
                            showFullscreenButton: true,
                            showProgress: true,
                            allowScrubbing: true,
                            showPlaybackSpeed: false,
                            resumePlayback: false,
                            startTime: VideoOption.None,
                            countDownAssetId: '',
                            isLiveStream: false,
                        },
                        password: '',
                        influencerPassword: '',
                        isDeleted: false,
                        url: '',
                        requestedBy: '',
                        activationDate: null,
                        status: LinkStatus.Active,
                        timer: null,
                        timerUnit: TimerUnit.Hours,
                        isNameRequired: true,
                        isPasswordRequired: true,
                        timerEndDate: null,
                        activeSubmissionCount: 0,
                        sessionLimit: 1,
                        startDate: null,
                        endDate: null,
                        type: LinkType.Shareable,
                        requiresApproval: false,
                        isLiveStream: false,
                        hasRegistration: false,
                        displayWatermark: true,
                        linkExpiration: LinkExpiration.None,
                        questions: [],
                        lastActivityDate: null,
                        tags: [],
                        lateRegistrationCount: 0,
                        approvalCount: 0,
                        createdDate: new Date(),
                        registrationMode: RegistrationMode.Default,
                        timeZone: DEFAULT_TIME_ZONE,
                        isGuidedSequence: false,
                        registrationEndDate: null,
                        timestamp: null,
                        archivedDate: null,
                        dayOfWeek: DayOfWeek.Sunday,
                        linkExpirationDate: null,
                        checklistTemplateId: null,
                        linkInactivityMode: LinkInactivityMode.Default,
                        influencerCount: 0,
                        isPublic: false,
                        publicAccessMode: PublicAccessMode.ViewOnly,
                        resubmissionCount: 0,
                        nodeOptions: {
                            associatedNodeIds: [],
                        },
                        checklist: '',
                        isPreambleRequired: true,
                        preambleTemplateId: null,
                        checklistTemplateIds: [],

                        templateName: '',
                        isTemplate: true,
                        isTemplatePublic: false,
                        canRequestLink: false,
                        requestedByVisitorId: null,

                        amount: 0,
                        productCode: '',
                        productName: '',
                        productMessage: '',
                        allowLinkAccess: false,
                        hasAdminAcknowledged: true,
                        autoArchive: true,
                        isExpired: false,
                        isActive: true,
                        displayRegistrationCountdown: false,
                        allowUsersToRequestMoreTime: false,
                    } as ShareableLinkTemplateModel;
                }

                const link = links.find(l => l.rowKey === linkId) || null;

                if (link) {
                    return { ...link, checklist: template };
                }

                return null;
            },
        );
    }

    static getFormLinkTemplateModel() {
        return createSelector(
            [
                ManageTemplatesState.getFormLinkTemplates(),
                ManageTemplatesState.getCopyLink(),
                RouterState.selectRouteParam('linkId'),
                ManageTemplatesState.isNewLink(),
                ManageTemplatesState.getFormSubmissionMessages(),
            ],
            (
                links: FormLinkTemplateModel[],
                copy: FormLinkTemplateModel,
                linkId: string,
                isNew: boolean,
                submissionMessages: FormSubmissionMessage[],
            ) => {
                if (isNew) {
                    if (copy) {
                        return {
                            ...copy,
                            rowKey: null,
                            isDeleted: false,
                            activationDate: null,
                            createdDate: new Date(),
                            status: LinkStatus.Active,
                            activeSubmissionCount: 0,
                            submissionMessages,
                        };
                    }
                    return {
                        rowKey: null,
                        description: '',
                        nodeIds: [],
                        questions: [],
                        videoSettings: {
                            allowListeners: false,
                            autoplay: false,
                            elapsedTimeInSeconds: 0,
                            enableWaitingRoom: false,
                            waitingRoomOffsetInSeconds: 60,
                            showWatermark: true,
                            showBackgroundWatermark: true,
                            showControls: true,
                            showPlayButton: true,
                            showFullscreenButton: true,
                            showProgress: true,
                            allowScrubbing: true,
                            showPlaybackSpeed: false,
                            resumePlayback: false,
                            startTime: VideoOption.None,
                            countDownAssetId: '',
                            isLiveStream: false,
                        },
                        password: '',
                        influencerPassword: '',
                        isDeleted: false,
                        url: '',
                        requestedBy: '',
                        activationDate: null,
                        status: LinkStatus.Active,
                        timer: null,
                        timerUnit: TimerUnit.Hours,
                        isNameRequired: true,
                        isPasswordRequired: false,

                        allowResubmission: false,
                        notifyOnUpdates: false,
                        notifyOnSubmission: true,

                        timerEndDate: null,
                        sessionLimit: null,
                        startDate: null,
                        endDate: null,
                        type: LinkType.Form,
                        requiresApproval: false,
                        requiresSubmission: true,
                        isGuidedSequence: false,
                        isPollLink: false,
                        formLinkType: FormLinkType.Form,
                        pages: [
                            {
                                id: randomId(6),
                                children: [
                                    {
                                        id: randomId(6),
                                        sectionType: FormSectionType.Question,
                                        title: `Question 1`,
                                        hint: '',
                                        description: '',
                                        minLabel: '',
                                        maxLabel: '',
                                        mediaAssetId: null,
                                        allowScrubbing: false,
                                        showPlaybackSpeed: false,
                                        resumePlayback: false,
                                        futureDatesOnly: false,
                                        fileId: null,
                                        fileName: null,
                                        isEnabled: true,
                                        showOther: false,
                                        isRequired: true,
                                        type: FormQuestionType.MultipleChoice,
                                        options: [{ id: randomId(6), text: `Option 1` }],
                                        matrix: { rows: [], columns: [] },
                                        bookingUrl: null,
                                        value: null,

                                        paymentProvider: PaymentProvider.Paypal,
                                        amount: 0,
                                        productCode: null,
                                        productName: null,
                                        hideSections: false,

                                        webinarId: null,
                                        webinarUniqueId: null,
                                        webinarTopic: null,
                                        webinarAgenda: null,
                                        webinarTemplateId: null,

                                        meetingId: null,
                                        meetingUniqueId: null,
                                        meetingTopic: null,
                                        meetingAgenda: null,
                                        meetingTemplateId: null,

                                        allowGuestRegistration: false,
                                        showGuestRegistrationJoinLink: false,
                                        showGuestRegistrationCopy: true,
                                        showJoinUrl: false,
                                        showJoinButton: false,
                                        showCopyLinkButton: false,
                                    },
                                ],
                                description: '',
                                sectionType: FormSectionType.Page,
                                title: 'Untitled Page 1',
                                backgroundColour: null,
                                fontColour: null,
                                goToPage: '',
                                isEnabled: true,
                            },
                        ],

                        hasRegistration: false,
                        displayWatermark: true,
                        linkExpiration: LinkExpiration.None,
                        linkInactivityMode: LinkInactivityMode.Default,
                        lastActivityDate: null,
                        tags: [],

                        createdDate: new Date(),
                        registrationMode: RegistrationMode.Default,
                        timeZone: 'America/Edmonton',
                        registrationEndDate: null,
                        timestamp: null,
                        archivedDate: null,
                        linkExpirationDate: null,
                        submissionMessage: `<p>Your submission has been completed.</p>
                                            <p>Thank you for your time.</p>`,

                        incompleteCount: 0,
                        completeCount: 0,
                        lateRegistrationCount: 0,
                        activeSubmissionCount: 0,
                        resubmissionCount: 0,
                        approvalCount: 0,
                        formSubmissionCount: 0,
                        requiresAttentionCount: 0,
                        influencerCount: 0,

                        isPreambleRequired: true,
                        preambleTemplateId: null,
                        checklistTemplateIds: [],
                        requestedByVisitorId: null,

                        templateName: '',
                        isTemplatePublic: false,
                        canRequestLink: false,
                        hasAdminAcknowledged: true,

                        submissionMessages: [
                            {
                                content: '<p>Your submission has been completed.</p><p>Thank you for your time.</p>',
                                name: 'Default',
                                rowKey: randomId(6),
                                sasUri: '',
                                hasLoaded: true,
                            },
                        ],
                        archivedBy: null,
                        archivedReason: null,
                        autoArchive: true,
                        isExpired: false,
                        isActive: true,
                        displayRegistrationCountdown: false,
                        allowUsersToRequestMoreTime: false,
                    } as FormLinkTemplateModel;
                }

                const link = links.find(l => l.rowKey === linkId) || null;

                if (link) {
                    return { ...link, submissionMessages };
                }

                return null;
            },
        );
    }

    static getChecklistLinkTemplateModel() {
        return createSelector(
            [
                ManageTemplatesState.getChecklistLinkTemplates(),
                ManageTemplatesState.getChecklistTemplates(),
                ManageTemplatesState.getCopyLink(),
                RouterState.selectRouteParam('linkId'),
                ManageTemplatesState.isNewLink(),
            ],
            (
                links: ChecklistLinkTemplateModel[],
                checklists: ChecklistTemplate[],
                copy: ChecklistLinkTemplateModel,
                linkId: string,
                isNew: boolean,
            ) => {
                if (isNew) {
                    if (copy) {
                        return {
                            ...copy,
                            rowKey: null,
                            isDeleted: false,
                            activationDate: null,
                            createdDate: new Date(),
                            status: LinkStatus.Active,
                            activeSubmissionCount: 0,
                        };
                    }

                    return {
                        rowKey: null,
                        questions: [],
                        password: generatePassword(),
                        isDeleted: false,
                        url: '',
                        requestedBy: '',
                        activationDate: null,
                        status: LinkStatus.Active,
                        timer: null,
                        timerUnit: TimerUnit.Hours,
                        isNameRequired: true,
                        isPasswordRequired: false,

                        timerEndDate: null,
                        sessionLimit: null,
                        startDate: null,
                        endDate: null,
                        type: LinkType.Checklist,
                        requiresApproval: false,

                        hasRegistration: false,
                        displayWatermark: true,
                        linkExpiration: LinkExpiration.None,
                        linkInactivityMode: LinkInactivityMode.Default,
                        lastActivityDate: null,
                        tags: [],

                        createdDate: new Date(),
                        registrationMode: RegistrationMode.Default,
                        timeZone: DEFAULT_TIME_ZONE,
                        registrationEndDate: null,
                        timestamp: null,
                        archivedDate: null,
                        linkExpirationDate: null,

                        lateRegistrationCount: 0,
                        activeSubmissionCount: 0,
                        approvalCount: 0,
                        preambleTemplateId: null,
                        isPreambleRequired: true,
                        checklistTemplateIds: [],
                        checklist: '',
                        requestedByVisitorId: null,

                        templateName: '',
                        isTemplatePublic: false,
                        canRequestLink: false,
                        hasAdminAcknowledged: true,
                        autoArchive: true,
                        isExpired: false,
                        isActive: true,
                        sasUris: [],
                        displayRegistrationCountdown: false,
                        allowUsersToRequestMoreTime: false,
                    } as ChecklistLinkTemplateModel;
                }

                const link = links.find(l => l.rowKey === linkId) || null;

                if (link) {
                    return { ...link };
                }

                return null;
            },
        );
    }

    constructor(
        private manageTemplatesService: ManageTemplatesService,
        private blobService: BlobService,
        private store: Store,
        private matDialog: MatDialog,
    ) {}

    @Action(ManageTemplateActions.SetCustomPreamble)
    setCustomPreamble({ patchState }: StateContext<StateModel>, { content }: ManageTemplateActions.SetCustomPreamble): void {
        patchState({
            preambleTemplate: content,
        });
    }

    @Action(ManageTemplateActions.CopyLink)
    copyLink({ patchState }: StateContext<StateModel>, { link }: ManageTemplateActions.CopyLink): void {
        patchState({
            copy: link,
        });
    }

    @Action(ManageTemplateActions.EnsureLoadLinkTemplates)
    ensureLoadLinkTemplates({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedShareableLinkTemplates } = getState();

        if (hasLoadedShareableLinkTemplates) {
            return;
        }

        return dispatch([new ManageTemplateActions.LoadShareableLinkTemplates()]);
    }

    @Action(ManageTemplateActions.EnsureLoadFormLinkTemplates)
    ensureLoadFormLinkTemplates({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedFormLinkTemplates } = getState();

        if (hasLoadedFormLinkTemplates) {
            return;
        }

        return dispatch([new ManageTemplateActions.LoadFormLinkTemplates()]);
    }

    @Action(ManageTemplateActions.EnsureLoadChecklistTemplates)
    ensureLoadChecklistTemplates({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedChecklistTemplates } = getState();

        if (hasLoadedChecklistTemplates) {
            return;
        }

        return dispatch([new ManageTemplateActions.LoadChecklistTemplates()]);
    }

    @Action(ManageTemplateActions.EnsureLoadPreambleTemplates)
    ensureLoadPreambleTemplates({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedPreambleTemplates } = getState();

        if (hasLoadedPreambleTemplates) {
            return;
        }

        return dispatch([new ManageTemplateActions.LoadPreambleTemplates()]);
    }

    @Action(ManageTemplateActions.EnsureLoadChecklistLinkTemplates)
    ensureLoadChecklistLinkTemplates({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedChecklistLinkTemplates } = getState();

        if (hasLoadedChecklistLinkTemplates) {
            return;
        }

        return dispatch([new ManageTemplateActions.LoadChecklistLinkTemplates()]);
    }

    @Action(ManageTemplateActions.LoadShareableLinkTemplates)
    loadShareableLinkTemplates({ patchState }: StateContext<StateModel>): Observable<any> | void {
        patchState({ isLoadingSharableLinkTemplates: true });

        return this.manageTemplatesService.getTemplates<ShareableLinkTemplateModel>(LinkType.Shareable).pipe(
            tap(templates => {
                patchState({ shareableLinkTemplates: templates });
            }),
            catchError(err => this.store.dispatch(new ManageTemplateActions.LoadShareableLinkTemplatesFailure(err))),
            finalize(() => {
                patchState({ isLoadingSharableLinkTemplates: false, hasLoadedShareableLinkTemplates: true });
            }),
        );
    }

    @Action(ManageTemplateActions.LoadFormLinkTemplates)
    loadFormLinkTemplates({ patchState }: StateContext<StateModel>): Observable<any> | void {
        patchState({ isLoadingFormLinkTemplates: true });

        return this.manageTemplatesService.getTemplates<FormLinkTemplateModel>(LinkType.Form).pipe(
            tap(templates => {
                patchState({ formLinkTemplates: templates });
            }),
            catchError(err => this.store.dispatch(new ManageTemplateActions.LoadFormLinkTemplatesFailure(err))),
            finalize(() => {
                patchState({ isLoadingFormLinkTemplates: false, hasLoadedFormLinkTemplates: true });
            }),
        );
    }

    @Action(ManageTemplateActions.LoadChecklistLinkTemplates)
    loadChecklistLinkTemplates({ patchState }: StateContext<StateModel>): Observable<any> | void {
        patchState({ isLoadingChecklistLinkTemplates: true });

        return this.manageTemplatesService.getTemplates<ChecklistLinkTemplateModel>(LinkType.ChecklistLink).pipe(
            tap(templates => {
                patchState({ checklistLinkTemplates: templates });
            }),
            catchError(err => this.store.dispatch(new ManageTemplateActions.LoadChecklistLinkTemplatesFailure(err))),
            finalize(() => {
                patchState({ isLoadingChecklistLinkTemplates: false, hasLoadedChecklistLinkTemplates: true });
            }),
        );
    }

    @Action(ManageTemplateActions.SaveShareableLinkTemplate)
    saveShareableLinkTemplate({ getState, patchState, dispatch }: StateContext<StateModel>) {
        patchState({ isLoading: true });

        const { shareableLinkTemplates: templates } = getState();

        const form = this.store.selectSnapshot(
            ManageTemplatesState.getShareableLinkTemplateForm(),
        ) as FormState<EditShareableLinkTemplateFormModel>;
        const model = form.model as EditShareableLinkTemplateFormModel;
        const preambleTemplate = model.preambleTemplate;
        const preambleTemplateId = model.preambleTemplateId;
        const headerForm = this.store.selectSnapshot(ManageTemplatesState.getTemplateHeaderForm());
        const template = {
            ...model,
            ...(model?.linkExpiration as any),
            ...headerForm.model,
            videoSettings: {
                elapsedTimeInSeconds: 0,
                isLiveStream: model.isLiveStream,
                startTime: model.startTime,
                autoplay: model.autoplay,
                showWatermark: model.showWatermark,
                enableWaitingRoom: model.enableWaitingRoom,
                waitingRoomOffsetInSeconds: model.waitingRoomOffsetInSeconds,
                showControls: model.showControls,
                showPlayButton: model.showPlayButton,
                showFullscreenButton: model.showFullscreenButton,
                showProgress: model.showProgress,
                allowScrubbing: model.allowScrubbing,
                showPlaybackSpeed: model.showPlaybackSpeed,
                resumePlayback: model.resumePlayback,
                allowListeners: model.allowListeners,
                countDownAssetId: model.countDownAssetId,
            },
        };

        const checklists = model.checklistTemplateIds.reduce((acc, rowKey) => {
            const content = model.checklists?.[rowKey];

            if (content) {
                acc.push({
                    rowKey,
                    content,
                });
            }

            return acc;
        }, [] as { rowKey: string; content: string }[]);

        template.checklist = null;
        template.preambleTemplate = null;

        const ix = templates.findIndex(n => n.rowKey === template.rowKey);

        return this.manageTemplatesService.saveShareableLinkTemplate(template).pipe(
            mergeMap(result => {
                if (ix !== -1) {
                    templates[ix] = result;
                } else {
                    templates.push(result);
                }

                patchState({
                    shareableLinkTemplates: templates,
                });

                const successActions = [
                    new Navigate(['/home/shareable-links']),
                    new NotificationActions.Success('Template has been successfully saved'),
                ];

                if (!checklists.length && preambleTemplateId !== 'custom') {
                    return dispatch(successActions);
                }

                const actions = [
                    ...checklists.map(
                        checklist => new ManageTemplateActions.SaveChecklist(checklist.content, checklist.rowKey as string),
                    ),
                    ...(preambleTemplateId === 'custom'
                        ? [
                              new ManageTemplateActions.SavePreambleForLink(
                                  preambleTemplate?.content || '',
                                  result.rowKey as string,
                              ),
                          ]
                        : []),
                ];

                return dispatch(actions).pipe(mergeMap(() => dispatch(successActions)));
            }),
            catchError(err => this.store.dispatch(new ManageTemplateActions.SaveShareableLinkTemplateFailure(err))),
            finalize(() => {
                patchState({ isLoading: false });
            }),
        );
    }

    @Action(ManageTemplateActions.LoadPreambleTemplates)
    loadPreambleTemplates({ getState, patchState }: StateContext<StateModel>): Observable<any> | void {
        patchState({ isLoadingPreambleTemplates: true });

        return this.manageTemplatesService.getTemplates<PreambleTemplate>(LinkType.Preamble).pipe(
            mergeMap(templates => {
                patchState({
                    preambleTemplates: templates,
                });

                return forkJoin(
                    templates.map(template =>
                        this.blobService
                            .downloadBlobBlock(`preamble/templates/${template.rowKey}.html`, BlobContainer.Public)
                            .pipe(transformResponseToText(), handleNotFoundError()),
                    ),
                ).pipe(
                    tap(contents => {
                        patchState({
                            preambleTemplates: templates.map((template, index) => ({
                                ...template,
                                hasLoaded: true,
                                content: contents[index],
                            })),
                        });
                    }),
                );
            }),
            catchError(err => this.store.dispatch(new ManageTemplateActions.LoadPreambleTemplatesFailure(err))),
            finalize(() => patchState({ isLoadingPreambleTemplates: false, hasLoadedPreambleTemplates: true })),
        );
    }

    @Action(ManageTemplateActions.LoadChecklistTemplates)
    loadChecklistTemplates({ dispatch, patchState }: StateContext<StateModel>): Observable<any> | void {
        patchState({ checklistLinkTemplates: [], isLoadingChecklistTemplates: true });

        return this.manageTemplatesService.getTemplates<ChecklistTemplate>(LinkType.Checklist).pipe(
            mergeMap(templates => {
                patchState({
                    checklistTemplates: templates,
                });

                return forkJoin(
                    templates.map(template =>
                        this.blobService
                            .downloadBlobBlock(`checklists/templates/${template.rowKey}.html`, BlobContainer.Private)
                            .pipe(transformResponseToText(), handleNotFoundError()),
                    ),
                ).pipe(
                    tap(contents => {
                        patchState({
                            checklistTemplates: templates.map((template, index) => ({
                                ...template,
                                hasLoaded: true,
                                content: contents[index],
                            })),
                        });
                    }),
                );
            }),
            catchError(err => this.store.dispatch(new ManageTemplateActions.LoadChecklistTemplatesFailure(err))),
            finalize(() => patchState({ isLoadingChecklistTemplates: false, hasLoadedChecklistTemplates: true })),
        );
    }

    @Action(ManageTemplateActions.SaveFormLinkTemplate)
    saveFormTemplate({ patchState, dispatch, getState }: StateContext<StateModel>) {
        patchState({ isLoading: true, errors: null });

        const { formLinkTemplates: templates } = getState();

        const form = this.store.selectSnapshot(ManageTemplatesState.getFormLinkTemplateForm());
        const model = form.model as EditFormLinkTemplateFormModel;
        const preambleTemplate = model.preambleTemplate;
        const preambleTemplateId = model.preambleTemplateId;
        const headerForm = this.store.selectSnapshot(ManageTemplatesState.getTemplateHeaderForm());
        const submissionMessages = (model.submissionMessages || []).map((message: FormSubmissionMessage) => {
            return {
                rowKey: message.rowKey,
                name: message.name,
            };
        });
        const template = {
            ...model,
            ...(model.linkExpiration as any),
            ...headerForm.model,
        };

        const ix = templates.findIndex(n => n.rowKey === template.rowKey);

        const { timeZone } = model.linkExpiration;
        // const timeZone = getTimeZoneByIanaTimeZone(ianaTimeZone);

        const pages = model?.pages.reduce((acc: FormSection[], current: any) => {
            if (current.data) {
                const page = {
                    ...current.data,
                    children: current.data.children.reduce((acc: FormSection[], current: any) => {
                        const section = {
                            ...current.data,
                            id: current.id,
                            sectionType: current.sectionType,
                        };

                        if (
                            section.type === FormQuestionType.ShortAnswer ||
                            section.type === FormQuestionType.LongAnswer ||
                            section.type === FormQuestionType.Date ||
                            section.type === FormQuestionType.Time ||
                            section.type === FormQuestionType.DateTime
                        ) {
                            section.options = [];
                        }

                        if (section.sectionType !== FormSectionType.Media) {
                            section.mediaAssetId = null;
                        }

                        acc.push(section);

                        return acc;
                    }, []),
                };

                acc.push(page);
            } else {
                acc.push(current);
            }

            return acc;
        }, []) as any[];

        template.preambleTemplate = null;

        return this.manageTemplatesService
            .saveFormLinkTemplate({
                ...template,
                timeZone: timeZone || DEFAULT_TIME_ZONE,
                submissionMessages,
                pages,
                videoSettings: {
                    showWatermark: model?.showWatermark,
                    elapsedTimeInSeconds: 0,
                    isLiveStream: false,
                    startTime: VideoOption.None,
                    autoplay: false,
                    enableWaitingRoom: false,
                    waitingRoomOffsetInSeconds: 0,
                    showControls: true,
                    showPlayButton: true,
                    showFullscreenButton: true,
                    showProgress: true,
                    allowScrubbing: true,
                    showPlaybackSpeed: true,
                    resumePlayback: false,
                    allowListeners: true,
                    countDownAssetId: null,
                },
            })
            .pipe(
                mergeMap(link => {
                    if (ix !== -1) {
                        templates[ix] = link;
                    } else {
                        templates.push(link);
                    }

                    patchState({
                        formLinkTemplates: templates,
                    });

                    const actions = [];

                    if (preambleTemplateId === 'custom') {
                        actions.push(
                            new ManageTemplateActions.SavePreambleForLink(
                                preambleTemplate?.content || '',
                                link.rowKey as string,
                            ),
                        );
                    }

                    if (submissionMessages.length) {
                        actions.push(
                            new ManageTemplateActions.SaveFormSubmissionMessageForTemplate(
                                link.rowKey as string,
                                model.submissionMessages,
                            ),
                        );
                    }

                    return dispatch(actions).pipe(
                        mergeMap(() =>
                            dispatch([
                                new Navigate(['/home/form-links']),
                                new NotificationActions.Success('Template has been successfully saved'),
                            ]),
                        ),
                    );
                }),
                catchError(err => dispatch(new ManageTemplateActions.SaveFormLinkTemplateFailure(err))),
                finalize(() =>
                    patchState({
                        isSaving: false,
                    }),
                ),
            );
    }

    @Action(ManageTemplateActions.SaveChecklistLinkTemplate)
    saveChecklistLinkTemplate({ dispatch, patchState }: StateContext<StateModel>): Observable<void> {
        patchState({
            isSaving: true,
            errors: null,
        });

        const { model } = this.store.selectSnapshot(ManageTemplatesState.getChecklistLinkTemplateForm());
        const templates = [...this.store.selectSnapshot(ManageTemplatesState.getChecklistLinkTemplates())];
        const headerForm = this.store.selectSnapshot(ManageTemplatesState.getTemplateHeaderForm());
        const ix = templates.findIndex(n => n.rowKey === model?.rowKey);
        const template = {
            ...model,
            ...(model?.linkExpiration as any),
            ...headerForm.model,
        };

        return this.manageTemplatesService.saveChecklistLinkTemplate(template as ChecklistLinkTemplateModel).pipe(
            mergeMap(template => {
                if (ix !== -1) {
                    templates[ix] = template;
                } else {
                    templates.push(template);
                }

                patchState({
                    checklistLinkTemplates: templates,
                });

                window.history.back();

                return dispatch([new NotificationActions.Success('Template has been successfully saved')]);
            }),
            catchError(err => dispatch(new ManageTemplateActions.SaveChecklistLinkTemplateFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageTemplateActions.SavePreambleTemplate)
    savePreambleTemplate({ dispatch, patchState }: StateContext<StateModel>): Observable<void> {
        patchState({
            isSaving: true,
            errors: null,
        });

        const model = this.store.selectSnapshot(ManageTemplatesState.getPreambleTemplateForm()).model as PreambleTemplate;
        const templates = [...this.store.selectSnapshot(ManageTemplatesState.getPreambleTemplates())];
        const ix = templates.findIndex(n => n.rowKey === model.rowKey);
        const content = model.content;
        model.content = '';

        return this.manageTemplatesService.savePreambleTemplate(model).pipe(
            mergeMap(template => {
                template.content = content;

                if (ix !== -1) {
                    templates[ix] = template;
                } else {
                    templates.push(template);
                }

                return dispatch(new ManageTemplateActions.SavePreamble(content, template.rowKey as string)).pipe(
                    mergeMap(() => {
                        patchState({
                            preambleTemplates: templates,
                        });

                        return dispatch([
                            new Navigate(['/home/preamble-templates']),
                            new NotificationActions.Success('Template has been successfully saved'),
                        ]);
                    }),
                );
            }),
            catchError(err => dispatch(new ManageTemplateActions.SavePreambleTemplateFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageTemplateActions.SaveChecklistTemplate)
    saveChecklistTemplate({ dispatch, patchState }: StateContext<StateModel>): Observable<void> {
        patchState({
            isSaving: true,
            errors: null,
        });

        const model = this.store.selectSnapshot(ManageTemplatesState.getChecklistTemplateForm()).model as ChecklistTemplate;
        const templates = [...this.store.selectSnapshot(ManageTemplatesState.getChecklistTemplates())];
        const ix = templates.findIndex(n => n.rowKey === model.rowKey);
        const content = model.content;
        model.content = '';

        return this.manageTemplatesService.saveChecklistTemplate(model).pipe(
            mergeMap(template => {
                template.content = content;

                if (ix !== -1) {
                    templates[ix] = template;
                } else {
                    templates.push(template);
                }

                return dispatch(new ManageTemplateActions.SaveChecklist(content, template.rowKey as string, true)).pipe(
                    mergeMap(() => {
                        patchState({
                            checklistTemplates: templates,
                        });

                        return dispatch([
                            new Navigate(['/home/checklist-links']),
                            new NotificationActions.Success('Template has been successfully saved'),
                        ]);
                    }),
                );
            }),
            catchError(err => dispatch(new ManageTemplateActions.SaveChecklistTemplateFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageTemplateActions.ConfirmDeleteTemplates)
    confirmDeleteTemplates(
        { dispatch }: StateContext<StateModel>,
        { type, templates }: ManageTemplateActions.ConfirmDeleteTemplates,
    ) {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: ConfirmArchiveResult({
                    title: 'Delete Template(s)',
                    message: `Are you sure you want to delete ${templates.length} template(s)?`,
                    confirmText: 'Delete',
                }),
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        const actions: any[] = [
                            new ManageTemplateActions.DeleteTemplates(type, templates?.map(r => r.rowKey || '') || []),
                        ];

                        switch (type) {
                            case LinkType.ChecklistLink:
                                actions.push(new Navigate(['/home/checklist-links']));
                                break;
                            case LinkType.Shareable:
                                actions.push(new Navigate(['/home/shareable-links']));
                                break;
                            case LinkType.Form:
                                actions.push(new Navigate(['/home/form-links']));
                                break;
                        }

                        dispatch(actions);
                    }
                }),
            );
    }

    @Action(ManageTemplateActions.DeleteTemplates)
    deleteChecklistTemplates(ctx: StateContext<StateModel>, { type, rowKeys }: ManageTemplateActions.DeleteTemplates) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageTemplatesService.deleteTemplates(type, rowKeys).pipe(
            mergeMap(result => {
                const actions: any[] = [new NotificationActions.Success('Template(s) has been successfully deleted')];

                switch (type) {
                    case LinkType.ChecklistLink:
                        actions.push(new ManageTemplateActions.LoadChecklistLinkTemplates());
                        break;
                    case LinkType.Checklist:
                        actions.push(new ManageTemplateActions.LoadChecklistTemplates());
                        break;
                    case LinkType.Shareable:
                        actions.push(new ManageTemplateActions.LoadShareableLinkTemplates());
                        break;
                    case LinkType.Form:
                        actions.push(new ManageTemplateActions.LoadFormLinkTemplates());
                        break;
                    case LinkType.Preamble:
                        actions.push(new ManageTemplateActions.LoadPreambleTemplates());
                        break;
                }

                return this.store.dispatch(actions);
            }),
            catchError(err => this.store.dispatch(new ManageTemplateActions.DeleteTemplatesFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageTemplateActions.LoadChecklist)
    loadChecklist({ patchState, dispatch }: StateContext<StateModel>, action: ManageTemplateActions.LoadChecklist) {
        patchState({
            checklist: null,
            isLoadingChecklist: true,
        });

        const linkId = action.rowKey || this.store.selectSnapshot(ManageTemplatesState.getLinkId());

        if (!linkId || linkId === 'new') {
            patchState({ isLoadingChecklist: false });
            return;
        }

        const name = action.isTemplate ? `checklists/templates/${linkId}.html` : `checklists/${linkId}.html`;

        return this.blobService.downloadBlobBlock(name).pipe(
            transformResponseToText(),
            handleNotFoundError('<p></p>'),
            tap(checklist => {
                patchState({ checklist });
            }),
            catchError(err => dispatch(new ManageTemplateActions.LoadChecklistFailure(err))),
            finalize(() => patchState({ isLoadingChecklist: false })),
        );
    }

    @Action(ManageTemplateActions.SaveChecklist)
    saveChecklist(
        { patchState }: StateContext<StateModel>,
        action: ManageTemplateActions.SaveChecklist,
    ): Observable<any> | void {
        patchState({
            isSavingChecklist: true,
            errors: null,
        });

        const name = action.isTemplate ? `checklists/templates/${action.rowKey}.html` : `checklists/${action.rowKey}.html`;

        return this.blobService
            .uploadBlobBlock(name, action.content, 'text/html', BlobContainer.Private)
            .pipe(finalize(() => patchState({ isSavingChecklist: false })));
    }

    @Action(ManageTemplateActions.LoadPreamble)
    loadPreamble({ patchState, dispatch }: StateContext<StateModel>, action: ManageTemplateActions.LoadPreamble) {
        patchState({
            isLoadingPreamble: true,
        });

        const linkId = action.rowKey || this.store.selectSnapshot(ManageTemplatesState.getLinkId());

        return this.blobService.downloadBlobBlock(`preamble/templates/${linkId}.html`, BlobContainer.Public).pipe(
            transformResponseToText(),
            handleNotFoundError(),
            mergeMap(preamble => {
                patchState({ checklist: preamble });
                return dispatch([
                    new UpdateFormValue({
                        value: preamble,
                        path: 'manageTemplates.formPreambleTemplate',
                        propertyPath: 'content',
                    }),
                    new ManageTemplateActions.LoadPreambleSuccess(preamble),
                ]);
            }),
            finalize(() => patchState({ isLoadingPreamble: false })),
        );
    }

    @Action(ManageTemplateActions.SavePreamble)
    savePreamble(
        { patchState }: StateContext<StateModel>,
        { content, rowKey }: ManageTemplateActions.SavePreamble,
    ): Observable<any> | void {
        patchState({
            isSavingPreamble: true,
            errors: null,
        });

        return this.blobService
            .uploadBlobBlock(`preamble/templates/${rowKey}.html`, content, 'text/html', BlobContainer.Public)
            .pipe(finalize(() => patchState({ isSavingPreamble: false })));
    }

    @Action(ManageTemplateActions.LoadPreambleTemplateForLink)
    loadPreambleTemplateForLink(
        { patchState, dispatch }: StateContext<StateModel>,
        action: ManageTemplateActions.LoadPreambleTemplateForLink,
    ) {
        patchState({
            preambleTemplate: null,
            isLoadingPreamble: true,
        });

        const linkId = action.rowKey || this.store.selectSnapshot(ManageTemplatesState.getLinkId());

        return this.blobService.downloadBlobBlock(`preamble/${linkId}.html`, BlobContainer.Public).pipe(
            transformResponseToText(),
            handleNotFoundError('<p></p>'),
            mergeMap(preambleTemplate => {
                patchState({
                    preambleTemplate,
                });

                return dispatch([new ManageTemplateActions.LoadPreambleTemplateForLinkSuccess(preambleTemplate)]);
            }),
            catchError(err => dispatch(new ManageTemplateActions.LoadPreambleTemplateForLinkFailure(err))),
            finalize(() => patchState({ isLoadingPreambleForLink: false })),
        );
    }

    @Action(ManageTemplateActions.SaveFormSubmissionMessageForTemplate)
    saveFormSubmissionMessageForTemplate(
        { dispatch }: StateContext<StateModel>,
        { rowKey, submissionMessages }: ManageTemplateActions.SaveFormSubmissionMessageForTemplate,
    ) {
        const actions = submissionMessages.reduce((acc, current) => {
            const name = `forms/${rowKey}/${current.rowKey}.html`;
            acc.push(this.blobService.uploadBlobBlock(name, current.content, 'text/html'));

            return acc;
        }, [] as Observable<any>[]);

        return forkJoin(actions);
    }

    @Action(ManageTemplateActions.LoadSubmissionMessagesForLink)
    loadSubmissionMessagesForLink(
        { patchState }: StateContext<StateModel>,
        { rowKey }: ManageTemplateActions.LoadSubmissionMessagesForLink,
    ) {
        const links = this.store.selectSnapshot(ManageTemplatesState.getFormLinkTemplates());
        const link = links.find(l => l.rowKey === rowKey);

        patchState({
            formSubmissionMessages: [],
        });

        if (!link || !link.submissionMessages?.length) {
            return;
        }

        const actions = link.submissionMessages.reduce((acc, current) => {
            const name = `forms/${rowKey}/${current.rowKey}.html`;
            acc.push(
                this.blobService.downloadBlobBlock(name, BlobContainer.Private).pipe(
                    transformResponseToText(),
                    map(content => {
                        return {
                            ...current,
                            content,
                        };
                    }),
                ),
            );

            return acc;
        }, [] as Observable<any>[]);

        return forkJoin(actions).pipe(
            tap(submissionMessages => {
                patchState({
                    formSubmissionMessages: submissionMessages,
                });
            }),
        );
    }

    @Action(ManageTemplateActions.SavePreambleForLink)
    savePreambleForLink(
        { patchState, dispatch }: StateContext<StateModel>,
        { content, rowKey }: ManageTemplateActions.SavePreambleForLink,
    ): Observable<any> | void {
        patchState({
            isSavingPreamble: true,
            errors: null,
        });

        const linkId = rowKey || this.store.selectSnapshot(ManageTemplatesState.getLinkId());
        const container = this.blobService.getContainerName(BlobContainer.Public);

        return this.blobService
            .uploadBlobBlock(`preamble/${linkId}.html`, content, 'text/html', BlobContainer.Public)
            .pipe(tap(() => dispatch(new AdminActions.PurgeCdn({ keys: [`/${container}/preamble/${linkId}.html`] }))));
    }
}

// tslint:disable:no-input-rename
import { Directionality } from '@angular/cdk/bidi';
import { ESCAPE } from '@angular/cdk/keycodes';
import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayConfig,
    OverlayRef,
    PositionStrategy,
} from '@angular/cdk/overlay';
import { _supportsShadowDom } from '@angular/cdk/platform';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    Optional,
    ViewContainerRef,
} from '@angular/core';

import { defer, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { delay, filter, switchMap, take, tap, map } from 'rxjs/operators';

import { SearchResultsComponent } from './search-results.component';

@Directive({
    selector: 'input[searchResults]',
    standalone: true,
})
export class SearchResultsDirective implements AfterViewInit, OnDestroy {
    private canOpenOnNextFocus = false;
    private previousValue: string | number | null = null;
    private overlayRef: OverlayRef | null = null;
    private portal: TemplatePortal | null = null;
    private positionStrategy: FlexibleConnectedPositionStrategy | null = null;
    private overlayAttached = false;
    private autocompleteDisabled = false;
    private componentDestroyed = false;
    private closingActionsSubscription: Subscription | null = null;
    private _isInsideShadowRoot = false;

    @Input('searchResults') autocomplete!: SearchResultsComponent;
    @Input('matAutocompletePosition') position: 'auto' | 'above' | 'below' = 'auto';

    get panelOpen(): boolean {
        return this.overlayAttached && this.autocomplete.showPanel;
    }
    get panelClosingActions$(): Observable<any | null> {
        return merge(
            this.optionSelections$,
            // this.autocomplete._keyManager.tabOut.pipe(filter(() => this.overlayAttached)),
            // this._closeKeyEventStream,
            this._getOutsideClickStream(),
            this.overlayRef ? this.overlayRef.detachments().pipe(filter(() => this.overlayAttached)) : of(),
        ).pipe(
            // Normalize the output so we return a consistent type.
            map(event => (!(event instanceof MouseEvent) ? event : null)),
        );
    }

    readonly optionSelections$: Observable<any> = defer(() => {
        if (this.autocomplete && this.autocomplete.options) {
            return merge(...this.autocomplete.options.map(option => option.selectionChange));
        }

        // If there are any subscribers before `ngAfterViewInit`, the `autocomplete` will be undefined.
        // Return a stream that we'll replace with the real one once everything is in place.
        return this.zone.onStable.asObservable().pipe(
            take(1),
            switchMap(() => this.optionSelections$),
        );
    }) as Observable<any>;

    constructor(
        private element: ElementRef<HTMLInputElement>,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private _dir: Directionality,
    ) {}

    @HostListener('input', ['$event']) handleInput(event: KeyboardEvent): void {
        const target = event.target as HTMLInputElement;
        let value: number | string | null = target.value;

        // Based on `NumberValueAccessor` from forms.
        if (target.type === 'number') {
            value = value === '' ? null : parseFloat(value);
        }

        // If the input has a placeholder, IE will fire the `input` event on page load,
        // focus and blur, in addition to when the user actually changed the value. To
        // filter out all of the extra events, we save the value on focus and between
        // `input` events, and we check whether it changed.
        // See: https://connect.microsoft.com/IE/feedback/details/885747/
        if (this.previousValue === value) {
            return;
        }

        this.previousValue = value;
        // this.onChange(value);

        if (this.canOpen() && document.activeElement === event.target) {
            this.openPanel();
        }
    }

    @HostListener('focusin') handleFocus(): void {
        if (!this.canOpenOnNextFocus) {
            this.canOpenOnNextFocus = true;
        } else {
            this.previousValue = this.element.nativeElement.value;
            this.attachOverlay();
            this.floatLabel(true);
        }
    }

    @HostListener('keydown', ['$event']) handleKeydown(event: KeyboardEvent): void {
        // tslint:disable-next-line: deprecation
        const keyCode = event.keyCode;

        // Prevent the default action on all escape key presses. This is here primarily to bring IE
        // in line with other browsers. By default, pressing escape on IE will cause it to revert
        // the input value to the one that it had on focus, however it won't dispatch any events
        // which means that the model value will be out of sync with the view.
        if (keyCode === ESCAPE) {
            event.preventDefault();
        }

        // if (this.activeOption && keyCode === ENTER && this.panelOpen) {
        //     this.activeOption._selectViaInteraction();
        //     this._resetActiveItem();
        //     event.preventDefault();
        // } else if (this.autocomplete) {
        //     const prevActiveItem = this.autocomplete._keyManager.activeItem;
        //     const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

        //     if (this.panelOpen || keyCode === TAB) {
        //         this.autocomplete._keyManager.onKeydown(event);
        //     } else if (isArrowKey && this._canOpen()) {
        //         this.openPanel();
        //     }

        //     if (isArrowKey || this.autocomplete._keyManager.activeItem !== prevActiveItem) {
        //         this._scrollToOption();
        //     }
        // }
    }

    openPanel(): void {
        this.attachOverlay();
        this.floatLabel();
    }

    closePanel(): void {
        // this.resetLabel();

        if (!this.overlayAttached) {
            return;
        }

        if (this.panelOpen) {
            // Only emit if the panel was visible.
            this.autocomplete.closed.emit();
        }

        this.autocomplete._isOpen = this.overlayAttached = false;

        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            this.closingActionsSubscription?.unsubscribe();
        }

        // Note that in some cases this can end up being called after the component is destroyed.
        // Add a check to ensure that we don't try to run change detection on a destroyed view.
        if (!this.componentDestroyed) {
            // We need to trigger change detection manually, because
            // `fromEvent` doesn't seem to do it at the proper time.
            // This ensures that the label is reset when the
            // user clicks outside.
            this.changeDetectorRef.detectChanges();
        }
    }

    ngAfterViewInit(): void {
        if (!_supportsShadowDom()) {
            return;
        }

        const element = this.element.nativeElement;
        const rootNode = element.getRootNode ? element.getRootNode() : null;

        // We need to take the `ShadowRoot` off of `window`, because the built-in types are
        // incorrect. See https://github.com/Microsoft/TypeScript/issues/27929.
        this._isInsideShadowRoot = rootNode instanceof (window as any).ShadowRoot;
    }

    ngOnDestroy(): void {
        this.componentDestroyed = true;
        this._destroyPanel();
        // this._closeKeyEventStream.complete();
    }

    private _getOutsideClickStream(): Observable<any> {
        return merge(
            fromEvent(document, 'click') as Observable<MouseEvent>,
            fromEvent(document, 'touchend') as Observable<TouchEvent>,
        ).pipe(
            filter(event => {
                // If we're in the Shadow DOM, the event target will be the shadow root, so we have to
                // fall back to check the first element in the path of the click event.
                const clickTarget = (
                    this._isInsideShadowRoot && event.composedPath ? event.composedPath()[0] : event.target
                ) as HTMLElement;

                return (
                    this.overlayAttached &&
                    clickTarget !== this.element.nativeElement &&
                    !!this.overlayRef &&
                    !this.overlayRef.overlayElement.contains(clickTarget)
                );
            }),
        );
    }

    private subscribeToClosingActions(): Subscription {
        const firstStable$ = this.zone.onStable.asObservable().pipe(take(1));
        const optionChanges$ = this.autocomplete.options.changes.pipe(
            tap(() => this.positionStrategy?.reapplyLastPosition()),
            // Defer emitting to the stream until the next tick, because changing
            // bindings in here will cause "changed after checked" errors.
            delay(0),
        );

        // When the zone is stable initially, and when the option list changes...
        return (
            merge(firstStable$, optionChanges$)
                .pipe(
                    // create a new stream of panelClosingActions, replacing any previous streams
                    // that were created, and flatten it so our stream only emits closing events...
                    switchMap(() => {
                        const wasOpen = this.panelOpen;
                        this.autocomplete.setVisibility();

                        if (this.panelOpen) {
                            this.overlayRef?.updatePosition();

                            // If the `panelOpen` state changed, we need to make sure to emit the `opened`
                            // event, because we may not have emitted it when the panel was attached. This
                            // can happen if the users opens the panel and there are no options, but the
                            // options come in slightly later or as a result of the value changing.
                            if (wasOpen !== this.panelOpen) {
                                this.autocomplete.opened.emit();
                            }
                        }

                        return this.panelClosingActions$;
                    }),
                    // when the first closing event occurs...
                    take(1),
                )
                // set the value, close the panel, and complete.
                .subscribe(event => this.setValueAndClose(event))
        );
    }

    private setValueAndClose(event: any | null): void {
        if (event) {
            this.element.nativeElement.focus();
            this.autocomplete.emitSelectEvent(event);
        }

        this.closePanel();
    }

    private _destroyPanel(): void {
        if (this.overlayRef) {
            this.closePanel();
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }

    private floatLabel(shouldAnimate = false): void {
        // if (this.formField && this._formField.floatLabel === 'auto') {
        //   if (shouldAnimate) {
        //     this._formField._animateAndLockLabel();
        //   } else {
        //     this._formField.floatLabel = 'always';
        //   }
        //   this._manuallyFloatingLabel = true;
        // }
    }

    private canOpen(): boolean {
        const element = this.element.nativeElement;
        return !element.readOnly && !element.disabled && !this.autocompleteDisabled;
    }

    private _getOverlayConfig(): OverlayConfig {
        return new OverlayConfig({
            positionStrategy: this.getOverlayPosition(),
            // scrollStrategy: this._scrollStrategy(),
            width: this._getPanelWidth(),
            direction: this._dir,
        });
    }

    private _getPanelWidth(): number | string {
        return this.autocomplete.panelWidth || this.getHostWidth();
    }

    /** Returns the width of the input element, so the panel width can match it. */
    private getHostWidth(): number {
        return this.getConnectedElement().nativeElement.getBoundingClientRect().width;
    }

    private getConnectedElement(): ElementRef {
        return this.element;
    }

    private getOverlayPosition(): PositionStrategy {
        const strategy = this.overlay
            .position()
            .flexibleConnectedTo(this.getConnectedElement())
            .withFlexibleDimensions(false)
            .withPush(false);

        this._setStrategyPositions(strategy);
        this.positionStrategy = strategy;
        return strategy;
    }

    private _setStrategyPositions(positionStrategy: FlexibleConnectedPositionStrategy): void {
        const belowPosition: ConnectedPosition = {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
        };
        const abovePosition: ConnectedPosition = {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',

            // The overlay edge connected to the trigger should have squared corners, while
            // the opposite end has rounded corners. We apply a CSS class to swap the
            // border-radius based on the overlay position.
            panelClass: 'mat-autocomplete-panel-above',
        };

        let positions: ConnectedPosition[];

        if (this.position === 'above') {
            positions = [abovePosition];
        } else if (this.position === 'below') {
            positions = [belowPosition];
        } else {
            positions = [belowPosition, abovePosition];
        }

        positionStrategy.withPositions(positions);
    }

    private attachOverlay(): void {
        if (!this.autocomplete) {
            throw Error('getMatAutocompleteMissingPanelError');
        }

        let overlayRef = this.overlayRef;

        if (!overlayRef) {
            this.portal = new TemplatePortal(this.autocomplete.template, this.viewContainerRef);
            overlayRef = this.overlay.create(this._getOverlayConfig());
            this.overlayRef = overlayRef;

            // Use the `keydownEvents` in order to take advantage of
            // the overlay event targeting provided by the CDK overlay.
            // overlayRef.keydownEvents().subscribe(event => {
            //     // Close when pressing ESCAPE or ALT + UP_ARROW, based on the a11y guidelines.
            //     // See: https://www.w3.org/TR/wai-aria-practices-1.1/#textbox-keyboard-interaction
            //     if (event.keyCode === ESCAPE || (event.keyCode === UP_ARROW && event.altKey)) {
            //         this._resetActiveItem();
            //         this._closeKeyEventStream.next();

            //         // We need to stop propagation, otherwise the event will eventually
            //         // reach the input itself and cause the overlay to be reopened.
            //         event.stopPropagation();
            //         event.preventDefault();
            //     }
            // });

            // if (this._viewportRuler) {
            //     this._viewportSubscription = this._viewportRuler.change().subscribe(() => {
            //         if (this.panelOpen && overlayRef) {
            //             overlayRef.updateSize({ width: this._getPanelWidth() });
            //         }
            //     });
            // }
        } else {
            // Update the trigger, panel width and direction, in case anything has changed.
            this.positionStrategy?.setOrigin(this.getConnectedElement());
            overlayRef.updateSize({ width: this._getPanelWidth() });
        }

        if (overlayRef && !overlayRef.hasAttached()) {
            overlayRef.attach(this.portal);
            this.closingActionsSubscription = this.subscribeToClosingActions();
        }

        const wasOpen = this.panelOpen;

        this.autocomplete.setVisibility();
        this.autocomplete._isOpen = this.overlayAttached = true;

        // We need to do an extra `panelOpen` check in here, because the
        // autocomplete won't be shown if there are no options.
        if (this.panelOpen && wasOpen !== this.panelOpen) {
            this.autocomplete.opened.emit();
        }
    }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { map } from 'rxjs';

import { AuthState } from '@app/data/state/auth.state';
import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';
import { ToastRef } from 'ngx-toastr';

import { HomeState } from '../../state/home.state';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ElementDirective } from '../../../../../shared/src/lib/directives/element.directive';
import { MediaAssetPlayerComponent } from '../media-asset-player/media-asset-player.component';
import { SrcUnloadDirective } from '../../../../../shared/src/lib/directives/src-unload.directive';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'admin-media-asset-mini-player',
    templateUrl: './media-asset-mini-player.component.html',
    styleUrls: ['./media-asset-mini-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        ElementDirective,
        MediaAssetPlayerComponent,
        SrcUnloadDirective,
        AsyncPipe,
    ],
})
export class MediaAssetMiniPlayerComponent {

    toastRef: ToastRef<any> | null = null;
    token$ = this.store.select(AuthState.getToken());
    src$ = this.store.select(HomeState.getPreviewMediaAssetId()).pipe(
        map(mediaAssetId => {
            if(!mediaAssetId) return null;

            return `${this.env.cdnUrl}/${this.env.privateContainerName}/media-assets/${mediaAssetId}/playlist.m3u8`;
        }),
    );

    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private store: Store) {}

    onClose() {
        if (this.toastRef) {
            this.toastRef.close();
        }
    }
}

import { ChecklistLinkModel, SearchOptionsModel } from '@app/data/models';
import { Timezone } from 'timezones.json';

const ns = `[Manage Checklists]`;

export class Save {
    static readonly type = `${ns} Save`;
}

export class SaveFailure {
    static readonly type = `${ns} Save Failure`;
    constructor(public error: any) {}
}

export class Edit {
    static readonly type = `${ns} Edit`;
}

export class LoadLinks {
    static readonly type = `${ns} Load Links`;
}
export class LoadLinksDone {
    static readonly type = `${ns} Load Links Done`;
}
export class LoadLinksSuccess {
    static readonly type = `${ns} Load Links Success`;
}

export class LoadLinksFailure {
    static readonly type = `${ns} Load Links Failure`;
    constructor(public error: any) {}
}

export class EnsureLoadLinks {
    static readonly type = `${ns} Ensure Load`;
}

export class EnsureLoadArchivedLinks {
    static readonly type = `${ns} Ensure Archived Load`;
}

export class LoadLinkSessions {
    static readonly type = `${ns} Load Link Sessions`;
    constructor(public rowKey?: string) {}
}

export class LoadLinkSessionsFailure {
    static readonly type = `${ns} Load Link Sessions Failure`;
    constructor(public error: any) {}
}

export class LoadLinkSubmissions {
    static readonly type = `${ns} Load Link Submissions`;
    constructor(public rowKey?: string) {}
}

export class LoadLinkSubmissionsFailure {
    static readonly type = `${ns} Load Link Submissions Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLink {
    static readonly type = `${ns} Load Archived Link`;
    constructor(public rowKey: string) {}
}
export class LoadArchivedLinkSuccess {
    static readonly type = `${ns} Load Archived Link Success`;
}
export class LoadArchivedLinkFailure {
    static readonly type = `${ns} Load Archived Link Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLinkSessions {
    static readonly type = `${ns} Load Archived Link Sessions`;
    constructor(public rowKey?: string | null) {}
}

export class LoadArchivedLinkSessionsFailure {
    static readonly type = `${ns} Load Archived Link Sessions Failure`;
    constructor(public error: any) {}
}

export class Error {
    static readonly type = `${ns} Error`;
    constructor(public error: any, public source: any) {}
}

export class ActivateSession {
    static readonly type = `${ns} Activate Session`;
    constructor(public rowKey: string) {}
}

export class ActivateSessionFailure {
    static readonly type = `${ns} Activate Session Failure`;
    constructor(public error: any) {}
}

export class DeactivateSession {
    static readonly type = `${ns} Deactivate Session`;
    constructor(public rowKey: string) {}
}

export class DeactivateSessionFailure {
    static readonly type = `${ns} Deactivate Session Failure`;
    constructor(public error: any) {}
}

export class UpdateSessionCount {
    static readonly type = `${ns} Update Session Count`;
    constructor(public rowKey: string) {}
}

export class UpdateSessionCountFailure {
    static readonly type = `${ns} Update Session Count Failure`;
    constructor(public error: any) {}
}

export class ConfirmMergeSession {
    static readonly type = `${ns} Confirm Merge Sessions`;
    constructor(public rowKeys: string[]) {}
}

export class MergeSessions {
    static readonly type = `${ns} Merge Sessions`;
    constructor(public primaryRowKey: string, public rowKeys: string[]) {}
}

export class MergeSessionsFailure {
    static readonly type = `${ns} Merge Sessions Failure`;
    constructor(public error: any) {}
}

export class ConfirmDeleteSession {
    static readonly type = `${ns} Confirm Delete Session`;
    constructor(public rowKeys: string[]) {}
}

export class DeleteSessions {
    static readonly type = `${ns} Delete Session`;
    constructor(public rowKeys: string[]) {}
}

export class DeleteSessionFailure {
    static readonly type = `${ns} Delete Session Failure`;
    constructor(public error: any) {}
}

export class ApproveSessions {
    static readonly type = `${ns} Approve Sessions`;
    constructor(public rowKeys: string[], public linkId: string) {}
}

export class ApproveSessionsFailure {
    static readonly type = `${ns} Approve Sessions Failure`;
    constructor(public error: any) {}
}

export class SyncActiveSessions {
    static readonly type = `${ns} Sync Active Sessions`;
    constructor(public rowKey: string) {}
}

export class SyncActiveSessionsFailure {
    static readonly type = `${ns} Sync Active Sessions Failure`;
    constructor(public error: any) {}
}

export class ArchiveLinks {
    static readonly type = `${ns} Archive Link`;
    constructor(public links: ChecklistLinkModel[]) {}
}

export class ArchiveLinksFailure {
    static readonly type = `${ns} Archive Link Failure`;
    constructor(public error: any) {}
}

export class ConfirmArchiveLinks {
    static readonly type = `${ns} Confirm Archive Link`;
    constructor(public links: ChecklistLinkModel[]) {}
}

export class ConfirmDeleteLinks {
    static readonly type = `${ns} Confirm Delete Link`;
    constructor(public links: ChecklistLinkModel[]) {}
}

export class DeleteLinks {
    static readonly type = `${ns} Delete Link`;
    constructor(public links: ChecklistLinkModel[]) {}
}

export class DeleteLinksFailure {
    static readonly type = `${ns} Delete Link Failure`;
    constructor(public error: any) {}
}

export class CopyLink {
    static readonly type = `${ns} Copy Link`;
    constructor(public link: ChecklistLinkModel | null) {}
}

export class CopyLinkToClipboard {
    static readonly type = `${ns} Copy Link to Clipboard`;
    constructor(public link: ChecklistLinkModel, public timeZone: Timezone, public includeInfluencer: boolean = false) {}
}

export class OpenLink {
    static readonly type = `${ns} Open Link`;
    constructor(public link: ChecklistLinkModel) {}
}

export class SetCurrentTabIndex {
    static readonly type = `${ns} Set Current Tab Index`;
    constructor(public index: number) {}
}

export class SearchArchivedLinks {
    static readonly type = `${ns} Search Archived Links`;
    constructor(public model?: SearchOptionsModel) {}
}

export class SearchArchivedLinksFailure {
    static readonly type = `${ns} Search Archived Links Failure`;
    constructor(public error: any) {}
}

export class UpdateLinkSummary {
    static readonly type = `${ns} Update Link Summary`;
}

export class UpdateLinkSummaryFailure {
    static readonly type = `${ns} Update Link Summary Failure`;
    constructor(public err: any) {}
}

export class SaveChecklist {
    static readonly type = `${ns} Save Checklist`;
    constructor(public checklist: { rowKey: string; content: string }, public rowKey?: string) {}
}

export class SaveChecklistFailure {
    static readonly type = `${ns} Save Checklist Failure`;
    constructor(public error: any) {}
}

export class LoadChecklist {
    static readonly type = `${ns} Load Checklist`;
    constructor(public rowKey: string, public retry = true) {}
}

export class LoadChecklistSuccess {
    static readonly type = `${ns} Load Checklist Success`;
}

export class LoadChecklistFailure {
    static readonly type = `${ns} Load Checklist Failure`;
    constructor(public error: any) {}
}

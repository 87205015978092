<h1 mat-dialog-title>Confirm delete link(s)</h1>
<mat-dialog-content>
    <p>
        Are you certain you want to delete {{ count }} links and all associated sessions? This action is permanent and cannot
        be undone.
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="warn" [mat-dialog-close]="true">Delete</button>
</mat-dialog-actions>

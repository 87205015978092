<section>
    <mat-progress-spinner *ngIf="!hasError" mode="determinate" diameter="50" [value]="progress"></mat-progress-spinner>
    <div class="status-container" [ngClass]="status">
        <span class="status">{{ status }}</span>
    </div>
    <section class="message" *ngIf="state && !isCompleted">
        <div *ngFor="let video of state.videos">
            {{ video.meta.width }}x{{ video.meta.height }}
            {{ video.status }}
            {{ video.percent / 100 | percent }}
        </div>
    </section>
    <span class="error-message" *ngIf="hasError">{{ errorMessage }}</span>
    <mat-progress-bar *ngIf="showIndeterminateBar" mode="indeterminate"></mat-progress-bar>
</section>

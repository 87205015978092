<h1 mat-dialog-title>Confirm delete content</h1>
<mat-dialog-content>
    <p>
        Are you certain you want to delete <i>{{ name }}</i
        >?
    </p>
    <p>This action is permanent and cannot be undone.</p>
    <ng-container *ngIf="nodes | any">
        The following nodes will be affected:
        <ul>
            <li *ngFor="let node of nodes">{{ node.title }}</li>
        </ul>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="warn" [mat-dialog-close]="true">Delete</button>
</mat-dialog-actions>

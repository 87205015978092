import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatSelectionListChange, MatSelectionList, MatListOption } from '@angular/material/list';
import { LinkSessionSummary } from '@app/data/models';
import { AdminSessionStatus, LinkType } from '@app/shared/enums';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgFor, NgSwitch, NgSwitchCase, NgTemplateOutlet, NgSwitchDefault, NgClass, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { SrcUnloadDirective } from '../../../../../shared/src/lib/directives/src-unload.directive';
import { MatBadge } from '@angular/material/badge';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';

@Component({
    templateUrl: './merge-link-sessions-confirmation-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [`./merge-link-sessions-confirmation-dialog.component.scss`],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatSelectionList,
        NgFor,
        MatListOption,
        NgSwitch,
        NgSwitchCase,
        NgTemplateOutlet,
        NgSwitchDefault,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        MatIcon,
        ExtendedModule,
        NgClass,
        NgIf,
        SrcUnloadDirective,
        MatBadge,
        FormatDatePipe,
    ],
})
export class MergeLinkSessionsConfirmationDialogComponent {
    selected: LinkSessionSummary | null = null;

    LinkType: typeof LinkType = LinkType;

    get linkType(): LinkType {
        console.log(this.data.linkType)
        return this.data.linkType;
    }

    get sessions(): LinkSessionSummary[] {
        return this.data.sessions;
    }

    constructor(@Inject(MAT_DIALOG_DATA) private data: any) {}

    onSelectionChange(change: MatSelectionListChange) {
        this.selected = change.options[0].value;
    }

    getRowClasses(session: LinkSessionSummary): string[] {
        const classes = [];

        if (session.expanded) {
            classes.push('expanded');
        }

        switch (session.sessionStatus) {
            case AdminSessionStatus.Disabled:
                classes.push('disabled');
                break;
            case AdminSessionStatus.Inactive:
                classes.push('inactive');
                break;
            case AdminSessionStatus.Active:
                classes.push('active');
                break;
            case AdminSessionStatus.ActionRequired:
                classes.push('action-required');
                break;
            case AdminSessionStatus.Reset:
                classes.push('reset');
                break;
        }

        return classes;
    }
}

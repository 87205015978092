<form [formGroup]="form">
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" autocomplete="off" (keyup.enter)="onLogin()" />
            @if(username.invalid){
            <mat-error>
                <ui-error-display [error]="username.errors"></ui-error-display>
            </mat-error>
            }
        </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password" (keyup.enter)="onLogin()" />
            @if(password.invalid) {
            <mat-error>
                <ui-error-display [error]="password.errors"></ui-error-display>
            </mat-error>
            }
        </mat-form-field>
    </div>
    <div class="footer">
        <div>
            @if(error) {
            <ui-error-display class="error-display" [error]="error"></ui-error-display>
            }
        </div>
        <button type="submit" mat-raised-button color="primary" (click)="onLogin()" [disabled]="isPending">
            @if(isPending){
            <mat-spinner diameter="15"></mat-spinner>
            }@else {
            <mat-icon>login</mat-icon>
            } Login
        </button>
    </div>
</form>

import { LinkExpiration, LinkInactivityMode, TimerUnit } from '@app/shared/enums';
import { replaceIllegalCharacters } from '@app/shared/util';

export function getFileNameForMediaAssetUpload(mediaAssetId: string, file: File) {
    return `media-assets/${mediaAssetId}/${replaceIllegalCharacters(file.name).replace(/\W/g, "-")}.original`;
}

export function hasLinkEnded(
    now: Date,
    createdDate: Date,
    activationDate: Date | null,
    lastActivityDate: Date | null,
    endDate: Date | null,
    timerEndDate: Date | null,
    linkInactivityMode: LinkInactivityMode,
    linkExpiration: LinkExpiration,
    timer: number | null = 0,
    timerUnit: TimerUnit,
    inactivityTimeoutInDays: number = 0,
): boolean {
    if (linkExpiration === LinkExpiration.None) {
        return false;
    }

    if (linkExpiration === LinkExpiration.Date && endDate) {
        return now > endDate;
    }

    if (linkExpiration === LinkExpiration.Timer) {
        if (timerEndDate) {
            return now > timerEndDate;
        }

        if (linkInactivityMode === LinkInactivityMode.Link) {
            const endDate = new Date(activationDate || createdDate);

            switch (timerUnit) {
                case TimerUnit.Months:
                    endDate.setDate(endDate.getDate() + (timer as number) * 30);
                    break;
                case TimerUnit.Weeks:
                    endDate.setDate(endDate.getDate() + (timer as number) * 7);
                    break;
                case TimerUnit.Days:
                    endDate.setDate(endDate.getDate() + (timer as number));
                    break;
                case TimerUnit.Hours:
                    endDate.setHours(endDate.getHours() + (timer as number));
                    break;
                default:
                    endDate.setMinutes(endDate.getMinutes() + (timer as number));
                    break;
            }

            return now > endDate;
        }

        if (linkInactivityMode === LinkInactivityMode.User) {
            const endDate = new Date(lastActivityDate || createdDate);

            switch (timerUnit) {
                case TimerUnit.Months:
                    endDate.setDate(endDate.getDate() + (timer as number) * 30);
                    break;
                case TimerUnit.Weeks:
                    endDate.setDate(endDate.getDate() + (timer as number) * 7);
                    break;
                case TimerUnit.Days:
                    endDate.setDate(endDate.getDate() + (timer as number));
                    break;
                case TimerUnit.Hours:
                    endDate.setHours(endDate.getHours() + (timer as number));
                    break;
                default:
                    endDate.setMinutes(endDate.getMinutes() + (timer as number));
                    break;
            }

            endDate.setDate(endDate.getDate() + inactivityTimeoutInDays);

            return now > endDate;
        }
    }

    return false;
}

import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    EmbeddedViewRef,
    inject,
    Injector,
    input,
    OnChanges,
    output,
    ViewContainerRef,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem } from '@angular/material/list';

import { finalize, from, tap } from 'rxjs';

import { ClipboardModel, EventLinkSessionSummary, FormPage, FormSection, LinkModel, RegistrantInfo } from '@app/data/models';
import { FormSectionType } from '@app/shared/enums';
import * as html2canvas from 'html2canvas';

import { FormSubmissionsExportComponent } from '../form-submissions-export/form-submissions-export.component';
import { RegistrantDetailsComponent } from '../registrant-details/registrant-details.component';

@Component({
    selector: 'ui-form-chart-tooltip',
    templateUrl: './form-chart-tooltip.component.html',
    styleUrls: ['./form-chart-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CdkOverlayOrigin,
        CdkConnectedOverlay,

        NgClass,

        MatButton,
        MatIcon,
        MatList,
        MatListItem,
        MatRipple,

        RegistrantDetailsComponent,
    ],
})
export class FormChartTooltipComponent implements OnChanges {
    readonly category = input<string | null>(null);
    readonly link = input<LinkModel | null>(null);
    readonly pages = input<FormPage[] | null>(null);
    readonly sessions = input<EventLinkSessionSummary[] | null>(null);
    readonly webinars = input<{ id: number; topic: string }[] | null>([]);
    readonly meetings = input<{ id: number; topic: string }[] | null>([]);

    readonly selectItem = output<EventLinkSessionSummary>();
    readonly copyToClipboard = output<ClipboardModel>();

    viewContainerRef = inject(ViewContainerRef);
    injector = inject(Injector);

    isTooltipOpen = false;
    model: RegistrantInfo | null = null;
    _origin: CdkOverlayOrigin | null = null;
    zoomMeetings: { id: number; topic: string }[] = [];
    filteredSessions: EventLinkSessionSummary[] = [];
    componentRef: ComponentRef<FormSubmissionsExportComponent>;

    get registrantOrigin(): CdkOverlayOrigin {
        return this._origin as CdkOverlayOrigin;
    }

    onSelectItem(item: EventLinkSessionSummary): void {
        this.selectItem.emit(item);
    }

    onExport() {
        this.createAndAttachComponent();
        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        from(html2canvas.default(domElem))
            .pipe(
                tap(canvas => {
                    const contentDataURL = canvas.toDataURL('image/png');
                    const name = [this.link()?.url, this.category, 'export.png']
                        .join('-')
                        .replace(/[/:*?"<>|]/g, '')
                        .replace(/ /g, '_');

                    const a = document.createElement('a');
                    a.download = name;
                    a.href = contentDataURL;
                    a.click();

                    // canvas.toBlob(blob => {
                    //     const items = [new ClipboardItem({ 'image/png': blob as Blob })];
                    //     // navigator.clipboard.write(items);
                    //     this.copyToClipboard.emit({ message: 'Image copied to clipboard', items });
                    // }, 'image/png');
                }),
                finalize(() => this.removeComponent()),
            )
            .subscribe();
    }

    showTooltip(model: RegistrantInfo, origin: CdkOverlayOrigin) {
        this._origin = origin;
        this.isTooltipOpen = true;
        this.model = model;
    }

    // Method to create and attach the component
    createAndAttachComponent() {
        // Create a component reference
        this.componentRef = this.viewContainerRef.createComponent(FormSubmissionsExportComponent, {
            injector: this.injector,
        });
        this.componentRef.setInput('category', this.category());
        this.componentRef.setInput('link', this.link());
        this.componentRef.setInput('sessions', this.filteredSessions);

        this.componentRef.changeDetectorRef.detectChanges();

        // Get DOM element from the component
        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        // Append the component to the body
        document.body.appendChild(domElem);
    }

    // Method to remove the component
    removeComponent() {
        this.componentRef.destroy();
    }

    ngOnChanges(): void {
        this.filteredSessions = (this.sessions() ?? []).sort((a, b) => a.name.localeCompare(b.name));
        const pages = this.pages() || [];
        const webinars = this.webinars() || [];
        const meetings = this.meetings() || [];

        if (pages.length > 0) {
            this.zoomMeetings = pages
                .reduce(
                    (acc: FormSection[], page: FormPage) => acc.concat(page.children as FormSection[]),
                    [] as FormSection[],
                )
                .filter(
                    (s: FormSection) =>
                        s.sectionType === FormSectionType.WebinarRegistration ||
                        s.sectionType === FormSectionType.MeetingRegistration,
                )
                .map((s: FormSection) => {
                    const meeting = webinars.find(w => w.id === s.webinarId) || meetings.find(m => m.id === s.meetingId);

                    if (meeting) {
                        return meeting;
                    }

                    return { id: 0, topic: 'Meeting not found' };
                });
        } else {
            this.zoomMeetings = [];
        }
    }
}

import { QueryOptions } from "@app/data/models";

export class LoadIndex {
    static readonly type = '[Search] Load Index';
}
export class LoadIndexFailure {
    static readonly type = '[Search] Load Index Failure';
    constructor(public error: any) {}
}

export class UpdateIndex {
    static readonly type = '[Search] Update Index';
}
export class UpdateIndexSuccess {
    static readonly type = '[Search] Update Index Success';
}
export class UpdateIndexFailure {
    static readonly type = '[Search] Update Index Failure';
    constructor(public error: any) {}
}
export class Search {
    static readonly type = '[Search] Search';
    constructor(public query: string) {}
}

export class SearchLinks {
    static readonly type = '[Search] Search Links';
}

export class SearchLinksFailure {
    static readonly type = '[Search] Search Links Failure';
    constructor(public error: any) {}
}

export class SearchLinksByName {
    static readonly type = '[Search] Search Links By Name';
    constructor(public options?: QueryOptions) { }
}

export class SearchLinksByNameFailure {
    static readonly type = '[Search] Search Links By Name Failure';
    constructor(public error: any) {}
}

export class SearchFormsByName {
    static readonly type = '[Search] Search Forms By Name';
    constructor(public options?: QueryOptions) { }
}

export class SearchFormsByNameFailure {
    static readonly type = '[Search] Search Forms By Name Failure';
    constructor(public error: any) {}
}

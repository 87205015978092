import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageEventActions, ManageTemplateActions } from '../actions';
import { ManageEventsState } from '../state/manage-events.state';

export const EventLinksResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    store.dispatch(new CoreActions.UpdateGlobalLoading(true));
    const currentTabIndex = store.selectSnapshot(ManageEventsState.currentTabIndex());
    const action =
        currentTabIndex === 1
            ? [new ManageEventActions.EnsureLoadArchivedLinks(), new ManageTemplateActions.EnsureLoadPreambleTemplates()]
            : [new ManageEventActions.EnsureLoadLinks(), new ManageTemplateActions.EnsureLoadPreambleTemplates()];

    return store.dispatch(action).pipe(
        take(1),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};
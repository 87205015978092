import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

import { Node } from '../models';
import { errorHandler, getData, verifyServerResult } from '../operators';

@Injectable({
    providedIn: 'root',
})
export class NodeService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    getNodesForCurrentUser(): Observable<Node[]> {
        return this.http
            .get<ServerResult<Node[]>>(`${this.env.serverUrl}/nodes/`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getNodeById(rowKey: string): Observable<Node> {
        return this.http
            .post<ServerResult<Node>>(`${this.env.serverUrl}/node/`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getFavourites(): Observable<string[]> {
        return this.http
            .get<ServerResult<string[]>>(`${this.env.serverUrl}/nodes/favourites`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    addNodeToFavourites(nodeId: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/nodes/favourites/add`, { nodeId })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    removeNodeFromFavourites(nodeId: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/nodes/favourites/remove`, { nodeId })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}

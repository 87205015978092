import { NoteModel, SaveNoteModel, TaskModel, Node } from '@app/data/models';

export class AddNote {
    static readonly type = '[Notes] Add Note';
    constructor(public node?: Node | null) {}
}
export class AddNoteSuccess {
    static readonly type = '[Notes] Add Note Success';
}
export class AddNoteFailure {
    static readonly type = '[Notes] Add Note Failure';
    constructor(public error: any) {}
}

export class ConfirmDeleteNote {
    static readonly type = '[Notes] Confirm Delete Note';
    constructor(public note: NoteModel) {}
}

export class UpdateNote {
    static readonly type = '[Notes] Update Note';
    constructor(public note: NoteModel) {}
}

export class DeleteNote {
    static readonly type = '[Notes] Delete Note';
    constructor(public note: NoteModel) {}
}

export class DeleteNoteSuccess {
    static readonly type = '[Notes] Delete Note Success';
}

export class DeleteNoteFailure {
    static readonly type = '[Notes] Delete Note Failure';
    constructor(public error: any) {}
}

export class SelectNote {
    static readonly type = '[Notes] Select Note';
    constructor(public note: NoteModel | null) {}
}

export class PinNote {
    static readonly type = '[Notes] Pin Note';
    constructor(public note: NoteModel) {}
}

export class SaveNote {
    static readonly type = '[Notes] Save Note';
    constructor(public model: SaveNoteModel) {}
}

export class SaveNoteSuccess {
    static readonly type = '[Notes] Save Note Success';
}

export class SaveNoteFailure {
    static readonly type = '[Notes] Save Note Failure';
    constructor(public error: any) {}
}

export class SaveTasks {
    static readonly type = '[Notes] Save Tasks';
    constructor(public tasks: TaskModel[]) {}
}

export class SaveTasksSuccess {
    static readonly type = '[Notes] Save Tasks Success';
}

export class SaveTasksFailure {
    static readonly type = '[Notes] Save Tasks Failure';
    constructor(public error: any) {}
}

export class EnsureLoad {
    static readonly type = '[Notes] Ensure Load';
}

export class LoadNotes {
    static readonly type = '[Notes] Load Notes';
}

export class LoadNotesSuccess {
    static readonly type = '[Notes] Load Notes Success';
}

export class LoadNotesFailure {
    static readonly type = '[Notes] Load Notes Failure';
    constructor(public error: any) {}
}

export class LoadTasks {
    static readonly type = '[Notes] Load Tasks';
}

export class LoadTasksSuccess {
    static readonly type = '[Notes] Load Tasks Success';
}

export class LoadTasksFailure {
    static readonly type = '[Notes] Load Tasks Failure';
    constructor(public error: any) {}
}

export class Error {
    static readonly type = '[Notes] Error';
    constructor(public error: any) {}
}

export class Show {
    static readonly type = '[Notes] Show';
}

export class Hide {
    static readonly type = '[Notes] Hide';
}

export class Toggle {
    static readonly type = '[Notes] Toggle';
}

export class ShowNote {
    static readonly type = '[Notes] Show Note';
    constructor(public note: NoteModel) {}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'elapsedTime',
    standalone: true,
})
export class ElapsedTimePipe implements PipeTransform {
    transform(value: number | null | undefined, trim = false): string {
        const totalSeconds: number = value || 0;
        const totalMinutes: number = Math.trunc((value || 0) / 60);
        const totalHours: number = Math.trunc(totalMinutes / 60);
        const hours = Math.trunc(totalHours);
        const minutes = Math.trunc(totalMinutes - totalHours * 60);
        const seconds = Math.trunc(totalSeconds - hours * 60 * 60 - minutes * 60);

        if (trim) {
            if (hours > 0) {
                return (
                    hours.toString().padStart(2, '0') +
                    ':' +
                    minutes.toString().padStart(2, '0') +
                    ':' +
                    seconds.toString().padStart(2, '0')
                );
            } else if (minutes > 0) {
                return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
            }

            return seconds.toString().padStart(2, '0');
        }

        return (
            hours.toString().padStart(2, '0') +
            ':' +
            minutes.toString().padStart(2, '0') +
            ':' +
            seconds.toString().padStart(2, '0')
        );
    }
}

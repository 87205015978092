import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

export const DefaultResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => store.dispatch(new CoreActions.UpdateGlobalLoading(false));

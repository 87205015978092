<section>
  @if (sections | any) {
    <div fxLayout="column nowrap" fxLayoutAlign="center" class="sections">
      <!-- <div class="actions" *ngIf="showComplete">
      <button mat-raised-button [disabled]="submission?.isSubmitted" color="primary" (click)="onCompleteSubmission()">
        <mat-icon>check</mat-icon>
        <span>Complete Submission</span>
      </button>
    </div> -->
    @for (page of sections; track page; let ix = $index; let isFirst = $first) {
      <div class="admin-form-section" fxFlex="1 1 80%">
        <div class="divider">
          @if (!isFirst) {
            <mat-divider> </mat-divider>
          }
          <!-- <mat-icon>unfold_more</mat-icon> -->
          Page {{ ix + 1 }} of {{ sections.length }}
        </div>
        <ui-form-section class="section" [model]="page"> </ui-form-section>
        @for (child of page.children; track child) {
          @switch (child.sectionType) {
            @case (FormSectionType.Text) {
              <ui-form-section-text
                class="section"
                [model]="child"
              ></ui-form-section-text>
            }
            @case (FormSectionType.Question) {
              <ui-form-section-question
                class="section"
                [readonly]="true"
                [print]="true"
                [model]="child"
              ></ui-form-section-question>
            }
            @case (FormSectionType.File) {
              <ui-form-section-download
                class="section"
                [readonly]="true"
                [model]="child"
              ></ui-form-section-download>
            }
            @case (FormSectionType.Media) {
              <ui-form-section-media
                class="section"
                [readonly]="true"
                [model]="child"
              ></ui-form-section-media>
            }
            @case (FormSectionType.Event) {
              <ui-form-section-event
                class="section"
                [payments]="getPaymentsForSection(child)"
                [readonly]="true"
                [model]="$any(child)"
              ></ui-form-section-event>
            }
            @case (FormSectionType.Booking) {
              <mat-card class="section">
                <mat-card-header>
                  <mat-card-title>
                    {{ child.title }}
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  @if (hasCalendlyResult(child)) {
                    <ng-container *ngTemplateOutlet="calendlyEvent; context: { data: child }">
                    </ng-container>
                  } @else {
                    User has not yet scheduled a meeting
                  }
                </mat-card-content>
              </mat-card>
            }
          }
        }
      </div>
    }
  </div>
}
</section>

<ng-template #calendlyEvent let-data="data">
  <ui-calendly-event [event]="getScheduledEvent(data)" (loadEvent)="onLoadScheduledEvent(data)"></ui-calendly-event>
</ng-template>


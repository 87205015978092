import { MediaAsset, Node, QueueItem } from '@app/data/models';
import { MediaType } from '@app/shared/enums';
import { IAction } from '@app/shared/models';

const ns = '[Manage Nodes]';

export class AddNode {
    static readonly type = `${ns} Add Node`;
    constructor(public model: Node, public navigate = true) {}
}

export class EditNode {
    static readonly type = `${ns} Edit Node`;
    constructor(public model: Node | null) {}
}

export class SaveNode {
    static readonly type = `${ns} Save Node`;
}

export class SaveNodeFailure {
    static readonly type = `${ns} Save Node Failure 2`;
    constructor(public node: Node, public error: any) {}
}

export class SaveNodeBinary {
    static readonly type = `${ns} Save Node Binary`;
    constructor(public rowKey: string, public fileName: string, public binary: File) {}
}

export class SaveNodeBinarySuccess {
    static readonly type = `${ns} Save Node Binary Success`;
    constructor(public rowKey: string, public fileName: string, public binary: File) {}
}

export class SaveNodeBinaryFailure {
    static readonly type = `${ns} Save Node Binary Failure`;
    constructor(public rowKey: string, public error: any) {}
}

export class SaveNodeBinaryComplete {
    static readonly type = `${ns} Save Node Binary Complete`;
    constructor(public rowKey: string, public fileName: string, public binary: File) {}
}

export class SaveNodeMediaAsset {
    static readonly type = `${ns} Save Node Media Asset`;
    constructor(public node: Node, public asset: File, public mediaType: MediaType, public replaceExisting: boolean) {}
}

export class SaveNodeMediaAssetSuccess {
    static readonly type = `${ns} Save Node Media Asset Success`;
    constructor(public node: Node, public asset: MediaAsset) {}
}

export class SaveNodeMediaAssetFailure {
    static readonly type = `${ns} Save Node Media Asset Failure`;
    constructor(public node: Node, public error: any) {}
}

export class SaveNodeContent {
    static readonly type = `${ns} Save Node Content`;
    constructor(public id: string, public content: string) {}
}

export class SaveNodeContentSuccess {
    static readonly type = `${ns} Save Node Content Success`;
    constructor(public id: string) {}
}

export class SaveNodeContentFailure {
    static readonly type = `${ns} Save Node Content Failure 2`;
    constructor(public error: any) {}
}

export class DeleteNodeConfirmation {
    static readonly type = `${ns} Delete Node Confirmation`;
    constructor(public node: Node) {}
}

export class DeleteNode {
    static readonly type = `${ns} Delete Node`;
    constructor(public node: Node, public includeChildren: boolean, public includeMediaAsset: boolean) {}
}

export class UpdateNodeDuration {
    static readonly type = `[Home] Update Node Duration`;
    constructor(public node: Node) {}
}

export class AddErrors {
    static readonly type = `${ns} Add Errors`;
    constructor(public action: IAction, public error: any) {}
}

export class ClearErrors {
    static readonly type = `${ns} Clear Errors`;
    constructor(public actions?: IAction | IAction[]) {}
}

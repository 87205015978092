import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'ui-global-loading',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ` <ui-loading [viewBox]="'100 0 900 300'"></ui-loading>`,
    styleUrls: [`./global-loading.component.scss`],
    standalone: true,
    imports: [LoadingComponent],
})
export class GlobalLoadingComponent {}

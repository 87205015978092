<h1 mat-dialog-title>Confirm merge link sessions</h1>
<mat-dialog-content>
    <p>
        Are you certain you want to merge {{ sessions.length }} link sessions? This action is permanent and cannot be undone.
    </p>
    <p>Please select the primary session to merge into.</p>

    <mat-selection-list [multiple]="false" (selectionChange)="onSelectionChange($event)">
        <mat-list-option *ngFor="let session of sessions" [value]="session" [disableRipple]="true">
            <ng-container [ngSwitch]="linkType">
                <ng-container *ngSwitchCase="LinkType.Form">
                    <ng-container *ngTemplateOutlet="forms; context: { session: session }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="default; context: { session: session }"></ng-container>
                </ng-container>
            </ng-container>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button [disabled]="selected === null" color="primary" [mat-dialog-close]="selected">
        <mat-icon>merge_type</mat-icon>
        Merge
    </button>
</mat-dialog-actions>

<ng-template #forms let-session="session">
    <div class="row" [ngClass]="getRowClasses(session)"  >
        <img *ngIf="session.hasLocation" class="avatar" [src]="session.location?.flag" />
        <mat-icon *ngIf="!session.hasLocation" class="avatar no-location">gps_off</mat-icon>
        <div class="details">
            <span>
                {{ session.name }}
            </span>
            <div *ngIf="!session.isInfluencer" class="meta-badges">
                <span
                    *ngIf="session.formSubmissionCount > 0"
                    matBadgePosition="after"
                    matBadgeOverlap="false"
                    [matBadgeHidden]="session.formSubmissionCount === 0"
                    [matBadge]="session.formSubmissionCount"
                    class="access-badge"
                    >&nbsp;
                </span>
            </div>
            <mat-icon *ngIf="session.isInfluencer" class="no-location influencer">verified_user</mat-icon>
            <small class="session-expires" *ngIf="session.sessionExpires && session.isActiveSession">
                Expires {{ session.sessionExpires | formatDate }}
            </small>
        </div>
        <div class="location">
            <ng-container *ngIf="session.hasLocation">
                {{ session.location?.city }}, {{ session.location?.region }}, {{ session.location?.country }}
            </ng-container>
            <ng-container *ngIf="!session.hasLocation">Unknown</ng-container>
        </div>
    </div>
</ng-template>

<ng-template #default let-session="session">
    <div class="row" [ngClass]="getRowClasses(session)">
        <img *ngIf="session.hasLocation" class="avatar" [src]="session.location?.flag" />
        <mat-icon *ngIf="!session.hasLocation" class="avatar no-location">gps_off</mat-icon>
        <div class="details">
            <span>
                {{ session.name }}
            </span>
            <div *ngIf="!session.isInfluencer" class="meta-badges">
                <span
                    *ngIf="session.lifeTimeSessionAccessCount > 0"
                    matBadgePosition="after"
                    matBadgeOverlap="false"
                    [matBadgeHidden]="session.lifeTimeSessionAccessCount === 0"
                    [matBadge]="session.lifeTimeSessionAccessCount"
                    class="access-badge"
                    >&nbsp;
                </span>
            </div>
            <mat-icon *ngIf="session.isInfluencer" class="no-location influencer">verified_user</mat-icon>
            <small class="session-expires" *ngIf="session.sessionExpires && session.isActiveSession">
                Expires {{ session.sessionExpires | formatDate }}
            </small>
        </div>
        <div class="location">
            <ng-container *ngIf="session.hasLocation">
                {{ session.location?.city }}, {{ session.location?.region }}, {{ session.location?.country }}
            </ng-container>
            <ng-container *ngIf="!session.hasLocation">Unknown</ng-container>
        </div>
    </div>
</ng-template>

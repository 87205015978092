import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, take, tap } from 'rxjs/operators';

import { NotificationActions } from '@app/data/actions';
import { EditNodeModel, FormState, Node } from '@app/data/models';
import { BlobService } from '@app/data/services/blob.service';
import { ToastrProgressComponent } from '@app/shared/components';
import { BlobContainer, EncodingProvider, EncodingStatus, MediaAssetType, MediaType, NodeType } from '@app/shared/enums';
import { Environment, IAction, isErrorModel } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { getContainerName, isArray, isFile, isFunction, isString, randomId } from '@app/shared/util';
import { TransferProgressEvent } from '@azure/core-http';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Actions, createSelector, ofActionSuccessful, State, StateContext, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import { AdminActions, HomeActions, ManageMediaActions, ManageNodeActions, SearchActions } from '../actions';
import {
    DeleteNodeConfirmationDialogComponent,
} from '../dialogs/delete-node-confirmation/delete-node-confirmation-dialog.component';
import { ManageMediaService,  } from '../services/manage-media.service';
import { getFileNameForMediaAssetUpload } from '../utils';
import { ManageNodesService } from '../services/manage-node.service';

export interface StateModel {
    isInitialized: boolean;
    model: Node | null;
    uploadProgress: Record<string, number | undefined>;

    hasLoaded: boolean;
    isLoading: boolean;
    isSaving: boolean;
    isWorking: boolean;

    form: FormState<EditNodeModel>;

    errors: { [key: string]: any } | null;
}

@State<StateModel>({
    name: 'manageNodes',
    defaults: {
        model: null,
        isInitialized: false,
        uploadProgress: {},

        hasLoaded: false,
        isLoading: false,
        isSaving: false,
        isWorking: false,
        errors: {},

        form: {
            model: null,
            errors: {},
            status: '',
            dirty: false,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class ManageNodesState {
    private timer: number | undefined = undefined;

    static getEditModel() {
        return createSelector([ManageNodesState], (state: StateModel) => state.model);
    }

    static isSaving() {
        return createSelector([ManageNodesState], (state: StateModel) => state.isSaving);
    }

    static getFormModel() {
        return createSelector([ManageNodesState], (state: StateModel) => state.form.model);
    }

    static isValid() {
        return createSelector([ManageNodesState], (state: StateModel) => state.form.status === 'VALID');
    }

    static isInitialized() {
        return createSelector([ManageNodesState], (state: StateModel) => state.isInitialized);
    }

    static getErrors() {
        return createSelector([ManageNodesState], (state: StateModel) => state.errors);
    }

    static getUploadProgress() {
        return createSelector([ManageNodesState], (state: StateModel) => state.uploadProgress);
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private store: Store,
        private dialog: MatDialog,
        private manageNodeService: ManageNodesService,
        private manageMediaService: ManageMediaService,
        private blobService2: BlobService,
        private toastr: ToastrService,
        private actions: Actions,
    ) {}

    @Action(ManageNodeActions.DeleteNodeConfirmation)
    deleteNodeConfirmation(ctx: StateContext<StateModel>, { node }: ManageNodeActions.DeleteNodeConfirmation) {
        return this.dialog
            .open(DeleteNodeConfirmationDialogComponent, {
                closeOnNavigation: false,
                disableClose: true,
                data: { ...node },
            })
            .afterClosed()
            .pipe(
                mergeMap(result => {
                    if (result) {
                        return this.store.dispatch(
                            new ManageNodeActions.DeleteNode(node, result.includeChildren, result.includeMediaAsset),
                        );
                    }

                    return of(null);
                }),
            );
    }

    @Action(ManageNodeActions.DeleteNode)
    deleteNode(
        { dispatch, patchState }: StateContext<StateModel>,
        { node, includeChildren, includeMediaAsset }: ManageNodeActions.DeleteNode,
    ) {
        patchState({ isSaving: true });
        dispatch(new HomeActions.UpdateNodes([{ ...node, isSaving: true }]));

        return this.manageNodeService.deleteNode(node, includeChildren, includeMediaAsset).pipe(
            mergeMap(result => {
                return dispatch([
                    new Navigate(['/home']),
                    new ManageMediaActions.RemoveMediaAsset(result.mediaAssetsToRemove),
                    new HomeActions.RemoveNodes(result.nodesToRemove),
                    new HomeActions.UpdateNodes(result.nodesToUpdate.map(n => ({ ...n, isLoaded: false }))),
                    new NotificationActions.Success('Content have been successfully deleted'),
                    new SearchActions.UpdateIndex(),
                ]);
            }),
            finalize(() => patchState({ isSaving: false })),
        );
    }

    @Action(ManageNodeActions.UpdateNodeDuration)
    updateNodeDuration(ctx: StateContext<StateModel>, { node }: ManageNodeActions.UpdateNodeDuration) {
        return this.manageNodeService.updateNodeDuration(node.rowKey as string).pipe(
            mergeMap(duration => {
                return this.store.dispatch([new HomeActions.UpdateNodes([{ ...node, duration }])]);
            }),
        );
    }

    @Action(ManageNodeActions.AddNode)
    addNode({ dispatch, patchState }: StateContext<StateModel>, { model, navigate }: ManageNodeActions.AddNode) {
        patchState({ isSaving: false, model });

        if (navigate) {
            const type = NodeType[model.type].toLowerCase();
            return dispatch(new Navigate(['/home/add', type]));
        }

        return of(null);
    }

    @Action(ManageNodeActions.SaveNode)
    saveNode({ dispatch, patchState }: StateContext<StateModel>) {
        patchState({ isSaving: true });

        dispatch(
            new ManageNodeActions.ClearErrors([
                ManageNodeActions.SaveNodeFailure,
                ManageNodeActions.SaveNodeBinaryFailure,
                ManageNodeActions.SaveNodeContentFailure,
                ManageNodeActions.SaveNodeMediaAssetFailure,
            ]),
        );

        const model = this.store.selectSnapshot(ManageNodesState.getEditModel()) as Node;
        const formModel = this.store.selectSnapshot(ManageNodesState.getFormModel()) as EditNodeModel;
        const { title, status, colour, content, parentId, binary, replaceExisting, type, mediaAssetId } = formModel;

        const node = {
            ...model,
            title,
            status,
            colour,
            parentId,
            type,
            mediaAssetId,
            content: '',
        };

        if (isFile(binary)) {
            const randId = randomId(6);
            const parts = binary.name.split('.');
            const ext = parts.pop();
            const safeFileName = parts.join('').replace(/[^\w\d\-_.]/g, '_');

            node.binary = `${safeFileName}-${randId}.${ext}`;
            node.binaryContentType = binary.type;
            node.binaryContentLength = binary.size;
        } else if (isString(binary)) {
            node.binary = binary;
        }

        return this.manageNodeService.saveNode(node).pipe(
            mergeMap(nodes => {
                const rowKey = nodes[0].rowKey;
                const id = `${rowKey}/${rowKey}.html`;
                const container = getContainerName(this.env, BlobContainer.Private);

                return dispatch(new ManageNodeActions.SaveNodeContent(id, content || '')).pipe(
                    mergeMap(() => {
                        return dispatch([
                            new NotificationActions.Success('Saved successfully'),
                            new AdminActions.PurgeCdn({ keys: [`/${container}/content/${id}`] }, 3000),
                            new NotificationActions.Info(
                                'Purge request sent. Please allow up to 3 minutes for changes to take effect.',
                            ),
                            new HomeActions.UpdateNodes(nodes.map(n => ({ ...n, isLoaded: false, isSaving: false }))),
                        ]);
                    }),
                    map(() => nodes[0]),
                );
            }),
            tap(n => {
                // any binary data to upload or media assets to process?
                if (isFile(binary)) {
                    switch (type) {
                        case NodeType.File:
                            dispatch([
                                new ManageNodeActions.SaveNodeBinary(n.rowKey, `content/${n.rowKey}/${n.binary}`, binary),
                                new Navigate(['/home', n.rowKey]),
                            ]);
                            break;
                        case NodeType.Audio:
                            dispatch([
                                new ManageNodeActions.SaveNodeBinary(n.rowKey, `content/${n.rowKey}/${n.binary}`, binary),
                                new Navigate(['/home', n.rowKey]),
                            ]);
                            this.actions
                                .pipe(
                                    ofActionSuccessful(
                                        ManageNodeActions.SaveNodeBinarySuccess,
                                        ManageNodeActions.SaveNodeBinaryComplete,
                                        ManageNodeActions.SaveNodeBinaryFailure,
                                    ),
                                    filter((action: any) => action.rowKey === n.rowKey),
                                    take(1),
                                    tap(e => {
                                        if (!(e instanceof ManageNodeActions.SaveNodeBinaryFailure)) {
                                            dispatch(new ManageNodeActions.UpdateNodeDuration(n));
                                        }
                                    }),
                                )
                                .subscribe();
                            break;
                        case NodeType.Video:
                            dispatch(
                                new ManageNodeActions.SaveNodeMediaAsset(
                                    n,
                                    binary,
                                    MediaType.Video,
                                    replaceExisting === true,
                                ),
                            );
                            break;
                    }
                    return;
                }

                dispatch(new Navigate(['/home', n.rowKey]));
            }),

            catchError(error => dispatch(new ManageNodeActions.SaveNodeFailure(node, error))),
            finalize(() => patchState({ isSaving: false })),
        );
    }

    @Action(ManageNodeActions.SaveNodeMediaAsset)
    saveNodeMediaAsset(
        { dispatch, patchState }: StateContext<StateModel>,
        { node, asset, mediaType, replaceExisting }: ManageNodeActions.SaveNodeMediaAsset,
    ) {
        dispatch(new ManageNodeActions.ClearErrors(ManageNodeActions.SaveNodeMediaAssetFailure));

        const result$ = this.manageMediaService
            .createMediaAssetForNode(node.rowKey, asset.name, mediaType, MediaAssetType.Default, replaceExisting)
            .pipe(
                mergeMap(({ item1: updatedNode, item2: mediaAsset }) => {
                    const toast = this.toastr.success('', '', {
                        toastComponent: ToastrProgressComponent,
                        disableTimeOut: true,
                        positionClass: 'toast-bottom-right',
                    });

                    dispatch([
                        new HomeActions.UpdateNodes([{ ...updatedNode, isLoaded: false, isSaving: false }]),
                        new ManageMediaActions.UpdateMediaAsset(mediaAsset),
                        new ManageMediaActions.AddPlaceHolderJob({
                            mediaAssetId: mediaAsset.rowKey,
                            status: EncodingStatus.Queued,
                            rowKey: '',
                            acknowledged: false,
                            progress: 0,
                            createdDate: new Date(),
                            name: mediaAsset.title,
                            provider: EncodingProvider.Qencode,
                            providerPayload: null,
                            providerPayloadAsString: '',
                            providerUniqueId: '',
                        }),
                        new Navigate(['/home', updatedNode.rowKey]),
                    ]);

                    const abortSignal = new AbortController();
                    const instance = toast.toastRef.componentInstance as ToastrProgressComponent;
                    instance.title = `Uploading`;
                    instance.message = `${asset.name}`;

                    instance.registerCancel('Cancel', () => {
                        abortSignal.abort();
                        this.toastr.remove(toast.toastId);
                    });

                    return this.blobService2
                        .uploadFile(
                            getFileNameForMediaAssetUpload(mediaAsset.rowKey, asset),
                            asset,
                            asset.type,
                            BlobContainer.Private,
                            abortSignal.signal,
                        )
                        .pipe(
                            tap((ev: TransferProgressEvent | BlobUploadCommonResponse) => {
                                // console.log('ev');
                                // check if the progress event has loadedBytes
                                if ('loadedBytes' in ev) {
                                    const progress = Math.floor(100 * (ev['loadedBytes'] / asset.size));
                                    instance.updateProgress(progress);
                                    patchState({
                                        uploadProgress: {
                                            ...this.store.selectSnapshot(ManageNodesState.getUploadProgress()),
                                            [node.rowKey]: progress,
                                        },
                                    });
                                } else if ('_response' in ev) {
                                    dispatch(new NotificationActions.Success(`${asset.name} uploaded successfully`));
                                }
                            }),
                            filter(ev => '_response' in ev),
                            tap(() => {
                                this.toastr.remove(toast.toastId);
                                dispatch([new ManageMediaActions.SendToEncodingQueue(mediaAsset)]);
                            }),
                            catchError(error => {
                                console.log('error', error);
                                if (error.name !== 'AbortError') {
                                    dispatch([new NotificationActions.Error(`Upload failed for ${asset.name}`, error)]);
                                }

                                return this.manageMediaService.cancelMediaAsset(mediaAsset.rowKey);
                            }),
                            finalize(() => {
                                this.toastr.remove(toast.toastId);
                                patchState({
                                    uploadProgress: {
                                        ...this.store.selectSnapshot(ManageNodesState.getUploadProgress()),
                                        [node.rowKey]: undefined,
                                    },
                                });
                            }),
                        );
                }),
                catchError(error => dispatch(new ManageNodeActions.SaveNodeMediaAssetFailure(node, error))),
            );

        return result$;
    }

    @Action(ManageNodeActions.SaveNodeBinary)
    saveNodeBinary({ dispatch }: StateContext<StateModel>, { rowKey, fileName, binary }: ManageNodeActions.SaveNodeBinary) {
        const toast = this.toastr.success('', '', {
            toastComponent: ToastrProgressComponent,
            disableTimeOut: true,
            positionClass: 'toast-bottom-right',
        });

        const abortSignal = new AbortController();
        const instance = toast.toastRef.componentInstance as ToastrProgressComponent;
        instance.title = `Uploading`;
        instance.message = `${binary.name}...`;

        instance.registerCancel('Cancel', () => {
            abortSignal.abort();
            this.toastr.remove(toast.toastId);
        });

        return this.blobService2.uploadFile(fileName, binary, binary.type, BlobContainer.Private, abortSignal.signal).pipe(
            tap((ev: TransferProgressEvent | BlobUploadCommonResponse) => {
                // check if the progress event has loadedBytes
                if ('loadedBytes' in ev) {
                    instance.updateProgress(Math.floor(100 * (ev['loadedBytes'] / binary.size)));
                } else if ('_response' in ev) {
                    dispatch([
                        new NotificationActions.Success(`${binary.name} uploaded successfully`, {
                            disableTimeOut: true,
                        }),
                        new ManageNodeActions.SaveNodeBinarySuccess(rowKey, fileName, binary),
                    ]);
                }
            }),
            catchError(error => {
                if (error.name === 'AbortError') {
                    dispatch([new NotificationActions.Info(`Upload cancelled for ${binary.name}`)]);
                } else {
                    dispatch([new NotificationActions.Error(`Upload failed for ${binary.name}`, error)]);
                }

                dispatch(new ManageNodeActions.SaveNodeBinaryFailure(rowKey, error));

                return of(null);
            }),
            finalize(() => {
                this.toastr.remove(toast.toastId);
                dispatch(new ManageNodeActions.SaveNodeBinaryComplete(rowKey, fileName, binary));
            }),
        );
    }

    @Action(ManageNodeActions.SaveNodeContent)
    saveNodeContent({ dispatch }: StateContext<StateModel>, { id, content }: ManageNodeActions.SaveNodeContent) {
        return this.blobService2.uploadBlobBlock(`content/${id}`, content, 'text/html', BlobContainer.Private).pipe(
            tap(() => dispatch(new SearchActions.UpdateIndex())),
            catchError(error => dispatch(new ManageNodeActions.SaveNodeContentFailure(error))),
        );
    }

    @Action(ManageNodeActions.EditNode)
    editNode(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageNodeActions.EditNode,
    ): Observable<any> | void {
        if (!action.model) {
            patchState({ model: null });
            return;
        }

        const { model } = action;

        dispatch(
            new ManageNodeActions.ClearErrors([
                ManageNodeActions.SaveNodeFailure,
                ManageNodeActions.SaveNodeContentFailure,
                ManageNodeActions.SaveNodeMediaAssetFailure,
            ]),
        );

        patchState({ model });
    }

    @Action(ManageNodeActions.AddErrors)
    addErrors(ctx: StateContext<StateModel>, { action, error }: ManageNodeActions.AddErrors) {
        const errors = { ...this.store.selectSnapshot(ManageNodesState.getErrors()) };

        errors[action.type] = error;

        ctx.patchState({ errors });
    }

    @Action(ManageNodeActions.ClearErrors)
    clearErrors(ctx: StateContext<StateModel>, { actions }: ManageNodeActions.ClearErrors) {
        let errors = { ...this.store.selectSnapshot(ManageNodesState.getErrors()) };

        if (isArray<IAction>(actions)) {
            actions.forEach(act => {
                errors[act.type] = null;
            });
        } else if (isFunction(actions)) {
            errors[actions.type] = null;
        } else {
            errors = {};
        }

        ctx.patchState({ errors });
    }

    @Action([ManageNodeActions.SaveNodeMediaAssetFailure, ManageNodeActions.SaveNodeContentFailure])
    handleFailure(
        { dispatch }: StateContext<StateModel>,
        action: ManageNodeActions.SaveNodeMediaAssetFailure | ManageNodeActions.SaveNodeContentFailure,
    ) {
        const error = action.error;
        let message = 'Unexpected error encountered';

        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof ManageNodeActions.SaveNodeFailure:
                message = 'Unexpected error encountered saving';
                break;
            case action instanceof ManageNodeActions.SaveNodeContentFailure:
                message = 'Unexpected error encountered saving HTML content';
                break;
            case action instanceof ManageNodeActions.SaveNodeMediaAssetFailure:
                if (isErrorModel(error)) {
                    return dispatch(
                        new ManageNodeActions.AddErrors(ManageNodeActions.SaveNodeMediaAssetFailure, action.error),
                    );
                }

                message = 'Unexpected error encountered while uploading media asset';
                break;
        }

        return dispatch([new NotificationActions.Error(message, error)]);
    }
}

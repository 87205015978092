<h1 mat-dialog-title>Rename asset</h1>
<mat-dialog-content [formGroup]="form">
    <mat-form-field class="full-width">
        <mat-label>Asset Name</mat-label>
        <input matInput formControlName="name" />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button
        mat-raised-button
        color="primary"
        [disabled]="form.invalid"
        [mat-dialog-close]="{ name: form.value.name, asset: data }"
    >
        Rename
    </button>
</mat-dialog-actions>

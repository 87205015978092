<ui-node-tree [nodes]="nodes" [nodeTemplate]="nodeTemplate" [nodeTemplateContext]="summary"></ui-node-tree>

<ng-template #nodeTemplate let-node let-summary="data">
  <div fxLayout="row nowrap" fxLayoutAlign="start center" [ngClass]="{ disabled: isDisabled(summary, node.data) }">
    <div class="indent">
      @if (isComplete(summary, node.data)) {
        <mat-icon class="complete">check</mat-icon>
      }
    </div>

    <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
    <a class="node-name">
      <span>{{ node.name }}</span>
    </a>
    @if (node.data.duration) {
      <time>&nbsp;({{ node.data.duration }})</time>
    }
  </div>
</ng-template>
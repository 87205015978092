<mat-toolbar>
  <mat-toolbar-row>
    <button mat-icon-button (click)="onToggleNavigation()">
      <mat-icon
        matBadgePosition="after"
        matBadgeOverlap="false"
        class="badge"
        [ngClass]="[badgeColor]"
        [matBadgeHidden]="!showBadge"
        [matBadge]="badgeText"
        >menu</mat-icon
        >
      </button>
      @if (showSearch) {
        <form [formGroup]="form">
          <input placeholder="Search" autocomplete="off" matInput formControlName="search" [searchResults]="auto" />
        </form>
      }
      <ng-content></ng-content>
    </mat-toolbar-row>
  </mat-toolbar>
  <ui-search-results #auto (optionSelected)="onSelectNode($event)">
    @if (isSearchEnabled && (searchResults | any)) {
      <mat-list class="search-results">
        @for (result of searchResults; track result; let isLast = $last) {
          @switch (result.type) {
            @case (SearchResultType.Node) {
              <mat-list-item class="item" [searchResultItem]="result">
                <mat-icon matListItemIcon>{{ result.data | nodeTypeIcon }}</mat-icon>
                <h3 class="title" matListItemLine [innerHTML]="result.data.title"></h3>
                @if (result.path) {
                  <i class="path" matListItemLine>{{ result.path }}</i>
                }
                @for (token of result.tokens; track token) {
                  <p
                    class="body"
                    mat-subheader
                    matListItemLine
                    [innerHTML]="token | highlight: search.value"
                  ></p>
                }
                <div matListItemLine>
                  @if (result.keywords.size > 0) {
                    <ul class="keywords">
                      <li>Keywords:</li>
                      @for (keyword of result.keywords | keyvalue; track keyword) {
                        <li>{{ keyword.key }} x{{ keyword.value }}</li>
                      }
                    </ul>
                  }
                </div>
              </mat-list-item>
            }
            @case (SearchResultType.Note) {
              <mat-list-item class="item" [searchResultItem]="result">
                <mat-icon matListItemIcon>note</mat-icon>
                <h3 class="title" matListItemLine [innerHTML]="result.data.title"></h3>
                @for (token of result.tokens; track token) {
                  <p
                    class="body"
                    mat-subheader
                    matListItemLine
                    [innerHTML]="token | highlight: search.value"
                  ></p>
                }
                <div matListItemLine>
                  @if (result.keywords.size > 0) {
                    <ul class="keywords">
                      <li>Keywords:</li>
                      @for (keyword of result.keywords | keyvalue; track keyword) {
                        <li>{{ keyword.key }} x{{ keyword.value }}</li>
                      }
                    </ul>
                  }
                </div>
              </mat-list-item>
            }
          }
        }
      </mat-list>
    }
  </ui-search-results>

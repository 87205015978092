import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';

import {
    AsyncSubject,
    catchError,
    filter,
    finalize,
    from,
    interval,
    mergeMap,
    of,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

import { EncodingJob } from '@app/data/models';
import { EncodingStatus } from '@app/shared/enums';
import { DestroyService } from '@app/shared/services';
import { isNullOrEmpty } from '@app/shared/util';
import { NgIf, NgClass, NgFor, PercentPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'admin-encoding-status',
    templateUrl: './encoding-status.component.html',
    styleUrls: ['./encoding-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinner,
        ExtendedModule,
        NgClass,
        NgFor,
        MatProgressBar,
        PercentPipe,
    ],
})
export class EncodingStatusComponent implements OnInit, OnChanges {
    response: ApiResponse | null = null;
    statusUrl = 'https://api.qencode.com/v1/status';
    completed$ = new AsyncSubject<void>();
    digest$ = new Subject<void>();

    get state() {
        if (this.response === null || this.response.error !== 0 || this.response.statuses['null']) {
            return null;
        }

        return this.response.statuses[this.encodingJob?.providerUniqueId as string];
    }

    get isCompleted() {
        if (this.encodingJob) {
            return this.encodingJob.status === EncodingStatus.Finished;
        }
        return false;
    }

    get status() {
        if (this.hasError) {
            return 'error encoding video';
        }

        return this.state?.status || 'queued';
    }

    get progress(): number {
        return this.state?.percent || 0;
    }

    get hasError() {
        return this.state?.error === 1;
    }

    get errorMessage() {
        return this.state?.error_description || 'Unknown error';
    }

    get showIndeterminateBar(): boolean {
        return this.progress === 0 && this.status !== 'completed';
    }

    @Input() encodingJob: EncodingJob | null;

    @Output() readonly encodingComplete: EventEmitter<EncodingJob | null> = new EventEmitter();

    constructor(private destroy$: DestroyService, private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.digest$
            .pipe(
                takeUntil(this.destroy$),
                filter(() => !isNullOrEmpty(this.encodingJob?.providerUniqueId)),
                switchMap(() => {
                    const body = new FormData();
                    body.append('task_tokens', this.encodingJob?.providerUniqueId as string);

                    return from(fetch(this.statusUrl, { method: 'POST', body })).pipe(
                        takeUntil(this.destroy$),
                        takeUntil(this.completed$),
                        mergeMap(response => {
                            if (response.ok) {
                                return response.json();
                            }

                            return of(null);
                        }),
                        tap((response: ApiResponse | null) => {
                            if (response !== null) {
                                this.response = response;
                                this.updateStatusUrl();
                            }

                            this.cd.detectChanges();
                        }),
                        tap(() => {
                            if (this.state?.status === 'completed') {
                                this.completed$.next();
                                this.completed$.complete();
                                this.encodingComplete.emit(this.encodingJob);
                            }
                        }),
                        mergeMap(() =>
                            interval(5000).pipe(
                                startWith(0),
                                takeUntil(this.destroy$),
                                takeUntil(this.completed$),
                                mergeMap(() => {
                                    const body = new FormData();
                                    body.append('task_tokens', this.encodingJob?.providerUniqueId as string);

                                    return from(fetch(this.statusUrl, { method: 'POST', body }));
                                }),
                                mergeMap(response => {
                                    if (response.ok) {
                                        return response.json();
                                    }

                                    return of(null);
                                }),
                                tap((response: ApiResponse | null) => {
                                    if (response !== null) {
                                        this.response = response;
                                        this.updateStatusUrl();
                                    }

                                    this.cd.detectChanges();
                                }),
                                tap(() => {
                                    if (this.state?.status === 'completed') {
                                        this.completed$.next();
                                        this.completed$.complete();
                                        this.encodingComplete.emit(this.encodingJob);
                                    }
                                }),
                                catchError(err => {
                                    return of(null);
                                }),
                            ),
                        ),
                    );
                }),
            )
            .subscribe();

        this.digest$.next(void 0);
    }

    updateStatusUrl() {
        if (this.response) {
            this.statusUrl = this.response.statuses[this.encodingJob?.providerUniqueId as string].status_url;
        }
    }

    ngOnChanges(): void {
        this.digest$.next(void 0);
    }
}

interface ApiResponse {
    error: number;
    statuses: {
        [taskToken: string]: {
            status: string;
            percent: number;
            error: number;
            error_description: string | null;
            warnings: string[] | null;
            images: any[];
            videos: VideoModel[];
            audios: any[];
            texts: any[];
            duration: number | string;
            source_size: number | string;
            api_version: string;
            status_url: string;
        };
    };
}

interface VideoModel {
    tag: string;
    profile: string | null;
    user_tag: string | null;
    storage: {
        url: string;
        playlist: string;
        type: string;
        format: string;
        expire: string | null;
        timestamp: string;
    };
    url: string;
    bitrate: number;
    meta: {
        resolution_width: number;
        resolution_height: number;
        framerate: string;
        height: number;
        width: number;
        codec: string;
        dar: string;
        aspect_ratio: number;
        sar: string;
        bitrate: string;
        audio_codec: string;
    };
    duration: string;
    size: string;
    output_format: string;
    percent: number;
    status: string;
    error: boolean;
    error_description: string | null;
}

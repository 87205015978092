<mat-card>
    <mat-card-content [formGroup]="form">
        <h2 matCardTitle>Admin actions</h2>
        <mat-form-field appearance="outline">
            <mat-select formControlName="action">
                <mat-optgroup label="Shareablelink.online">
                    <mat-option value="calc-shareable-links">Calc Shareable Links</mat-option>
                    <mat-option value="calc-form-links">Calc Form Links</mat-option>
                    <mat-option value="migrate-shareable-links">Migrate Shareable Links</mat-option>
                    <mat-option value="migrate-form-links">Migrate Form Links</mat-option>
                    <mat-option value="archive-shareable-links">Archive Links</mat-option>
                    <!-- <mat-option value="migrate-archived-links">Migrate Archived Links</mat-option> -->
                </mat-optgroup>
                <!-- <mat-optgroup label="Shareable Links">
                    <mat-option value="auto-archive-shareable-links">Auto-Archive Links</mat-option>
                    <mat-option value="calculate-shareable-link-counts">Calculate Link Counts</mat-option>
                    <mat-option value="migrate-shareable-links">Migrate Links</mat-option>
                    <mat-option value="recalculate-session-location-data">Recalculate Session Location Data</mat-option>
                    <mat-option value="link-session-metadata">Update Link Session Metadata</mat-option>
                </mat-optgroup>
                <mat-optgroup label="Form Links">
                    <mat-option value="auto-archive-form-links">Auto-Archive Links</mat-option>
                    <mat-option value="calculate-form-link-counts">Calculate Link Counts</mat-option>
                    <mat-option value="generate-form-submission-data">Generate test link</mat-option>
                    <mat-option value="migrate-form-links">Migrate Form Links</mat-option>
                </mat-optgroup>
                <mat-optgroup label="Event Links">
                    <mat-option value="auto-archive-event-links">Auto-Archive Links</mat-option>
                    <mat-option value="calculate-event-link-counts">Calculate Link Counts</mat-option>
                    <mat-option value="migrate-event-links">Migrate Event Links</mat-option>
                </mat-optgroup>

                <mat-optgroup label="Azure Media Services">
                    <mat-option value="clear-locators">Clear media locators</mat-option>
                    <mat-option value="sync-media">Sync Media</mat-option>
                    <mat-option value="find-orphaned-mediaassets">Find orphaned media assets</mat-option>
                    <mat-option value="media-asset-library">Calculate Media Library Stats</mat-option>
                </mat-optgroup>
                <mat-optgroup label="Content">
                    <mat-option value="migrate-nodes">Migrate Nodes</mat-option>
                    <mat-option value="recalculate-node-paths">Recalculate Node Paths</mat-option>
                    <mat-option value="html-snapshot">Take HTML content snapshot</mat-option>
                </mat-optgroup>
                <mat-optgroup label="Preamble">
                    <mat-option value="migrate-preamble">Migrate Preamble</mat-option>
                </mat-optgroup>
                <mat-optgroup label="Webinars">
                    <mat-option value="sync-zoom-webinar-participants">Sync webinar participants</mat-option>
                </mat-optgroup> -->
            </mat-select>
            <mat-error *ngIf="form.invalid"> Required </mat-error>
        </mat-form-field>
        <button mat-raised-button [disabled]="isPending" (click)="onExecuteAction()">
            <mat-spinner class="button-spinner" color="primary" diameter="15" *ngIf="isPending"></mat-spinner>Execute Action
        </button>
    </mat-card-content>
</mat-card>

import { Injector } from '@angular/core';

import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';
import { Editor, markPasteRule, PasteRuleMatch } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import { MarkType } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { mergeAttributes } from '@tiptap/core';
import { find } from 'linkifyjs';
import { transformToCdnUrl } from '@app/shared/util';

type PasteHandlerOptions = {
    editor: Editor;
    defaultProtocol: string;
    type: MarkType;
};

const ATTR_WHITESPACE = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g; // eslint-disable-line no-control-regex
const IS_ALLOWED_URI = /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i; // eslint-disable-line no-useless-escape

function isAllowedUri(uri: string | undefined) {
    return !uri || uri.replace(ATTR_WHITESPACE, '').match(IS_ALLOWED_URI);
}

export const createLinkExtension = (injector: Injector) => {
    const store = injector.get(Store);
    const env = injector.get(APP_ENVIRONMENT);

    const rteLink = Link.extend({
        priority: 1000,

        addPasteRules() {
            return [
                markPasteRule({
                    find: text => {
                        console.log('text', text);
                        const foundLinks: PasteRuleMatch[] = [];

                        if (text) {
                            const { validate: validateFn } = this.options;
                            const validate = validateFn as (value: string) => boolean;

                            const links = find(text).filter(item => item.isLink && validate(item.value));

                            if (links.length) {
                                links.forEach(link =>
                                    foundLinks.push({
                                        text: link.value,
                                        data: {
                                            href: link.href,
                                        },
                                        index: link.start,
                                    }),
                                );
                            }
                        }

                        return foundLinks;
                    },
                    type: this.type,
                    getAttributes: match => {
                        return {
                            href: match.data?.['href'],
                        };
                    },
                }),
            ];
        },

        addProseMirrorPlugins() {
            const plugins: Plugin[] = [];

            // if (this.options.autolink) {
            //     plugins.push(
            //         autolink({
            //             type: this.type,
            //             defaultProtocol: this.options.defaultProtocol,
            //             validate: this.options.validate,
            //         }),
            //     );
            // }

            if (this.options.linkOnPaste) {
                plugins.push(
                    pasteHandler({
                        editor: this.editor,
                        defaultProtocol: 'https',
                        type: this.type,
                    }),
                );
            }

            return plugins;
        },

        renderHTML({ HTMLAttributes }) {
            // prevent XSS attacks
            if (!isAllowedUri(HTMLAttributes['href'])) {
                // strip out the href
                return ['a', mergeAttributes(this.options.HTMLAttributes, { ...HTMLAttributes, href: '' }), 0];
            }

            const href = transformToCdnUrl(HTMLAttributes['href'], env, 'file');

            return ['a', mergeAttributes(this.options.HTMLAttributes, { ...HTMLAttributes, href }), 0];
        },
    });

    return rteLink;
};

export function pasteHandler(options: PasteHandlerOptions): Plugin {
    return new Plugin({
        key: new PluginKey('handlePasteLink'),
        props: {
            handlePaste: (view, event, slice) => {
                const { state } = view;
                const { selection } = state;
                const { empty } = selection;

                if (empty) {
                    return false;
                }

                let textContent = '';

                slice.content.forEach(node => {
                    textContent += node.textContent;
                });

                const link = find(textContent, { defaultProtocol: options.defaultProtocol }).find(
                    item => item.isLink && item.value === textContent,
                );

                if (!textContent || !link) {
                    return false;
                }

                options.editor.commands.setMark(options.type, {
                    href: link.href
                });

                // replace the existing selection with a new text node, but the same formatting
                view.dispatch(view.state.tr.replaceSelectionWith(options.editor.schema.text(textContent)));

                return true;
            },
        },
    });
}

<ng-container *ngTemplateOutlet="isUploading ? uploadingTpl : imageTpl"> </ng-container>

<ng-template #imageTpl>
    <img
        [src]="src | cdnUrl : 'image' | async | safeUrl"
        [title]="title"
        tiptapDraggable
        [ngStyle]="styles"
        [ngClass]="getClasses()"
        loading="lazy"
    />
</ng-template>
<ng-template #uploadingTpl>
    <div class="uploading">
        <mat-spinner [mode]="'determinate'" [value]="progress" diameter="20"></mat-spinner>
        <span>Uploading {{ title }}</span>
    </div>
</ng-template>

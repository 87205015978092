import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { catchError, filter, finalize, mergeMap, Observable, of, take, takeUntil, tap, withLatestFrom } from 'rxjs';

import { AuthActions, CoreActions } from '@app/data/actions';
import { TokenResult } from '@app/data/models';
import { AuthService } from '@app/data/services/auth.service';
import { AuthState } from '@app/data/state/auth.state';
import { RouterState } from '@app/data/state/router.state';
import { COOKIE_LOGIN_URL } from '@app/shared/constants';
import { isAdmin, parseJwt } from '@app/shared/util';
import { Navigate } from '@ngxs/router-plugin';
import {
    Action,
    Actions,
    createSelector,
    NgxsOnInit,
    ofActionCompleted,
    ofActionDispatched,
    State,
    StateContext,
    Store,
} from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';

import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { SignInMethod } from '@app/shared/enums';
import { LogoutConfirmationDialogComponent } from '../dialogs/logout-confirmation/logout-confirmation-dialog.component';

export interface StateModel {
    signInMethod: SignInMethod;
    isPending: boolean;
    form: {
        model: any;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: Record<string, unknown> | null;
    };
}

@State<StateModel>({
    name: 'login',
    defaults: {
        signInMethod: SignInMethod.Form,
        isPending: false,
        form: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class LoginState implements NgxsOnInit {
    static getSignMethod() {
        return createSelector([LoginState], (state: StateModel) => state.signInMethod);
    }

    constructor(
        private store: Store,
        private actions: Actions,
        private authService: AuthService,
        private cookieService: CookieService,
        private location: Location,
        private dialog: MatDialog,
    ) {}

    ngxsOnInit(ctx: StateContext<any>) {
        this.store
            .select(AuthState.isReady())
            .pipe(
                filter(isReady => isReady),
                take(1),
                mergeMap(() => ctx.dispatch(new AuthActions.RequestLogin())),
            )
            .subscribe();
    }

    @Action(AuthActions.RequestLogin)
    requestLogin({ dispatch, patchState }: StateContext<any>): Observable<any> | void {
        const token = this.store.selectSnapshot(AuthState.getToken());

        if (token) {
            dispatch(new AuthActions.UserAuthenticated());
        } else {
            dispatch([new AuthActions.NotAuthenticated(), new CoreActions.UpdateGlobalLoading(false)]);
        }
    }

    @Action(AuthActions.NotAuthenticated)
    notAuthenticated(
        { dispatch, patchState }: StateContext<StateModel>,
        action: AuthActions.NotAuthenticated,
    ): Observable<any> | void {
        if (action.method === 'modal') {
            const modal = this.dialog.getDialogById('login-modal');

            if (modal) {
                return;
            }

            this.dialog.open(LoginDialogComponent, {
                id: 'login-modal',
                closeOnNavigation: false,
                backdropClass: 'login',
                // panelClass: 'login-modal',
                disableClose: true,
            });
            return;
        }

        const loginUrl = this.cookieService.get(COOKIE_LOGIN_URL) || null;
        const returnUrl = this.store.selectSnapshot(AuthState.getReturnUrl());

        if (loginUrl) {
            if (!returnUrl && this.location.path()) {
                dispatch(new AuthActions.UpdateReturnUrl(this.location.path()));
            }

            dispatch(new Navigate(['/login', loginUrl]));
        }
    }

    @Action(AuthActions.Login)
    login({ getState, dispatch, patchState }: StateContext<StateModel>, { credentials, signInMethod }: AuthActions.Login) {
        dispatch(new AuthActions.ClearErrors([AuthActions.LoginFailure]));
        patchState({ isPending: true, signInMethod });

        const thumbprint = this.store.selectSnapshot(AuthState.getThumbprint());
        const organizationUrl = this.store.selectSnapshot(RouterState.selectRouteParam('organization-url'));
        const url = organizationUrl || this.cookieService.get(COOKIE_LOGIN_URL);

        return this.authService.signIn({ ...credentials, thumbprint: thumbprint as string, url }).pipe(
            mergeMap(challengeToken => {
                const modal = this.dialog.getDialogById('login-modal');
                if (modal) {
                    modal.close();
                }

                return dispatch([new AuthActions.SetAuthToken(challengeToken), new AuthActions.LoginSuccess()]);
            }),
            catchError(error => dispatch(new AuthActions.LoginFailure(error))),
            finalize(() => patchState({ isPending: false })),
        );
    }

    @Action([AuthActions.Logout, AuthActions.LoginRedirect])
    logoutRedirect({ dispatch }: StateContext<StateModel>, { loginUrl }: AuthActions.Logout | AuthActions.LoginRedirect) {
        return dispatch(new Navigate([`/login/${loginUrl}`]));
    }

    @Action(AuthActions.UserAuthenticated)
    userAuthenticated({ dispatch, patchState }: StateContext<StateModel>): Observable<any> {
        patchState({ isPending: true });

        this.actions
            .pipe(
                ofActionCompleted(AuthActions.LoadUser),
                takeUntil(this.actions.pipe(ofActionDispatched(AuthActions.LoadUserFailure))),
                withLatestFrom(
                    this.store.select(AuthState.getReturnUrl()),
                    this.store.select(AuthState.getCurrentUser()),
                    this.store.select(LoginState.getSignMethod()),
                ),
                take(1),
                tap(([, returnUrl, user, signInMethod]) => {
                    if (signInMethod === SignInMethod.Dialog) {
                        return;
                    }

                    let url = isAdmin(user?.role) && !returnUrl ? '/home/shareable-links' : returnUrl || '/home';
                    if (url.includes('login')) {
                        url = isAdmin(user?.role) ? '/home/shareable-links' : '/home';
                    }

                    if (url) {
                        const hasParams = url.includes('?');
                        const path = hasParams ? url.split('?')[0] : url;
                        const query = hasParams ? url.split('?')[1] : '';
                        const params = query
                            .split('&')
                            .filter(s => s !== '')
                            .reduce((acc: { [key: string]: string }, current) => {
                                const values = current.split('=');
                                const key = values[0];
                                const value = values.length > 1 ? values[1] : '';
                                acc[key] = value;
                                return acc;
                            }, {});

                        dispatch([new AuthActions.UpdateReturnUrl(null), new Navigate([path || '/home'], params)]);
                    }
                }),
                finalize(() => patchState({ isPending: false })),
            )
            .subscribe();

        return dispatch(new AuthActions.LoadUser());
    }

    @Action([AuthActions.UserAuthenticated])
    updateToken({ dispatch }: StateContext<StateModel>) {
        const token = this.store.selectSnapshot(AuthState.getToken());
        const jwt = parseJwt(token?.token || '');

        this.cookieService.set(COOKIE_LOGIN_URL, jwt.login, 90, '/', window.location.hostname);
        return dispatch(new AuthActions.UpdateCookie());
    }

    @Action(AuthActions.LogoutConfirmation)
    logoutConfirmation({ getState, dispatch }: StateContext<StateModel>) {
        return this.dialog
            .open(LogoutConfirmationDialogComponent)
            .afterClosed()
            .pipe(
                mergeMap(result => {
                    const token = this.store.selectSnapshot(AuthState.getToken()) as TokenResult;
                    const jwt = parseJwt(token.token);
                    const loginUrl = jwt.login;
                    if (result) {
                        return dispatch([new CoreActions.UpdateSideNavigation(false), new AuthActions.Logout(loginUrl)]);
                    }

                    return of(null);
                }),
            );
    }
}

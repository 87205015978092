<section>
  <div (click)="onSelect(null)">
    <span class="crumb">Home</span>
  </div>
  @for (node of breadcrumb; track node) {
    <div (click)="onSelect(node)">
      <span class="crumb">{{ node.title }}</span>
    </div>
  }
</section>

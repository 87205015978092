import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { DropMediaComponent } from '../../components/drop-media/drop-media.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { DropMediaComponent as DropMediaComponent_1 } from '../../components/drop-media/drop-media.component';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: `./drop-media-dialog.component.html`,
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        DropMediaComponent_1,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ],
})
export class DropMediaDialogComponent {
    mediaFiles: File[] = [];
    isPublic = false;

    @ViewChild(DropMediaComponent) dropMediaComponent!: DropMediaComponent;

    constructor(@Inject(MAT_DIALOG_DATA) data: any, private dialogRef: MatDialogRef<DropMediaDialogComponent>) {
        this.mediaFiles = data.mediaFiles;
        this.isPublic = data.isPublic;
    }

    onClose() {
        this.dialogRef.close(this.dropMediaComponent.validMediaFiles);
    }
}

import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[searchResultItem]',
    standalone: true,
})
export class SearchResultItemDirective {
    private selected = false;

    @Input('searchResultItem') model: any;
    @Output() readonly selectionChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    @HostListener('click') selectViaInteraction(): void {
        this.selected = true;
        this.emitSelectionChangeEvent(true);
    }

    private emitSelectionChangeEvent(isUserInput = false): void {
        this.selectionChange.emit(this.model);
    }
}

/* eslint-disable no-empty */
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { CurrentUser } from '@app/data/models';
import { AuthState } from '@app/data/state/auth.state';
import { Layout } from '@app/shared/enums';
import { isAdmin } from '@app/shared/util';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';

import {
    HomeActions,
    ManageChecklistActions,
    ManageEventActions,
    ManageFormsActions,
    ManageLinksActions,
    ManageMediaActions,
    ManageRequestActions,
} from '../actions';

export const RootResolver: ResolveFn<Observable<CurrentUser | null>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
) => {

    const expandedNodeIds = tryAndReadFromLocalStorage('expandedNodeIds') || [];
    const linkFilters = tryAndReadFromLocalStorage('manageLinks.filters') || null;
    const eventFilters = tryAndReadFromLocalStorage('manageEvents.filters') || null;
    const archivedFilters = tryAndReadFromLocalStorage('manageLinks.searchArchivedLinks') || null;
    const sessionFilters = tryAndReadFromLocalStorage('manageLinks.sessionFilters') || null;
    const searchLinksByName = tryAndReadFromLocalStorage('search.searchForm') || null;
    const homeNodeFilters = tryAndReadFromLocalStorage('home.nodeFilters') || null;
    const shareableLinksTabIndex = tryAndReadFromLocalStorage('manageLinks.currentTabIndex') || 0;
    const formLinksTabIndex = tryAndReadFromLocalStorage('manageForms.currentTabIndex') || 0;
    const checklistLinksTabIndex = tryAndReadFromLocalStorage('manageChecklists.currentTabIndex') || 0;
    const eventLinksTabIndex = tryAndReadFromLocalStorage('manageEvents.currentTabIndex') || 0;
    const path = tryAndReadFromLocalStorage('path') || null;
    const layout = tryAndReadFromLocalStorage('layout') || Layout.Tree;

    store.dispatch([
        new UpdateFormValue({ path: 'search.searchForm', value: searchLinksByName }),
        new UpdateFormValue({ path: 'manageLinks.filters', value: linkFilters }),
        new UpdateFormValue({ path: 'manageLinks.sessionFilters', value: sessionFilters }),
        new UpdateFormValue({
            path: 'manageLinks.searchArchivedLinks',
            value: { ...archivedFilters, total: null, offset: 0 },
        }),
        new UpdateFormValue({ path: 'home.nodeFilters', value: homeNodeFilters }),

        new UpdateFormValue({
            path: 'manageEvents.filters',
            value: { ...eventFilters, total: null, offset: 0 },
        }),
        new ManageLinksActions.SetCurrentTabIndex(shareableLinksTabIndex),
        new ManageFormsActions.SetCurrentTabIndex(formLinksTabIndex),
        new ManageChecklistActions.SetCurrentTabIndex(checklistLinksTabIndex),
        new ManageEventActions.SetCurrentTabIndex(eventLinksTabIndex),

        new HomeActions.UpdatePath(path),
        new HomeActions.UpdateLayout(layout),
        new HomeActions.UpdateExpandedNodeIds(expandedNodeIds),
        new HomeActions.EnsureLoadNodes(),
        new ManageRequestActions.LoadLinkModel()
    ]);

    return store.select(AuthState.getCurrentUser()).pipe(
        filter(user => user !== null),
        take(1),
        tap(currentUser => {

            if (currentUser && isAdmin(currentUser.role)) {
                store.dispatch([new ManageMediaActions.EnsureLoadMediaAssets(), new ManageLinksActions.LoadLinkSummary()]);
            }

            store.dispatch(new CoreActions.UpdateGlobalLoading(false));
        }),

    );
};

function tryAndReadFromLocalStorage(key: string) {
    try {
        return JSON.parse(localStorage.getItem(key) || '');
    } catch {}
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    DeleteResult,
    FormLinkModel,
    FormLinkSearchResult,
    FormLinkTemplateModel,
    FormSubmissionMessage,
    LinkSessionSummary,
    QueryResult,
    SaveFormLinkModel,
    SaveFormLinkTemplateModel,
    SearchFormOptions,
    Submission,
} from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { LinkType } from '@app/shared/enums';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable({
    providedIn: 'root',
})
export class ManageFormLinksService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    getTags(): Observable<string[]> {
        return this.http
            .get<ServerResult<string[]>>(`${this.env.serverUrl}/manage/forms/tags`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getLinkById(rowKey: string) {
        return this.http
            .get<ServerResult<FormLinkModel>>(`${this.env.serverUrl}/manage/forms/${rowKey}`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    searchLinks(model: SearchFormOptions) {
        return this.http
            .post<ServerResult<QueryResult<FormLinkSearchResult>>>(
                `${this.env.serverUrl}/manage/forms/search-for-list`,
                model,
            )
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinks(): Observable<FormLinkModel[]> {
        return this.http
            .get<ServerResult<FormLinkModel[]>>(`${this.env.serverUrl}/manage/forms`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getArchivedLink(rowkey: string): Observable<FormLinkModel> {
        return this.http
            .post<ServerResult<FormLinkModel>>(`${this.env.serverUrl}/manage/forms/archived`, { rowkey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllTemplates(): Observable<FormLinkTemplateModel[]> {
        return this.http
            .get<ServerResult<FormLinkTemplateModel[]>>(`${this.env.serverUrl}/manage/forms/templates`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveLink(model: SaveFormLinkModel): Observable<FormLinkModel> {
        return this.http
            .post<ServerResult<FormLinkModel>>(`${this.env.serverUrl}/manage/forms`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveTemplate(model: SaveFormLinkTemplateModel): Observable<FormLinkTemplateModel> {
        return this.http
            .post<ServerResult<FormLinkTemplateModel>>(`${this.env.serverUrl}/manage/forms/template`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinkSessions(rowKey: string): Observable<LinkSessionSummary[]> {
        return this.http
            .post<ServerResult<LinkSessionSummary[]>>(`${this.env.serverUrl}/manage/forms/sessions`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinkSubmissions(rowKey: string): Observable<Submission[]> {
        return this.http
            .post<ServerResult<Submission[]>>(`${this.env.serverUrl}/manage/forms/submissions`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    activateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/activate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    syncActiveSessions(rowKey: string): Observable<FormLinkModel> {
        return this.http
            .post<ServerResult<FormLinkModel>>(`${this.env.serverUrl}/manage/sessions/sync`, {
                rowKeys: [rowKey],
                linkType: LinkType.Form,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    updateLinkCounts(rowKey: string): Observable<FormLinkModel> {
        return this.http
            .post<ServerResult<FormLinkModel>>(
                `${this.env.serverUrl}/manage/forms/sessions/update-count`,
                JSON.stringify(rowKey),
            )
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deactivateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/forms/sessions/deactivate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    archiveLinks(rowKeys: string[]) {
        return this.http
            .post<ServerResult<DeleteResult>>(`${this.env.serverUrl}/manage/forms/archive`, rowKeys)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteLinks(rowKeys: string[]) {
        return this.http
            .request<ServerResult<DeleteResult>>('DELETE', `${this.env.serverUrl}/manage/forms`, {
                body: rowKeys,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteTemplates(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/forms/template/delete`, JSON.stringify(rowKeys))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    approveSession(rowKey: string, formSubmissionRowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/forms/sessions/approve`, {
                rowKey,
                formSubmissionRowKeys,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteSessions(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/forms/sessions/delete`, JSON.stringify(rowKeys))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    cancelEventsForSessions(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/event/cancel`, JSON.stringify(rowKeys))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    mergeSessions(linkId: string, primary: string, rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/merge`, { rowKeys, primary, linkId })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    searchArchivedLinks(model: SearchFormOptions) {
        return this.http
            .post<ServerResult<QueryResult<FormLinkSearchResult>>>(`${this.env.serverUrl}/manage/forms/search-archived`, model)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    completeSubmission(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/forms/submission/complete`, {
                rowKey,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    acknowledgeSubmissions(sessionIds: string[], rowKey: string): Observable<LinkSessionSummary[]> {
        return this.http
            .post<ServerResult<LinkSessionSummary[]>>(`${this.env.serverUrl}/manage/forms/submission/acknowledge`, {
                rowKey,
                sessionIds,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    acknowledgeInfluencerNotification(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/forms/influencer/acknowledge`, JSON.stringify(rowKeys))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    extendLinkExpirationDate(rowKeys: string[], days: number){
        return this.http
            .post<ServerResult<FormLinkModel[]>>(`${this.env.serverUrl}/manage/links/extend`, {
                rowKeys,
                days,
                linkType: LinkType.Form,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getFormSubmissionMessagesForLink(rowKey: string): Observable<FormSubmissionMessage[]> {
        return this.http
            .post<ServerResult<FormSubmissionMessage[]>>(`${this.env.serverUrl}/manage/form/submission-messages`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}

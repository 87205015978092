import { Pipe, PipeTransform } from '@angular/core';

import { TimerUnit } from '@app/shared/enums';

@Pipe({
    name: 'timerUnit',
    standalone: true,
})
export class TimerUnitPipe implements PipeTransform {
    transform(value: TimerUnit, full = false): string {
        switch (value) {
            case TimerUnit.Months:
                return full ? 'month(s)' : 'm';
            case TimerUnit.Weeks:
                return full ? 'week(s)' : 'w';
            case TimerUnit.Days:
                return full ? 'day(s)' : 'd';
            case TimerUnit.Hours:
                return full ? 'hour(s)' : 'h';

            default:
                return full ? 'minute(s)' : 'min';
        }
    }
}

import { inject, Injectable } from '@angular/core';

import { combineLatest, interval, Observable } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { NotificationActions } from '@app/data/actions';
import {
    FormState,
    LinkSearchResultModel,
    QueryOptions,
    QueryResult,
    SearchByNameFormModel,
    SearchResult,
} from '@app/data/models';
import { transformResponseToText } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { SearchService } from '@app/data/services/search.service';
import { BlobContainer } from '@app/shared/enums';
import { isErrorModel } from '@app/shared/models';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Action, Actions, createSelector, NgxsOnInit, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';

import { SearchActions } from '../actions';
import { HomeState } from './home.state';
import { NotesState } from './notes.state';
import { AdminState } from './admin.state';
import { AuthState } from '@app/data/state/auth.state';

const SEARCH_TIMEOUT_IN_SECONDS = 15000;

export interface StateModel {
    isLoading: boolean;
    isSearching: boolean;
    linksQueryResult: QueryResult<LinkSearchResultModel> | null;
    formsQueryResult: QueryResult<LinkSearchResultModel> | null;
    results: SearchResult[];
    searchForm: FormState<SearchByNameFormModel>;

    error: any;
}

@State<StateModel>({
    name: 'search',
    defaults: {
        isLoading: false,
        isSearching: false,
        results: [],
        linksQueryResult: null,
        formsQueryResult: null,
        searchForm: { model: null, status: '', dirty: false, errors: null },

        error: null,
    },
})
@Injectable({
    providedIn: 'root',
})
export class SearchState implements NgxsOnInit {
    static isLoading() {
        return createSelector([SearchState], (state: StateModel) => state.isLoading);
    }

    static selectSearchResults() {
        return createSelector([SearchState], (model: StateModel) => model.results);
    }

    static isSearching() {
        return createSelector([SearchState], (state: StateModel) => state.isSearching);
    }

    static getLinksByNameResults() {
        return createSelector([SearchState], (state: StateModel) => state.linksQueryResult);
    }

    static getFormsByNameResults() {
        return createSelector([SearchState], (state: StateModel) => state.formsQueryResult);
    }

    static getSearchByNameForm() {
        return createSelector([SearchState], (state: StateModel) => state.searchForm);
    }

    static getError() {
        return createSelector([SearchState], (state: StateModel) => state.error);
    }

    store = inject(Store);
    actions = inject(Actions);
    searchService = inject(SearchService);
    blobService = inject(BlobService);

    ngxsOnInit({ dispatch }: StateContext<any>) {
        this.actions
            .pipe(
                ofActionDispatched(SearchActions.UpdateIndex),
                switchMap(() => interval(SEARCH_TIMEOUT_IN_SECONDS).pipe(take(1))),
                switchMap(() => this.searchService.updateIndex()),
                switchMap(() => interval(SEARCH_TIMEOUT_IN_SECONDS).pipe(take(1))),
                mergeMap(() =>
                    dispatch([new SearchActions.LoadIndex(), new NotificationActions.Success('Search index updated')]),
                ),
                catchError(error => dispatch(new SearchActions.UpdateIndexFailure(error))),
            )
            .subscribe();

        combineLatest([
            this.store.select(AuthState.isAuthenticated()).pipe(filter(isAuthenticated => isAuthenticated)),
            this.store.select(AdminState.isReady()).pipe(filter(isReady => isReady)),
        ])
            .pipe(
                take(1),
                switchMap(() => this.store.dispatch(new SearchActions.LoadIndex())),
            )
            .subscribe();
    }

    @Action(UpdateFormValue)
    updateFormValue({ patchState }: StateContext<StateModel>, action: UpdateFormValue): void {
        if (action.payload.path === 'search.searchForm') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }
    }

    @Action(SearchActions.SearchLinks)
    searchLinks({ dispatch, patchState }: StateContext<StateModel>) {
        const form = this.store.selectSnapshot(SearchState.getSearchByNameForm());
        const { model } = form;
        const { forms, links } = model as SearchByNameFormModel;

        return dispatch([
            new UpdateFormValue({
                value: {
                    ...model,
                    forms: {
                        ...forms,
                        total: null,
                        offset: 0,
                    },
                    links: {
                        ...links,
                        total: null,
                        offset: 0,
                    },
                },
                path: 'search.searchForm',
            }),
            new SearchActions.SearchFormsByName(),
            new SearchActions.SearchLinksByName({
                total: null,
                offset: 0,
                limit: links.limit,
            }),
        ]);
    }

    @Action(SearchActions.SearchLinksByName)
    searchLinksByName(
        { dispatch, patchState }: StateContext<StateModel>,
        { options }: SearchActions.SearchLinksByName,
    ): Observable<any> | void {
        const form = this.store.selectSnapshot(SearchState.getSearchByNameForm());

        if (!form) {
            return;
        }

        patchState({ isSearching: true });

        const { model } = form;
        const { firstName, lastName } = model as SearchByNameFormModel;

        return this.searchService.searchLinksByName({ ...(options as QueryOptions), firstName, lastName }).pipe(
            tap(result => {
                patchState({ linksQueryResult: result });
                dispatch(
                    new UpdateFormValue({
                        value: {
                            limit: result.limit,
                            total: result.total,
                            offset: result.offset,
                        },
                        propertyPath: 'links',
                        path: 'search.searchForm',
                    }),
                );
            }),
            catchError(err => this.store.dispatch(new SearchActions.SearchLinksByNameFailure(err))),
            finalize(() => patchState({ isSearching: false })),
        );
    }

    @Action(SearchActions.SearchFormsByName)
    searchFormsByName({ dispatch, patchState }: StateContext<StateModel>): Observable<any> | void {
        const form = this.store.selectSnapshot(SearchState.getSearchByNameForm());

        if (!form) {
            return;
        }

        patchState({ isSearching: true });

        const { model } = form;
        const { firstName, lastName, forms } = model as SearchByNameFormModel;

        return this.searchService.searchFormsByName({ ...forms, firstName, lastName }).pipe(
            tap(result => {
                patchState({ formsQueryResult: result });
                dispatch(
                    new UpdateFormValue({
                        value: {
                            limit: result.limit,
                            total: result.total,
                            offset: result.offset,
                        },
                        propertyPath: 'forms',
                        path: 'search.searchForm',
                    }),
                );
            }),
            catchError(err => this.store.dispatch(new SearchActions.SearchFormsByNameFailure(err))),
            finalize(() => patchState({ isSearching: false })),
        );
    }

    @Action(SearchActions.Search)
    search(ctx: StateContext<StateModel>, action: SearchActions.Search) {
        const nodes = this.store.selectSnapshot(HomeState.getNodes());
        const notes = this.store.selectSnapshot(NotesState.getNotes());
        const results = this.searchService.search(nodes as any, notes, action.query, { expand: true });
        ctx.patchState({ results });
    }

    @Action(SearchActions.LoadIndex)
    loadIndex(ctx: StateContext<StateModel>, action: SearchActions.LoadIndex) {
        ctx.patchState({
            isLoading: true,
        });

        return this.blobService.downloadBlobBlock('index.json', BlobContainer.Private).pipe(
            transformResponseToText(),
            map(index => JSON.parse(index)),
            tap(index => this.searchService.loadIndex(index)),
            catchError(error => this.store.dispatch(new SearchActions.LoadIndexFailure(error))),
            finalize(() => ctx.patchState({ isLoading: false })),
        );
    }

    @Action([SearchActions.LoadIndexFailure, SearchActions.UpdateIndexFailure])
    handleFailure({ dispatch }: StateContext<StateModel>, action: { error: any }): Observable<any> | void {
        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof SearchActions.LoadIndexFailure:
                return dispatch(new NotificationActions.Error(`Error loading search index`));
            case action instanceof SearchActions.UpdateIndexFailure:
                return dispatch(new NotificationActions.Error(`Error updating search index`));
        }
    }
}

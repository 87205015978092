import { Injectable } from '@angular/core';

import { catchError, finalize, mergeMap, tap } from 'rxjs';

import { NotificationActions } from '@app/data/actions';
import {
    CreateMeetingFromTemplate,
    CreateWebinarFromTemplate,
    FormState,
    Meeting,
    MeetingTemplate,
    MeetingTemplateListResponse,
    Webinar,
    WebinarTemplate,
    WebinarTemplateListResponse,
} from '@app/data/models';
import { isErrorModel } from '@app/shared/models';
import { Action, State, StateContext, Store, createSelector } from '@ngxs/store';

import { ZoomActions } from '../actions';
import { ZoomService } from '../services/zoom.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateMeetingFromTemplateDialogComponent,  } from '../dialogs/create-meeting-from-template/create-meeting-from-template-dialog.component';
import { CreateWebinarFromTemplateDialogComponent } from '../dialogs/create-webinar-from-template/create-webinar-from-template-dialog.component';

export interface StateModel {
    isSaving: boolean;
    errors: any;
    isLoadingWebinars: boolean;
    isLoadingWebinarTemplates: boolean;
    isLoadingMeetings: boolean;
    isLoadingMeetingTemplates: boolean;
    isLoadingMetrics: boolean;
    hasLoadedWebinars: boolean;
    hasLoadedWebinarTemplates: boolean;
    hasLoadedMeetings: boolean;
    hasLoadedMeetingTemplates: boolean;
    webinars: Webinar[];
    webinarTemplates: WebinarTemplate[];
    meetings: Meeting[];
    meetingTemplates: MeetingTemplate[];
    templateModel: CreateWebinarFromTemplate;
    webinarTemplateResponse: WebinarTemplateListResponse | null;
    meetingTemplateResponse: MeetingTemplateListResponse | null;
    webinarFilters: FormState<any>;
    createWebinarForm: FormState<CreateWebinarFromTemplate>;
    createMeetingForm: FormState<CreateMeetingFromTemplate>;
}

@State<StateModel>({
    name: 'zoom',
    defaults: {
        isSaving: false,
        errors: null,
        isLoadingWebinars: false,
        isLoadingWebinarTemplates: false,
        isLoadingMeetings: false,
        isLoadingMeetingTemplates: false,
        isLoadingMetrics: false,
        hasLoadedWebinars: false,
        hasLoadedWebinarTemplates: false,
        hasLoadedMeetings: false,
        hasLoadedMeetingTemplates: false,
        webinars: [],
        webinarTemplates: [],
        meetings: [],
        meetingTemplates: [],
        templateModel: {
            templateId: '',
            topic: '',
            agenda: '',
            password: '',
            startDate: null,
            duration: 0,
        },
        webinarTemplateResponse: null,
        meetingTemplateResponse: null,

        createWebinarForm: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },

        createMeetingForm: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        webinarFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class ZoomState {
    static getErrors() {
        return createSelector([ZoomState], (state: StateModel) => state.errors);
    }

    static isSaving() {
        return createSelector([ZoomState], (state: StateModel) => state.isSaving);
    }

    static getWebinars() {
        return createSelector([ZoomState], (state: StateModel) => state.webinars);
    }

    static getMeetings() {
        return createSelector([ZoomState], (state: StateModel) => state.meetings);
    }

    static getWebinarTemplates() {
        return createSelector([ZoomState], (state: StateModel) => state.webinarTemplates);
    }

    static getMeetingTemplates() {
        return createSelector([ZoomState], (state: StateModel) => state.meetingTemplates);
    }

    static getCreateWebinarFromTemplateModel() {
        return createSelector([ZoomState], (state: StateModel) => state.templateModel);
    }

    static isLoadingWebinars() {
        return createSelector([ZoomState], (state: StateModel) => state.isLoadingWebinars);
    }

    static isLoadingMeetings() {
        return createSelector([ZoomState], (state: StateModel) => state.isLoadingMeetings);
    }

    static isLoadingWebinarTemplates() {
        return createSelector([ZoomState], (state: StateModel) => state.isLoadingWebinarTemplates);
    }

    static getCreateWebinarForm() {
        return createSelector([ZoomState], (state: StateModel) => state.createWebinarForm);
    }

    static getCreateMeetingForm() {
        return createSelector([ZoomState], (state: StateModel) => state.createMeetingForm);
    }

    constructor(private store: Store, private zoomService: ZoomService, private dialog: MatDialog) {}

    @Action(ZoomActions.EnsureLoadWebinars)
    ensureLoadWebinars({ dispatch, getState, patchState }: StateContext<StateModel>) {
        const { hasLoadedWebinars } = getState();

        if (!hasLoadedWebinars) {
            return dispatch(new ZoomActions.LoadWebinars());
        }

        return null;
    }

    @Action(ZoomActions.EnsureLoadWebinarTemplates)
    ensureLoadWebinarTemplates({ dispatch, getState, patchState }: StateContext<StateModel>) {
        const { hasLoadedWebinarTemplates } = getState();

        if (!hasLoadedWebinarTemplates) {
            return dispatch(new ZoomActions.LoadWebinarTemplates());
        }

        return null;
    }

    @Action(ZoomActions.EnsureLoadMeetings)
    ensureLoadMeetings({ dispatch, getState }: StateContext<StateModel>) {
        const { hasLoadedMeetings } = getState();

        if (!hasLoadedMeetings) {
            return dispatch(new ZoomActions.LoadMeetings());
        }

        return null;
    }

    @Action(ZoomActions.EnsureLoadMeetingTemplates)
    ensureLoadMeetingTemplates({ dispatch, getState }: StateContext<StateModel>) {
        const { hasLoadedMeetingTemplates } = getState();

        if (!hasLoadedMeetingTemplates) {
            return dispatch(new ZoomActions.LoadMeetingTemplates());
        }

        return null;
    }

    @Action(ZoomActions.LoadWebinars)
    loadWebinars({ getState, patchState, dispatch }: StateContext<StateModel>, { retry }: ZoomActions.LoadWebinars) {
        patchState({
            isLoadingWebinars: true,
        });

        return this.zoomService.getWebinars().pipe(
            tap(response => {
                patchState({
                    webinars: response,
                });
            }),
            catchError(err => {
                return dispatch(new ZoomActions.LoadWebinarsFailure(err, retry))
            }),
            finalize(() => patchState({ isLoadingWebinars: false })),
        );
    }

    @Action(ZoomActions.LoadMeetings)
    loadMeetings({ getState, patchState, dispatch }: StateContext<StateModel>, { retry }: ZoomActions.LoadMeetings) {
        patchState({
            isLoadingMeetings: true,
        });

        return this.zoomService.getMeetings().pipe(
            tap(response => {
                patchState({
                    meetings: response,
                });
            }),
            catchError(err => {
                return dispatch(new ZoomActions.LoadMeetingsFailure(err, retry))
            }),
            finalize(() => patchState({ isLoadingMeetings: false })),
        );
    }

    @Action(ZoomActions.LoadWebinarTemplates)
    loadWebinarTemplates(
        { getState, patchState, dispatch }: StateContext<StateModel>,
        { retry }: ZoomActions.LoadWebinarTemplates,
    ) {
        patchState({
            isLoadingWebinarTemplates: true,
        });

        const { next_page_token: nextPageToken } = getState().webinarTemplateResponse || { next_page_token: null };
        const clearWebinars = !nextPageToken;

        return this.zoomService.getWebinarTemplates(nextPageToken).pipe(
            tap(response => {
                patchState({
                    webinarTemplateResponse: response,
                    webinarTemplates: clearWebinars
                        ? [...response.templates]
                        : [...getState().webinarTemplates, ...response.templates],
                });
            }),
            catchError(err => dispatch(new ZoomActions.LoadWebinarTemplatesFailure(err, retry))),
            finalize(() => patchState({ isLoadingWebinarTemplates: false, hasLoadedWebinarTemplates: true })),
        );
    }

    @Action(ZoomActions.LoadMeetingTemplates)
    loadMeetingTemplates(
        { getState, patchState, dispatch }: StateContext<StateModel>,
        { retry }: ZoomActions.LoadMeetingTemplates,
    ) {
        patchState({
            isLoadingMeetingTemplates: true,
        });

        const { next_page_token: nextPageToken } = getState().meetingTemplateResponse || { next_page_token: null };
        const clearMeetings = !nextPageToken;

        return this.zoomService.getMeetingTemplates(nextPageToken).pipe(
            tap(response => {
                patchState({
                    meetingTemplateResponse: response,
                    meetingTemplates: clearMeetings
                        ? [...response.templates]
                        : [...getState().meetingTemplates, ...response.templates],
                });
            }),
            catchError(err => dispatch(new ZoomActions.LoadWebinarTemplatesFailure(err, retry))),
            finalize(() => patchState({ isLoadingWebinarTemplates: false, hasLoadedWebinarTemplates: true })),
        );
    }

    @Action(ZoomActions.ShowCreateWebinarFromTemplateDialog)
    showCreateWebinarFromTemplateDialog(
        { getState, patchState, dispatch }: StateContext<StateModel>,
        { model }: ZoomActions.ShowCreateWebinarFromTemplateDialog,
    ) {
        dispatch(new ZoomActions.EnsureLoadWebinarTemplates());

        patchState({
            errors: null,
        });

        if (model) {
            patchState({
                templateModel: {
                    ...getState().templateModel,
                    ...model,
                },
            });
        } else {
            patchState({
                templateModel: {
                    ...getState().templateModel,
                    topic: '',
                    password: '',
                    startDate: null,
                },
            });
        }

        this.dialog.open(CreateWebinarFromTemplateDialogComponent, {
            minWidth: '50vw',
        });
    }

    @Action(ZoomActions.ShowCreateMeetingFromTemplateDialog)
    showCreateMeetingFromTemplateDialog(
        { getState, patchState, dispatch }: StateContext<StateModel>,
        { model }: ZoomActions.ShowCreateMeetingFromTemplateDialog,
    ) {
        dispatch(new ZoomActions.EnsureLoadMeetingTemplates());

        patchState({
            errors: null,
        });

        if (model) {
            patchState({
                templateModel: {
                    ...getState().templateModel,
                    ...model,
                },
            });
        } else {
            patchState({
                templateModel: {
                    ...getState().templateModel,
                    topic: '',
                    password: '',
                    startDate: null,
                },
            });
        }

        this.dialog.open(CreateMeetingFromTemplateDialogComponent, {
            minWidth: '50vw',
        });
    }

    @Action(ZoomActions.CreateWebinarFromTemplate)
    createWebinarFromTemplate({ getState, patchState, dispatch }: StateContext<StateModel>) {
        patchState({
            isSaving: true,
            errors: null,
        });

        const model = getState().createWebinarForm.model as CreateWebinarFromTemplate;

        return this.zoomService.createWebinarFromTemplate(model).pipe(
            mergeMap(() => {
                this.dialog.closeAll();

                return dispatch([
                    new ZoomActions.LoadWebinars(),
                    new NotificationActions.Success('Webinar has been created successfully'),
                ]);
            }),
            catchError(err => dispatch(new ZoomActions.CreateWebinarFromTemplateFailure(err))),
            finalize(() => patchState({ isSaving: false })),
        );
    }

    @Action(ZoomActions.CreateMeetingFromTemplate)
    createMeetingFromTemplate({ getState, patchState, dispatch }: StateContext<StateModel>) {
        patchState({
            isSaving: true,
            errors: null,
        });

        const model = getState().createMeetingForm.model as CreateMeetingFromTemplate;

        return this.zoomService.createMeetingFromTemplate(model).pipe(
            mergeMap(() => {
                this.dialog.closeAll();

                return dispatch([
                    new ZoomActions.LoadMeetings(),
                    new NotificationActions.Success('Meeting has been created successfully'),
                ]);
            }),
            catchError(err => dispatch(new ZoomActions.CreateMeetingFromTemplateFailure(err))),
            finalize(() => patchState({ isSaving: false })),
        );
    }

    @Action([
        ZoomActions.LoadWebinarsFailure,
        ZoomActions.LoadWebinarTemplatesFailure,
        ZoomActions.CreateWebinarFromTemplateFailure,
        ZoomActions.LoadMeetingsFailure,
        ZoomActions.LoadMeetingTemplatesFailure,
        ZoomActions.CreateMeetingFromTemplateFailure,
    ])
    handleFailures(
        { patchState, dispatch }: StateContext<StateModel>,
        action:
            | ZoomActions.LoadWebinarsFailure
            | ZoomActions.LoadWebinarTemplatesFailure
            | ZoomActions.CreateWebinarFromTemplateFailure
            | ZoomActions.LoadMeetingsFailure
            | ZoomActions.LoadMeetingTemplatesFailure
            | ZoomActions.CreateMeetingFromTemplateFailure,
    ) {
        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof ZoomActions.LoadWebinarsFailure: {
                if (action.retry) {
                    return this.store.dispatch([new ZoomActions.LoadWebinars(false)]);
                }
                return this.store.dispatch([
                    new NotificationActions.Error('Unexpected Error Loading Webinars', action.error),
                ]);
            }
            case action instanceof ZoomActions.CreateWebinarFromTemplateFailure: {
                patchState({ errors: action.error });
                break;
            }
            case action instanceof ZoomActions.LoadMeetingsFailure: {
                if (action.retry) {
                    return this.store.dispatch([new ZoomActions.LoadMeetings(false)]);
                }
                return this.store.dispatch([
                    new NotificationActions.Error('Unexpected Error Loading Meetings', action.error),
                ]);
            }
            case action instanceof ZoomActions.CreateMeetingFromTemplateFailure: {
                patchState({ errors: action.error });
                break;
            }
            // default:
            // return this.store.dispatch(new NotificationActions.Error('Unexpected Error', action.error));
        }

        return null;
    }
}

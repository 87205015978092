@if (path !== null) {
  <section class="container">
    <ui-breadcrumb [path]="path" [nodes]="nodes" (selectNode)="setRoot($event)"></ui-breadcrumb>
  </section>
}

@if (!isLoading && !(nodes | any)) {
  <div class="container no-content mat-elevation-z9">
    There is currently no content available to you.
  </div>
}
@if (nodeDataSource.data.length > 0) {
  <mat-tree [dataSource]="nodeDataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="24">
      <ng-container
        *ngTemplateOutlet="nodeTemplate !== null ? nodeTemplate : branchTemplate; context: { $implicit: node, data: nodeTemplateContext }"
        >
      </ng-container>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="24">
      @switch (toggleMode) {
        @case (ToggleMode.ScopeToNode) {
          <button mat-icon-button matTreeNodeToggle (click)="toggleNode(node)">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
          <div
            class="node-name has-children"
            [ngClass]="{ 'is-expanded': isExpanded(node) }"
            (click)="setRoot(node.data); $event.stopPropagation()"
            >
            <span>{{ node.name }}</span>
          </div>
        }
        @default {
          <button mat-icon-button matTreeNodeToggle (click)="toggleNode(node)">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
          <div
            class="node-name has-children"
            (click)="setRoot(node.data); $event.stopPropagation()"
            [ngClass]="{ 'is-expanded': isExpanded(node) }"
            matTreeNodeToggle
            >
            <span>{{ node.name }}</span>
          </div>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { node: node }"></ng-container>
        }
      }
      <ng-container *ngTemplateOutlet="actionsTemplate; context: { node: node }"></ng-container>
    </mat-tree-node>
  </mat-tree>
}

@if (isLoading) {
  <mat-spinner></mat-spinner>
}

<ng-template #branchTemplate let-node>
  <div class="indent"></div>
  <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
  @if (node.data.type !== NodeType.Node) {
    <a class="node-name" (click)="onViewNode(node.data)">
      <span>{{ node.name }}</span>
    </a>
  }
  <ng-container *ngTemplateOutlet="labelTemplate; context: { node: node }"></ng-container>
  @if (node.data.duration) {
    <time>&nbsp;({{ node.data.duration }})</time>
  }
  <ng-container *ngTemplateOutlet="actionsTemplate; context: { node: node }"></ng-container>
</ng-template>

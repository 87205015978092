import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { SearchResultItemDirective } from './search-result-item.directive';

@Component({
    selector: 'ui-search-results',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-template>
            <div class="mat-autocomplete-panel" [class.mat-autocomplete-visible]="isOpen" role="listbox" #panel>
                <ng-content></ng-content>
            </div>
        </ng-template>
    `,
    standalone: true,
})
export class SearchResultsComponent implements AfterContentInit {
    @Input() panelWidth!: string | number;
    @Output() readonly optionSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() readonly opened: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly closed: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(TemplateRef, { static: true }) template!: TemplateRef<any>;
    @ViewChild('panel') panel!: ElementRef;
    @ContentChildren(SearchResultItemDirective, { descendants: true }) options!: QueryList<SearchResultItemDirective>;

    get isOpen(): boolean {
        return this._isOpen && this.showPanel;
    }
    _isOpen = false;
    showPanel = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    setVisibility(): void {
        this.showPanel = !!this.options.length;
        this.changeDetectorRef.markForCheck();
    }

    emitSelectEvent(option: any): void {
        this.optionSelected.emit(option);
    }

    ngAfterContentInit(): void {
        this.setVisibility();
    }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { filter, Subject, take, takeUntil, tap } from 'rxjs';

import { DestroyService } from '@app/shared/services';
import { ScreenOrientation, ScreenProfile } from '@app/shared/enums';
import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { transformToCdnUrl } from '@app/shared/util';

declare const videojs: any;

@Component({
    selector: 'admin-media-asset-player',
    templateUrl: './media-asset-player.component.html',
    styleUrls: ['./media-asset-player.component.scss'],
    viewProviders: [DestroyService],
    standalone: true,
})
export class MediaAssetPlayerComponent implements AfterViewInit, OnChanges, OnInit {
    digest$ = new Subject<void>();
    player: any;

    @ViewChild('videoEl') videoEl: ElementRef<HTMLVideoElement>;

    @Input() src: string | null = null;
    @Input() token: any | null = null;
    @Input() isTheatreMode: boolean | null = false;
    @Input() screenProfile: ScreenProfile | null;
    @Input() screenOrientation: ScreenOrientation | null;

    @Output() readonly markUserActivity: EventEmitter<any> = new EventEmitter();
    @Output() readonly toggleTheatreMode: EventEmitter<void> = new EventEmitter();

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private destroy$: DestroyService,
    ) { }

    onMarkUserActivity() {
        this.markUserActivity.emit();
    }

    onToggleTheatreMode() {
        this.toggleTheatreMode.emit();
    }

    ngOnInit(): void {
        this.digest$
            .pipe(
                takeUntil(this.destroy$),
                filter(
                    () =>
                        (this.videoEl && this.videoEl.nativeElement != null && this.token != null && this.src != null) ===
                        true,
                ),
                take(1),
                tap(() => {
                    this.setupVideo();
                }),
            )
            .subscribe();

        this.destroy$
            .pipe(
                tap(() => {
                    if (this.player) {
                        this.player.dispose();
                    }
                }),
            )
            .subscribe();
    }

    setupVideo() {
        const me = this;
        this.player = videojs(this.videoEl.nativeElement);
        // is this global?
        videojs.Vhs.xhr.beforeRequest = function (opt: any) {
            opt.beforeSend = function (xhr: any) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + me.token?.token);

                if(me.env.debug) {
                    xhr.setRequestHeader('Fastly-Debug', '1');
                }
            };
            return opt;
        };
        this.player.src({
            src: transformToCdnUrl(this.src as string, this.env),
            type: 'application/x-mpegURL',
        });
    }

    updatePlayerSrc() {

        if (!this.player || !this.src) {
            return;
        }

        this.player.src({
            src: transformToCdnUrl(this.src, this.env),
            type: 'application/x-mpegURL',
        });
    }

    ngAfterViewInit(): void {
        this.digest$.next();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if(changes['src'] && !changes['src'].firstChange) {
            this.updatePlayerSrc();
        }
    }
}

import { HttpClient, HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';

import { catchError, filter, finalize, map, of, switchMap, tap } from 'rxjs';

import { Store } from '@ngxs/store';
import { AngularNodeViewComponent } from 'ngx-tiptap';

import { Environment } from '../../models';
import { APP_ENVIRONMENT } from '../../tokens';
import { transformToCdnUrl } from '../../util';
import { NgClass } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SrcUnloadDirective } from '../../directives/src-unload.directive';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { SizePipe } from '../../pipes/size.pipe';

@Component({
    selector: 'shared-tiptap-video-container',
    templateUrl: './tiptap-video-container.component.html',
    styleUrls: ['./tiptap-video-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    MatProgressSpinner,
    NgClass,
    SrcUnloadDirective,
    SafeUrlPipe,
    SizePipe
],
})
export class TiptapVideoContainerComponent extends AngularNodeViewComponent implements OnInit {
    videoSrc: string | null = null;

    isDownloading = false;
    loadedBytes = 0;
    totalBytes = 0;

    get downloadProgress() {
        return (this.loadedBytes / (this.totalBytes || 0)) * 100;
    }

    get cssClassName(): string {
        return `custom-video-${this.size}`;
    }

    get size(): 'small' | 'medium' | 'large' {
        return this.node.attrs['size'];
    }

    get src() {
        return this.node.attrs['src'];
    }

    get style() {
        const textAlign = this.node.attrs['textAlign'];

        return {
            'text-align': textAlign,
        };
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private store: Store,
        private http: HttpClient,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    updateSource() {
        this.videoSrc = null;

        if (!this.src) {
            return;
        }

        const result = transformToCdnUrl(this.src, this.env, 'video');
        const options = { headers: {} };

        if (result.includes(this.env.privateContainerName)) {
            const token = this.store.selectSnapshot(root => root.auth.token);
            options['headers'] = { Authorization: `Bearer ${token.token}` };
        }

        this.isDownloading = true;

        this.http
            .get(result, {
                reportProgress: true,
                observe: 'events',
                responseType: 'blob',
                headers: options['headers'],
            })
            .pipe(
                switchMap(response => {
                    if (response.type === HttpEventType.Response) {
                        return of(response.body);
                    } else if (response.type === HttpEventType.DownloadProgress) {
                        this.totalBytes = response.total || 0;
                        this.loadedBytes = response.loaded;
                        this.cd.detectChanges();
                    }

                    return of(null);
                }),
                filter(blob => blob !== null),
                map((blob: Blob | null) => {
                    const blobUri = URL.createObjectURL(blob as Blob);
                    return blobUri;
                }),
                tap(src => {
                    this.videoSrc = src;
                }),
                catchError(() => of('')), // set to 'could not load' icon
                finalize(() => {
                    this.isDownloading = false;
                    this.cd.detectChanges();
                }),
            )
            .subscribe();
    }

    ngOnInit() {
        this.updateSource();
    }

    @HostListener('contextmenu', ['$event'])
    onMenu($event: MouseEvent) {
        $event.preventDefault();
        return false;
    }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { Store } from '@ngxs/store';

import { ManageTemplateActions } from '../actions';

export const ChecklistLinksResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {

    store.dispatch([new ManageTemplateActions.EnsureLoadPreambleTemplates()]);

    return of(void 0);
};

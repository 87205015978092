import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { debounceTime, delay, startWith, Subject, takeUntil, tap } from 'rxjs';

import {
    FormPage,
    FormSection,
    FormSubmissionMessage,
    Registrant,
    TokenResult,
    VideoSettingsViewModel,
} from '@app/data/models';
import { DestroyService } from '@app/shared/services';
import { FormQuestionType, FormSectionType, VideoOption } from '@app/shared/enums';
import { isNullOrEmpty } from '@app/shared/util';
import { SubmissionCompleteDialogComponent } from '@app/ui/dialogs';

import { FormSectionComponent } from '../form-section/form-section.component';
import { FormSectionTextComponent } from '../form-section-text/form-section-text.component';
import { PreviewDirective } from '../../../../../shared/src/lib/directives/preview.directive';
import { FormSectionMediaComponent } from '../form-section-media/form-section-media.component';
import { FormSectionQuestionComponent } from '../form-section-question/form-section-question.component';
import { FormSectionDownloadComponent } from '../form-section-download/form-section-download.component';
import { FormSectionEventComponent } from '../form-section-event/form-section-event.component';
import { FormSectionWebinarComponent } from '../form-section-webinar/form-section-webinar.component';
import { FormSectionMeetingComponent } from '../form-section-meeting/form-section-meeting.component';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'ui-form-preview',
    templateUrl: './form-preview.component.html',
    styleUrls: ['./form-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
    imports: [
    FormSectionComponent,
    FormSectionTextComponent,
    PreviewDirective,
    FormSectionMediaComponent,
    FormSectionQuestionComponent,
    FormSectionDownloadComponent,
    FormSectionEventComponent,
    FormSectionWebinarComponent,
    FormSectionMeetingComponent,
    MatButton
],
})
export class FormPreviewComponent implements OnInit, OnChanges {
    $registerForWebinar = new Subject<void>();
    $requestGuestLink = new Subject<void>();

    FormSectionType: typeof FormSectionType = FormSectionType;

    update$ = new Subject<void>();
    valueChanged$ = new Subject<void>();

    formState: any = null;
    currentFormState: any = null;
    // videoSettings: { [key: string]: VideoSettingsViewModel } | null = {};
    videoSettings = {
        autoplay: false,
        showProgress: true,
        allowScrubbing: true,
        showControls: true,
        showPlayButton: true,
        showPlaybackSpeed: true,
        resumePlayback: false,
        allowListeners: true,
        elapsedTimeInSeconds: 0,
        enableWaitingRoom: false,
        isLiveStream: false,
        showFullscreenButton: true,
        showWatermark: true,
        startTime: VideoOption.None,
        waitingRoomOffsetInSeconds: 0,
    } as VideoSettingsViewModel;

    submission: any = null;
    formPages: FormPage[] | null = null;
    currentPage: FormPage | null = null;
    currentStateIndex = 0;

    isBackVisible = false;
    isNextVisible = false;
    isSubmitVisible = false;
    isBackEnabled = false;
    isNextEnabled = false;
    isSubmitEnabled = false;

    submissionMessage = '';

    registrant: Registrant = {
        topic: '',
        firstName: 'John',
        lastName: 'Doe',
        email: 'john.doe@gmail.com',
        joinUrl: '',
        hasStarted: true,
        guestLink: '',
        password: '',
        meetingId: 0,
        webinarId: 0,
        uniqueMeetingId: '',
        uniqueWebinarId: '',
    };

    @Input() showPreview = false;
    @Input() pages: FormPage[] | null;
    @Input() token: TokenResult | null;
    @Input() defaultSubmissionMessage: string | null;
    @Input() submissionMessages: FormSubmissionMessage[] | null;

    constructor(private cd: ChangeDetectorRef, private dialog: MatDialog, private destroy$: DestroyService) {}

    getSubmissionMessage(): string {
        return this.submissionMessage;
    }

    ngOnInit(): void {
        this.update$
            .pipe(
                startWith(null),
                takeUntil(this.destroy$),
                debounceTime(100),
                tap(() => (this.currentStateIndex = 0)), //reset
                tap(() => (this.submission = {})), //reset
                tap(() => this.updateFormPages()),
                tap(() => this.buildFormState()),
                tap(() => this.cd.markForCheck()),
                tap(() => this.cd.detectChanges()),
            )
            .subscribe();

        this.valueChanged$
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(100),
                tap(() => this.buildFormState()),
                tap(() => this.cd.markForCheck()),
                tap(() => this.cd.detectChanges()),
            )
            .subscribe();
        this.$registerForWebinar
            .asObservable()
            .pipe(
                takeUntil(this.destroy$),
                delay(1000),
                tap(() => {
                    this.registrant = {
                        ...this.registrant,
                        topic: '',
                        joinUrl: 'https://zoom.us/j/123456789',
                    };

                    // this.cd.detectChanges();
                    this.cd.markForCheck();
                }),
                delay(5000),
                tap(() => {
                    this.registrant = {
                        firstName: 'John',
                        lastName: 'Doe',
                        email: 'john.doe@gmail.com',
                        hasStarted: true,
                        guestLink: '',
                        topic: '',
                        joinUrl: '',
                        password: '',
                        meetingId: 0,
                        webinarId: 0,
                        uniqueMeetingId: '',
                        uniqueWebinarId: '',
                    };

                    // this.cd.detectChanges();
                    this.cd.markForCheck();
                }),
            )
            .subscribe();

        this.$requestGuestLink
            .asObservable()
            .pipe(
                takeUntil(this.destroy$),
                tap(() => {
                    this.registrant = { ...this.registrant, guestLink: 'https://zoom.us/j/123456789' };

                    // this.cd.detectChanges();
                    this.cd.markForCheck();
                }),
            )
            .subscribe();
    }

    onRegisterForWebinar() {
        this.$registerForWebinar.next();
    }

    onBack() {
        this.currentStateIndex--;
        const state = this.formState?.[this.currentStateIndex];
        const page = this.formPages?.find(p => p.id === state.pageId) as FormPage;
        this.currentPage = page;
        this.valueChanged$.next();
    }

    onNext() {
        this.currentStateIndex++;
        const state = this.formState?.[this.currentStateIndex];
        const page = this.formPages?.find(p => p.id === state.pageId) as FormPage;
        this.currentPage = page;
        this.valueChanged$.next();
    }

    getVideoSettings(section: FormSection): VideoSettingsViewModel | null {
        this.videoSettings.allowScrubbing = section.allowScrubbing;
        this.videoSettings.showPlaybackSpeed = section.showPlaybackSpeed;

        return this.videoSettings;
    }

    onSubmit() {
        this.dialog.open(SubmissionCompleteDialogComponent, {
            data: {
                showCloseButton: true,
                submissionMessage: this.submissionMessage,
            },
        });
    }

    onValuedChanged(child: FormSection, value: any) {
        this.submission[value.id] = value;
        child.value = value.value;
        this.valueChanged$.next();
    }

    updateFormPages() {
        if (!this.pages || this.pages.length === 0) {
            return;
        }

        const pages = this.pages.reduce((acc: FormSection[], current: any) => {
            if (current.data) {
                const page = {
                    ...current.data,
                    children: current.data.children.reduce((acc: FormSection[], current: any) => {
                        const section = {
                            ...current.data,
                            id: current.id,
                            sectionType: current.sectionType,
                            value: this.submission[current.id] || null,
                        };

                        if (
                            section.type === FormQuestionType.ShortAnswer ||
                            section.type === FormQuestionType.LongAnswer ||
                            section.type === FormQuestionType.Date ||
                            section.type === FormQuestionType.Time ||
                            section.type === FormQuestionType.DateTime
                        ) {
                            section.options = [];
                        }

                        if (section.sectionType !== FormSectionType.Media) {
                            section.mediaAssetId = null;
                        }

                        acc.push(section);

                        return acc;
                    }, []),
                };

                acc.push(page);
            } else {
                acc.push(current);
            }

            return acc;
        }, []) as any[];

        this.formPages = pages;
        const page = this.formPages[this.currentStateIndex];
        this.currentPage = page;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['pages']) {
            this.update$.next();
        }
    }

    buildFormState() {
        if (!this.formPages || this.formPages.length === 0) {
            return;
        }

        const pages = [...(this.formPages as FormPage[])];
        const states = [];
        let pageIx = 0;
        let page = pages[pageIx];

        pages.splice(0, 1);
        let goToPage: string | null = '';
        let isFirst = true;
        let isLast = pages.length === 1;
        let isFirstEvent = true;
        let isComplete = false;
        const answers = Object.entries<Record<string, string>>(this.submission);
        this.submissionMessage = this.submissionMessages?.[0]?.content || '';
        const defaultSubmissionMessageId = this.submissionMessages?.[0]?.rowKey ?? '';

        do {
            goToPage = null;

            if (page === null) {
                break;
            }

            const section = page.children.find(
                c =>
                    c.type === FormQuestionType.MultipleChoice &&
                    c.options.some(opt => !isNullOrEmpty(opt['goToPage'] as string)),
            );

            const sectionsWithCustomSubmissionMessages = page.children.filter(
                c =>
                    c.type === FormQuestionType.MultipleChoice &&
                    c.options.some(opt => !isNullOrEmpty(opt['submissionMessageId'] as string)),
            );

            const answersWithCustomMessageIds = sectionsWithCustomSubmissionMessages
                .flatMap(s => s.options)
                .filter(
                    opt =>
                        !isNullOrEmpty(opt['submissionMessageId'] as string) &&
                        opt['submissionMessageId'] !== defaultSubmissionMessageId,
                )
                .map(opt => opt['id'] as string);

            const submissionMessageAnswer = answers
                .reverse()
                .find(([, value]) => answersWithCustomMessageIds.includes(value['value']));

            if (submissionMessageAnswer) {
                const matchingSection = sectionsWithCustomSubmissionMessages.find(
                    s => s['id'] === submissionMessageAnswer[0],
                );
                if (matchingSection) {
                    const matchingOption = matchingSection.options.find(
                        opt => opt['id'] === submissionMessageAnswer[1]['value'],
                    );

                    if (matchingOption) {
                        this.submissionMessage =
                            this.submissionMessages?.find(
                                m => m.rowKey === (matchingOption['submissionMessageId'] as string),
                            )?.content || '';
                    }
                }
            }

            if (section) {
                goToPage = null;

                const response = this.submission[section.id] as any;

                if (response) {
                    const opts = section.options.filter(opt => !isNullOrEmpty(opt['goToPage'] as string));
                    goToPage = opts.find(opt => opt['id'] === response.value.toString())?.['goToPage'] as string;

                    if (goToPage !== 'submit' && (this.formPages as FormPage[]).every(p => p.id !== goToPage)) {
                        goToPage = null;
                    }
                }
            } else if (!isNullOrEmpty(page.goToPage)) {
                goToPage = page.goToPage;
            }

            const isPageValid = this.isPageValid(page);

            if (!isNullOrEmpty(goToPage)) {
                if (goToPage === 'submit') {
                    states.push({
                        pageId: page.id,
                        isNextEnabled: false,
                        isNextVisible: false,

                        isBackEnabled: !isFirst,
                        isBackVisible: !isFirst,

                        isSubmitEnabled: isPageValid,
                        isSubmitVisible: true,
                    });

                    isComplete = isPageValid;
                    break;
                }

                states.push({
                    pageId: page.id,
                    isNextEnabled: isPageValid,
                    isNextVisible: true,

                    isBackEnabled: !isFirst,
                    isBackVisible: !isFirst,

                    isSubmitEnabled: false,
                    isSubmitVisible: false,
                });

                pageIx = pages.findIndex(p => p.id === goToPage);

                if (!isPageValid || pageIx === -1) {
                    break;
                }

                page = pages[pageIx];
                pages.splice(pageIx, 1);
                isFirst = false;
            } else {
                states.push({
                    pageId: page.id,
                    isNextEnabled: isPageValid && pages.length > 0 && pageIx < pages.length,
                    isNextVisible: pages.length > 0 && pageIx < pages.length,

                    isBackEnabled: !isFirst,
                    isBackVisible: !isFirst,

                    isSubmitEnabled: isPageValid && (pages.length === 0 || pageIx >= pages.length),
                    isSubmitVisible: pages.length === 0 || pageIx >= pages.length,
                });

                if (!isPageValid || pages.length === 0 || pageIx >= pages.length) {
                    isComplete = isPageValid;
                    break;
                }

                page = pages[pageIx];
                pages.splice(pageIx, 1);
                isFirst = false;
            }
        } while (page !== null);

        this.formState = states;
        this.currentFormState = states.find(s => s.pageId === this.currentPage?.id) || null;

        if (this.currentFormState) {
            this.isBackEnabled = this.currentFormState.isBackEnabled;
            this.isBackVisible = this.currentFormState.isBackVisible;
            this.isNextEnabled = this.currentFormState.isNextEnabled;
            this.isNextVisible = this.currentFormState.isNextVisible;
            this.isSubmitEnabled = this.currentFormState.isSubmitEnabled;
            this.isSubmitVisible = this.currentFormState.isSubmitVisible;
        }
    }

    isPageValid(page: FormPage): boolean {
        if (page.children.length === 0) {
            return true;
        }

        const isValid = page.children
            .filter(c => c.sectionType === FormSectionType.Question)
            .every(c => {
                if ((c.isRequired && !this.submission[c.id]) || isNullOrEmpty(this.submission[c.id]?.value)) {
                    return false;
                }

                return true;
            });

        return isValid;
    }
}

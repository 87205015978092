<section tiptapDraggable [ngClass]="{ editable: isEditable, selected: isEditable && isSelected }">
    <ui-tiptap-audio
        [src]="src"
        [title]="title"
        [display]="display"
        [file]="file"
        [token]="tokenResult"
        [alignment]="alignment"
        [hasInteracted]="hasInteracted$ | async"
        [isPlayingMedia]="isPlayingMedia$ | async"
        (playMedia)="onPlayMedia()"
        (uploadFile)="onUploadMediaFile()"
    ></ui-tiptap-audio>

    <ng-container *ngIf="file && isUploading">
        <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    </ng-container>
</section>

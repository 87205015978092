import { WebinarTemplate, CreateWebinarFromTemplate as CreateModel } from '@app/data/models';

const ns = '[Zoom]';

export class EnsureLoadWebinars {
    static readonly type = `${ns} Ensure Load Webinars`;
}

export class LoadWebinars {
    static readonly type = `${ns} Load Webinars`;
    constructor(public retry = true) {}
}

export class LoadWebinarsFailure {
    static readonly type = `${ns} Load Webinars Failure`;
    constructor(public error: any, public retry = false) {}
}

export class EnsureLoadMeetings {
    static readonly type = `${ns} Ensure Load Meetings`;
}

export class LoadMeetings {
    static readonly type = `${ns} Load Meetings`;
    constructor(public retry = true) {}
}

export class LoadMeetingsFailure {
    static readonly type = `${ns} Load Meetings Failure`;
    constructor(public error: any, public retry = false) {}
}

export class EnsureLoadWebinarTemplates {
    static readonly type = `${ns} Ensure Load Webinar Templates`;
}

export class LoadWebinarTemplates {
    static readonly type = `${ns} Load Webinar Templates`;
    constructor(public retry = true) {}
}

export class LoadWebinarTemplatesFailure {
    static readonly type = `${ns} Load Webinar Templates Failure`;
    constructor(public error: any, public retry = false) {}
}

export class EnsureLoadMeetingTemplates {
    static readonly type = `${ns} Ensure Load Meeting Templates`;
}

export class LoadMeetingTemplates {
    static readonly type = `${ns} Load Meeting Templates`;
    constructor(public retry = true) {}
}

export class LoadMeetingTemplatesFailure {
    static readonly type = `${ns} Load Meeting Templates Failure`;
    constructor(public error: any, public retry = false) {}
}

export class CreateWebinarFromTemplate {
    static readonly type = `${ns} Create Webinar From Template`;
}

export class CreateWebinarFromTemplateFailure {
    static readonly type = `${ns} Create Webinar From Template Failure`;
    constructor(public error: any, public retry = false) {}
}

export class CreateMeetingFromTemplate {
    static readonly type = `${ns} Create Meeting From Template`;
}

export class CreateMeetingFromTemplateFailure {
    static readonly type = `${ns} Create Meeting From Template Failure`;
    constructor(public error: any, public retry = false) {}
}

export class ShowCreateWebinarFromTemplateDialog {
    static readonly type = `${ns} Show Create Webinar From Template Dialog`;
    constructor(public model?: Partial<CreateModel> | null) {}
}

export class ShowCreateMeetingFromTemplateDialog {
    static readonly type = `${ns} Show Create Meeting From Template Dialog`;
    constructor(public model?: Partial<CreateModel> | null) {}
}

export class Error {
    static readonly type = `${ns} Error`;
    constructor(public error: any, public source: any) {}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Submission } from '@app/data/models';
import { MatActionList, MatListItem, MatListItemAvatar, MatListItemLine } from '@angular/material/list';
import { NgClass } from '@angular/common';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatIcon } from '@angular/material/icon';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';

@Component({
    selector: 'ui-form-submission-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './form-submission-list.component.html',
    styleUrls: ['./form-submission-list.component.scss'],
    standalone: true,
    imports: [
    MatActionList,
    MatListItem,
    ExtendedModule,
    NgClass,
    MatListItemAvatar,
    MatIcon,
    MatListItemLine,
    FormatDatePipe
],
})
export class FormSubmissionListComponent {
    @Input() submissions: Submission[] | null = [];

    @Output() readonly submissionSelected: EventEmitter<Submission> = new EventEmitter();

    onSessionGroupSelected(session: Submission) {
        this.submissionSelected.emit(session);
    }
}

import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TrackByFunction,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';

import { MediaAsset } from '@app/data/models';
import { AddMedia } from '@app/shared/enums';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgxsFormDirective } from '@ngxs/form-plugin';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { MatOption, MatRipple } from '@angular/material/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MediaDurationPipe } from '../../pipes/media-duration.pipe';

const DEFAULT_PAGE_SIZE = 25;

interface DataEntry extends MediaAsset {
    selected: boolean;
}

@Component({
    selector: 'admin-media-asset-list',
    templateUrl: './media-asset-list.component.html',
    styleUrls: ['./media-asset-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MediaAssetListComponent,
        },
    ],
    standalone: true,
    imports: [
        FlexModule,
        NgxsFormDirective,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatIconButton,
        MatSuffix,
        MatIcon,
        MatSelect,
        NgFor,
        MatOption,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        MatCheckbox,
        MatRipple,
        MatMenu,
        NgIf,
        MatMenuItem,
        MatMenuTrigger,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        ExtendedModule,
        NgClass,
        MatPaginator,
        MediaDurationPipe,
    ],
})
export class MediaAssetListComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
    touched = false;
    disabled = false;
    dataSource: MatTableDataSource<DataEntry>;
    selectedAssetId: string | null = null;

    columns: { key: string; value: string }[] = [
        { key: 'Id', value: 'index' },
        { key: 'Title', value: 'title' },
        { key: 'Duration', value: 'duration' },
        { key: 'Actions', value: 'actions' },
    ];

    _displayedColumns: string[] = ['title'];

    isMenuOpen = false;
    _originAsset: MediaAsset | null = null;
    _origin: CdkOverlayOrigin | null = null;

    get indicatorIconOrigin(): CdkOverlayOrigin {
        return this._origin as CdkOverlayOrigin;
    }

    get pageSize() {
        if (this.form) {
            return this.form.get('pageSize')?.value;
        }

        return DEFAULT_PAGE_SIZE;
    }

    get pageIndex() {
        if (this.form) {
            return this.form.get('pageIndex')?.value;
        }

        return 0;
    }

    get displayedColumns(): string[] {
        return this._displayedColumns;
    }

    get allowActions() {
        return this.allowRename || this.allowDelete || this.allowDownload;
    }

    get selectedAsset(): MediaAsset | null {
        return this.dataSource?.data.find(n => n.rowKey === this.selectedAssetId) || null;
    }

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @Input() filter: string | null = null;
    @Input() mediaAssets: MediaAsset[] | null = [];
    @Input() allowRecord = true;
    @Input() allowUpload = true;
    @Input() allowDownload = true;
    @Input() allowDelete = true;
    @Input() allowRename = true;
    @Input() allowPreview = true;

    @Output() readonly add: EventEmitter<AddMedia> = new EventEmitter();
    @Output() readonly delete: EventEmitter<MediaAsset> = new EventEmitter();
    @Output() readonly rename: EventEmitter<MediaAsset> = new EventEmitter();
    @Output() readonly download: EventEmitter<MediaAsset> = new EventEmitter();
    @Output() readonly preview: EventEmitter<MediaAsset> = new EventEmitter();
    @Output() readonly selectionChange: EventEmitter<MediaAsset | null> = new EventEmitter();

    form = this.fb.group({
        filter: new FormControl(this.filter),
        displayedColumns: new FormControl(['select', 'title', 'duration', 'actions']),
        nodeFilters: new FormControl([]),
        pageSize: new FormControl(DEFAULT_PAGE_SIZE),
        pageIndex: new FormControl(0),
    });

    constructor(private fb: FormBuilder) {}

    onShowIndicatorMenu(asset: MediaAsset, origin: CdkOverlayOrigin) {
        this._originAsset = asset;
        this._origin = origin;
        this.isMenuOpen = true;
    }

    updateDisplayedColumns(event: MatSelectChange) {
        this._displayedColumns = event.value || [];

        if (!this._displayedColumns.includes('select')) {
            this._displayedColumns = ['select'].concat(this._displayedColumns);
        }
    }

    onTouched = () => {};

    onChange = (value: any) => {};

    writeValue(obj: any): void {
        this.selectedAssetId = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    onAdd(type: AddMedia) {
        this.markAsTouched();
        this.add.emit(type);
    }

    onDelete(asset: MediaAsset | null) {
        if (!asset) return;
        this.markAsTouched();
        this.delete.emit(asset);
    }

    onPreview(asset: MediaAsset | null) {
        if (!asset) return;
        this.preview.emit(asset);
    }

    onRename(asset: MediaAsset | null) {
        if (!asset) return;
        this.markAsTouched();
        this.rename.emit(asset);
    }

    onDownload(asset: MediaAsset | null) {
        if (!asset) return;

        this.markAsTouched();
        this.download.emit(asset);
    }

    trackBy(): TrackByFunction<MediaAsset> {
        return (ix: number, asset: MediaAsset) => asset?.rowKey;
    }

    onUpdateFilter(): void {
        this.dataSource.filter = this.form.controls['filter'].value + '.';
    }

    clearFilter(): void {
        this.form.controls['filter'].setValue('');
    }

    updateDataSource(): void {
        if (!this.mediaAssets) {
            return;
        }

        const mediaAssets = this.mediaAssets.map(n => ({ ...n, selected: n.rowKey === this.selectedAssetId }));
        mediaAssets.sort((a, b) => a.title.localeCompare(b.title));

        this.dataSource = new MatTableDataSource<DataEntry>(mediaAssets);
        this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        this.dataSource.filter = `${new Date()}`;
        this.dataSource.paginator = this.paginator;
    }

    ngAfterViewInit(): void {
        if (this.dataSource && this.paginator && !this.dataSource.paginator) {
            this.dataSource.paginator = this.paginator;
        }

        if (!this.touched) {
            const selectedAsset = this.selectedAsset;

            if (selectedAsset) {
                this.form.controls['filter'].setValue(selectedAsset.title);
            }
        }

        this.updateFilter();

        const displayedColumns = this.form.controls['displayedColumns'].value || [];

        if (displayedColumns.length === 0) {
            this._displayedColumns = this.allowActions ? ['select', 'title', 'actions'] : ['select', 'title'];
            this.form.controls['displayedColumns'].patchValue(this._displayedColumns);
        } else {
            this._displayedColumns = displayedColumns;
        }
    }

    updateFilter(): void {
        this.dataSource.filter = this.form.controls['filter'].value + '.';
    }

    filterPredicate(data: MediaAsset, filter: string): boolean {
        const filterValue = (this.form.controls['filter'].value || '').toLocaleLowerCase();

        if (filterValue === '') {
            return true;
        }

        return (
            (data?.rowKey || '').toLocaleLowerCase().startsWith(filterValue) ||
            data.title.toLocaleLowerCase().includes(filterValue)
        );
    }

    onSelectionChange(model: DataEntry) {
        this.markAsTouched();

        if (model.rowKey === this.selectedAssetId) {
            this.selectedAssetId = null;
            this.onChange(null);
            this.selectionChange.emit(null);
            return;
        }

        this.selectedAssetId = model.rowKey;
        this.onChange(this.selectedAssetId);
        this.selectionChange.emit(model);
    }

    onPage($event: PageEvent) {
        // this.store.dispatch([
        //     new UpdateFormValue({
        //         path: 'home.nodeFilters',
        //         value: { pageIndex: $event.pageIndex },
        //     }),
        //     new UpdateFormValue({
        //         path: 'home.nodeFilters',
        //         value: { pageSize: $event.pageSize },
        //     }),
        // ]);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['mediaAssets']) {
            this.updateDataSource();
        }
    }
}

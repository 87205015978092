import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    TrackByFunction,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';

import { Subject } from 'rxjs';

import { ShareableLinkTemplateModel } from '@app/data/models';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgxsFormDirective } from '@ngxs/form-plugin';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { LoadingButtonComponent } from '../../../../../ui/src/lib/components/loading-button/loading-button.component';
import { NgIf, NgClass } from '@angular/common';
import { ErrorDisplayComponent } from '../../../../../ui/src/lib/components/error-display/error-display.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { RouterLink } from '@angular/router';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTooltip } from '@angular/material/tooltip';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';

@Component({
    selector: 'admin-shareable-link-template-list',
    templateUrl: './shareable-link-template-list.component.html',
    styleUrls: ['./shareable-link-template-list.component.scss'],
    standalone: true,
    imports: [
        FlexModule,
        NgxsFormDirective,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        LoadingButtonComponent,
        NgIf,
        ErrorDisplayComponent,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatMenu,
        MatMenuItem,
        MatIcon,
        MatIconButton,
        MatMenuTrigger,
        MatCellDef,
        MatCell,
        MatCheckbox,
        RouterLink,
        ExtendedModule,
        NgClass,
        MatTooltip,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        FormatDatePipe,
    ],
})
export class ShareableLinkTemplateListComponent implements OnChanges, OnDestroy, AfterViewInit {
    isAlive$: Subject<void> = new Subject();
    dataSource: MatTableDataSource<ShareableLinkTemplateModel>;
    selection: SelectionModel<ShareableLinkTemplateModel> = new SelectionModel<ShareableLinkTemplateModel>(true, []);

    pageSize = 25;
    pageIndex = 0;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @Input() error: any;
    @Input() templates: ShareableLinkTemplateModel[] | null = [];
    @Input() filter: string | null = null;
    @Input() isLoading: boolean | null = false;

    @Output() readonly copyTemplate: EventEmitter<ShareableLinkTemplateModel> = new EventEmitter();
    @Output() readonly edit: EventEmitter<ShareableLinkTemplateModel> = new EventEmitter();
    @Output() readonly delete: EventEmitter<ShareableLinkTemplateModel[]> = new EventEmitter();
    @Output() readonly refresh: EventEmitter<void> = new EventEmitter();
    @Output() readonly view: EventEmitter<ShareableLinkTemplateModel> = new EventEmitter();

    form: FormGroup = this.fb.group({
        filter: new FormControl(this.filter),
    });

    columns: { key: string; value: string }[] = [
        { key: 'Id', value: 'index' },
        { key: 'Name', value: 'name' },
        { key: 'Create Date', value: 'createdDate' },
    ];
    _displayedColumns: string[] = ['select', 'name', 'createdDate', 'actions'];

    get displayedColumns(): string[] {
        return this._displayedColumns;
    }

    constructor(private fb: FormBuilder) {}

    onRefresh(): void {
        this.refresh.emit();
    }

    onUpdateFilter($event: KeyboardEvent): void {
        if (this.dataSource) {
            this.dataSource.filter = this.form.controls['filter'].value + '.';
        }
    }

    ngAfterViewInit(): void {
        if (this.dataSource && this.paginator && !this.dataSource.paginator) {
            this.dataSource.paginator = this.paginator;
        }

        this.updateFilter();
    }

    updateFilter(): void {
        if (this.dataSource) {
            this.dataSource.filter = this.form.controls['filter'].value + '.';
        }
    }

    filterPredicate(data: ShareableLinkTemplateModel, filter: string): boolean {
        const filterValue = (this.form.controls['filter'].value || '').toLocaleLowerCase();

        if (filterValue === '') {
            return true;
        }

        return (
            (data.rowKey || '').toLocaleLowerCase().startsWith(filterValue) ||
            data.templateName.toLocaleLowerCase().includes(filterValue)
        );
    }

    updateDataSource(): void {
        if (!this.templates) {
            return;
        }

        const links: ShareableLinkTemplateModel[] = [...this.templates];

        // If the result is negative a is sorted before b.
        // If the result is positive b is sorted before a.
        // If the result is 0 no changes are done with the sort order of the two values.

        links.sort((a: ShareableLinkTemplateModel, b: ShareableLinkTemplateModel) => {
            return a.templateName.localeCompare(b.templateName);
        });

        this.dataSource = new MatTableDataSource<ShareableLinkTemplateModel>(links);

        this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        this.dataSource.filter = `${new Date()}`;
        this.dataSource.paginator = this.paginator;
    }

    onDeleteSelected(): void {
        if (this.selection.hasValue()) {
            this.delete.emit(this.selection.selected);
        }
    }

    trackBy = (): TrackByFunction<ShareableLinkTemplateModel> => {
        return (ix, model) => model.rowKey;
    };

    onCopyTemplate(model: ShareableLinkTemplateModel): void {
        this.copyTemplate.emit(model);
    }

    onEdit(model: ShareableLinkTemplateModel): void {
        this.edit.emit(model);
    }

    onDelete(model: ShareableLinkTemplateModel): void {
        this.delete.emit([model]);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['templates']) {
            this.updateDataSource();
        }
    }

    ngOnDestroy() {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}

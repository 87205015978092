import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppSettings } from '@app/data/models';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

import { errorHandler, getData, verifyServerResult } from '@app/data/operators';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    execute(action: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/admin`, JSON.stringify(action))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getSettings(): Observable<AppSettings> {
        return this.http
            .post<ServerResult<AppSettings>>(`${this.env.serverUrl}/app/settings`, null, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    purgeCdn(model: { url?: string | null; keys?: string[] | null }): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/cdn/purge`, JSON.stringify(model))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}

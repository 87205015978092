<section>
    <div fxLayout="row nowrap" class="header">
        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>Filter</mat-label>
            <input #filterInput matInput autocomplete="off" (keyup)="filter = filterInput.value; onUpdateFilter()" />
        </mat-form-field>
        <button *ngIf="node" mat-icon-button (click)="showAll = !showAll; onUpdateFilter()">
            <mat-icon>{{ showAll ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        <button color="accent" mat-mini-fab (click)="onAdd()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <mat-selection-list *ngIf="!isLoading; else loading" (selectionChange)="onSelectionChange($event)" [multiple]="false">
        <ng-container *ngFor="let note of filteredNotes">
            <mat-list-option [value]="note">
                <div class="note">
                    <!-- <button
                        mat-icon-button
                        class="pin"
                        [class.pinned]="note.isFavourite"
                        (click)="$event.stopPropagation(); onPin(note)"
                    >
                        <mat-icon [class.material-icons-outlined]="!note.isFavourite">push_pin</mat-icon>
                    </button> -->
                    <span class="title">{{ note.title }}</span>
                    <button color="warn" mat-icon-button (click)="$event.stopPropagation(); onDelete(note)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </mat-list-option>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-selection-list>
    <!-- <ul *ngIf="!isLoading; else loading">
        <li *ngFor="let note of filteredNotes; trackBy: trackBy" [value]="note" (click)="onSelectionChange(note)">
            <div class="note">
                <button
                    mat-icon-button
                    class="pin"
                    [class.pinned]="note.isFavourite"
                    (click)="$event.stopPropagation(); onPin(note)"
                >
                    <mat-icon [class.material-icons-outlined]="!note.isFavourite">push_pin</mat-icon>
                </button>
                <span class="title">{{ note.title }}</span>
                <button color="warn" mat-icon-button (click)="$event.stopPropagation(); onDelete(note)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </li>
    </ul> -->
</section>
<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>

<mat-card>
  <mat-card-header>
    @if (showTitle) {
      <mat-card-title>{{ title }}</mat-card-title>
    }
  </mat-card-header>
  <mat-card-content>
    <ui-rich-text-view [content]="model?.description"></ui-rich-text-view>
  </mat-card-content>
</mat-card>

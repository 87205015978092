export interface ConfirmDialogData {
    title: string;
    message?: string | null;
    cancelText?: string;
    confirmText?: string;
    confirmColor?: 'warn' | 'primary' | 'accent' | '';
}

export function ConfirmArchiveResult(config?: Partial<ConfirmDialogData>): ConfirmDialogData {
    return {
        title: config?.title || 'Archive Data',
        message: config?.message || 'Are you sure you want to archive this data?',
        confirmText: 'Archive',
    } as ConfirmDialogData;
}

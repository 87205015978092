<section fxLayout="column wrap" class="tasks-container">
    <input
        #addInput
        class="new-task"
        placeholder="What needs to be done?"
        autofocus=""
        (keyup.enter)="onAdd(addInput.value); addInput.value = ''"
    />

    <section fxFlex="1 1" class="main" *ngIf="tasks | any">
        <!-- <input
            id="toggle-all"
            class="toggle-all"
            type="checkbox"
            *ngIf="tasks.length"
            #toggleall
            [checked]="areAllCompleted()"
            (click)="onCompleteAllTasks(toggleall.checked)"
        /> -->
        <ul
            class="task-list"
            cdkDropList
            (cdkDropListDropped)="onReorderList($event)"
            (cdkDropListSorted)="onSorted($event)"
        >
            <li
                cdkDrag
                *ngFor="let task of tasks; let index = index"
                [class.completed]="task.isComplete"
                [class.editing]="task.isEditing"
                [class.subtask]="task.isSubtask"
                [cdkDragData]="task"
                (cdkDragStarted)="onDragStarted()"
                (cdkDragMoved)="onMoved($event, task)"
            >
                <div *cdkDragPreview data="task">
                    <li [class.completed]="task.isComplete" [class.subtask]="task.isSubtask">
                        <div class="view">
                            <input class="toggle" type="checkbox" [checked]="task.isComplete" />
                            <label>{{ task.title }}</label>
                        </div>
                    </li>
                </div>
                <div *cdkDragPlaceholder data="task">
                    <li [class.completed]="task.isComplete" [class.subtask]="task.isSubtask">
                        <div class="view">
                            <input class="toggle" type="checkbox" [checked]="task.isComplete" />
                            <label>{{ task.title }}</label>
                        </div>
                    </li>
                </div>
                <div class="view">
                    <input
                        class="toggle"
                        type="checkbox"
                        (click)="onToggleCompletion(task, index); $event.stopPropagation()"
                        [checked]="task.isComplete"
                    />
                    <label (click)="onHandleClick(task)">{{ task.title }}</label>
                    <button class="destroy" (click)="onDelete(index)"></button>
                    <div class="toolbar" [ngClass]="{ 'has-note': !!task.notes }">
                        <ng-container *ngIf="hasSubtasks(index)"
                            >{{ completedSubtasks(index) }} of {{ totalSubtasks(index) }}
                        </ng-container>
                        <span class="note"> <mat-icon>sticky_note_2</mat-icon> Note </span>
                    </div>
                </div>
                <input
                    #editInput
                    *ngIf="task.isEditing"
                    [focus]="true"
                    class="edit"
                    [value]="task.title"
                    (blur)="onUpdateTask(task, editInput.value, index)"
                    (keyup.enter)="onUpdateTask(task, editInput.value, index)"
                    (keyup.escape)="task.isEditing = false"
                />
            </li>
        </ul>
    </section>
    <footer class="footer" *ngIf="tasks | any">
        <!-- <span class="todo-count"
            ><strong>{{ todoStore.getRemaining().length }}</strong>
            {{ todoStore.getRemaining().length == 1 ? 'item' : 'items' }} left</span
        > -->
        <!-- <button class="clear-completed" *ngIf="todoStore.getCompleted().length > 0" (click)="removeCompleted()">
            Clear completed
        </button> -->
        <a mat-button class="clear-completed" *ngIf="areAnyCompleted()" (click)="onClearCompleted()">Clear Completed</a>
    </footer>
</section>

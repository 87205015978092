import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EncodingJob, MediaAsset } from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EncodingJobsService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    getEncodingJobs(): Observable<EncodingJob[]> {
        return this.http
            .get<ServerResult<EncodingJob[]>>(`${this.env.serverUrl}/manage/encoding-jobs`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    acknowledgeEncodingJob(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/encoding-jobs/acknowledge`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    createEncodingJobFromMediaAsset(model: MediaAsset): Observable<EncodingJob> {
        return this.http
            .post<ServerResult<EncodingJob>>(`${this.env.serverUrl}/manage/encoding-jobs/start`, { rowKey: model.rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}

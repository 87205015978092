import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { filter, finalize, from, fromEvent, interval, takeUntil, tap } from 'rxjs';

import { TokenResult } from '@app/data/models';
import { Environment } from '@app/shared/models';
import { DestroyService } from '@app/shared/services';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { transformToCdnUrl } from '@app/shared/util';
import { NgStyle, NgClass } from '@angular/common';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatIcon } from '@angular/material/icon';
import { MatRipple } from '@angular/material/core';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'ui-tiptap-audio',
    templateUrl: './tiptap-audio.component.html',
    styleUrls: ['./tiptap-audio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
    imports: [
    ExtendedModule,
    NgStyle,
    MatIcon,
    MatRipple,
    NgClass,
    MatProgressBar
],
})
export class TiptapAudioComponent implements OnInit, OnChanges, OnDestroy {
    speed = 1;
    canPlay = false;
    isUploading = false;
    isLoading = false;
    position = 0;
    duration = 0;
    audio: HTMLAudioElement | null = new Audio();

    hasNotStarted = true;
    objectUrl: string | null = null;

    get isPlaying(): boolean {
        return (this.audio && !this.audio.paused) === true;
    }

    @Input() src: string | null = null;
    @Input() alignment: string | null = 'flex-start';
    @Input() title: string | null = null;
    @Input() display: 'default' | 'inline' = 'default';
    @Input() file: File | null = null;
    @Input() hasInteracted: boolean | null = false;
    @Input() isPlayingMedia: boolean | null = false;
    @Input() token: TokenResult | null = null;

    @Output() readonly uploadFile = new EventEmitter();
    @Output() readonly playMedia = new EventEmitter();

    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private destroy$: DestroyService, private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        interval(60)
            .pipe(
                takeUntil(this.destroy$),
                filter(() => this.isPlaying),
                tap(() => {
                    this.position = this.audio?.currentTime || 0;
                }),
            )
            .subscribe();

        fromEvent(this.audio as HTMLAudioElement, 'canplay')
            .pipe(
                takeUntil(this.destroy$),
                tap(() => {
                    this.canPlay = true;
                    this.duration = this.audio?.duration || 0;
                    this.cd.detectChanges();
                }),
            )
            .subscribe();
    }

    @HostListener('contextmenu', ['$event'])
    onMenu($event: MouseEvent) {
        $event.preventDefault();
        return false;
    }

    onPlayMedia() {
        this.playMedia.emit();
    }

    getStyling() {
        const styles: Record<string, string> = {};

        if (this.display === 'inline') {
            switch (this.alignment) {
                case 'left':
                    styles['justify-content'] = 'flex-start';
                    break;
                case 'center':
                    styles['justify-content'] = 'center';
                    break;
                case 'right':
                    styles['justify-content'] = 'flex-end';
                    break;
                default:
                    styles['justify-content'] = 'flex-start';
                    break;
            }
        } else {
            styles['text-align'] = this.alignment || 'inherit';
        }

        return styles;
    }

    checkForFileUpload() {
        if (this.file && !this.isUploading) {
            this.isUploading = true;
            this.uploadFile.emit();
        }
    }

    onSeek(amount: number) {
        if (!this.audio) return;

        const current = (this.audio?.currentTime || 0) + amount;
        this.audio.currentTime = current;
    }

    onPlay() {
        if (this.hasNotStarted || this.audio?.paused) {
            this.audio?.play();
        } else {
            this.audio?.pause();
        }

        this.hasNotStarted = false;
    }

    onChangePlaybackRate(): void {
        if (this.speed >= 2) {
            this.speed = 0.5;
        } else {
            this.speed += 0.5;
        }

        if (this.audio) {
            this.audio.playbackRate = this.speed;
        }
    }

    async fetchAudio() {
        if(!this.src) return;

        const options = { headers: {} };

        const uri = transformToCdnUrl(this.src, this.env, 'audio');

        if (this.token && this.src.includes(this.env.privateContainerName)) {
            options['headers'] = { Authorization: `Bearer ${this.token.token}` };
        }

        const response = await fetch(uri, options);
        const blob = await response.blob();
        this.objectUrl = URL.createObjectURL(blob);
    }

    update() {
        if (!this.src) {
            return;
        }

        if (this.display === 'default') {
            this.isLoading = true;
            from(this.fetchAudio())
                .pipe(
                    takeUntil(this.destroy$),
                    tap(() => {
                        if (this.audio) {
                            this.audio.src = this.objectUrl as string;
                            this.audio.load();
                        }
                    }),
                    finalize(() => {
                        this.isLoading = false;
                        this.cd.detectChanges();
                    }),
                )
                .subscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['file']) {
            this.checkForFileUpload();
        }

        this.update();
    }

    ngOnDestroy(): void {
        this.audio?.pause();
        this.audio = null;
    }
}

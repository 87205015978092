import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { Node } from '@app/data/models';
import { NodeType } from '@app/shared/enums';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf, NgFor } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { AnyPipe } from '../../../../../shared/src/lib/pipes/any.pipe';

@Component({
    templateUrl: './delete-media-asset-confirmation-dialog.component.html',
    styles: [
        `
            :host {
                width: 600px;
                display: block;
            }

            mat-dialog-content {
                height: 100px;
            }
        `,
    ],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        NgIf,
        NgFor,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        AnyPipe,
    ],
})
export class DeleteMediaAssetConfirmationDialogComponent {
    NodeType: typeof NodeType = NodeType;

    get name(): string {
        return `${this.data.asset.title}`;
    }

    get nodes(): Node[] {
        return this.data.nodes;
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

<mat-action-list>
  @for (session of sessionGroups; track session) {
    <mat-list-item
      class="session-group"
      [ngClass]="{ active: session.isActive }"
      (click)="onSessionGroupSelected(session)"
      >
      @if (session.hasLocation) {
        <img matListItemAvatar class="avatar" [src]="session.location?.flag" />
      }
      @if (!session.hasLocation) {
        <div matListItemAvatar class="avatar">
          <mat-icon class="no-location">gps_off</mat-icon>
        </div>
      }
      <time matListItemLine class="small mute">
        {{ session.lastActivityTimestamp | formatDate }}
      </time>
      <div matListItemLine>
        @if (session.hasLocation) {
          {{ session.location?.city }}, {{ session.location?.region }}, {{ session.location?.country }}
        }
        @if (!session.hasLocation) {
          Unknown
        }
      </div>
      @if (session.deviceInfo) {
        <div class="badge-overflow" matListItemLine>
          <span
            matBadgePosition="after"
            matBadgeOverlap="false"
            [matBadgeHidden]="session.accessCount === 0 || !hasLinkStarted"
            [matBadge]="session.accessCount"
            class="badge access-badge"
            >
            {{ session.deviceInfo.browser }} ({{ session.deviceInfo.browserVersion }})
            {{ session.deviceInfo.os }}
            {{ session.deviceInfo.osVersion }}
          </span>
        </div>
      }
      @if (!session.deviceInfo) {
        <div matListItemLine>No Device Info</div>
      }
      <div matListItemLine class="mute">Session Id: {{ session.sessionId }}</div>
    </mat-list-item>
  }
</mat-action-list>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

import { AppSettings } from '@app/data/models';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOptgroup, MatOption } from '@angular/material/core';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'admin-admin-actions',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./admin-actions.component.html`,
    standalone: true,
    imports: [
        MatCard,
        MatCardContent,
        ReactiveFormsModule,
        MatCardTitle,
        MatFormField,
        MatSelect,
        MatOptgroup,
        MatOption,
        NgIf,
        MatError,
        MatButton,
        MatProgressSpinner,
    ],
})
export class AdminActionsComponent {
    form = this.fb.group({
        action: ['', [Validators.required]],
    });

    @Input() isPending: boolean | null = null;
    @Input() isSnapshotPending: boolean | null = null;
    @Input() isNodePermissionPending: boolean | null = null;
    @Input() isMigrateNotificationsPending: boolean | null = null;
    @Input() isMigrateBinariesPending: boolean | null = null;
    @Input() isMigrateContentLinksPending: boolean | null = null;
    @Input() settings: AppSettings | null = null;

    @Output() readonly calculateNodePermissions: EventEmitter<void> = new EventEmitter();
    @Output() readonly snapshotContent: EventEmitter<void> = new EventEmitter();
    @Output() readonly migrateNotifications: EventEmitter<void> = new EventEmitter();
    @Output() readonly migrateBinaries: EventEmitter<void> = new EventEmitter();
    @Output() readonly migrateContentLinks: EventEmitter<void> = new EventEmitter();
    @Output() readonly executeAction: EventEmitter<string> = new EventEmitter();

    constructor(private fb: FormBuilder) {}

    onCalculateNodePermissions() {
        this.calculateNodePermissions.emit();
    }

    onSnapshotContent() {
        this.snapshotContent.emit();
    }

    onMigrateNotifications() {
        this.migrateNotifications.emit();
    }

    onMigrateBinaries() {
        this.migrateBinaries.emit();
    }

    onMigrateContentLinks() {
        this.migrateContentLinks.emit();
    }

    onExecuteAction() {
        if (this.form.invalid) {
            return;
        }

        this.executeAction.emit(this.form.get('action')?.value as string);
    }
}

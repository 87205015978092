import { NgClass, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    input,
    model,
    signal,
    ViewChild,
    effect,
    OnChanges,
    SimpleChanges,
    OnInit,
    output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconButton } from '@angular/material/button';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';

import { debounceTime, startWith, Subject, takeUntil, tap } from 'rxjs';

import { DestroyService } from '@app/shared/services';
import { LoadingButtonComponent } from '@app/ui/components';

export interface FilterConfig {
    type: 'select' | 'hidden';
    key: string;
    label?: string | null;
    multiple?: boolean;
    options?: { key: string | number; value: string }[];
    default?: any;
}

export interface FilterModel {
    [key: string]: any;
    filter: string | null;
    tags: string[];
}

const DEBOUNCE_IN_MS = 200;

@Component({
    selector: 'admin-link-filter',
    templateUrl: `./link-filter.component.html`,
    styleUrl: `./link-filter.component.scss`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,

        NgClass,

        MatFormField,
        MatInput,
        MatLabel,
        MatIcon,
        MatIconButton,
        MatSuffix,

        MatChipListbox,
        MatChipOption,
        MatChipRemove,

        MatSelect,
        MatOption,

        LoadingButtonComponent,
    ],
    viewProviders: [DestroyService],
})
export class LinkFilterComponent implements OnInit, OnChanges {
    tags = input<string[] | null>([]);
    config = input<FilterConfig[]>();
    model = model<FilterModel>({ filter: '', tags: [] });
    storageKey = input<string | null>(null);
    isLoading = input<boolean | null>(false);

    filterChange = output<FilterModel>();

    fb = inject(FormBuilder);
    destroy$ = inject(DestroyService);

    filteredTags = signal<string[]>([]);
    displayTags = signal<string[]>([]);
    keyup$: Subject<void> = new Subject();
    form: FormGroup = this.fb.group({
        filter: new FormControl(''),
        tags: new FormControl([] as string[]),
    });

    get filterControlClasses() {
        const config = this.config();
        const count = (config?.filter(c => c.type !== 'hidden')?.length || 0) + 1;

        return `filter-ctrls-${count}`;
    }

    @ViewChild(MatAutocompleteTrigger, { static: true }) autoTrigger: MatAutocompleteTrigger;

    ngOnInit() {
        const key = this.storageKey();
        let storage = null;

        try {
            storage = JSON.parse(localStorage.getItem(key as string) || '');
        } catch {}

        this.form.controls['filter'].setValue(storage?.['filter'] || '');
        this.form.controls['tags'].setValue(storage?.['tags'] || []);

        this.form.controls['filter'].valueChanges
            .pipe(
                takeUntil(this.destroy$),
                tap(filter => this.filteredTags.set(this.filterTags(filter))),
                debounceTime(DEBOUNCE_IN_MS),
                tap(() => this.onValueChanged({ event: 'filter' })),
            )
            .subscribe();

        this.form.controls['tags'].valueChanges
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(DEBOUNCE_IN_MS),
                tap(() => this.onValueChanged({ event: 'tags' })),
            )
            .subscribe();

        this.onValueChanged({ event: 'init' });
    }

    onUpdateFilter() {
        if (
            !this.autoTrigger.panelOpen &&
            this.autoTrigger.autocomplete &&
            this.form.controls['filter'].value?.length >= 1
        ) {
            this.autoTrigger.openPanel();
        }

        this.keyup$.next();
    }

    onRefresh() {
        this.onValueChanged({ event: 'refresh' });
    }

    onFilterClick() {
        if (!this.autoTrigger.panelOpen) {
            this.autoTrigger.openPanel();
        }
    }

    filterTags(filter: string): string[] {
        const tags = this.tags();
        if (!tags) return [];

        if (!filter) {
            const displayTags = this.displayTags();
            return tags.filter(tag => !displayTags.map(s => s.toLocaleLowerCase()).includes(tag.toLocaleLowerCase()));
        }

        return tags.filter(tag => tag.toLocaleLowerCase().startsWith(filter));
    }

    selectTag(event: MatAutocompleteSelectedEvent): void {
        this.displayTags.update(t => {
            t.push(event.option.viewValue);
            return t;
        });

        this.form.controls['filter'].setValue(null);
        this.form.controls['tags'].setValue(this.displayTags().slice());
    }

    removeTag(tag: string): void {
        const displayTags = this.displayTags();
        const index = displayTags.indexOf(tag);

        if (index >= 0) {
            displayTags.splice(index, 1);
        }

        this.displayTags.set(displayTags);
        this.form.controls['tags'].setValue(displayTags.slice());
    }

    onClearFilter(): void {
        this.form.controls['filter'].patchValue('');
    }

    buildForm() {
        const key = this.storageKey();
        const config = this.config() || [];
        let storage = null;

        for (const name in this.form.controls) {
            if (name === 'filter' || name === 'tags' || config.some(c => c.key === name)) {
                continue;
            }

            this.form.removeControl(name);
        }

        try {
            storage = JSON.parse(localStorage.getItem(key as string) || '');
        } catch {}

        config.forEach(c => {
            if (!this.form.contains(c.key)) {
                const ctrl = new FormControl(storage?.[c.key] || c.default || null);
                ctrl.valueChanges
                    .pipe(
                        takeUntil(this.destroy$),
                        debounceTime(DEBOUNCE_IN_MS),
                        tap(v => this.onValueChanged({ event: c.key })),
                    )
                    .subscribe();

                this.form.addControl(c.key, ctrl);
            }
        });
    }

    onValueChanged(value: Record<string, string>) {
        const formValue = this.form.value;
        const modelValue = this.model();
        this.model.set({ ...modelValue, ...formValue });
        this.filterChange.emit({ ...formValue, ...value });
        this.saveToStorage();
    }

    saveToStorage() {
        const key = this.storageKey();

        if (key) {
            localStorage.setItem(key, JSON.stringify(this.form.value));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['config']) {
            this.buildForm();
        }
    }
}

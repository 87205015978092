import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { ScreenProfile } from '../../enums';
import { DestroyService } from '../../services';
import { VIDEOJS_DEFAULT_CONFIGURATION } from '../../tokens';

declare const videojs: any;

@Component({
    selector: 'shared-media-asset-player',
    templateUrl: './media-asset-player.component.html',
    styleUrls: ['./media-asset-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
})
export class MediaAssetPlayerComponent implements OnInit, AfterViewInit, OnChanges {
    digest$ = new Subject<void>();
    handlePlayEventDelegate = this.handlePlayEvent.bind(this);
    eventList = [
        'ready',
        'play',
        'pause',
        'playing',
        'ended',
        'loadeddata',
        'loadedmetadata',
        'beforeplaylistitem',
        'playlistitem',
        'error',
    ];
    player: any;


    @ViewChild('videoEl') videoEl: ElementRef<HTMLVideoElement>;

    @Input() token: any | null = null;
    @Input() isTheatreMode: boolean | null = false;
    @Input() screenProfile: ScreenProfile | null;
    @Input() screenOrientation: ScreenOrientation | null;
    @Input() videoConfiguration: any | null = this.defaultVideoJsConfig;
    @Input() controlBarConfiguration: any | null = null;

    @Output() readonly playerEvent: EventEmitter<{
        player: any;
        event: any;
        data?: any;
    }> = new EventEmitter();

    constructor(
        @Optional()
        @Inject(VIDEOJS_DEFAULT_CONFIGURATION)
        private defaultVideoJsConfig: any,
        private destroy$: DestroyService,
    ) {
        if (!this.defaultVideoJsConfig) {
            this.defaultVideoJsConfig = {
                controls: true,
                preload: 'auto',
                enableDocumentPictureInPicture: false,
                children: {
                    controlBar: {
                        children: [
                            'playToggle',
                            'progressControl',
                            'volumePanel',
                            'remainingTimeDisplay',
                            'fullscreenToggle',
                        ],
                    },
                    videoWatermark: { text: '' },
                    bigPlayButton: {},
                    loadingSpinner: {},
                    tapToPlay: { hasInteracted: () => (window as any).hasPointerEventHappened === true },
                },
            };
        }
    }

    ngOnInit(): void {
        this.digest$
            .pipe(
                takeUntil(this.destroy$),
                filter(
                    () => (this.videoEl && this.videoEl.nativeElement !== null && this.videoConfiguration !== null) === true,
                ),
                take(1),
                tap(() => {
                    this.setupVideo();
                }),
            )
            .subscribe();

        this.destroy$
            .pipe(
                tap(() => {
                    if (this.player) {
                        this.player.off(this.eventList, this.handlePlayEventDelegate);
                        this.player.dispose();
                    }
                }),
            )
            .subscribe();
    }

    updateControlBarConfiguration() {
        if (this.player && this.controlBarConfiguration) {
            const currentCtrlBar = this.player.getChild('ControlBar');

            if (currentCtrlBar) {
                currentCtrlBar.dispose();
                this.player.removeChild(currentCtrlBar);
            }

            const newCtrlBar = this.player.addChild('ControlBar', this.controlBarConfiguration);

            if (this.controlBarConfiguration.children.progressControl?.disabled) {
                newCtrlBar.progressControl.disable();
            }
        }
    }

    handlePlayEvent(e: Event, data: any) {
        this.playerEvent.emit({ player: this.player, event: e, data });
    }

    setupVideo() {
        if (!this.videoConfiguration || this.player) {
            return;
        }

        const me = this;
        videojs.Vhs.xhr.beforeRequest = function (opt: any) {
            opt.beforeSend = function (xhr: any) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + me.token?.token);
            };
            return opt;
        };

        const config = {
            ...this.defaultVideoJsConfig,
            ...this.videoConfiguration,
            tech: {
                html5: {
                    nativeControlsForTouch: false,
                    nativeAudioTracks: false,
                    nativeVideoTracks: false,
                    hls: {
                        overrideNative: true,
                    },
                },
            },
        };

        if (this.controlBarConfiguration) {
            config.controlBar = {
                ...this.controlBarConfiguration,
            };
        }

        this.player = videojs(this.videoEl.nativeElement, config);

        this.player.on(this.eventList, this.handlePlayEventDelegate);
        this.player.on('contextmenu', (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
        });


        if (this.controlBarConfiguration?.children.progressControl?.disabled) {
            this.player.controlBar.progressControl.disable();
        }

        if (this.videoConfiguration.children.waitingRoom) {
            this.player.waitingRoom.on('waitingended', (e: any) => {
                this.playerEvent.emit({ player: this.player, event: e });
            });
        }

        if (this.videoConfiguration.playlist) {
            this.player.playlist(this.videoConfiguration.playlist);
            this.player.playlist.repeat(false);
            this.player.playlist.autoadvance(1);
        }

        if (this.videoConfiguration.src) {
            this.player.src(this.videoConfiguration.src);
        }

        if (this.videoConfiguration.currentTime) {
            this.player.currentTime(this.videoConfiguration.currentTime);
        }
    }

    ngAfterViewInit(): void {
        this.digest$.next();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['controlBarConfiguration'] && changes['controlBarConfiguration'].isFirstChange() === false) {
            this.updateControlBarConfiguration();
        }

        if (changes['videoConfiguration']) {
            this.digest$.next();
        }

        if(changes['isTheatreMode']) {
            this.player?.fill(this.isTheatreMode === true);
        }
    }
}

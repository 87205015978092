<h2 matDialogTitle>Submission Complete</h2>
<mat-dialog-content>
  @for (event of scheduledEvents; track event) {
    <div>{{ event.eventName }} {{ event.startTime | date : 'short' }} - {{ event.endTime | date : 'short' }}</div>
    @if (event.location?.join_Url) {
      <a [href]="event.location?.join_Url" target="_blank" rel="nofollow">{{
        event.location?.join_Url
      }}</a>
    }
  }
  <ui-rich-text-view [content]="data.submissionMessage"></ui-rich-text-view>
</mat-dialog-content>
@if (showCloseButton) {
  <mat-dialog-actions align="end">
    <div class="resubmit">Would you like to update your answers?</div>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">Update Answers</button>
  </mat-dialog-actions>
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    CreateMeetingFromTemplate,
    CreateWebinarFromTemplate,
    Meeting,
    MeetingTemplateListResponse,
    Webinar,
    WebinarTemplateListResponse,
} from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable({
    providedIn: 'root',
})
export class ZoomService {
    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private env: Environment) {}

    getWebinars(): Observable<Webinar[]> {
        return this.http
            .post<ServerResult<Webinar[]>>(`${this.env.serverUrl}/manage/zoom/webinars`, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getWebinarTemplates(nextPageToken: string | null): Observable<WebinarTemplateListResponse> {
        return this.http
            .post<ServerResult<WebinarTemplateListResponse>>(`${this.env.serverUrl}/manage/zoom/webinar/templates`, {
                nextPageToken,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    createWebinarFromTemplate(model: CreateWebinarFromTemplate): Observable<any> {
        return this.http
            .post<ServerResult<any>>(`${this.env.serverUrl}/manage/zoom/webinar/create`, model)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getMeetings(): Observable<Meeting[]> {
        return this.http
            .post<ServerResult<Meeting[]>>(`${this.env.serverUrl}/manage/zoom/meetings`, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getMeetingTemplates(nextPageToken: string | null): Observable<MeetingTemplateListResponse> {
        return this.http
            .post<ServerResult<MeetingTemplateListResponse>>(`${this.env.serverUrl}/manage/zoom/meeting/templates`, {
                nextPageToken,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    createMeetingFromTemplate(model: CreateMeetingFromTemplate): Observable<any> {
        return this.http
            .post<ServerResult<any>>(`${this.env.serverUrl}/manage/zoom/meeting/create`, model)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { isString } from '@app/shared/util';

@Pipe({
    name: 'mediaDuration',
    standalone: true,
})
export class MediaDurationPipe implements PipeTransform {
    transform(value: string | number | null | undefined, trim = false): string {
        if (isString(value)) {
            const duration = value.split('.')[0];
            if (trim) {
                // remove hours if 0
                if (duration.startsWith('00:')) {
                    return duration.substring(3);
                }

                return duration;
            }

            return duration;
        }

        const totalSeconds: number = value || 0;
        const totalMinutes: number = Math.trunc((value || 0) / 60);
        const totalHours: number = Math.trunc(totalMinutes / 60);
        const hours = Math.trunc(totalHours);
        const minutes = Math.trunc(totalMinutes - totalHours * 60);
        const seconds = Math.trunc(totalSeconds - hours * 60 * 60 - minutes * 60);

        if (trim) {
            if (hours > 0) {
                return (
                    hours.toString().padStart(2, '0') +
                    ':' +
                    minutes.toString().padStart(2, '0') +
                    ':' +
                    seconds.toString().padStart(2, '0')
                );
            } else if (minutes > 0) {
                return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
            }

            return seconds.toString().padStart(2, '0');
        }

        return (
            hours.toString().padStart(2, '0') +
            ':' +
            minutes.toString().padStart(2, '0') +
            ':' +
            seconds.toString().padStart(2, '0')
        );
    }
}

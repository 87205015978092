import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ZoomState } from '@app/admin/state/zoom.state';
import { Meeting, Webinar } from '@app/data/models';
import { DestroyService } from '@app/shared/services';
import { Store } from '@ngxs/store';
import { AngularNodeViewComponent, NgxTiptapModule } from 'ngx-tiptap';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf } from '@angular/common';
import { TiptapZoomLinkComponent } from '../../../../../ui/src/lib/components/tiptap-zoom-link/tiptap-zoom-link.component';

@Component({
    selector: 'admin-tiptap-zoom-link-container',
    templateUrl: './tiptap-zoom-link-container.component.html',
    styleUrls: ['./tiptap-zoom-link-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
    imports: [
        NgxTiptapModule,
        ExtendedModule,
        NgClass,
        TiptapZoomLinkComponent,
        NgIf,
    ],
})
export class TiptapZoomLinkContainerComponent extends AngularNodeViewComponent implements OnInit {
    joinUrl = 'https://zoom.us/j/1234567890?pwd=abc123';
    password = '123456';

    get showJoinButton(): boolean {
        return this.node.attrs['showJoinButton'] === true;
    }

    get showJoinUrl(): boolean {
        return this.node.attrs['showJoinUrl'] === true;
    }

    get showCopyLinkButton(): boolean {
        return this.node.attrs['showCopyLinkButton'] === true;
    }

    get showCopyPasswordButton(): boolean {
        return this.node.attrs['showCopyPasswordButton'] === true;
    }

    get zoomId(): number {
        return this.node.attrs['zoomId'];
    }

    get isEditable() {
        return this.editor.isEditable;
    }

    get isSelected() {
        return this.selected;
    }

    get alignment() {
        return this.node.attrs['textAlign'];
    }

    zoomMeeting: Webinar | Meeting | null = null;

    constructor(private cd: ChangeDetectorRef, private store: Store) {
        super();
    }

    ngOnInit(): void {
        this.editor.on('transaction', ({ editor, transaction }) => {
            this.updateZoomMeeting();
            this.cd.detectChanges();
        });

        this.editor.on('selectionUpdate', () => {
            this.updateZoomMeeting();
            this.cd.detectChanges();
        });
    }

    updateZoomMeeting() {
        const webinars = this.store.selectSnapshot(ZoomState.getWebinars());
        const meetings = this.store.selectSnapshot(ZoomState.getMeetings());

        this.zoomMeeting = (webinars.find(w => w.id === this.zoomId) || meetings.find(m => m.id === this.zoomId)) || null;
    }
}

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { CalendlyState } from '@app/admin/state/calendly.state';
import { HomeState } from '@app/admin/state/home.state';
import { LoginState } from '@app/admin/state/login.state';
import { ManageChecklistsState } from '@app/admin/state/manage-checklists.state';
import { ManageEventsState } from '@app/admin/state/manage-events.state';
import { ManageFormsState } from '@app/admin/state/manage-forms.state';
import { ManageLinksState } from '@app/admin/state/manage-links.state';
import { ManageMediaState } from '@app/admin/state/manage-media.state';
import { ManageNodesState } from '@app/admin/state/manage-nodes.state';
import { ManageRequestsState } from '@app/admin/state/manage-requests.state';
import { ManageTemplatesState } from '@app/admin/state/manage-templates.state';
import { NotesState } from '@app/admin/state/notes.state';
import { ProfileState } from '@app/admin/state/profile.state';
import { SearchState } from '@app/admin/state/search.state';
import { ZoomState } from '@app/admin/state/zoom.state';
import { ApiVersionInterceptor } from '@app/data/interceptors/api-version-interceptor.service';
import { CredentialsInterceptor } from '@app/data/interceptors/credentials-interceptor.service';
import { DateInterceptor } from '@app/data/interceptors/date-interceptor.service';
import { TokenInterceptor } from '@app/data/interceptors/token-interceptor.service';
import { AuthState } from '@app/data/state/auth.state';
import { CoreState } from '@app/data/state/core.state';
import { DebugState } from '@app/data/state/debug.state';
import { MediaPlayerState } from '@app/data/state/media-player.state';
import { NotificationsState } from '@app/data/state/notifications.state';
import { provideFingerprintjsPro } from '@app/shared/util';
import { withNgxsFormPlugin } from '@ngxs/form-plugin';
import { withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { provideStore } from '@ngxs/store';
import { provideToastr } from 'ngx-toastr';

import { LoginInterceptor } from '../../../../libs/admin/src/lib/interceptors/login-interceptor.service';
import { NoConnectionInterceptor } from '../../../../libs/admin/src/lib/interceptors/no-connection-interceptor.service';
import { PROVIDERS } from '../../../../libs/admin/src/lib/providers';
import { AdminState } from '../../../../libs/admin/src/lib/state/admin.state';
import { Environment } from '../../../../libs/shared/src/lib/models';
import {
    APP_AUTOUPDATE,
    APP_DEBUG,
    APP_DOCUMENT,
    APP_ENVIRONMENT,
    APP_FPJS_TOKEN,
    APP_INITIAL_POINTER_EVENT,
    APP_WINDOW,
} from '../../../../libs/shared/src/lib/tokens';
import { environment } from '../environments/environment';
import { ROUTES } from './app.routing';

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: APP_ENVIRONMENT, useValue: environment },
        {
            provide: APP_AUTOUPDATE,
            useFactory: (env: Environment) => {
                return env.autoUpdate;
            },
            deps: [APP_ENVIRONMENT],
        },
        {
            provide: APP_FPJS_TOKEN,
            useFactory: (env: Environment) => {
                return env.fpjsApiToken;
            },
            deps: [APP_ENVIRONMENT],
        },
        {
            provide: APP_INITIAL_POINTER_EVENT,
            useFactory: () => {
                return () => (window as any).hasPointerEventHappened === true;
            },
        },
        {
            provide: APP_DEBUG,
            useFactory: (env: Environment) => {
                return env.debug;
            },
            deps: [APP_ENVIRONMENT],
        },
        { provide: APP_WINDOW, useFactory: () => window },
        { provide: APP_DOCUMENT, useFactory: () => document },
        { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NoConnectionInterceptor, multi: true },

        ...(environment.production ? [{ provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true }] : []),

        provideRouter(ROUTES),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),

        provideStore([
            AuthState,
            CoreState,
            NotificationsState,
            MediaPlayerState,

            AdminState,
            HomeState,
            NotesState,
            ManageLinksState,
            ManageMediaState,
            ManageNodesState,
            ManageFormsState,
            ManageRequestsState,
            ManageEventsState,
            ManageChecklistsState,
            ManageTemplatesState,
            SearchState,
            ProfileState,
            DebugState,
            CalendlyState,
            ZoomState,
            LoginState,
        ]),
        withNgxsFormPlugin(),
        withNgxsRouterPlugin(),
        provideToastr({
            countDuplicates: true,
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            maxOpened: 5,
        }),

        provideFingerprintjsPro({
            loadOptions: {
                apiKey: 'icVKtNvtbIY3JkgZyj6w',
                endpoint: `${environment.fpEndpointUrl}/BsbFofJFJvUkLyun/xfOZAu6KX83CSEvs`,
                scriptUrlPattern: `${environment.fpEndpointUrl}/BsbFofJFJvUkLyun/8XRZpMMbaeslEyJZ?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>`,
            },
        }),

        ...PROVIDERS,
    ],
};


const ns = '[Calendly]';

export class ClearEvents {
    static readonly type = `${ns} Clear Events`;
}

export class LoadScheduledEvents {
    static readonly type = `${ns} Load Scheduled Events`;
    constructor(public eventIds: string[]) {}
}

export class LoadScheduledEventsFailure{
    static readonly type = `${ns} Load Scheduled Events Failure`;
    constructor(public error: any) {}
}
<mat-card>
  <mat-card-header>
    @if (showTitle) {
      <mat-card-title>{{ title }}</mat-card-title>
    }
  </mat-card-header>
  <mat-card-content>
    <ui-rich-text-view [content]="model.description"></ui-rich-text-view>
    <!-- <p>Meeting registration for: {{ registrant?.topic }}</p> -->
    @if (!joinUrl) {
      <div>
        <div [formGroup]="form">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>First Name</mat-label>
            <input formControlName="firstName" matInput />
            @if (form.get('firstName')?.hasError('required')) {
              <mat-error>Required</mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Last Name</mat-label>
            <input formControlName="lastName" matInput />
            @if (form.get('lastName')?.hasError('required')) {
              <mat-error>Required</mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput />
            @if (form.get('email')?.hasError('required')) {
              <mat-error>Required</mat-error>
            }
          </mat-form-field>
          @if (!isRegistering) {
            <ui-error-display [error]="error"></ui-error-display>
          }
        </div>
      </div>
    } @else {
      <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        @if (showJoinUrl) {
          <a fxFlex="1 1" target="_blank" class="short-link" rel="nofollow" [href]="joinUrl">{{
            joinUrl
          }}</a>
        }
        @if (showJoinButton) {
          <button fxFlex="0 0 auto" mat-raised-button color="primary" (click)="onJoinMeeting()">
            @if (isJoiningMeeting) {
              <mat-spinner class="spinner" diameter="20"></mat-spinner>
              }Join Meeting<mat-icon
              >video_call</mat-icon
              >
            </button>
          }
          @if (showCopyLinkButton) {
            <button
              fxFlex="0 0 auto"
              class="nowrap"
              mat-raised-button
              (click)="onCopyLinkToClipboard()"
              >
              <mat-icon>content_copy</mat-icon>
              Copy Link
            </button>
          }
        </div>
        <ng-container *ngTemplateOutlet="allowGuestRegistration ? guest : empty"></ng-container>
      }
    </mat-card-content>
    <mat-card-actions align="end">
      @if (!joinUrl) {
        <button
          mat-raised-button
          color="primary"
          [disabled]="form.invalid || isRegistering"
          (click)="onRegister()"
          >
          @if (isRegistering) {
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
          }
          Register
        </button>
      }
    </mat-card-actions>
  </mat-card>


  <ng-template #guest>
    @if (!guestLink) {
      <button
        mat-raised-button
        [disabled]="isRequestingLink"
        color="primary"
        (click)="onRequestGuestLink()"
        >
        @if (isRequestingLink) {
          <mat-spinner class="spinner" diameter="20"></mat-spinner>
          } Request Guest Link<mat-icon
          >video_call</mat-icon
          >
        </button>
      }
      @if (guestLink) {
        Use this link to join the meeting as a guest:
        <span (click)="onCopyLink()">{{ guestLink }}</span>
      }
    </ng-template>

    <ng-template #empty> </ng-template>

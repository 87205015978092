import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, finalize, mergeMap, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageTemplateActions } from '../actions';
import { ManageTemplatesState } from '../state/manage-templates.state';

export const EditChecklistTemplateResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    store.dispatch([
        new CoreActions.UpdateGlobalLoading(true),
        new ManageTemplateActions.EnsureLoadChecklistTemplates(),
    ]);

    const { linkId } = route.params;

    return store.select(ManageTemplatesState.hasLoadedChecklistTemplates()).pipe(
        filter(loaded => loaded === true),
        take(1),
        mergeMap(() => store.dispatch(new ManageTemplateActions.LoadChecklist(linkId, true))),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h1 mat-dialog-title>Confirm cancel events(s)</h1>
        <mat-dialog-content>
            <p>
                Are you certain you want to cancel any scheduled events for {{ count }} sessions? This action is permanent
                and cannot be undone.
            </p>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-button [mat-dialog-close]="false">Cancel</button>
            <button mat-raised-button color="warn" [mat-dialog-close]="true">Confirm</button>
        </mat-dialog-actions>
    `,
    styles: [
        `
            :host {
                width: 600px;
                display: block;
            }

            mat-dialog-content {
                height: 100px;
            }
        `,
    ],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ],
})
export class CancelScheduledEventsConfirmationDialogComponent {
    get count(): number {
        return this.data.length;
    }

    constructor(@Inject(MAT_DIALOG_DATA) private data: any) {}
}

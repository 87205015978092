import { Injectable } from '@angular/core';

import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from '@app/data/services/auth.service';
import { ProfileActions } from '../actions';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';

import { AuthActions, NotificationActions } from '@app/data/actions';

export interface StateModel {
    error: any;
    isLoading: boolean;
    form: {
        model: any;
        dirty: false;
        status: '' | 'VALID' | 'INVALID';
        errors: {};
    };
}

@State<StateModel>({
    name: 'profile',
    defaults: {
        error: null,
        isLoading: false,
        form: {
            dirty: false,
            errors: {},
            model: [],
            status: '',
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class ProfileState {
    static isLoading() {
        return createSelector([ProfileState], (state: StateModel) => state.isLoading);
    }

    static getError() {
        return createSelector([ProfileState], (model: StateModel) => model.error);
    }

    static getForm() {
        return createSelector([ProfileState], (model: StateModel) => model.form);
    }

    static getModel() {
        return createSelector([ProfileState], (model: StateModel) => model.form?.model);
    }

    static isValid() {
        return createSelector([ProfileState], (model: StateModel) => model.form.status === 'VALID');
    }

    constructor(private store: Store, private authService: AuthService) {}

    @Action(ProfileActions.SaveProfile)
    saveUserProfile({ dispatch }: StateContext<StateModel>, action: ProfileActions.SaveProfile) {
        const profile = this.store.selectSnapshot(ProfileState.getModel());
        return this.authService.saveUserProfile(profile).pipe(
            mergeMap(() =>
                dispatch([
                    new ProfileActions.SaveProfileSuccess(),
                    new NotificationActions.Success('Successfully updated profile'),
                    new AuthActions.UpdateCookie()
                ]),
            ),
            catchError(error => dispatch(new ProfileActions.SaveProfileFailure(error))),
        );
    }

    @Action(ProfileActions.UpdatePassword)
    updatePassword({ dispatch }: StateContext<StateModel>, action: ProfileActions.UpdatePassword) {

        return this.authService.changePassword(action.model).pipe(
            mergeMap(() =>
                dispatch([
                    new ProfileActions.UpdatePasswordSuccess(),
                    new NotificationActions.Success('Successfully updated password'),
                ]),
            ),
            catchError(error => dispatch(new ProfileActions.UpdatePasswordFailure(error))),
        );
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TrackByFunction,
} from '@angular/core';
import { MatSelectionListChange, MatSelectionList, MatListOption } from '@angular/material/list';
import { Node } from '@app/data/models';

import { NoteModel } from '@app/data/models';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgFor } from '@angular/common';
import { MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'admin-notes-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./notes-list.component.html`,
    styleUrls: [`./notes-list.component.scss`],
    standalone: true,
    imports: [
        FlexModule,
        MatFormField,
        MatLabel,
        MatInput,
        NgIf,
        MatIconButton,
        MatIcon,
        MatMiniFabButton,
        MatSelectionList,
        NgFor,
        MatListOption,
        MatDivider,
        MatProgressSpinner,
    ],
})
export class NotesListComponent implements OnChanges {
    filter: string | null = null;
    showAll = true;

    filteredNotes: NoteModel[] = [];

    @Input() node: Node | null = null;
    @Input() notes: NoteModel[] | null = null;
    @Input() isLoading: boolean | null = false;

    @Output() readonly add: EventEmitter<void> = new EventEmitter();
    @Output() readonly delete: EventEmitter<NoteModel> = new EventEmitter();
    @Output() readonly pin: EventEmitter<NoteModel> = new EventEmitter();
    @Output() readonly selectionChange: EventEmitter<NoteModel> = new EventEmitter();

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    onAdd() {
        this.add.emit();
    }

    onDelete(note: NoteModel) {
        this.delete.emit(note);
    }

    onUpdateFilter() {
        this.filteredNotes = (this.notes as NoteModel[]).filter(n => {
            if (!this.showAll && this.node && !n.nodeIds.includes(this.node.rowKey)) {
                return false;
            }

            if (this.filter) {
                return n.title.toLocaleLowerCase().trim().includes(this.filter.toLocaleLowerCase().trim());
            }

            return true;
        });

        this.changeDetectorRef.detectChanges();
    }

    trackBy(): TrackByFunction<NoteModel> {
        return (ix: number, note: NoteModel) => note?.rowKey;
    }

    onPin(note: NoteModel) {
        this.pin.emit(note);
    }

    onSelectionChange($event: MatSelectionListChange) {
        const note = $event.options.length > 0 ? $event.options[0].value : null;
        this.selectionChange.emit(note);
    }

    // onSelectionChange($event: MatSelectionListChange) {
    //     const note = $event.options.length > 0 ? $event.options[0].value : null;
    //     this.selectionChange.emit(note);
    // }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['notes'] || changes['node']) {
            this.notes = this.notes?.map(n => ({ ...n })) || [];

            if (changes['node'] && changes['node'].firstChange) {
                this.showAll = false;
            }

            this.onUpdateFilter();
        }
    }
}

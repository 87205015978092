import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    ChecklistLinkModel,
    ChecklistLinkTemplateModel,
    DeleteResult,
    LinkSessionSummary,
    QueryResult,
    SaveChecklistLinkModel,
    SearchOptionsModel,
    Submission,
} from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { LinkType } from '@app/shared/enums';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable({
    providedIn: 'root',
})
export class ManageChecklistLinksService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    getAllLinks(): Observable<ChecklistLinkModel[]> {
        return this.http
            .get<ServerResult<ChecklistLinkModel[]>>(`${this.env.serverUrl}/manage/checklists`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getArchivedLink(rowkey: string): Observable<ChecklistLinkModel> {
        return this.http
            .post<ServerResult<ChecklistLinkModel>>(`${this.env.serverUrl}/manage/checklists/archived`, { rowkey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveLink(model: SaveChecklistLinkModel): Observable<ChecklistLinkModel> {
        return this.http
            .post<ServerResult<ChecklistLinkModel>>(`${this.env.serverUrl}/manage/checklists`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinkSessions(rowKey: string): Observable<LinkSessionSummary[]> {
        return this.http
            .post<ServerResult<LinkSessionSummary[]>>(`${this.env.serverUrl}/manage/checklists/sessions`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllLinkSubmissions(rowKey: string): Observable<Submission[]> {
        return this.http
            .post<ServerResult<Submission[]>>(`${this.env.serverUrl}/manage/checklists/submissions`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    activateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/activate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    syncActiveSessions(rowKey: string): Observable<ChecklistLinkModel> {
        return this.http
            .post<ServerResult<ChecklistLinkModel>>(`${this.env.serverUrl}/manage/sessions/sync`, {
                rowKeys: [rowKey],
                linkType: LinkType.Checklist,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    updateLinkCounts(rowKey: string): Observable<ChecklistLinkModel> {
        return this.http
            .post<ServerResult<ChecklistLinkModel>>(
                `${this.env.serverUrl}/manage/checklists/sessions/update-count`,
                JSON.stringify(rowKey),
            )
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deactivateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/checklists/sessions/deactivate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    archiveLinks(links: ChecklistLinkModel[]): Observable<DeleteResult> {
        return this.http
            .post<ServerResult<DeleteResult>>(
                `${this.env.serverUrl}/manage/checklists/archive`,
                links.map(m => m.rowKey),
            )
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteLinks(links: ChecklistLinkModel[]): Observable<DeleteResult> {
        return this.http
            .request<ServerResult<DeleteResult>>('DELETE', `${this.env.serverUrl}/manage/checklists`, {
                body: links.map(m => m.rowKey),
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    approveSession(rowKey: string, formSubmissionRowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/checklists/sessions/approve`, {
                rowKey,
                formSubmissionRowKeys,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteSessions(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/checklists/sessions/delete`, rowKeys)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    mergeSessions(linkId: string, primary: string, rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/merge`, { rowKeys, primary, linkId })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    searchArchivedLinks(model: SearchOptionsModel): Observable<QueryResult<ChecklistLinkModel>> {
        return this.http
            .post<ServerResult<QueryResult<ChecklistLinkModel>>>(
                `${this.env.serverUrl}/manage/checklists/search-archived`,
                model,
            )
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}
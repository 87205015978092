import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaymentModel } from '@app/data/models';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'ui-view-payment',
    templateUrl: './view-payment.component.html',
    styleUrls: ['./view-payment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormatDatePipe, CurrencyPipe],
})
export class ViewPaymentComponent {
    @Input() payment: PaymentModel | null = null;
}

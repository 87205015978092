import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { HomeState } from '@app/admin/state/home.state';
import { AuthState } from '@app/data/state/auth.state';
import { Store } from '@ngxs/store';

import { AdminActions } from '../../actions';
import { AdminActionsComponent } from '../../components/admin-actions/admin-actions.component';
import { IsAdminPipe } from '../../pipes/is-admin.pipe';
import { AdminState } from '../../state/admin.state';

@Component({
    selector: 'admin-admin-actions-dialog',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <admin-admin-actions
            [isPending]="isLoading$ | async"
            [settings]="settings$ | async"
            (executeAction)="onExecuteAction($event)"
            *ngIf="currentUser$ | async | isAdmin"
        ></admin-admin-actions>
    `,
    standalone: true,
    imports: [
        NgIf,
        AdminActionsComponent,
        IsAdminPipe,
        AsyncPipe,
    ],
})
export class AdminActionsDialogComponent implements OnInit {
    currentUser$ = this.store.select(AuthState.getCurrentUser());
    isLoading$ = this.store.select(AdminState.isLoading());
    settings$ = this.store.select(AdminState.getSettings());

    nodes$ = this.store.select(HomeState.getNodes());

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch([new AdminActions.LoadSettings()]);
    }

    onExecuteAction(action: string): void {
        this.store.dispatch(new AdminActions.ExecuteAction(action));
    }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './delete-link-session-confirmation-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            :host {
                width: 600px;
                display: block;
            }

            mat-dialog-content {
                height: 100px;
            }
        `,
    ],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ],
})
export class DeleteLinkSessionsConfirmationDialogComponent {
    get count(): number {
        return this.data.length;
    }

    constructor(@Inject(MAT_DIALOG_DATA) private data: any) {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { EncodingJob } from '@app/data/models';
import { EncodingStatus, MediaState, MediaType } from '@app/shared/enums';
import { DestroyService } from '@app/shared/services';
import { isNullOrEmpty } from '@app/shared/util';
import { start } from 'repl';
import {
    interval,
    takeUntil,
    filter,
    mergeMap,
    from,
    of,
    tap,
    map,
    catchError,
    AsyncSubject,
    startWith,
    Subject,
    switchMap,
} from 'rxjs';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf, NgFor, PercentPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'admin-toastr-encoding-job',
    templateUrl: `./toastr-encoding-job.component.html`,
    styleUrls: [`./toastr-encoding-job.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
    imports: [
        ExtendedModule,
        NgClass,
        MatIcon,
        NgIf,
        NgFor,
        MatProgressBar,
        MatButton,
        PercentPipe,
    ],
})
export class ToastrEncodingJobComponent implements OnInit {
    MediaType: typeof MediaType = MediaType;
    EncodingStatus: typeof EncodingStatus = EncodingStatus;

    completed$ = new AsyncSubject<void>();
    digest$ = new Subject<void>();
    isCancelling = false;
    statusUrl = 'https://api.qencode.com/v1/status';
    response: ApiResponse | null = null;
    encodingJob: EncodingJob | null = null;
    cancelFn: () => void = () => {};
    completedFn: () => void = () => {};
    copyFn: (message: string) => void = () => {};

    get state() {
        if (this.response === null || this.response.error !== 0 || this.response.statuses['null']) {
            return null;
        }

        return this.response.statuses[this.encodingJob?.providerUniqueId as string];
    }

    get isCompleted() {
        if (this.encodingJob) {
            return this.encodingJob.status === EncodingStatus.Finished;
        }
        return false;
    }

    get downloadProgress() {
        if (this.state) {
            return this.state.progress?.downloading || 0;
        }
        return;
    }

    get encodingProgress() {
        if (this.state) {
            return this.state.progress?.encoding || 0;
        }
        return;
    }

    get savingProgress() {
        if (this.state) {
            return this.state.progress?.saving || 0;
        }
        return;
    }

    get isError() {
        if (this.encodingJob) {
            return this.encodingJob.status === EncodingStatus.Error;
        }
        return false;
    }

    get title() {
        if (this.encodingJob) {
            return this.encodingJob.name;
        }
        return '';
    }

    get message() {
        if (this.isCancelling) {
            return 'Cancelling...';
        }

        if (this.response) {
            switch (this.state?.status) {
                case 'queued':
                    return 'Queued';
                case 'encoding':
                    return `Encoding ${this.encodingProgress}%`;
                case 'downloading':
                    return `Downloading ${this.downloadProgress}%`;
                case 'saving':
                    // return `Saving ${this.downloadProgress}%`;
                    return `Saving`;
                case 'error':
                    return 'Error: ' + this.state.error_description;
            }
        }

        switch (this.assetState) {
            case EncodingStatus.Finished:
                return 'Finished';
            case EncodingStatus.Processing:
                return 'Processing';
            case EncodingStatus.Queued:
                return 'Queued';
            case EncodingStatus.Cancelled:
                return 'Cancelled';
            case EncodingStatus.Error: {
                const payload = this.encodingJob?.providerPayload as any;
                return payload.error_description;
            }
        }

        return 'Unknown';
    }

    get progress() {
        if (this.isCompleted) {
            return 100;
        }

        if (this.encodingJob) {
            return this.encodingJob.progress;
        }
        return 0;
    }

    get assetState() {
        if (this.encodingJob) {
            return this.encodingJob.status;
        }
        return MediaState.None;
    }

    get mediaType() {
        return MediaType.Video;
    }

    get isCancelEnabled() {
        // if (this.state && !this.isCancelling) {
        //     const cancellableStates = [MediaState.Scheduled, MediaState.Processing, MediaState.Queued, MediaState.Error];
        //     return cancellableStates.includes(this.state.state);
        // }

        return false;
    }

    constructor(private destroy$: DestroyService, private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.digest$
            .pipe(
                takeUntil(this.destroy$),
                filter(() => !isNullOrEmpty(this.encodingJob?.providerUniqueId)),
                switchMap(() => {
                    const body = new FormData();
                    body.append('task_tokens', this.encodingJob?.providerUniqueId as string);

                    return from(fetch(this.statusUrl, { method: 'POST', body })).pipe(
                        takeUntil(this.destroy$),
                        takeUntil(this.completed$),
                        mergeMap(response => {
                            if (response.ok) {
                                return response.json();
                            }

                            return of(null);
                        }),
                        tap((response: ApiResponse | null) => {
                            if (response !== null) {
                                this.response = response;
                                this.updateStatusUrl();
                            }

                            this.cd.detectChanges();
                        }),
                        tap(() => {
                            if (this.state?.status === 'completed') {
                                this.completed$.next();
                                this.completed$.complete();
                            }
                        }),
                        mergeMap(() =>
                            interval(5000).pipe(
                                startWith(0),
                                takeUntil(this.destroy$),
                                takeUntil(this.completed$),
                                mergeMap(() => {
                                    const body = new FormData();
                                    body.append('task_tokens', this.encodingJob?.providerUniqueId as string);

                                    return from(fetch(this.statusUrl, { method: 'POST', body }));
                                }),
                                mergeMap(response => {
                                    if (response.ok) {
                                        return response.json();
                                    }

                                    return of(null);
                                }),
                                tap((response: ApiResponse | null) => {
                                    if (response !== null) {
                                        this.response = response;
                                        this.updateStatusUrl();
                                    }

                                    this.cd.detectChanges();
                                }),
                                tap(() => {
                                    if (this.state?.status === 'completed') {
                                        this.completed$.next();
                                        this.completed$.complete();
                                    }
                                }),
                                catchError(err => {
                                    // reset
                                    this.statusUrl = 'https://api.qencode.com/v1/status';
                                    this.digest$.next(void 0);

                                    return of(null);
                                }),
                            ),
                        ),
                    );
                }),
            )
            .subscribe();

        this.digest$.next(void 0);
    }

    updateStatusUrl() {
        if (this.response) {
            this.statusUrl = this.response.statuses[this.encodingJob?.providerUniqueId as string].status_url;
        }
    }

    registerCancel(cancel: () => void): void {
        this.cancelFn = cancel;
    }

    registerCompleted(completedFn: () => void): void {
        this.completedFn = completedFn;
    }

    registerCopy(copyFn: (message: string) => void): void {
        this.copyFn = copyFn;
    }

    updateProgress(state: EncodingJob): void {
        this.encodingJob = state;
        this.digest$.next(void 0);
        this.cd.detectChanges();
    }

    markAsCompleted() {
        if (this.isCancelling) {
            this.onCompleted();
            return;
        }

        this.cd.detectChanges();
    }

    onCancel(): void {
        this.isCancelling = true;
        this.cancelFn();
        this.cd.detectChanges();
    }

    onCompleted(): void {
        this.completedFn();
        this.cd.detectChanges();
    }

    onDismiss(): void {
        this.cd.detectChanges();
    }

    onCopyErrorMessage(): void {
        this.copyFn(this.message);
    }
}

interface ApiResponse {
    error: number;
    statuses: {
        [taskToken: string]: {
            status: string;
            percent: number;
            error: number;
            error_description: string | null;
            warnings: string[] | null;
            images: any[];
            videos: VideoModel[];
            audios: any[];
            texts: any[];
            progress: {
                downloading: number;
                encoding: number;
                saving: number;
            };
            duration: number | string;
            source_size: number | string;
            api_version: string;
            status_url: string;
        };
    };
}

interface VideoModel {
    tag: string;
    profile: string | null;
    user_tag: string | null;
    storage: {
        url: string;
        playlist: string;
        type: string;
        format: string;
        expire: string | null;
        timestamp: string;
    };
    url: string;
    bitrate: number;
    meta: {
        resolution_width: number;
        resolution_height: number;
        framerate: string;
        height: number;
        width: number;
        codec: string;
        dar: string;
        aspect_ratio: number;
        sar: string;
        bitrate: string;
        audio_codec: string;
    };
    duration: string;
    size: string;
    output_format: string;
    percent: number;
    status: string;
    error: boolean;
    error_description: string | null;
}

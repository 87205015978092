import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { CurrentUser, SearchResult } from '@app/data/models';
import { Layout, SearchResultType } from '@app/shared/enums';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, KeyValuePipe } from '@angular/common';
import { MatBadge } from '@angular/material/badge';
import { MatInput } from '@angular/material/input';
import { SearchResultsDirective } from './search-results.directive';
import { SearchResultsComponent } from './search-results.component';
import { MatList, MatListItem, MatListItemIcon, MatListItemLine, MatListSubheaderCssMatStyler } from '@angular/material/list';
import { SearchResultItemDirective } from './search-result-item.directive';
import { AnyPipe } from '../../../../../shared/src/lib/pipes/any.pipe';
import { HighlightPipe } from '../../../../../shared/src/lib/pipes/highlight.pipe';
import { NodeTypeIconPipe } from '../../../../../shared/src/lib/pipes/node-type-icon.pipe';

const DEBOUNCE_IN_MS = 300;

@Component({
    selector: 'ui-search',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: true,
    imports: [
    MatToolbar,
    MatToolbarRow,
    MatIconButton,
    MatIcon,
    ExtendedModule,
    NgClass,
    MatBadge,
    ReactiveFormsModule,
    MatInput,
    SearchResultsDirective,
    SearchResultsComponent,
    MatList,
    MatListItem,
    SearchResultItemDirective,
    MatListItemIcon,
    MatListItemLine,
    MatListSubheaderCssMatStyler,
    AnyPipe,
    HighlightPipe,
    NodeTypeIconPipe,
    KeyValuePipe
],
})
export class SearchComponent implements OnInit, OnDestroy {
    private isAlive$: Subject<void> = new Subject();

    Layout: typeof Layout = Layout;
    SearchResultType: typeof SearchResultType = SearchResultType;

    form: FormGroup = new FormGroup({
        search: new FormControl(''),
    });

    get search(): AbstractControl {
        return this.form.controls['search'];
    }

    @Input() showBadge: boolean | null = false;
    @Input() badgeColor: string | null = '';
    @Input() badgeText: string | null = '';
    @Input() isSearchEnabled: boolean | null = true;
    @Input() showSearch: boolean | null = true;
    @Input() currentUser: CurrentUser | null = null;
    @Input() searchResults: SearchResult[] | null = null;

    @Output() readonly searchText: EventEmitter<string> = new EventEmitter();
    @Output() readonly selectNode: EventEmitter<SearchResult> = new EventEmitter();
    @Output() readonly toggleNavigation: EventEmitter<void> = new EventEmitter();

    onSelectNode(result: SearchResult): void {
        this.selectNode.emit(result);
    }

    ngOnInit(): void {
        this.search.valueChanges
            .pipe(
                takeUntil(this.isAlive$),
                debounceTime(DEBOUNCE_IN_MS),
                tap(s => {
                    this.searchText.emit(s);
                }),
            )
            .subscribe();
    }

    onToggleNavigation(): void {
        this.toggleNavigation.emit();
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter, finalize, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { NodeType } from '@app/shared/enums';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

import { HomeActions, ManageNodeActions } from '../actions';
import { HomeState } from '../state/home.state';

export const ViewNodeResolver: ResolveFn<Observable<string | boolean | null>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
) => {
    store.dispatch([new CoreActions.UpdateGlobalLoading(true), new ManageNodeActions.EditNode(null)]);

    const isEdit = route.url.some(seg => seg.path === 'edit');

    return store.select(HomeState.hasLoadedNodes()).pipe(
        filter(hasLoaded => hasLoaded),
        take(1),
        withLatestFrom(store.select(HomeState.getCurrentNode(route))),
        mergeMap(([, node]) => {
            const actions = [];
            if (node) {
                if (!isEdit && node.type === NodeType.Node) {
                    store.dispatch(new Navigate(['/home']));
                    return of(false);
                }

                if (!node.isLoaded && !node.isLoading) {
                    actions.push(new HomeActions.LoadNodeContent([node]));
                }

                return store.dispatch(actions).pipe(
                    take(1),
                    mergeMap(() => {
                        if (isEdit) {
                            const editNode = store.selectSnapshot(HomeState.getCurrentNode(route));
                            return store.dispatch(new ManageNodeActions.EditNode(editNode));
                        }

                        return of(null);
                    }),
                    map(() => node?.title),
                );
            }

            return of(null);
        }),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};

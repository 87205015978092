@if (intakeForms | any) {
  <dl class="link-entry" fxFlex>
    @for (submission of intakeForms; track submission) {
      @if (submission.submissionKey) {
        <dt class="submission-date">Submission Date</dt>
        <dd>
          {{ submission.submissionKey | submissionDate | formatDate }}
        </dd>
      }
      @for (q of submission.questions; track q; let ix = $index) {
        <dt>
          {{ q.question }}
        </dt>
        <dd>{{ q.answer }}</dd>
      }
    }
  </dl>
} @else {
  No Submissions
}


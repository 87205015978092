import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { filter, finalize, mergeMap, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageLinksActions, ManageTemplateActions } from '../actions';
import { ManageLinksState } from '../state/manage-links.state';
import { ManageTemplatesState } from '../state/manage-templates.state';

export const EditShareableLinkResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    const { linkId } = route.params;

    store.dispatch([
        new CoreActions.UpdateGlobalLoading(true),
        new ManageLinksActions.EnsureLoadLinks(),
        new ManageTemplateActions.EnsureLoadChecklistTemplates(),
        new ManageTemplateActions.EnsureLoadPreambleTemplates(),
        new ManageLinksActions.LoadGuidedSequenceSummariesForLink(linkId),
    ]);

    return combineLatest({
        hasLinksLoaded: store.select(ManageLinksState.hasLoadedLinks()).pipe(
            filter(loaded => loaded),
            take(1),
        ),
        preambleTemplates: store.select(ManageTemplatesState.hasLoadedPreambleTemplates()).pipe(
            filter(loaded => loaded),
            take(1),
        ),
        checklistTemplates: store.select(ManageTemplatesState.hasLoadedChecklistTemplates()).pipe(
            filter(loaded => loaded),
            take(1),
        ),
    }).pipe(
        take(1),
        mergeMap(() => store.dispatch(new ManageLinksActions.LoadLink(linkId))),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};
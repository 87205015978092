import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { map, take, withLatestFrom } from 'rxjs/operators';

import { AuthState } from '@app/data/state/auth.state';
import { isNullOrEmpty } from '@app/shared/util';
import { Store } from '@ngxs/store';

export const LoginResolver: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
    location: Location = inject(Location),
    router: Router = inject(Router),
) => {
    return store.select(AuthState.isAuthenticated()).pipe(
        take(1),
        withLatestFrom(store.select(AuthState.getReturnUrl())),
        map(([isLoggedIn, returnUrl]) => {
            if (isLoggedIn) {
                if (!isNullOrEmpty(returnUrl) && returnUrl !== location.path()) {
                    return router.parseUrl(returnUrl as string);
                }

                return router.parseUrl('/');
            }

            return true;
        }),
    );
};
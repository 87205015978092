import { Injector, Type } from '@angular/core';
import { mergeAttributes, Node as TipTapNode } from '@tiptap/core';
import { AngularNodeViewComponent, AngularNodeViewRenderer } from 'ngx-tiptap';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        inlineAudio: {
            setAudioDisplay: (options: { display: 'default' | 'inline' }) => ReturnType;
        };
    }
}

export const createAudioExtension = (injector: Injector, component: Type<AngularNodeViewComponent>) => {
    return TipTapNode.create({
        name: 'inlineAudio', // unique name for the Node
        inline: false,
        group: 'block', // belongs to the 'block' group of extensions
        selectable: true, // so we can select the video
        draggable: true, // so we can drag the video
        atom: true, // is a single unit

        // inline: true,
        // content: 'inline*',
        // group: 'inline', // belongs to the 'block' group of extensions
        // // selectable: true, // so we can select the video
        // // draggable: true, // so we can drag the video
        // // atom: true, // is a single unit

        addOptions() {
            return {
                display: ['default','inline'],
                HTMLAttributes: {
                    id: null,
                    class: null,
                    preload: 'none',
                    file: null,
                    src: null,
                    size: null,
                },
            };
        },

        addStorage() {
            return {
                file: null,
            };
        },

        addAttributes() {
            return {
                id: {
                    default: null,
                },
                file: {
                    default: null,
                    rendered: false,
                },
                type: {
                    default: null,
                },
                src: {
                    default: null,
                },
                controls: {
                    default: 'controls',
                },
                class: {
                    default: null,
                },
                title: {
                    default: null,
                },
                display: {
                    default: 'inline',
                },
                controlsList: {
                    default: 'nodownload',
                },
                preload: {
                    default: 'none',
                },
            };
        },

        parseHTML() {
            return [{ tag: 'div[data-upload-audio] audio' }];
        },

        renderHTML({ HTMLAttributes }) {
            return [
                'div',
                { 'data-upload-audio': '', style: HTMLAttributes['style'] },
                ['audio', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
            ];
        },

        addNodeView() {
            return AngularNodeViewRenderer(component, { injector });
        },

        addCommands() {
            return {
                setAudioDisplay:
                    (options: { display: string }) =>
                    ({ tr, dispatch, commands, chain, view, state }) => {
                        return chain().updateAttributes(this.name, { display: options.display }).run();
                    },
            };
        },
    });
};

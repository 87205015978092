import { Injector, Type } from '@angular/core';

import { mergeAttributes, Node as TipTapNode } from '@tiptap/core';
import { AngularNodeViewComponent, AngularNodeViewRenderer } from 'ngx-tiptap';

export type GuestLinkOptions = {
    HTMLAttributes: Record<string, any>;
};
declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        guestLink: {
            insertGuestLink: (options: {
                showJoinButton?: boolean;
                showJoinUrl?: boolean;
                showCopyLinkButton?: boolean;
                showCopyPasswordButton?: boolean;
                zoomId?: string;
            }) => ReturnType;

            updateGuestLink: (options: {
                showJoinButton?: boolean;
                showJoinUrl?: boolean;
                showCopyLinkButton?: boolean;
                showCopyPasswordButton?: boolean;
                zoomId?: string;
            }) => ReturnType;
        };
    }
}

export const createGuestLinkExtension = (injector: Injector, component: Type<AngularNodeViewComponent>) => {
    return TipTapNode.create<GuestLinkOptions>({
        name: 'guestLink',
        inline: false,
        group: 'block',
        selectable: true,
        draggable: true,
        atom: true,

        addOptions() {
            return {
                HTMLAttributes: {},
            };
        },

        addAttributes() {
            return {
                showJoinButton: {
                    default: false,
                },
                showJoinUrl: {
                    default: true,
                },
                showCopyLinkButton: {
                    default: false,
                },
                showCopyPasswordButton: {
                    default: false,
                },
                zoomId: {
                    default: '',
                },
            };
        },

        parseHTML() {
            return [{ tag: `[data-type="${this.name}"]` }];
        },

        renderHTML({ node, HTMLAttributes }) {
            return [
                'div',
                mergeAttributes(this.options.HTMLAttributes, {
                    // ...HTMLAttributes,
                    showJoinButton: HTMLAttributes['showJoinButton'],
                    showJoinUrl: HTMLAttributes['showJoinUrl'],
                    showCopyLinkButton: HTMLAttributes['showCopyLinkButton'],
                    showCopyPasswordButton: HTMLAttributes['showCopyPasswordButton'],
                    zoomId: HTMLAttributes['zoomId'],

                    style: HTMLAttributes['style'],
                    'data-type': this.name,
                }),
            ];
        },

        addNodeView() {
            return AngularNodeViewRenderer(component, { injector });
        },

        addCommands() {
            return {
                insertGuestLink:
                    options =>
                    ({ tr, dispatch, editor }) => {
                        const { selection } = tr;
                        const node = this.type.create(options);

                        if (dispatch) {
                            tr.replaceRangeWith(selection.from, selection.to, node);
                        }

                        return true;
                    },

                updateGuestLink:
                    options =>
                    ({ chain, tr }) => {
                        const config: Record<string, boolean | string> = {};
                        const node = (tr as any).curSelection?.node;

                        if (!node || node.type.name !== this.name) {
                            return false;
                        }

                        const { attrs } = node;

                        if (options.showJoinButton !== undefined) {
                            config['showJoinButton'] = !(attrs['showJoinButton'] === true);
                        }

                        if (options.showJoinUrl !== undefined) {
                            config['showJoinUrl'] = !(attrs['showJoinUrl'] === true);
                        }

                        if (options.showCopyLinkButton !== undefined) {
                            config['showCopyLinkButton'] = !(attrs['showCopyLinkButton'] === true);
                        }

                        if (options.showCopyPasswordButton !== undefined) {
                            config['showCopyPasswordButton'] = !(attrs['showCopyPasswordButton'] === true);
                        }

                        if (options.zoomId !== undefined) {
                            config['zoomId'] = options.zoomId;
                        }

                        return chain().updateAttributes(this.name, config).run();
                    },
            };
        },
    });
};

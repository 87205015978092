import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

import { AuthActions } from '@app/data/actions';
import { AuthState } from '@app/data/state/auth.state';
import { parseJwt } from '@app/shared/util';
import { Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';

export const ProtectedGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
    router: Router = inject(Router),
    location: Location = inject(Location),
    cookieService: CookieService = inject(CookieService),
): Observable<boolean | UrlTree> => {
    const allowedRoles = route.data['roles'] || null;

    return store.select(AuthState.isReady()).pipe(
        filter(ready => ready === true),
        take(1),
        withLatestFrom(
            store.select(AuthState.isAuthenticated()),
            store.select(AuthState.getReturnUrl()),
            store.select(AuthState.getToken()),
        ),
        map(([, isLoggedIn, returnUrl, token]) => {
            const jwt = isLoggedIn && token?.token ? parseJwt(token.token) : null;
            const key = Object.keys(jwt || {}).find(s => s.endsWith('role')) || '';
            const role = isLoggedIn ? jwt[key] : 0;
            const path = location.path();

            if (!isLoggedIn || (allowedRoles && (role & allowedRoles) === 0) === true) {
                /**
                 * If the returnUrl is equal to the location path, it means that we've already tried
                 * to login to the page but was denied already. Prevents redirect loops.
                 */
                if (returnUrl === path) {
                    store.dispatch([new AuthActions.UpdateReturnUrl(null)]);
                    return router.parseUrl('/');
                }

                const loginUrl = jwt?.login || `/login/${cookieService.get('l')}`;
                store.dispatch([new AuthActions.UpdateReturnUrl(path)]);
                router.navigateByUrl(loginUrl);
                return false;
            }

            if (!path.includes('login') && isLoggedIn) {
                store.dispatch([new AuthActions.UpdateReturnUrl(path)]);
            }

            return true;
        }),
    );
};

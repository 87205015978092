import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'any',
    standalone: true,
})
export class AnyPipe implements PipeTransform {
    transform(value: any): boolean {
        return value && value.length > 0;
    }
}

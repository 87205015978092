<h2 matDialogTitle>Choose an audio/video</h2>
<mat-dialog-content>
    <div [formGroup]="form">
        <admin-media-asset-list
            formControlName="mediaAssetId"
            [mediaAssets]="mediaAssets$ | async"
            [allowDelete]="false"
            [allowRename]="false"
            [allowRecord]="false"
            [allowUpload]="false"
            [allowDownload]="false"
            (preview)="onPreviewMediaAsset($event)"
        >
        </admin-media-asset-list>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSave()">Save</button>
</mat-dialog-actions>

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { finalize, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { ShareableLinkModel } from '@app/data/models';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';

import { ManageLinksActions } from '../actions';
import { ManageLinksState } from '../state/manage-links.state';

export const ViewArchivedLinkResolver: ResolveFn<Observable<any>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store = inject(Store),
    actions = inject(Actions),
) => {
    const rowKey = route.params?.['linkId'];

    store.dispatch([new CoreActions.UpdateGlobalLoading(true)]);

    return store.dispatch([new ManageLinksActions.LoadArchivedLink(rowKey)]).pipe(
        take(1),
        withLatestFrom(store.select(ManageLinksState.getArchivedLink())),
        mergeMap(([, model]) => {
            const link = model as ShareableLinkModel;

            if (link?.checklistTemplateIds?.length > 0) {
                store.dispatch([
                    ...link.checklistTemplateIds.map(id => new ManageLinksActions.LoadChecklist(`${rowKey}.${id}`)),
                ]);

                return actions.pipe(
                    ofActionCompleted(ManageLinksActions.LoadChecklistSuccess, ManageLinksActions.LoadChecklistFailure),
                    take(link.checklistTemplateIds.length),
                );
            }

            return of(link);
        }),
        finalize(() =>
            store.dispatch([new CoreActions.UpdateGlobalLoading(false), new ManageLinksActions.LoadArchivedLinkSessions()]),
        ),
    );
};

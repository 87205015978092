import { Directive, ElementRef } from '@angular/core';

@Directive({
    exportAs: 'el',
    selector: '[el]',
    standalone: true,
})
export class ElementDirective {
    constructor(public el: ElementRef) {}
}

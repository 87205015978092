import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { APP_TIPTAP_EDITOR_CONFIG } from '@app/shared/tokens';
import { Editor, EditorOptions } from '@tiptap/core';
import { NgxTiptapModule } from 'ngx-tiptap';

@Component({
    selector: 'ui-rich-text-view',
    template: `<div class="ProseMirror" unselectable="on" tiptap [editor]="editor"></div> `,
    styles: [
        `
            :host {
                display: block;
                background-color: #fff;
            }
        `,
    ],
    standalone: true,
    imports: [NgxTiptapModule],
})
export class RichTextViewComponent implements OnChanges {
    editor: Editor;

    @Input() content: string | undefined | null = null;

    constructor(@Inject(APP_TIPTAP_EDITOR_CONFIG) config: EditorOptions) {
        this.editor = new Editor({ ...config, editable: false });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.editor.commands.setContent(this.content as any);
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'submissionDate',
    standalone: true,
})
export class SubmissionDatePipe implements PipeTransform {
    transform(value: string): Date | null {
        const regexResult = /(\d\d)(\d\d)(\d\d\d\d)/.exec(value);
        const submissionDate = regexResult
            ? new Date(parseInt(regexResult[3], 10), parseInt(regexResult[1], 10) - 1, parseInt(regexResult[2], 10))
            : null;
        return submissionDate;
    }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageFormsActions, ManageTemplateActions } from '../actions';

export const EditFormLinkResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    const { linkId } = route.params;

    return store
        .dispatch([
            new CoreActions.UpdateGlobalLoading(true),
            new ManageTemplateActions.EnsureLoadPreambleTemplates(),
            new ManageFormsActions.LoadLink(linkId),
            new ManageFormsActions.LoadTags(),
        ])
        .pipe(finalize(() => store.dispatch([new CoreActions.UpdateGlobalLoading(false)])));
};

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageRequestActions, ManageTemplateActions } from '../actions';

export const LinkRequestsResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    return store
        .dispatch([
            new CoreActions.UpdateGlobalLoading(true),
            new ManageRequestActions.EnsureLoadLinks(),
            new ManageTemplateActions.EnsureLoadPreambleTemplates(),
            new ManageRequestActions.UpdateSessionCount('linkrequest'),
        ])
        .pipe(
            take(1),
            finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
        );
};

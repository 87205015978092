import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, filter, finalize, mergeMap, take, tap } from 'rxjs/operators';

import { CoreActions, NotificationActions } from '@app/data/actions';
import {
    ActiveLinkSummary,
    ArchivedLinkContentModel,
    ChecklistTemplate,
    EditShareableLinkFormModel,
    FormState,
    GsVideoSummary,
    LinkSessionSummary,
    PaymentModel,
    PreambleTemplate,
    QueryResult,
    SearchOptionsModel,
    ShareableLinkModel,
    ShareableLinkTemplateModel,
} from '@app/data/models';
import { handleNotFoundError, transformResponseToText } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { RouterState } from '@app/data/state/router.state';
import {
    BlobContainer,
    DayOfWeek,
    LinkExpiration,
    LinkInactivityMode,
    LinkStatus,
    LinkType,
    PublicAccessMode,
    RegistrationMode,
    TimerUnit,
    VideoOption,
} from '@app/shared/enums';
import { Environment, isErrorModel } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import {
    convertObjectKeysToCamelCase,
    DEFAULT_TIME_ZONE,
    formatDatetimeWithTimeZone,
    formatLinkUrl,
    generatePassword,
    isNullOrEmpty,
} from '@app/shared/util';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Actions, createSelector, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';
import * as localforage from 'localforage';
import { Timezone } from 'timezones.json';

import { ManageLinksActions, ManageTemplateActions } from '../actions';
import { ArchiveContentLinkConfirmationDialogComponent } from '../dialogs/archive-content-link-confirmation/archive-content-link-confirmation-dialog.component';
import { ManageShareableLinksService } from '../services/manage-shareable-links.service';
import { ManageTemplatesState } from './manage-templates.state';
import { DeleteLinkSessionsConfirmationDialogComponent } from '../dialogs/delete-link-session-confirmation/delete-link-session-confirmation-dialog.component';
import { DeleteShareableLinkConfirmationDialogComponent } from '../dialogs/delete-shareable-link-confirmation/delete-shareable-link-confirmation-dialog.component';
import { ExtendLinkDialogComponent } from '../dialogs/extend-link-dialog/extend-link-dialog.component';
import { ExtendSessionDialogComponent } from '../dialogs/extend-session-dialog/extend-session-dialog.component';
import { MergeLinkSessionsConfirmationDialogComponent } from '../dialogs/merge-link-sessions-confirmation/merge-link-sessions-confirmation-dialog.component';

export interface StateModel {
    checklistContent: Record<string, string | null | undefined>;

    hasLoadedChecklistTemplates: boolean;
    hasLoadedShareableLinkTemplates: boolean;
    shareableLinkTemplates: ShareableLinkTemplateModel[];

    isLoadingTemplates: boolean;
    isLoadingChecklist: boolean;

    summary: ActiveLinkSummary | null;
    gsVideoSummaries: GsVideoSummary[];

    isSavingChecklist: boolean;

    hasLoadedTemplates: boolean;
    hasLoadedLinks: boolean;
    hasLoadedArchivedLinks: boolean;
    hasLoadedTags: boolean;

    isLoadingLinks: boolean;
    isLoadingGsSummaries: boolean;
    isLoadingArchivedLinks: boolean;
    isSearching: boolean;
    isSaving: boolean;
    searchArchivedLinksQueryResult: QueryResult<ShareableLinkModel> | null;
    errors: any;
    tags: string[];
    links: ShareableLinkModel[];
    archivedContent: ArchivedLinkContentModel | null;
    archivedLinks: ShareableLinkModel[];
    archivedLink: ShareableLinkModel | null;
    linkSessions: LinkSessionSummary[];
    linkPayments: PaymentModel[];
    editLink: ShareableLinkModel | null;
    copyLink: ShareableLinkModel | null;
    currentTabIndex: number;
    searchArchivedLinksOptions: SearchOptionsModel | null;
    form: FormState<EditShareableLinkFormModel>;
    searchArchivedLinks: FormState<SearchOptionsModel>;
    questions: FormState<Record<string, any>>;
    filters: FormState<Record<string, any>>;
    archivedFilters: FormState<Record<string, any>>;
    templateFilters: FormState<Record<string, any>>;
    sessionFilters: FormState<Record<string, any>>;
    checklistTemplate: FormState<{ rowKey: string; name: string }>;
}

@State<StateModel>({
    name: 'manageLinks',
    defaults: {
        checklistContent: {},

        hasLoadedChecklistTemplates: false,

        hasLoadedShareableLinkTemplates: false,
        shareableLinkTemplates: [],

        isLoadingTemplates: false,
        isLoadingChecklist: false,

        summary: null,
        isLoadingGsSummaries: false,
        gsVideoSummaries: [],

        isSavingChecklist: false,

        hasLoadedTemplates: false,
        hasLoadedLinks: false,
        hasLoadedArchivedLinks: false,
        hasLoadedTags: false,
        isLoadingLinks: false,
        isLoadingArchivedLinks: false,
        isSearching: false,
        isSaving: false,
        searchArchivedLinksQueryResult: null,
        searchArchivedLinksOptions: { filter: '', tags: [], total: null, limit: 25, offset: 0 },
        errors: null,
        tags: [],
        links: [],
        archivedContent: null,
        archivedLinks: [],
        archivedLink: null,
        linkSessions: [],
        linkPayments: [],
        editLink: null,
        copyLink: null,
        currentTabIndex: 0,
        form: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        searchArchivedLinks: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        questions: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        filters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        archivedFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        templateFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        sessionFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        checklistTemplate: {
            model: { rowKey: 'new', name: '' },
            status: '',
            dirty: false,
            errors: null,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class ManageLinksState {
    readonly format: string = 'MMM DD YYYY @ hh:mmA';

    static hasLoadedLinks() {
        return createSelector([ManageLinksState], (state: StateModel) => state.hasLoadedLinks);
    }

    static hasLoadedArchivedLinks() {
        return createSelector([ManageLinksState], (state: StateModel) => state.hasLoadedArchivedLinks);
    }

    static hasLoadedChecklistTemplates() {
        return createSelector([ManageLinksState], (state: StateModel) => state.hasLoadedChecklistTemplates);
    }

    static hasLoadedShareableLinkTemplates() {
        return createSelector([ManageLinksState], (state: StateModel) => state.hasLoadedShareableLinkTemplates);
    }

    static isLoading() {
        return createSelector([ManageLinksState], (state: StateModel) => state.isLoadingLinks);
    }

    static isLoadingArchivedLinks() {
        return createSelector([ManageLinksState], (state: StateModel) => state.isLoadingArchivedLinks);
    }

    static currentTabIndex() {
        return createSelector([ManageLinksState], (state: StateModel) => state.currentTabIndex);
    }

    static getLinkId() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId);
    }

    static getEditModel() {
        return createSelector([ManageLinksState], (state: StateModel) => state.editLink);
    }

    static getChecklistContentForLink() {
        return createSelector(
            [
                ManageLinksState.getLinkId(),
                ManageLinksState.getChecklistContent(),
                ManageTemplatesState.getChecklistTemplates(),
            ],
            (
                linkId: string,
                checklistContent: Record<string, string | null | undefined>,
                templates: ChecklistTemplate[],
            ) => {
                return templates.reduce((acc, current) => {
                    const rowKey = current.rowKey as string;
                    const content = checklistContent[`${linkId}.${rowKey}`] || current.content;

                    acc.push({ ...current, content });

                    return acc;
                }, [] as ChecklistTemplate[]);
            },
        );
    }

    static getPreambleContentForLink() {
        return createSelector(
            [ManageTemplatesState.getPreambleTemplates(), ManageTemplatesState.getPreambleTemplateForLink()],
            (templates: PreambleTemplate[], template: string | null) => {
                const preambleTemplates = templates
                    .reduce((acc, current) => {
                        const rowKey = current.rowKey as string;

                        if (!current.isGlobal || rowKey === 'preamble-links') {
                            acc.push(current);
                        }

                        return acc;
                    }, [] as PreambleTemplate[])
                    .sort((a, b) => {
                        if (a.isGlobal && !b.isGlobal) {
                            return -1;
                        } else if (!a.isGlobal && b.isGlobal) {
                            return 1;
                        }

                        return a.name.localeCompare(b.name);
                    });

                preambleTemplates.push({
                    rowKey: 'custom',
                    name: 'Custom',
                    isEditable: true,
                    isGlobal: false,
                    content: template || '',
                    createdDate: new Date(),
                    hasLoaded: true,
                    sasUri: '',
                });

                return preambleTemplates;
            },
        );
    }

    static getErrors() {
        return createSelector([ManageLinksState], (state: StateModel) => state.errors);
    }

    static getLinks() {
        return createSelector([ManageLinksState], (state: StateModel) => state.links);
    }

    static isNewLink() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId === 'new');
    }

    static getCopyLink() {
        return createSelector([ManageLinksState], (state: StateModel) => state.copyLink);
    }

    static getArchivedLinks() {
        return createSelector([ManageLinksState], (state: StateModel) => state.archivedLinks);
    }

    static getArchivedContent() {
        return createSelector([ManageLinksState], (state: StateModel) => state.archivedContent);
    }

    static getArchivedLinkNodes() {
        return createSelector([ManageLinksState], (state: StateModel) => state.archivedContent?.nodes || []);
    }

    static getArchivedLinkSessions() {
        return createSelector([ManageLinksState], (state: StateModel) => state.archivedContent?.sessions || []);
    }

    static getArchivedLinkSessionVideoSettings() {
        return createSelector([ManageLinksState], (state: StateModel) => state.archivedContent?.sessionVideoSettings || []);
    }

    static getLinkSessions() {
        return createSelector([ManageLinksState], (state: StateModel) => state.linkSessions);
    }

    static getLinkPayments() {
        return createSelector([ManageLinksState], (state: StateModel) => state.linkPayments);
    }

    static getSummaries() {
        return createSelector([ManageLinksState], (state: StateModel) => state.gsVideoSummaries);
    }

    static isLoadingGsSummaries() {
        return createSelector([ManageLinksState], (state: StateModel) => state.isLoadingGsSummaries);
    }

    static isSaving() {
        return createSelector([ManageLinksState], (state: StateModel) => state.isSaving);
    }

    static isSavingChecklist() {
        return createSelector([ManageLinksState], (state: StateModel) => state.isSavingChecklist);
    }

    static getSearchArchivedLinksResults() {
        return createSelector([ManageLinksState], (state: StateModel) => state.searchArchivedLinksQueryResult);
    }

    static getArchivedLink() {
        return createSelector([ManageLinksState], (state: StateModel) => state.archivedLink);
    }

    static getTags() {
        return createSelector([ManageLinksState], (state: StateModel) => state.tags);
    }

    static isValid() {
        return createSelector([ManageLinksState], (state: StateModel) => state.form.status === 'VALID');
    }

    static getForm() {
        return createSelector([ManageLinksState], (state: StateModel) => state.form);
    }

    static getSearchArchivedLinksForm() {
        return createSelector([ManageLinksState], (state: StateModel) => state.searchArchivedLinks);
    }

    static getSearchArchivedLinksSearchOptions() {
        return createSelector([ManageLinksState], (state: StateModel) => state.searchArchivedLinksOptions);
    }

    static getQuestions() {
        return createSelector([ManageLinksState], (state: StateModel) => state.questions);
    }

    static getActiveLinkSummary() {
        return createSelector([ManageLinksState], (state: StateModel) => state.summary);
    }

    static getChecklistTemplateForm() {
        return createSelector([ManageLinksState], (state: StateModel) => state.checklistTemplate);
    }

    static isChecklistTemplateFormValid() {
        return createSelector(
            [ManageLinksState.getChecklistTemplateForm()],
            (form: FormState<Record<string, any>>) => form.status === 'VALID',
        );
    }

    static getChecklistContent() {
        return createSelector([ManageLinksState], (state: StateModel) => state.checklistContent);
    }

    static isSearchingArchivedLinks() {
        return createSelector([ManageLinksState], (state: StateModel) => state.isSearching);
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private manageLinksService: ManageShareableLinksService,
        private blobService: BlobService,
        private store: Store,
        private actions: Actions,
        private matDialog: MatDialog,
    ) {}

    @Action(ManageLinksActions.CopyShareableLinkTemplate)
    copyShareableLinkTemplate(
        { dispatch }: StateContext<StateModel>,
        { template }: ManageLinksActions.CopyShareableLinkTemplate,
    ): Observable<any> | void {
        dispatch(new ManageTemplateActions.SetCustomPreamble(''));

        if (template) {
            if (!isNullOrEmpty(template.password)) {
                template.password = generatePassword();
            }

            if (!isNullOrEmpty(template.influencerPassword)) {
                template.influencerPassword = generatePassword();
            }

            return dispatch(new ManageTemplateActions.LoadPreambleTemplateForLink(template?.rowKey)).pipe(
                mergeMap(() =>
                    dispatch([
                        new ManageTemplateActions.CopyLink(template),
                        new Navigate(['/home/shareable-links/template/new']),
                    ]),
                ),
            );
        }

        return dispatch([
            new ManageTemplateActions.CopyLink(template),
            new Navigate(['/home/shareable-links/template/new']),
        ]);
    }

    @Action(CoreActions.Refresh)
    autoRefresh({ dispatch }: StateContext<StateModel>) {
        dispatch([new ManageLinksActions.LoadLinkSummary()]);

        const route = this.store.selectSnapshot(RouterState.selectCurrentRoute());
        if (!route) return;

        const url = route.url?.map(s => s.path).join('/') || '';

        if (url.includes('link-requests')) {
            dispatch(new ManageLinksActions.LoadLinks());
        }
    }

    @Action(ManageLinksActions.LoadLinkSummary)
    loadLinkSummary({ dispatch, patchState }: StateContext<StateModel>): Observable<any> | void {
        return this.manageLinksService.getLinksSummary().pipe(
            tap(summary => {
                patchState({
                    summary,
                });
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.LoadLinkSummaryFailure(err))),
        );
    }

    @Action(UpdateFormValue)
    updateFormValue({ patchState, getState }: StateContext<StateModel>, action: UpdateFormValue): void {
        if (action.payload.path === 'manageLinks.filters') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }

        if (action.payload.path === 'manageLinks.searchArchivedLinks') {
            const options = getState().searchArchivedLinksOptions;
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
            patchState({ searchArchivedLinksOptions: { ...options, ...action.payload.value } });
        }

        if (action.payload.path === 'manageLinks.sessionFilters') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }

        if (action.payload.path === 'manageLinks.searchLinksByName') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }
    }

    @Action(ManageLinksActions.SetCurrentTabIndex)
    setCurrentTabIndex(ctx: StateContext<StateModel>, action: ManageLinksActions.SetCurrentTabIndex): void {
        ctx.patchState({
            currentTabIndex: action.index,
        });
        localStorage.setItem('manageLinks.currentTabIndex', JSON.stringify(action.index));
    }

    @Action(ManageLinksActions.LoadLink)
    loadLink(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageLinksActions.LoadLink,
    ): Observable<any> | void {
        const isNew = action.value === 'new';
        const links = this.store.selectSnapshot(ManageLinksState.getLinks());
        const copyLink = this.store.selectSnapshot(ManageLinksState.getCopyLink());
        const model = isNew
            ? copyLink || getDefaultShareableLinkModel()
            : links.find(l => l.rowKey === action.value) || null;

        if (!model) {
            return;
        }

        patchState({
            editLink: null,
            copyLink: null,
            linkSessions: [],
            linkPayments: [],
        });

        if (!isNew) {
            const rowKey: string = model.rowKey as string;
            const actions = [
                ...(model.checklistTemplateIds.length > 0
                    ? model.checklistTemplateIds.map(id => new ManageLinksActions.LoadChecklist(`${rowKey}.${id}`))
                    : []),
                new ManageLinksActions.UpdateSessionCount(rowKey),
                new ManageLinksActions.LoadLinkSessions(rowKey),
                new ManageLinksActions.LoadLinkPayments(rowKey),
                new ManageTemplateActions.LoadPreambleTemplateForLink(rowKey),
            ];

            return dispatch(actions).pipe(
                tap(() => {
                    patchState({
                        editLink: model,
                    });
                }),
            );
        }

        patchState({
            editLink: model,
        });

        if (!copyLink) {
            return dispatch(new ManageTemplateActions.SetCustomPreamble(''));
        }
    }

    @Action(ManageLinksActions.CopyLink)
    copyLink({ dispatch, patchState }: StateContext<StateModel>, action: ManageLinksActions.CopyLink) {
        const { link } = action;

        if (!isNullOrEmpty(link.password)) {
            link.password = generatePassword();
        }

        if (!isNullOrEmpty(link.influencerPassword)) {
            link.influencerPassword = generatePassword();
        }

        const templates = this.store.selectSnapshot(ManageTemplatesState.getChecklistTemplates());

        const copyLink = {
            ...link,
            rowKey: null,
            isDeleted: false,
            activationDate: null,
            createdDate: new Date(),
            status: LinkStatus.Active,
            activeSubmissionCount: 0,
            checklists: {
                ...(link.checklistTemplateIds || []).reduce((acc, current) => {
                    const template = templates.find(t => t.rowKey === current) || null;

                    if (template) {
                        acc[template.rowKey as string] = template.content;
                    }

                    return acc;
                }, {} as Record<string, string>),
            },
        } as ShareableLinkModel;

        patchState({
            copyLink,
        });

        return dispatch(new ManageTemplateActions.LoadPreambleTemplateForLink(link.rowKey)).pipe(
            mergeMap(() => dispatch(new Navigate(['/home/shareable-links/new']))),
        );
    }

    @Action(ManageLinksActions.EnsureLoadLinks)
    ensureLoadLinks({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedLinks } = getState();

        if (hasLoadedLinks) {
            return;
        }

        return dispatch([new ManageLinksActions.LoadLinks()]);
    }

    @Action(ManageLinksActions.LoadArchivedLink)
    loadArchivedLink(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageLinksActions.LoadArchivedLink,
    ): Observable<any> | void {
        return this.manageLinksService.getArchivedLink(action.rowKey).pipe(
            tap(archivedLink => {
                patchState({
                    archivedLink,
                });
                dispatch(new ManageLinksActions.LoadArchivedLinkSuccess());
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.LoadArchivedLinkFailure(err))),
        );
    }

    @Action(ManageLinksActions.EnsureLoadTags)
    ensureLoadTags({ dispatch, patchState, getState }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedTags } = getState();

        if (hasLoadedTags) {
            return;
        }

        patchState({ hasLoadedTags: true });

        return from(localforage.getItem('Tags') as Promise<string[]>).pipe(
            mergeMap((tags: string[]) => {
                if (tags && tags.length > 0) {
                    patchState({ tags });
                    dispatch([new ManageLinksActions.LoadTags()]);
                    return of(void 0);
                }

                return dispatch([new ManageLinksActions.LoadTags()]);
            }),
        );
    }

    @Action(ManageLinksActions.LoadTags)
    loadTags({ patchState }: StateContext<StateModel>): Observable<any> | void {
        return this.manageLinksService.getTags().pipe(
            tap(tags => {
                tags.sort();
                patchState({ tags });
                localforage.setItem('Tags', tags);
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.LoadTagsFailure(err))),
            finalize(() => {
                patchState({ hasLoadedTags: true });
            }),
        );
    }

    @Action(ManageLinksActions.ViewLink)
    viewLink({ dispatch }: StateContext<StateModel>, { model }: ManageLinksActions.ViewLink) {
        return dispatch(new Navigate(['/home/shareable-links', model.rowKey], { t: 'sessions' }));
    }

    @Action(ManageLinksActions.ViewArchivedLink)
    viewArchivedLink({ patchState, dispatch }: StateContext<StateModel>, { model }: ManageLinksActions.ViewArchivedLink) {
        patchState({ archivedLink: model });

        return dispatch(new Navigate(['/home/shareable-links/archived', model.rowKey], { t: 'content' }));
    }

    @Action(ManageLinksActions.LoadLinks)
    loadLinks({ getState, patchState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { isLoadingLinks } = getState();

        if (isLoadingLinks) {
            return this.actions.pipe(ofActionDispatched(ManageLinksActions.LoadLinksDone), take(1));
        }

        patchState({ isLoadingLinks: true });

        return this.manageLinksService.getAllLinks().pipe(
            tap(links => {
                patchState({ links });
                localforage.setItem('Links', links);
                dispatch(new ManageLinksActions.LoadLinksSuccess());
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.LoadLinksFailure(err))),
            finalize(() => {
                patchState({ isLoadingLinks: false, hasLoadedLinks: true });
                dispatch(new ManageLinksActions.LoadLinksDone());
            }),
        );
    }

    @Action(ManageLinksActions.SearchArchivedLinks, { cancelUncompleted: true })
    searchArchivedLinks(
        { dispatch, patchState, getState }: StateContext<StateModel>,
        action: ManageLinksActions.SearchArchivedLinks,
    ) {
        const { searchArchivedLinksOptions: options } = getState();
        const form = this.store.selectSnapshot(ManageLinksState.getSearchArchivedLinksForm());
        const formModel = form.model as SearchOptionsModel;
        const model = action.model || {
            ...formModel,
            limit: options?.limit || 0,
            total: options?.total || null,
            offset: options?.offset || 0,
        };

        if (model.total === undefined) {
            model.total = options?.total;
        }

        patchState({ isSearching: true });

        return this.manageLinksService.searchArchivedLinks(model).pipe(
            // takeUntil(this.actions.pipe(ofAction(ManageLinksActions.SearchArchivedLinks))), // cancel if another search goes through
            tap(queryResult => {
                patchState({
                    searchArchivedLinksQueryResult: queryResult,
                    searchArchivedLinksOptions: {
                        filter: '',
                        tags: [],
                        limit: queryResult.limit,
                        total: queryResult.total,
                        offset: queryResult.offset,
                    },
                });
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.SearchArchivedLinksFailure(err))),
            finalize(() => patchState({ isSearching: false })),
        );
    }

    @Action(ManageLinksActions.LoadArchivedLinkSessions)
    loadArchivedLinkSessions(
        ctx: StateContext<StateModel>,
        action: ManageLinksActions.LoadArchivedLinkSessions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageLinksState.getArchivedLink());
        const rowKey = action.rowKey || model?.rowKey;

        if (!rowKey) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.blobService.downloadBlobBlock(`archived/${rowKey}.json`, BlobContainer.Private).pipe(
            transformResponseToText(),
            handleNotFoundError('{}'),
            tap(content => {
                const result = JSON.parse(content) as any;
                const archivedContent = convertObjectKeysToCamelCase(result);

                ctx.patchState({ archivedContent });
            }),
            catchError(err => ctx.dispatch(new ManageLinksActions.LoadArchivedLinkSessionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageLinksActions.LoadLinkSessions)
    loadLinkSessions(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageLinksActions.LoadLinkSessions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageLinksState.getEditModel());

        if (!rowKey && (!model || !model.rowKey)) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageLinksService.getLinkSessions((model?.rowKey || rowKey) as string).pipe(
            tap(linkSessions => ctx.patchState({ linkSessions })),
            catchError(err => this.store.dispatch(new ManageLinksActions.LoadLinkSessionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageLinksActions.LoadLinkPayments)
    loadLinkPayments(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageLinksActions.LoadLinkPayments,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageLinksState.getEditModel());
        if (((!model || !model.rowKey) && !rowKey) || rowKey === 'new') {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageLinksService.getAllLinkPayments((model?.rowKey || rowKey) as string).pipe(
            tap(linkPayments => ctx.patchState({ linkPayments })),
            catchError(err => this.store.dispatch(new ManageLinksActions.LoadLinkPaymentsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageLinksActions.LoadGuidedSequenceSummariesForLink)
    loadGuidedSequenceSummariesForLink(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageLinksActions.LoadGuidedSequenceSummariesForLink,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageLinksState.getEditModel());

        if (!rowKey && (!model || !model.rowKey)) {
            return;
        }

        ctx.patchState({ isLoadingGsSummaries: true });

        return this.manageLinksService.getGuidedSequenceSummariesForLink((model?.rowKey || rowKey) as string).pipe(
            tap(gsVideoSummaries => ctx.patchState({ gsVideoSummaries })),
            catchError(err => this.store.dispatch(new ManageLinksActions.LoadGuidedSequenceSummariesForLinkFailure(err))),
            finalize(() => ctx.patchState({ isLoadingGsSummaries: false })),
        );
    }

    @Action(ManageLinksActions.Save)
    save({ patchState, dispatch }: StateContext<StateModel>): Observable<void> {
        patchState({
            isSaving: true,
            errors: null,
        });

        const links = [...this.store.selectSnapshot(ManageLinksState.getLinks())];
        const model = this.store.selectSnapshot(ManageLinksState.getForm()).model as EditShareableLinkFormModel;
        const preambleTemplate = model.preambleTemplate;
        const preambleTemplateId = model.preambleTemplateId;
        const checklists = model.checklistTemplateIds.reduce((acc, rowKey) => {
            const content = model.checklists?.[rowKey];

            if (content) {
                acc.push({
                    rowKey,
                    content,
                });
            }

            return acc;
        }, [] as { rowKey: string; content: string }[]);

        model.checklists = null;
        model.preambleTemplate = null;

        const { timeZone } = model.linkExpiration;
        // const timeZone = getTimeZoneByIanaTimeZone(ianaTimeZone);
        const ix = links.findIndex(n => n.rowKey === model.rowKey);

        return this.manageLinksService
            .saveLink({
                ...model,
                ...model.linkExpiration,
                timeZone: timeZone || DEFAULT_TIME_ZONE,
                videoSettings: {
                    elapsedTimeInSeconds: 0,
                    isLiveStream: model.isLiveStream,
                    startTime: model.startTime,
                    autoplay: model.autoplay,
                    showWatermark: model.showWatermark,
                    showBackgroundWatermark: model.showBackgroundWatermark,
                    enableWaitingRoom: model.enableWaitingRoom,
                    waitingRoomOffsetInSeconds: model.waitingRoomOffsetInSeconds,
                    showControls: model.showControls,
                    showPlayButton: model.showPlayButton,
                    showFullscreenButton: model.showFullscreenButton,
                    showProgress: model.showProgress,
                    allowScrubbing: model.allowScrubbing,
                    showPlaybackSpeed: model.showPlaybackSpeed,
                    resumePlayback: model.resumePlayback,
                    allowListeners: model.allowListeners,
                    countDownAssetId: model.countDownAssetId,
                },
            })
            .pipe(
                mergeMap(link => {
                    if (ix !== -1) {
                        links[ix] = link;
                    } else {
                        links.push(link);
                    }

                    patchState({
                        links,
                        copyLink: null,
                    });

                    if (!checklists.length && preambleTemplateId !== 'custom') {
                        return dispatch([
                            new Navigate(['/home/shareable-links']),
                            new ManageLinksActions.UpdateSessionCount(link.rowKey as string),
                            new NotificationActions.Success('Link has been successfully saved'),
                        ]);
                    }

                    const actions = [
                        ...checklists.map(
                            checklist => new ManageLinksActions.SaveChecklist(checklist, link.rowKey as string),
                        ),
                        ...(preambleTemplateId === 'custom'
                            ? [
                                  new ManageTemplateActions.SavePreambleForLink(
                                      preambleTemplate?.content || '',
                                      link.rowKey as string,
                                  ),
                              ]
                            : []),
                    ];

                    return dispatch(actions).pipe(
                        mergeMap(() => {
                            return dispatch([
                                new Navigate(['/home/shareable-links']),
                                new ManageLinksActions.UpdateSessionCount(link.rowKey as string),
                                new NotificationActions.Success('Link has been successfully saved'),
                            ]);
                        }),
                    );
                }),
                catchError(err => dispatch(new ManageLinksActions.SaveFailure(err))),
                finalize(() =>
                    patchState({
                        isSaving: false,
                    }),
                ),
            );
    }

    @Action(ManageLinksActions.ConfirmArchiveLinks)
    confirmArchiveLinks(ctx: StateContext<StateModel>, action: ManageLinksActions.ConfirmArchiveLinks) {
        return this.matDialog
            .open(ArchiveContentLinkConfirmationDialogComponent, {
                disableClose: true,
                data: action.links,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageLinksActions.ArchiveLinks(action.links));
                    }
                }),
            );
    }

    @Action(ManageLinksActions.ArchiveLinks)
    archiveLinks(ctx: StateContext<StateModel>, action: ManageLinksActions.ArchiveLinks) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageLinksService.archiveLinks(action.links).pipe(
            mergeMap(result => {
                const links = this.store.selectSnapshot(ManageLinksState.getLinks()).map(link => ({ ...link }));
                const archivedLinks = this.store
                    .selectSnapshot(ManageLinksState.getArchivedLinks())
                    .map(link => ({ ...link }));
                const linksToArchive = links.filter(link => result.linksToRemove.includes(link.rowKey as string));
                const linksToArchiveIds = linksToArchive.map(link => link.rowKey);

                linksToArchive.forEach(link => (link.isDeleted = true));

                ctx.patchState({
                    links: links.filter(link => !linksToArchiveIds.includes(link.rowKey)),
                    archivedLinks: archivedLinks.concat(linksToArchive),
                });

                const linkId = this.store.selectSnapshot(RouterState.selectRouteParam('linkId'));

                if (linkId) {
                    this.store.dispatch(new Navigate(['/home/shareable-links']));
                }

                return this.store.dispatch(new NotificationActions.Success('Link(s) has been successfully archived'));
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.ArchiveLinksFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.ConfirmDeleteLinks)
    confirmDeleteLinks(ctx: StateContext<StateModel>, action: ManageLinksActions.ConfirmDeleteLinks) {
        return this.matDialog
            .open(DeleteShareableLinkConfirmationDialogComponent, {
                disableClose: true,
                data: action.links,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageLinksActions.DeleteLinks(action.links));
                    }
                }),
            );
    }

    @Action(ManageLinksActions.DeleteLinks)
    deleteLinks(ctx: StateContext<StateModel>, action: ManageLinksActions.DeleteLinks) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageLinksService.deleteLinks(action.links).pipe(
            tap(result => {
                const archivedLinks = this.store
                    .selectSnapshot(ManageLinksState.getArchivedLinks())
                    .filter(link => !result.linksToRemove.includes(link.rowKey as string));

                ctx.patchState({
                    archivedLinks,
                });

                const data = this.store.selectSnapshot(RouterState.selectRouteData());

                if (data?.['linkId']) {
                    this.store.dispatch(new Navigate(['/home/shareable-links']));
                }

                this.store.dispatch(new NotificationActions.Success('Link has been successfully deleted'));
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.DeleteLinksFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.ConfirmMergeSession)
    confirmMergeSessions(ctx: StateContext<StateModel>, action: ManageLinksActions.ConfirmMergeSession) {
        const allSessions = this.store.selectSnapshot(ManageLinksState.getLinkSessions());
        const sessions: LinkSessionSummary[] = [];

        action.rowKeys.forEach(rowKey => {
            const session = allSessions.find(s => s.rowKey === rowKey);
            if (session) {
                sessions.push(session);
            }
        });

        return this.matDialog
            .open(MergeLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    linkType: LinkType.Shareable,
                    sessions,
                },
            })
            .afterClosed()
            .pipe(
                tap((primary: LinkSessionSummary) => {
                    if (primary) {
                        this.store.dispatch(new ManageLinksActions.MergeSessions(primary.rowKey, action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageLinksActions.MergeSessions)
    mergeSessions(ctx: StateContext<StateModel>, action: ManageLinksActions.MergeSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageLinksState.getEditModel()) as ShareableLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageLinksService.mergeSessions(rowKey, action.primaryRowKey, action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageLinksActions.LoadLinkSessions(),
                    new ManageLinksActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submissions Merged Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.MergeSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.ConfirmDeleteSession)
    confirmDeleteSession(ctx: StateContext<StateModel>, action: ManageLinksActions.ConfirmDeleteSession) {
        return this.matDialog
            .open(DeleteLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: action.rowKeys,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageLinksActions.DeleteSession(action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageLinksActions.DeleteSession)
    deleteSession(ctx: StateContext<StateModel>, action: ManageLinksActions.DeleteSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageLinksState.getEditModel()) as ShareableLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageLinksService.deleteSession(action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageLinksActions.LoadLinkSessions(),
                    new ManageLinksActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Deleted Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.DeleteSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.ActivateSession)
    activateSession(ctx: StateContext<StateModel>, action: ManageLinksActions.ActivateSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageLinksState.getEditModel()) as ShareableLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageLinksService.activateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageLinksActions.LoadLinkSessions(),
                    new ManageLinksActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission Activated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.ActivateSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.DeactivateSession)
    deactivateSession(ctx: StateContext<StateModel>, action: ManageLinksActions.DeactivateSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageLinksState.getEditModel()) as ShareableLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageLinksService.deactivateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageLinksActions.LoadLinkSessions(),
                    new ManageLinksActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission Deactivated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.DeactivateSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.SyncActiveSessions)
    syncActiveSessions(ctx: StateContext<StateModel>, action: ManageLinksActions.SyncActiveSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageLinksService.syncActiveSessions(action.rowKey).pipe(
            tap(result => {
                const links = this.store.selectSnapshot(ManageLinksState.getLinks()).concat();
                const ix = links.findIndex(l => l.rowKey === action.rowKey);
                const link = links[ix];

                links[ix] = {
                    ...link,
                    lateRegistrationCount: result.lateRegistrationCount,
                    activeSubmissionCount: result.activeSubmissionCount,
                    approvalCount: result.approvalCount,
                    sessionLimit: result.sessionLimit,
                };

                ctx.patchState({
                    links,
                });
            }),
            mergeMap(link =>
                this.store.dispatch([
                    new NotificationActions.Success('Active Submissions Synchronized'),
                    new UpdateFormValue({
                        value: { sessionLimit: link.sessionLimit, activeSubmissionCount: link.activeSubmissionCount },
                        path: 'manageLinks.form',
                    }),
                ]),
            ),
            catchError(err => this.store.dispatch(new ManageLinksActions.SyncActiveSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.UpdateSessionCount)
    updateSessionCount(ctx: StateContext<StateModel>, action: ManageLinksActions.UpdateSessionCount) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageLinksService.updateActiveSessionCount(action.rowKey).pipe(
            tap(result => {
                const links = [...this.store.selectSnapshot(ManageLinksState.getLinks())];
                const ix = links.findIndex(l => l.rowKey === action.rowKey);
                const link = links[ix];

                links[ix] = {
                    ...link,
                    lateRegistrationCount: result.lateRegistrationCount,
                    activeSubmissionCount: result.activeSubmissionCount,
                    approvalCount: result.approvalCount,
                    resubmissionCount: result.resubmissionCount,
                };

                ctx.patchState({
                    links,
                });
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.UpdateSessionCountFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.CopyLinkToClipboard)
    copyLinkToClipboard(ctx: StateContext<StateModel>, action: ManageLinksActions.CopyLinkToClipboard): void {
        const model = action.link;
        const registrationModes = [RegistrationMode.Daily, RegistrationMode.Weekly, RegistrationMode.Monthly];
        const url = formatLinkUrl(model, this.env);
        const hasSubmissionReset = registrationModes.includes(model.registrationMode);
        const dayOfWeek = DayOfWeek[model.dayOfWeek];
        const details = [`Shareable Link: ${url}`];

        if (model.isPasswordRequired) {
            details.push(`User Password: ${model.password}`);
        }

        switch (model.linkExpiration) {
            case LinkExpiration.Timer:
                {
                    const w1 = model.requiresApproval ? 'approval' : 'activation';
                    switch (model.timerUnit) {
                        case TimerUnit.Months:
                            details.push(`Timer length after ${w1} (${model.timer}m)`);
                            break;
                        case TimerUnit.Weeks:
                            details.push(`Timer length after ${w1} (${model.timer}w)`);
                            break;
                        case TimerUnit.Days:
                            details.push(`Timer length after ${w1} (${model.timer}d)`);
                            break;
                        case TimerUnit.Hours:
                            details.push(`Timer length after ${w1} (${model.timer}h)`);
                            break;
                        case TimerUnit.Minutes:
                            details.push(`Timer length after ${w1} (${model.timer}min)`);
                            break;
                        default:
                    }

                    if (model.timerEndDate && !hasSubmissionReset) {
                        details.push(`Expires ${this.formatDate(model.timerEndDate, action.timeZone)}`);
                    } else if (hasSubmissionReset) {
                        const reset =
                            model.registrationMode === RegistrationMode.Daily
                                ? `Daily Reset @ 11:59pm`
                                : model.registrationMode === RegistrationMode.Weekly
                                ? `Weekly Reset ${dayOfWeek} @ 11:59pm`
                                : 'Monthly Reset @ 11:59pm';

                        details.push(`${reset} ${action.timeZone.abbr}`);
                    }
                }
                break;
            case LinkExpiration.Date:
                if (model.startDate) {
                    details.push(`Starts ${this.formatDate(model.startDate, action.timeZone)}`);
                }
                if (model.endDate && !hasSubmissionReset) {
                    details.push(`Expires ${this.formatDate(model.endDate, action.timeZone)}`);
                } else if (hasSubmissionReset) {
                    const reset =
                        model.registrationMode === RegistrationMode.Daily
                            ? `Daily Reset @ 11:59pm`
                            : model.registrationMode === RegistrationMode.Weekly
                            ? `Weekly Reset ${dayOfWeek} @ 11:59pm`
                            : 'Monthly Reset @ 11:59pm';

                    details.push(`${reset} ${action.timeZone.abbr}`);
                }
                break;
            default:
                if (hasSubmissionReset) {
                    const reset =
                        model.registrationMode === RegistrationMode.Daily
                            ? `Daily Reset @ 11:59pm`
                            : model.registrationMode === RegistrationMode.Weekly
                            ? `Weekly Reset ${dayOfWeek} @ 11:59pm`
                            : 'Monthly Reset @ 11:59pm';

                    details.push(`${reset} ${action.timeZone.abbr}`);
                }
        }

        if (model.sessionLimit) {
            details.push(`Active Device & Browser Limit (${model.sessionLimit})`);
        }

        if (model.registrationEndDate) {
            details.push(`Registration Cut-off ${this.formatDate(model.registrationEndDate, action.timeZone)}`);
        }

        if (model.registrationMode === RegistrationMode.Onetime) {
            details.push(`Link is configured for a one-time access`);
        }

        if (action.includeInfluencer) {
            details.push('');
            details.push(`*Influencer Password: ${model.influencerPassword}*`);
        }

        this.store.dispatch(
            new CoreActions.CopyToClipboard({
                text: details.join('\r\n'),
                message: 'Link copied to clipboard',
            }),
        );
    }

    @Action(ManageLinksActions.ShowExtendSessionDialog)
    showExtendSessionDialog(ctx: StateContext<StateModel>, action: ManageLinksActions.ShowExtendSessionDialog) {
        return this.matDialog
            .open(ExtendSessionDialogComponent, {})
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        ctx.dispatch(new ManageLinksActions.ExtendSessions(action.rowKeys, result));
                    }
                }),
            );
    }

    @Action(ManageLinksActions.ShowExtendLinkDialog)
    showExtendLinkDialog(ctx: StateContext<StateModel>, action: ManageLinksActions.ShowExtendLinkDialog) {
        return this.matDialog
            .open(ExtendLinkDialogComponent, {})
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        ctx.dispatch(new ManageLinksActions.ExtendLinkExpirationDate(action.links, result));
                    }
                }),
            );
    }

    @Action(ManageLinksActions.ExtendSessions)
    extendSession(ctx: StateContext<StateModel>, action: ManageLinksActions.ExtendSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageLinksService.extendSessions(action.rowKeys, action.days).pipe(
            mergeMap(() => {
                return ctx.dispatch([
                    new ManageLinksActions.LoadLinkSessions(),
                    new NotificationActions.Success('Session(s) extended successfully'),
                ]);
            }),
            catchError(err => ctx.dispatch(new ManageLinksActions.ExtendSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.OpenLink)
    openLink(ctx: StateContext<StateModel>, action: ManageLinksActions.OpenLink): Observable<any> | void {
        const url = formatLinkUrl(action.link, this.env);

        if (action.link.isPasswordRequired) {
            return this.store
                .dispatch(
                    new CoreActions.CopyToClipboard({
                        text: action.link.password,
                    }),
                )
                .pipe(tap(() => window.open(url, `_blank`)));
        }

        window.open(url, `_blank`);
    }

    @Action(ManageLinksActions.UpdateEditLinkModel)
    updateEditLink(ctx: StateContext<StateModel>, action: ManageLinksActions.UpdateEditLinkModel) {
        const editLink = this.store.selectSnapshot(ManageLinksState.getEditModel()) as ShareableLinkModel;
        const rowKey = editLink?.rowKey as string;
        const links = this.store.selectSnapshot(ManageLinksState.getLinks());
        const link = links.find(l => l.rowKey === rowKey);

        if (!editLink || !link) {
            return;
        }

        ctx.patchState({
            editLink: { ...editLink, linkExpiration: link.linkExpiration, endDate: link.endDate },
        });
    }

    @Action(ManageLinksActions.ExtendLinkExpirationDate)
    extendLinkExpirationDate(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageLinksActions.ExtendLinkExpirationDate,
    ) {
        const rowKeys = action.links.map(r => r.rowKey) as string[];
        const days = action.days;

        return this.manageLinksService.extendLinkExpirationDate(rowKeys, days).pipe(
            mergeMap(result => {
                return dispatch([
                    new ManageLinksActions.LoadLinks(),
                    new NotificationActions.Success('Link(s) extended successfully'),
                ]);
            }),
            mergeMap(() => dispatch(new ManageLinksActions.UpdateEditLinkModel())),
            catchError(err => dispatch(new ManageLinksActions.ExtendLinkExpirationDateFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.ApproveSearchResultSessions)
    approveSearchResultSessions(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageLinksActions.ApproveSearchResultSessions,
    ) {
        patchState({
            isSaving: true,
            errors: null,
        });

        const actions = [];

        const result = action.rowKeys.reduce((acc, current) => {
            if (!acc[current.linkId]) {
                acc[current.linkId] = [];
            }

            acc[current.linkId].push(current.intakeResponse.rowKey);

            return acc;
        }, {} as { [key: string]: string[] });

        for (const linkId of Object.keys(result)) {
            actions.push(
                this.manageLinksService.approveSession(linkId, result[linkId]).pipe(
                    mergeMap(() => dispatch(new ManageLinksActions.UpdateSessionCount(linkId))),
                    catchError(() => of(null)),
                ),
            );
        }

        return forkJoin(actions).pipe(
            mergeMap(() => {
                return dispatch([
                    new NotificationActions.Success('Submission(s) Approved'),
                    new ManageLinksActions.SearchLinksByName(),
                ]);
            }),
            catchError(err => dispatch(new ManageLinksActions.ApproveSessionsFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.ApproveSessions)
    approveSessions(ctx: StateContext<StateModel>, action: ManageLinksActions.ApproveSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageLinksState.getEditModel()) as ShareableLinkModel;
        const rowKey = link?.rowKey || action.linkId;

        return this.manageLinksService.approveSession(rowKey, action.rowKeys).pipe(
            mergeMap(() => {
                const actions = [
                    new ManageLinksActions.LoadLinkSessions(),
                    new ManageLinksActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Approved'),
                ];

                if (link && link.hasRegistration && link.registrationEndDate) {
                    const endDate = new Date(link.registrationEndDate);
                    const now = new Date();

                    if (now > endDate) {
                        actions.push(new ManageLinksActions.SyncActiveSessions(rowKey));
                    }
                }

                return this.store.dispatch(actions);
            }),
            catchError(err => this.store.dispatch(new ManageLinksActions.ApproveSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageLinksActions.LoadChecklist)
    loadChecklist({ patchState, getState, dispatch }: StateContext<StateModel>, action: ManageLinksActions.LoadChecklist) {
        patchState({
            isLoadingChecklist: true,
        });

        const linkId = action.rowKey || this.store.selectSnapshot(ManageLinksState.getLinkId());

        return this.blobService.downloadBlobBlock(`checklists/${linkId}.html`, BlobContainer.Private).pipe(
            transformResponseToText(),
            handleNotFoundError('<p></p>'),
            mergeMap(template => {
                const checklistContent = getState().checklistContent;
                patchState({
                    checklistContent: {
                        ...checklistContent,
                        [linkId]: template,
                    },
                });
                return dispatch(new ManageLinksActions.LoadChecklistSuccess());
            }),
            catchError(err => dispatch(new ManageLinksActions.LoadChecklistFailure(err))),
            finalize(() => patchState({ isLoadingChecklist: false })),
        );
    }

    @Action(ManageLinksActions.SaveChecklist)
    saveChecklist(
        { patchState }: StateContext<StateModel>,
        action: ManageLinksActions.SaveChecklist,
    ): Observable<any> | void {
        const rowKey = `${action.rowKey}.${action.checklist.rowKey}`;

        patchState({
            isSavingChecklist: true,
            errors: null,
        });

        return this.blobService.uploadBlobBlock(
            `checklists/${rowKey}.html`,
            action.checklist.content,
            'text/plain',
            BlobContainer.Private,
        );
    }

    @Action([
        ManageLinksActions.ActivateSessionFailure,
        ManageLinksActions.LoadLinksFailure,
        ManageLinksActions.SaveFailure,
        ManageLinksActions.DeleteLinksFailure,
        ManageLinksActions.ArchiveLinksFailure,
        ManageLinksActions.DeleteSessionFailure,
        ManageLinksActions.MergeSessionsFailure,
        ManageLinksActions.ApproveSessionsFailure,
        ManageLinksActions.LoadLinkSessionsFailure,
        ManageLinksActions.DeactivateSessionFailure,
        ManageLinksActions.LoadArchivedLinksFailure,
        ManageLinksActions.SyncActiveSessionsFailure,
        ManageLinksActions.UpdateSessionCountFailure,
        ManageLinksActions.LoadArchivedLinkSessionsFailure,
        ManageLinksActions.LoadChecklistFailure,
    ])
    handleFailures(ctx: StateContext<StateModel>, action: ManageLinksFailureTypes): Observable<any> | void {
        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof ManageLinksActions.LoadLinksFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Loading Links', action.error));
            case action instanceof ManageLinksActions.SaveFailure:
                // ctx.patchState({ errors: action.error });
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Saving Link', action.error));
            case action instanceof ManageLinksActions.DeleteLinksFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Deleting Link(s)', action.error));
            case action instanceof ManageLinksActions.ArchiveLinksFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Archiving Link(s)', action.error),
                );
            case action instanceof ManageLinksActions.DeactivateSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deactivating Session(s)', action.error),
                );
            case action instanceof ManageLinksActions.DeleteSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deleting Link Session(s)', action.error),
                );
            case action instanceof ManageLinksActions.LoadArchivedLinksFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Archived Links', action.error),
                );
            case action instanceof ManageLinksActions.SyncActiveSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Syncing Link Sessions', action.error),
                );
            case action instanceof ManageLinksActions.UpdateSessionCountFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Updating Session Counts', action.error),
                );
            case action instanceof ManageLinksActions.LoadArchivedLinkSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Link Sessions', action.error),
                );
            case action instanceof ManageLinksActions.LoadLinkSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Link Sessions', action.error),
                );
            case action instanceof ManageLinksActions.MergeSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Merging Link Sessions', action.error),
                );
            case action instanceof ManageLinksActions.ApproveSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Approving Link Session(s)', action.error),
                );
            case action instanceof ManageLinksActions.LoadChecklistFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Checklist', action.error),
                );
            default:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error', action.error));
        }
    }

    private formatDate(date: Date, timeZone: Timezone): string {
        return formatDatetimeWithTimeZone(date, timeZone);
    }
}

function getDefaultShareableLinkModel() {
    return {
        rowKey: null,
        description: '',
        nodeIds: [],
        videoSettings: {
            allowListeners: false,
            autoplay: false,
            elapsedTimeInSeconds: 0,
            enableWaitingRoom: false,
            waitingRoomOffsetInSeconds: 60,
            showWatermark: true,
            showBackgroundWatermark: true,
            showControls: true,
            showPlayButton: true,
            showFullscreenButton: true,
            showProgress: true,
            allowScrubbing: true,
            showPlaybackSpeed: false,
            resumePlayback: false,
            startTime: VideoOption.None,
            countDownAssetId: '',
            isLiveStream: false,
        },
        password: generatePassword(),
        influencerPassword: '',
        isDeleted: false,
        url: '',
        requestedBy: '',
        activationDate: null,
        status: LinkStatus.Active,
        timer: null,
        timerUnit: TimerUnit.Hours,
        isNameRequired: true,
        isPasswordRequired: true,
        timerEndDate: null,
        activeSubmissionCount: 0,
        sessionLimit: 1,
        startDate: null,
        endDate: null,
        type: LinkType.Shareable,
        requiresApproval: false,
        isLiveStream: false,
        hasRegistration: false,
        displayWatermark: true,
        linkExpiration: LinkExpiration.None,
        questions: [],
        lastActivityDate: null,
        tags: [],
        lateRegistrationCount: 0,
        approvalCount: 0,
        createdDate: new Date(),
        registrationMode: RegistrationMode.Default,
        timeZone: 'America/Edmonton',
        isGuidedSequence: false,
        registrationEndDate: null,
        timestamp: null,
        archivedDate: null,
        dayOfWeek: DayOfWeek.Sunday,
        linkExpirationDate: null,
        checklists: {},
        isPreambleRequired: true,
        preambleTemplateId: null,
        checklistTemplateIds: [],
        linkInactivityMode: LinkInactivityMode.Default,
        influencerCount: 0,
        isPublic: false,
        publicAccessMode: PublicAccessMode.ViewOnly,
        resubmissionCount: 0,
        nodeOptions: {
            associatedNodeIds: [],
        },
        requestedByVisitorId: null,

        productName: '',
        productCode: '',
        productMessage: '',
        amount: 0,
        allowLinkAccess: false,
        hasAdminAcknowledged: true,
        autoArchive: true,
        isExpired: false,
        isActive: true,
        displayRegistrationCountdown: false,
        allowUsersToRequestMoreTime: false,
    } as ShareableLinkModel;
}

type ManageLinksFailureTypes =
    | ManageLinksActions.ActivateSessionFailure
    | ManageLinksActions.LoadLinksFailure
    | ManageLinksActions.SaveFailure
    | ManageLinksActions.DeleteLinksFailure
    | ManageLinksActions.ArchiveLinksFailure
    | ManageLinksActions.DeleteSessionFailure
    | ManageLinksActions.MergeSessionsFailure
    | ManageLinksActions.ApproveSessionsFailure
    | ManageLinksActions.LoadLinkSessionsFailure
    | ManageLinksActions.DeactivateSessionFailure
    | ManageLinksActions.LoadArchivedLinksFailure
    | ManageLinksActions.SyncActiveSessionsFailure
    | ManageLinksActions.UpdateSessionCountFailure
    | ManageLinksActions.LoadArchivedLinkSessionsFailure
    | ManageLinksActions.LoadChecklistFailure;

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ElapsedTimePipe } from '../../pipes/elapsed-time.pipe';

@Component({
    selector: 'shared-mini-player',
    templateUrl: './mini-player.component.html',
    styleUrls: ['./mini-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    MatIconButton,
    MatIcon,
    MatMiniFabButton,
    MatProgressBar,
    ElapsedTimePipe,
    DecimalPipe
],
})
export class MiniPlayerComponent {
    @Input() isPlaying: boolean | null = false;
    @Input() title: string | null = '';
    @Input() duration: number | null = 0;
    @Input() position: number | null = 0;
    @Input() canPlay: boolean | null = false;
    @Input() canSeek: boolean | null = true;
    @Input() isLoading: boolean | null = false;
    @Input() playbackRate: number | null = 1;

    @Output() readonly playMedia: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly pauseMedia: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly seekMedia: EventEmitter<number> = new EventEmitter<number>();
    @Output() readonly changePlaybackRate: EventEmitter<number> = new EventEmitter<number>();

    onPlay() {
        if (this.isPlaying) {
            this.pauseMedia.emit();
        } else {
            this.playMedia.emit();
        }
    }

    onSeek(amount: number) {
        this.seekMedia.emit(amount);
    }

    onChangePlaybackRate() {
        const rate = this.playbackRate || 1;

        if (rate >= 2) {
            this.changePlaybackRate.emit(0.5);
        } else {
            this.changePlaybackRate.emit(rate + 0.5);
        }
    }
}

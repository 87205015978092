<mat-card>
    <mat-card-header class="card-header">
        <mat-card-title>Shareable Links</mat-card-title>
        <ng-container [ngSwitch]="selectedIndex$ | async">
            <ng-container *ngSwitchCase="TabIndex.Links" [ngTemplateOutlet]="links"></ng-container>
            <ng-container *ngSwitchCase="TabIndex.Templates" [ngTemplateOutlet]="templates"></ng-container>
        </ng-container>
    </mat-card-header>
    <mat-card-content>
        <ui-error-display [error]="error$ | async"></ui-error-display>
        <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            [selectedIndex]="selectedIndex$ | async"
            (selectedTabChange)="onTabClick($event)"
        >
            <mat-tab label="Active">
                <admin-shareable-link-list
                    [isLoading]="isLoading$ | async"
                    [links]="links$ | async"
                    (delete)="onArchive($event)"
                    (edit)="onEdit($event)"
                    (openLink)="onOpenLink($event)"
                    (copyLink)="onCopyLink($event)"
                    (copyLinkToClipboard)="onCopyLinkToClipboard($event)"
                    (refresh)="onRefresh()"
                    (view)="onView($event)"
                    (extend)="onExtend($event)"
                ></admin-shareable-link-list>
            </mat-tab>
            <mat-tab label="Create Link Templates">
                <admin-shareable-link-template-list
                    [templates]="shareableLinkTemplates$ | async"
                    [isLoading]="isLoading$ | async"
                    (edit)="onEditShareableLinkTemplate($event)"
                    (copyTemplate)="onCopyShareableLinkTemplate($event)"
                    (delete)="onDeleteShareableLinkTemplate($event)"
                    (refresh)="onRefreshShareableLinkTemplates()"
                >
                </admin-shareable-link-template-list>
            </mat-tab>
            <mat-tab label="Archived">
                <admin-archived-link-list
                    [tags]="tags$ | async"
                    [isLoading]="isLoading$ | async"
                    [queryResult]="archivedLinks$ | async"
                    [searchOptions]="archivedLinksSearchOptions$ | async"
                    (delete)="onDelete($event)"
                    (view)="onViewArchivedLink($event)"
                    (copyLink)="onCopyLink($event)"
                    (refresh)="onRefreshArchived($event)"
                >
                </admin-archived-link-list>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<ng-template #links>
    <mat-button-toggle-group>
        <mat-button-toggle (click)="onAddLink()"> <mat-icon>add</mat-icon>Add</mat-button-toggle>
        <mat-button-toggle *ngIf="shareableLinkTemplates$ | async | any" class="templates" [matMenuTriggerFor]="menu">
            <mat-icon>arrow_drop_down</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onSelectTemplate(template)" *ngFor="let template of shareableLinkTemplates$ | async">
            {{ template.templateName }}
        </button>
    </mat-menu>
</ng-template>
<ng-template #templates>
    <a mat-button (click)="onCopyShareableLinkTemplate(null)"> <mat-icon>add</mat-icon>Add Template</a>
</ng-template>

<mat-card>
    <mat-card-header>
        <mat-card-title-group>
            <mat-card-title>{{ link()?.url }} ({{ category() }})</mat-card-title>
        </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
        <table mat-table multiTemplateDataRows [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    @if(element.isGuest){ (Guest) } {{ element.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                    @for(registrant of element.registrantsInfo; track $index){

                    <mat-icon
                        class="webinar-icon"
                        [ngClass]="{
                            'has-registered': registrant.hasRegistered,
                            'has-joined': registrant.hasJoined
                        }"
                        cdkOverlayOrigin
                        #origin="cdkOverlayOrigin"
                        svgIcon="zoom_logo"
                    ></mat-icon>
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="joinTime">
                <th mat-header-cell *matHeaderCellDef>Join Time</th>
                <td mat-cell *matCellDef="let element">
                    @for(registrant of element.registrantsInfo; track $index){
                    {{ registrant.joinTime ? (registrant.joinTime | formatDate) : '--' }}
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="leaveTime">
                <th mat-header-cell *matHeaderCellDef>Leave Time</th>
                <td mat-cell *matCellDef="let element">
                    @for(registrant of element.registrantsInfo; track $index){
                    {{ registrant.leaveTime ? (registrant.leaveTime | formatDate) : '--' }}
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="durationInSeconds">
                <th mat-header-cell *matHeaderCellDef>Duration</th>
                <td mat-cell *matCellDef="let element">
                    @for(registrant of element.registrantsInfo; track $index){
                    {{ registrant.durationInSeconds ? (registrant.durationInSeconds | elapsedTime) : '--' }}
                    }
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name']"></tr>
            <tr mat-row [ngClass]="{ 'has-guests': row.hasGuests }" *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-card-content>
</mat-card>

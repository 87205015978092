import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h2 matDialogTitle><mat-icon>app_blocking</mat-icon> Browser not supported</h2>
        <mat-dialog-content>
            At this time, the browser <i>{{ browser }}</i> is not supported. Please use an alternative browser.
        </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }
        `,
    ],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatIcon,
        CdkScrollable,
        MatDialogContent,
    ],
})
export class DenyBrowserDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public browser: any, private deviceService: DeviceDetectorService) {}
}

import { Injector, Provider } from '@angular/core';

import {
    TiptapVideoContainerComponent,
} from '@app/shared/containers/tiptap-video-container/tiptap-video-container.component';
import { createAudioExtension } from '@app/shared/tiptap/audio';
import { createFileExtension } from '@app/shared/tiptap/file';
import { Color } from '@app/shared/tiptap/font-colour';
import { FontForeground } from '@app/shared/tiptap/font-foreground';
import { FontSize } from '@app/shared/tiptap/font-size';
import { createGuestLinkExtension } from '@app/shared/tiptap/guest-link';
import { createImageExtension } from '@app/shared/tiptap/image';
import { Indent } from '@app/shared/tiptap/indent';
import { createMediaAssetExtension } from '@app/shared/tiptap/media-asset';
import { createVideoExtension } from '@app/shared/tiptap/video';
import { createZoomLinkExtension } from '@app/shared/tiptap/zoom-link';
import { APP_TIPTAP_EDITOR_CONFIG } from '@app/shared/tokens';
import FontFamily from '@tiptap/extension-font-family';
import GapCursor from '@tiptap/extension-gapcursor';
import Highlight from '@tiptap/extension-highlight';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import TaskItem from '@tiptap/extension-task-item';
import Tasklist from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

import { TiptapAudioContainerComponent } from './containers/tiptap-audio-container/tiptap-audio-container.component';
import {
    TiptapGuestLinkContainerComponent,
} from './containers/tiptap-guest-link-container/tiptap-guest-link-container.component';
import { TiptapImageContainerComponent } from './containers/tiptap-image-container/tiptap-image-container.component';
import {
    TiptapMediaAssetContainerComponent,
} from './containers/tiptap-media-asset-container/tiptap-media-asset-container.component';
import {
    TiptapZoomLinkContainerComponent,
} from './containers/tiptap-zoom-link-container/tiptap-zoom-link-container.component';
import { createLinkExtension } from './tiptap/link';

export const PROVIDERS: Provider[] = [
    {
        provide: APP_TIPTAP_EDITOR_CONFIG,
        useFactory: (injector: Injector) => {
            return {
                editable: false,
                extensions: [
                    Indent,
                    StarterKit,
                    createLinkExtension(injector).configure({ openOnClick: false, linkOnPaste: true, validate: (url) => true }),
                    createFileExtension(injector),
                    createImageExtension(injector, TiptapImageContainerComponent),
                    createVideoExtension(injector, TiptapVideoContainerComponent),
                    createAudioExtension(injector, TiptapAudioContainerComponent),
                    createMediaAssetExtension(injector, TiptapMediaAssetContainerComponent),
                    createZoomLinkExtension(injector, TiptapZoomLinkContainerComponent),
                    createGuestLinkExtension(injector, TiptapGuestLinkContainerComponent),
                    Underline,
                    GapCursor,
                    Highlight,
                    Color,
                    FontSize,
                    TextStyle,
                    FontForeground,
                    FontFamily,
                    // Link.configure({ openOnClick: false, linkOnPaste: true }),

                    HorizontalRule,
                    TextAlign.configure({
                        types: ['heading', 'paragraph', 'video', 'inlineAudio', 'zoomLink'],
                    }),
                    Tasklist.configure({}),
                    TaskItem.configure({
                        nested: true,
                    }),
                ],
            };
        },
        deps: [Injector],
    },
];

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { filter, startWith, takeUntil, tap, timer } from 'rxjs';

import { FormSection, PaymentModel } from '@app/data/models';
import { SECONDS_10, SECONDS_15 } from '@app/shared/constants';
import { PaymentMethod, PaymentProvider, PaymentStatus } from '@app/shared/enums';
import { DestroyService } from '@app/shared/services';
import { hasContent, isNullOrEmpty } from '@app/shared/util';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Environment } from '@app/shared/models';
import { MatCard, MatCardContent, MatCardActions } from '@angular/material/card';
import { RichTextViewComponent } from '../rich-text-view/rich-text-view.component';
import { NgClass, NgTemplateOutlet, CurrencyPipe } from '@angular/common';
import { MatList, MatListItem, MatListItemIcon, MatListItemTitle, MatListItemLine } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AnyPipe } from '../../../../../shared/src/lib/pipes/any.pipe';
import { PaymentStatusPipe } from '../../../../../shared/src/lib/pipes/payment-status.pipe';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';

@Component({
    selector: 'ui-form-section-event',
    templateUrl: './form-section-event.component.html',
    styleUrls: ['./form-section-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
    imports: [
    MatCard,
    MatCardContent,
    RichTextViewComponent,
    MatList,
    MatListItem,
    MatIcon,
    MatListItemIcon,
    ExtendedModule,
    NgClass,
    MatListItemTitle,
    MatListItemLine,
    MatButton,
    MatDivider,
    MatProgressBar,
    NgTemplateOutlet,
    MatCardActions,
    AnyPipe,
    PaymentStatusPipe,
    FormatDatePipe,
    CurrencyPipe
],
})
export class FormSectionEventComponent implements OnInit, OnChanges {
    PaymentStatus: typeof PaymentStatus = PaymentStatus;
    PaymentMethod: typeof PaymentMethod = PaymentMethod;
    PaymentProvider: typeof PaymentProvider = PaymentProvider;

    showPayNow = false;
    makingPayment = false;
    isJoiningMeeting = false;
    hasSuccessMessage = false;
    pollingCount = 0;
    checkout: Stripe | null = null;

    get isDisabled() {
        return this.makingPayment || (this.payments && this.payments.some(p => p.status === PaymentStatus.Complete));
    }

    get showTitle() {
        return this.model && this.model.title;
    }

    get title() {
        return this.model?.title;
    }

    get showLink() {
        return !isNullOrEmpty(this.link);
    }

    get shouldDisplayPending() {
        return (
            (this.isTransactionPending() && this.model?.paymentProvider === PaymentProvider.Paypal) ||
            (this.isTransactionWaiting() && this.model?.paymentProvider === PaymentProvider.Stripe)
        );
    }

    @Input() isCancelling: boolean | null = false;
    @Input() readonly: boolean | null = false;
    @Input() link: string | null = null;
    @Input() message: string | null = null;
    @Input() payments: PaymentModel[] | null = null;
    @Input() model: FormSection | null = null;

    @Output() readonly cancelPayment = new EventEmitter<PaymentModel>();
    @Output() readonly loadPayments = new EventEmitter<void>();
    @Output() readonly makePayment = new EventEmitter<void>();
    @Output() readonly retryPayment = new EventEmitter<PaymentModel>();
    @Output() readonly pollPaymentStatus = new EventEmitter<string>();
    @Output() readonly joinMeeting = new EventEmitter<void>();

    constructor(private destroy$: DestroyService, @Inject(APP_ENVIRONMENT) private env: Environment) {}

    async ngOnInit(): Promise<void> {
        timer(SECONDS_15, SECONDS_10)
            .pipe(
                takeUntil(this.destroy$),
                startWith(null),
                filter(() => !this.showLink && (this.isTransactionPending() || this.isTransactionWaiting())),
                tap(() => this.pollingCount++),
                tap(() => {
                    if (this.pollingCount >= 3) {
                        // this.showPayNow = true;
                        this.onCancelPayment();
                    }
                }),
                tap(() => this.onLoadPayments()),
                tap(() => this.onPollPaymentStatus()),
            )
            .subscribe();

        this.checkout = await loadStripe(this.env.stripePublishableKey as string);
    }

    isPaymentPending(payment: PaymentModel) {
        return payment.status === PaymentStatus.Pending;
    }

    isPaymentComplete(payment: PaymentModel) {
        return payment.status === PaymentStatus.Complete;
    }

    isPaymentRefunded(payment: PaymentModel) {
        return payment.status === PaymentStatus.Refunded;
    }

    isTransactionWaiting() {
        return (this.payments && this.payments.some(p => p.status === PaymentStatus.Waiting)) === true;
    }

    isTransactionPending() {
        return (this.payments && this.payments.some(p => p.status === PaymentStatus.Pending)) === true;
    }

    isTransactionComplete() {
        return (this.payments && this.payments.some(p => p.status === PaymentStatus.Complete)) === true;
    }

    onJoinMeeting() {
        this.isJoiningMeeting = true;
        this.joinMeeting.emit();
    }

    onLoadPayments() {
        this.loadPayments.emit();
    }

    onCancelPayment() {
        const payment = this.payments?.find(p => p.status === PaymentStatus.Pending);
        if (payment) {
            this.cancelPayment.emit(payment);
        }
    }

    onRetryPayment(payment: PaymentModel) {
        if (payment) {
            this.retryPayment.emit(payment);
        }
    }

    onPollPaymentStatus() {
        this.pollPaymentStatus.emit(this.model?.id as string);
    }

    onPurchaseTicket() {
        if (this.isDisabled) {
            return;
        }

        this.makingPayment = true;

        this.makePayment.emit();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes['message'] && this.message) {
            this.hasSuccessMessage = hasContent(this.message, true);
        }
    }
}

import { CdkScrollable } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';

import { ScheduledEvent } from '@app/data/models';

import { RichTextViewComponent } from '../../components/rich-text-view/rich-text-view.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './submission-complete-dialog.component.html',
    styleUrls: ['./submission-complete-dialog.component.scss'],
    standalone: true,
    imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    RichTextViewComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    DatePipe
],
})
export class SubmissionCompleteDialogComponent {
    get showCloseButton() {
        return this.data.showCloseButton || false;
    }

    get scheduledEvents(): ScheduledEvent[] {
        return this.data.scheduledEvents || [];
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

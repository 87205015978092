import { EventLinkModel, SearchOptionsModel, Submission } from '@app/data/models';
import { ErrorModel } from '@app/shared/models';
import { Timezone } from 'timezones.json';

const ns = `[Manage Events]`;

export class Save {
    static readonly type = `${ns} Save`;
}

export class SaveFailure {
    static readonly type = `${ns} Save Failure`;
    constructor(public error: any) {}
}

export class Edit {
    static readonly type = `${ns} Edit`;
}

export class LoadLinks {
    static readonly type = `${ns} Load Links`;
}
export class LoadLinksDone {
    static readonly type = `${ns} Load Links Done`;
}
export class LoadLinksSuccess {
    static readonly type = `${ns} Load Links Success`;
}

export class LoadLinksFailure {
    static readonly type = `${ns} Load Links Failure`;
    constructor(public error: any) {}
}

export class EnsureLoadLinks {
    static readonly type = `${ns} Ensure Load`;
}

export class EnsureLoadArchivedLinks {
    static readonly type = `${ns} Ensure Archived Load`;
}

export class EnsureLoadTemplates {
    static readonly type = `${ns} Ensure Load Templates`;
}

export class LoadLinkSessions {
    static readonly type = `${ns} Load Link Sessions`;
    constructor(public rowKey: string) {}
}

export class LoadLinkSessionsFailure {
    static readonly type = `${ns} Load Link Sessions Failure`;
    constructor(public error: any) {}
}

export class LoadLinkRegistrants {
    static readonly type = `${ns} Load Link Registrants`;
    constructor(public rowKey?: string) {}
}

export class LoadLinkRegistrantsFailure {
    static readonly type = `${ns} Load Link Registrants Failure`;
    constructor(public error: any) {}
}

export class LoadLinkSubmissions {
    static readonly type = `${ns} Load Link Submissions`;
    constructor(public rowKey: string) {}
}

export class LoadLinkSubmissionsFailure {
    static readonly type = `${ns} Load Link Submissions Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLinksFailure {
    static readonly type = `${ns} Load Archived Links Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLink {
    static readonly type = `${ns} Load Archived Link`;
    constructor(public rowKey: string) {}
}
export class LoadArchivedLinkSuccess {
    static readonly type = `${ns} Load Archived Link Success`;
}
export class LoadArchivedLinkFailure {
    static readonly type = `${ns} Load Archived Link Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLinkSessions {
    static readonly type = `${ns} Load Archived Link Sessions`;
    constructor(public rowKey?: string | null) {}
}

export class LoadArchivedLinkSessionsFailure {
    static readonly type = `${ns} Load Archived Link Sessions Failure`;
    constructor(public error: any) {}
}

export class Error {
    static readonly type = `${ns} Error`;
    constructor(public error: any, public source: any) {}
}

export class ActivateSession {
    static readonly type = `${ns} Activate Session`;
    constructor(public rowKey: string) {}
}

export class ActivateSessionFailure {
    static readonly type = `${ns} Activate Session Failure`;
    constructor(public error: any) {}
}

export class DeactivateSession {
    static readonly type = `${ns} Deactivate Session`;
    constructor(public rowKey: string) {}
}

export class DeactivateSessionFailure {
    static readonly type = `${ns} Deactivate Session Failure`;
    constructor(public error: any) {}
}

export class UpdateSessionCount {
    static readonly type = `${ns} Update Session Count`;
    constructor(public rowKey: string) {}
}

export class UpdateSessionCountFailure {
    static readonly type = `${ns} Update Session Count Failure`;
    constructor(public error: any) {}
}

export class ConfirmMergeSession {
    static readonly type = `${ns} Confirm Merge Sessions`;
    constructor(public rowKeys: string[]) {}
}

export class MergeSessions {
    static readonly type = `${ns} Merge Sessions`;
    constructor(public primaryRowKey: string, public rowKeys: string[]) {}
}

export class MergeSessionsFailure {
    static readonly type = `${ns} Merge Sessions Failure`;
    constructor(public error: any) {}
}

export class ConfirmDeleteSession {
    static readonly type = `${ns} Confirm Delete Session`;
    constructor(public rowKeys: string[]) {}
}

export class DeleteSessions {
    static readonly type = `${ns} Delete Session`;
    constructor(public rowKeys: string[]) {}
}

export class DeleteSessionFailure {
    static readonly type = `${ns} Delete Session Failure`;
    constructor(public error: any) {}
}

export class ApproveSessions {
    static readonly type = `${ns} Approve Sessions`;
    constructor(public rowKeys: string[], public linkId: string) {}
}

export class ApproveSessionsFailure {
    static readonly type = `${ns} Approve Sessions Failure`;
    constructor(public error: any) {}
}

export class SyncActiveSessions {
    static readonly type = `${ns} Sync Active Sessions`;
    constructor(public rowKey: string) {}
}

export class SyncActiveSessionsFailure {
    static readonly type = `${ns} Sync Active Sessions Failure`;
    constructor(public error: any) {}
}

export class ArchiveLinks {
    static readonly type = `${ns} Archive Link`;
    constructor(public links: EventLinkModel[]) {}
}

export class ArchiveLinksFailure {
    static readonly type = `${ns} Archive Link Failure`;
    constructor(public error: any) {}
}

export class ConfirmArchiveLinks {
    static readonly type = `${ns} Confirm Archive Link`;
    constructor(public links: EventLinkModel[]) {}
}

export class ConfirmDeleteLinks {
    static readonly type = `${ns} Confirm Delete Link`;
    constructor(public links: EventLinkModel[]) {}
}

export class DeleteLinks {
    static readonly type = `${ns} Delete Link`;
    constructor(public links: EventLinkModel[]) {}
}

export class DeleteLinksFailure {
    static readonly type = `${ns} Delete Link Failure`;
    constructor(public error: any) {}
}

export class CopyLink {
    static readonly type = `${ns} Copy Link`;
    constructor(public link: EventLinkModel) {}
}

export class CopyLinkToClipboard {
    static readonly type = `${ns} Copy Link to Clipboard`;
    constructor(public link: EventLinkModel, public timezone: Timezone, public includeInfluencer: boolean = false) {}
}

export class OpenLink {
    static readonly type = `${ns} Open Link`;
    constructor(public link: EventLinkModel) {}
}

export class SetCurrentTabIndex {
    static readonly type = `${ns} Set Current Tab Index`;
    constructor(public index: number) {}
}

export class SearchArchivedLinks {
    static readonly type = `${ns} Search Archived Links`;
    constructor(public model?: SearchOptionsModel) {}
}

export class SearchArchivedLinksFailure {
    static readonly type = `${ns} Search Archived Links Failure`;
    constructor(public error: any) {}
}
export class UploadFile {
    static readonly type = `${ns} Upload File`;
    constructor(public fileId: string, public getFile: () => File) {}
}

export class AcknowledgeSubmissions {
    static readonly type = `${ns} Acknowledge Submissions`;
    constructor(public submissionRowKeys: string[], public linkId?: string) {}
}

export class AcknowledgeSubmissionsFailure {
    static readonly type = `${ns} Acknowledge Submissions Failure`;
    constructor(public err: any) {}
}

export class UpdateLinkSummary {
    static readonly type = `${ns} Update Link Summary`;
}

export class UpdateLinkSummaryFailure {
    static readonly type = `${ns} Update Link Summary Failure`;
    constructor(public err: any) {}
}

export class CompleteSubmission {
    static readonly type = `${ns} Complete Submission`;
    constructor(public submission: Submission) {}
}

export class CompleteSubmissionFailure {
    static readonly type = `${ns} Complete Submission Failure`;
    constructor(public err: any) {}
}

export class LoadFormTemplates {
    static readonly type = `${ns} Load Form Templates`;
}
export class LoadFormTemplatesSuccess {
    static readonly type = `${ns} Load Form Templates Success`;
}
export class LoadFormTemplatesDone {
    static readonly type = `${ns} Load Form Templates Done`;
}

export class LoadFormTemplatesFailure {
    static readonly type = `${ns} Load Form Templates Failure`;
    constructor(public err: any) {}
}

export class SaveFormTemplate {
    static readonly type = `${ns} Save Form Link Template`;
}

export class SaveFormTemplateFailure {
    static readonly type = `${ns} Save Form Link Template Failure`;
    constructor(public err: any) {}
}

export class DeleteFormTemplate {
    static readonly type = `${ns} Delete Form Link Template`;
}

export class DeleteFormTemplateFailure {
    static readonly type = `${ns} Delete Form Link Template Failure`;
    constructor(public err: any) {}
}

export class ShowFormTemplateDialog {
    static readonly type = `${ns} Show Form Template Dialog`;
}

export class LoadLinkPayments {
    static readonly type = `${ns} Load Link Payments`;
    constructor(public rowKey?: string) {}
}

export class LoadLinkPaymentsFailure {
    static readonly type = `${ns} Load Link Payments Failure`;
    constructor(public err: any) {}
}

export class AcknowledgeInfluencerNotification {
    static readonly type = `${ns} Acknowledge Influencer Notifications`;
    constructor(public rowKeys: string[]) {}
}

export class AcknowledgeInfluencerNotificationFailure {
    static readonly type = `${ns} Acknowledge Influencer Notifications Failure`;
    constructor(public error: any) {}
}

export class LoadSubmissionMessagesForLink {
    static readonly type = `${ns} Load Submission Messages For Link`;
    constructor(public link: EventLinkModel) {}
}

export class SaveFormSubmissionMessages {
    static readonly type = `${ns} Save Form Submission Messages`;
    constructor(public linkId: string) {}
}

export class LoadParticipants {
    static readonly type = `${ns} Load Participants`;
    constructor(public rowKey?: string) {}
}

export class LoadParticipantsSuccess {
    static readonly type = `${ns} Load Participants Success`;
}

export class LoadParticipantsFailure {
    static readonly type = `${ns} Load Participants Failure`;
    constructor(public error: any) {}
}

export class SyncParticipants {
    static readonly type = `${ns} Sync Participants`;
}
export class SyncParticipantsFailure {
    static readonly type = `${ns} Sync Participants Failure`;
    constructor(public error: any) {}
}
export class ValidationError {
    static readonly type = `${ns} Validation Error`;
    constructor(public error: ErrorModel<any>) {}
}

export class LoadLink {
    static readonly type = `${ns} Load Link`;
    constructor(public value: string) {}
}

export class LoadGuestRegistrations {
    static readonly type = `${ns} Load Guest Registrations`;
    constructor(public rowKey: string) {}
}

export class LoadGuestRegistrationsSuccess {
    static readonly type = `${ns} Load Guest Registrations Success`;
}

export class LoadGuestRegistrationsFailure {
    static readonly type = `${ns} Load Guest Registrations Failure`;
    constructor(public error: any) {}
}

import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';

import { debounceTime, filter, finalize, from, map, mergeMap, Subject, takeUntil, tap } from 'rxjs';

import { DropMediaModel } from '@app/data/models';
import { AuthState } from '@app/data/state/auth.state';
import { Environment, ServerResult } from '@app/shared/models';
import { DestroyService } from '@app/shared/services';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { isOldStorageUrl, LOADING_SPINNER, migrateToStorageUrl, prepareImageForUpload } from '@app/shared/util';
import { Store } from '@ngxs/store';
import { AngularNodeViewComponent, NgxTiptapModule } from 'ngx-tiptap';
import { NgTemplateOutlet, NgStyle, NgClass, AsyncPipe } from '@angular/common';
import { SrcUnloadDirective } from '../../../../../shared/src/lib/directives/src-unload.directive';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { CdnUrlPipe } from '../../../../../shared/src/lib/pipes/cdn-url.pipe';

@Component({
    selector: 'admin-tiptap-image-container',
    templateUrl: './tiptap-image-container.component.html',
    styleUrls: ['./tiptap-image-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgxTiptapModule,
        SrcUnloadDirective,
        ExtendedModule,
        NgStyle,
        NgClass,
        MatProgressSpinner,
        SafeUrlPipe,
        CdnUrlPipe,
        AsyncPipe,
    ],
})
export class TiptapImageContainerComponent extends AngularNodeViewComponent implements OnInit, AfterViewInit {
    uploadCheck$ = new Subject<void>();

    id = performance.now().toString();
    isUploading = false;
    progress = 0;

    tokenResult = this.store.selectSnapshot(AuthState.getToken());

    get model(): DropMediaModel | null {
        return this.node.attrs['file'] || null;
    }

    get size(): 'small' | 'medium' | 'large' {
        return this.node.attrs['size'];
    }

    get file(): File | null {
        return this.model?.file || null;
    }

    get isPublic(): boolean {
        return this.model?.isPublic === true;
    }

    get src() {
        return this.node.attrs['src'] || LOADING_SPINNER;
    }

    get isEditable() {
        return this.editor.isEditable;
    }

    get isSelected() {
        return this.selected;
    }

    get title() {
        return this.node.attrs['title'] || '';
    }

    get styles() {
        const textAlign = this.node.attrs['textAlign'];

        return {
            'text-align': textAlign,
        };
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private store: Store,
        private destroy$: DestroyService,
        private cd: ChangeDetectorRef,
        private http: HttpClient,
    ) {
        super();
    }

    getClasses() {
        return {
            selected: this.isSelected && this.isEditable,
            'custom-image-small': this.size === 'small',
            'custom-image-medium': this.size === 'medium',
            'custom-image-large': this.size === 'large',
        };
    }

    ngOnInit(): void {
        this.editor.on('transaction', ({ editor, transaction }) => {
            this.cd.detectChanges();
            this.uploadCheck$.next();
        });

        this.editor.on('selectionUpdate', () => {
            this.cd.detectChanges();
            this.uploadCheck$.next();
        });

        this.uploadCheck$
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(250),
                filter(s => this.isUploading === false && this.file !== null),
                tap(() => {
                    this.progress = 0;
                    this.isUploading = true;
                }),
                mergeMap(() => prepareImageForUpload(this.file as File)),
                mergeMap(src => fetch(src)),
                mergeMap(response =>
                    from(response.arrayBuffer()).pipe(
                        map(buffer => ({ buffer, contentType: response.headers.get('Content-Type') as string })),
                    ),
                ),
                mergeMap(({ buffer, contentType }) => {
                    const { name } = this.file as File;
                    const file = new File([buffer], name || 'file', { type: contentType });
                    const formData = new FormData();

                    formData.append('isPublic', this.isPublic.toString());
                    formData.append('data', file);
                    formData.append('contentType', contentType);

                    return this.http
                        .post(`${this.env.serverUrl}/file/public/upload`, formData, {
                            reportProgress: true,
                            observe: 'events',
                            responseType: 'json',
                        })
                        .pipe(
                            takeUntil(this.destroy$),
                            map((response: HttpEvent<object>) => {
                                if (response.type === HttpEventType.Response) {
                                    return (response.body as ServerResult<string>).data;
                                } else if (response.type === HttpEventType.UploadProgress) {
                                    this.progress = Math.floor(100 * (response.loaded / (response?.total || 0)));
                                    this.cd.detectChanges();
                                }

                                return '';
                            }),
                            finalize(() => {
                                this.isUploading = false;
                                this.cd.detectChanges();
                            }),
                        );
                }),
                filter(src => src !== ''),
                tap(src => {
                    this.updateAttributes({ src, file: null });
                    this.cd.detectChanges();
                }),
            )
            .subscribe();

        const srcAttr = this.node.attrs['src'] || '';

        // Migrate old storage urls to new storage urls
        if (isOldStorageUrl(srcAttr)) {
            this.updateAttributes({ src: migrateToStorageUrl(srcAttr, this.env, 'image') });
            this.cd.markForCheck();
        }
    }

    ngAfterViewInit() {
        this.uploadCheck$.next();
    }
}

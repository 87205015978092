import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';

import { FormatDatePipe } from '../../pipes/format-date.pipe';

@Component({
    selector: 'shared-build-info',
    template: `
        @if (show$ | async) {
          <div class="build-info">
            <span>{{ env.buildVersion }} ({{ env.buildDate | formatDate }})</span>
          </div>
        }
        `,
    styles: [
        `
            .build-info {
                pointer-events: none;
                opacity: 0.5;
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 12px;
                z-index: 999;
            }
        `,
    ],
    standalone: true,
    imports: [
    FormatDatePipe,
    AsyncPipe
],
})
export class BuildInfoComponent {
    show$ = this.store.select(state => state.debug?.isEnabled);

    constructor(@Inject(APP_ENVIRONMENT) protected env: Environment, private store: Store) {}
}

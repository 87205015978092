import { ChangeDetectionStrategy, ChangeDetectorRef, Component, input, Input } from '@angular/core';
import { MatDivider } from '@angular/material/divider';

import { FormPage, FormSection, LinkSessionSummary, Submission } from '@app/data/models';
import { FormSectionType } from '@app/shared/enums';

import { ViewFormSubmissionComponent } from '../view-form-submission/view-form-submission.component';

@Component({
    selector: 'ui-view-form-submissions',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './view-form-submissions.component.html',
    styleUrls: ['./view-form-submissions.component.scss'],
    standalone: true,
    imports: [MatDivider, ViewFormSubmissionComponent],
})
export class ViewFormSubmissionsComponent {
    FormSectionType: typeof FormSectionType = FormSectionType;

    readonly summaries = input<LinkSessionSummary[] | null>(null);
    readonly submissions = input<Submission[] | null>(null);
    readonly pages = input<FormPage[] | null>([]);

    sections: FormSection[] = [];

    getFormSubmission(summary: LinkSessionSummary): Submission {

        return this.submissions()?.find(
            s => s.visitorId === summary.visitorId && s.sessionId === summary.selectedSessionId,
        ) as Submission;
    }
}

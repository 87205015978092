import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { CoreActions, InsightActions, NotificationActions } from '@app/data/actions';
import {
    AdminRegistrant,
    ArchivedEventLinkContentModel,
    EditEventLinkModel,
    EventLinkModel,
    EventLinkTemplateModel,
    FormSection,
    FormSubmissionMessage,
    GuestRegistration,
    LinkSessionSummary,
    Participant,
    PaymentModel,
    PreambleTemplate,
    QueryResult,
    SearchOptionsModel,
    Submission,
} from '@app/data/models';
import { handleNotFoundError, transformResponseToText } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { RouterState } from '@app/data/state/router.state';
import {
    BlobContainer,
    ErrorCodes,
    FormQuestionType,
    FormSectionType,
    LinkExpiration,
    LinkInactivityMode,
    LinkStatus,
    LinkType,
    RegistrationMode,
    TimerUnit,
    VideoOption,
} from '@app/shared/enums';
import { Environment, isErrorModel } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import {
    convertObjectKeysToCamelCase,
    DEFAULT_TIME_ZONE,
    formatDatetimeWithTimeZone,
    formatLinkUrl,
    generatePassword,
    isArray,
    isDate,
    isDst,
    isNullOrEmpty,
    isString,
    parseTimeZoneFromOffset,
    randomId,
    toDateTimeString,
} from '@app/shared/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Actions, createSelector, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';
import { Timezone } from 'timezones.json';

import { ManageEventActions, ManageTemplateActions } from '../actions';
import {
    ArchiveContentLinkConfirmationDialogComponent,
} from '../dialogs/archive-content-link-confirmation/archive-content-link-confirmation-dialog.component';
import {
    DeleteLinkSessionsConfirmationDialogComponent,
} from '../dialogs/delete-link-session-confirmation/delete-link-session-confirmation-dialog.component';
import {
    DeleteShareableLinkConfirmationDialogComponent,
} from '../dialogs/delete-shareable-link-confirmation/delete-shareable-link-confirmation-dialog.component';
import {
    MergeLinkSessionsConfirmationDialogComponent,
} from '../dialogs/merge-link-sessions-confirmation/merge-link-sessions-confirmation-dialog.component';
import { ValidationSummaryComponent } from '../dialogs/validation-summary/validation-summary.component';
import { ManageEventLinksService,  } from '../services/manage-event-links.service';
import { ManageTemplatesState } from './manage-templates.state';
import { ManageMediaService } from '../services/manage-media.service';

interface StateModel {
    searchArchivedLinksOptions: SearchOptionsModel | null;
    searchArchivedLinksQueryResult: QueryResult<EventLinkModel> | null;
    tags: string[];

    isSearching: boolean;
    hasLoadedLinks: boolean;
    hasLoadedArchivedLinks: boolean;
    hasLoadedTemplates: boolean;
    isLoadingLinks: boolean;
    isLoadingArchivedLinks: boolean;
    isLoadingTemplates: boolean;
    isSaving: boolean;
    errors: any;
    links: EventLinkModel[];
    templates: EventLinkTemplateModel[];
    archivedContent: ArchivedEventLinkContentModel | null;
    archivedLinks: EventLinkModel[];
    archivedLink: EventLinkModel | null;
    linkSessions: LinkSessionSummary[];
    linkRegistrants: AdminRegistrant[];
    guestRegistrations: GuestRegistration[];
    linkPayments: PaymentModel[];
    linkSubmissions: Submission[];
    formSubmissionMessages: FormSubmissionMessage[];
    copyLink: EventLinkModel | null;
    editLink: EventLinkModel | null;
    currentTabIndex: number;

    isLoadingParticipants: boolean;
    participants: Participant[];

    form: {
        model: EditEventLinkModel | null;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
    filters: {
        model: any;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
    archivedFilters: {
        model: any;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
    sessionFilters: {
        model: any;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
    searchArchivedLinks: {
        model: SearchOptionsModel | null;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
}

@State<StateModel>({
    name: 'manageEvents',
    defaults: {
        isSearching: false,
        searchArchivedLinksQueryResult: null,
        searchArchivedLinksOptions: { filter: '', tags: [], total: null, limit: 25, offset: 0 },
        tags: [],

        hasLoadedLinks: false,
        hasLoadedArchivedLinks: false,
        isLoadingLinks: false,
        isLoadingArchivedLinks: false,
        isLoadingTemplates: false,
        hasLoadedTemplates: false,
        isSaving: false,
        errors: null,
        links: [],
        templates: [],
        archivedContent: null,
        archivedLinks: [],
        archivedLink: null,
        linkSessions: [],
        linkRegistrants: [],
        guestRegistrations: [],
        linkPayments: [],
        linkSubmissions: [],
        copyLink: null,
        editLink: null,
        currentTabIndex: 0,
        formSubmissionMessages: [],

        isLoadingParticipants: false,
        participants: [],

        form: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        filters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        archivedFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        sessionFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        searchArchivedLinks: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class ManageEventsState {
    readonly format: string = 'MMM DD YYYY @ hh:mmA';

    static hasLoadedLinks() {
        return createSelector([ManageEventsState], (state: StateModel) => state.hasLoadedLinks);
    }

    static isLoading() {
        return createSelector([ManageEventsState], (state: StateModel) => state.isLoadingLinks);
    }

    static isLoadingArchivedLinks() {
        return createSelector([ManageEventsState], (state: StateModel) => state.isLoadingArchivedLinks);
    }

    static currentTabIndex() {
        return createSelector([ManageEventsState], (state: StateModel) => state.currentTabIndex);
    }

    static isNewLink() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId === 'new');
    }

    static getFormSubmissionMessages() {
        return createSelector([ManageEventsState], (state: StateModel) => state.formSubmissionMessages);
    }

    static getParticipants() {
        return createSelector([ManageEventsState], (state: StateModel) => state.participants);
    }

    static isLoadingParticipants() {
        return createSelector([ManageEventsState], (state: StateModel) => state.isLoadingParticipants);
    }

    static getEditModel() {
        return createSelector([ManageEventsState], (state: StateModel) => state.editLink);
    }

    static getErrors() {
        return createSelector([ManageEventsState], (state: StateModel) => state.errors);
    }

    static getLinks() {
        return createSelector([ManageEventsState], (state: StateModel) => state.links);
    }

    static getCopyLink() {
        return createSelector([ManageEventsState], (state: StateModel) => state.copyLink);
    }

    static getArchivedContent() {
        return createSelector([ManageEventsState], (state: StateModel) => state.archivedContent);
    }

    static getArchivedLinkSessions() {
        return createSelector([ManageEventsState], (state: StateModel) => state.archivedContent?.sessions || []);
    }

    static getLinkSessions() {
        return createSelector([ManageEventsState], (state: StateModel) => state.linkSessions);
    }

    static getLinkRegistrants() {
        return createSelector([ManageEventsState], (state: StateModel) => state.linkRegistrants);
    }

    static getGuestRegistrations() {
        return createSelector([ManageEventsState], (state: StateModel) => state.guestRegistrations);
    }

    static getLinkSubmissions() {
        return createSelector([ManageEventsState], (state: StateModel) => state.linkSubmissions);
    }

    static getLinkPayments() {
        return createSelector([ManageEventsState], (state: StateModel) => state.linkPayments);
    }

    static isSaving() {
        return createSelector([ManageEventsState], (state: StateModel) => state.isSaving);
    }

    static isValid() {
        return createSelector([ManageEventsState], (state: StateModel) => state.form.status === 'VALID');
    }

    static getForm() {
        return createSelector([ManageEventsState], (state: StateModel) => state.form);
    }

    static getTags() {
        return createSelector([ManageEventsState], (state: StateModel) => state.tags);
    }

    static getSearchArchivedLinksForm() {
        return createSelector([ManageEventsState], (state: StateModel) => state.searchArchivedLinks);
    }

    static getSearchArchivedLinksSearchOptions() {
        return createSelector([ManageEventsState], (state: StateModel) => state.searchArchivedLinksOptions);
    }

    static isSearching() {
        return createSelector([ManageEventsState], (state: StateModel) => state.isSearching);
    }

    static getSearchArchivedLinksResults() {
        return createSelector([ManageEventsState], (state: StateModel) => state.searchArchivedLinksQueryResult);
    }

    static getArchivedLink() {
        return createSelector([ManageEventsState], (state: StateModel) => state.archivedLink);
    }

    static getLinkId() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId);
    }

    static getPreambleContentForLink() {
        return createSelector(
            [
                ManageEventsState.getLinkId(),
                ManageTemplatesState.getPreambleTemplates(),
                ManageTemplatesState.getPreambleTemplateForLink(),
            ],
            (linkId: string, templates: PreambleTemplate[], template: string | null) => {
                const preambleTemplates = templates
                    .reduce((acc, current) => {
                        const rowKey = current.rowKey as string;

                        if (!current.isGlobal || rowKey === 'preamble-events') {
                            acc.push(current);
                        }

                        return acc;
                    }, [] as PreambleTemplate[])
                    .sort((a, b) => {
                        if (a.isGlobal && !b.isGlobal) {
                            return -1;
                        } else if (!a.isGlobal && b.isGlobal) {
                            return 1;
                        }

                        return a.name.localeCompare(b.name);
                    });

                preambleTemplates.push({
                    rowKey: 'custom',
                    name: 'Custom',
                    isEditable: true,
                    isGlobal: false,
                    content: linkId !== 'new' ? template || '' : '',
                    createdDate: new Date(),
                    hasLoaded: true,
                    sasUri: '',
                });

                return preambleTemplates;
            },
        );
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private manageEventsService: ManageEventLinksService,
        private manageMediaService: ManageMediaService,
        private blobService: BlobService,
        private store: Store,
        private actions: Actions,
        private matDialog: MatDialog,
        private snack: MatSnackBar,
    ) {}

    @Action(UpdateFormValue)
    updateFormValue({ patchState, getState }: StateContext<StateModel>, action: UpdateFormValue): void {
        if (action.payload.path === 'manageEvents.filters') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }

        if (action.payload.path === 'manageEvents.searchArchivedLinks') {
            const options = getState().searchArchivedLinksOptions;
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
            patchState({ searchArchivedLinksOptions: { ...options, ...action.payload.value } });
        }

        if (action.payload.path === 'manageEvents.sessionFilters') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }
    }

    @Action(ManageEventActions.AcknowledgeInfluencerNotification)
    acknowledgeInfluencerNotification(
        { patchState, dispatch }: StateContext<StateModel>,
        action: ManageEventActions.AcknowledgeInfluencerNotification,
    ) {
        patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageEventsService.acknowledgeInfluencerNotification(action.rowKeys).pipe(
            tap(() => {
                const sessions = this.store.selectSnapshot(ManageEventsState.getLinkSessions());
                patchState({
                    linkSessions: sessions.map(session => {
                        if (action.rowKeys.includes(session.rowKey)) {
                            session.hasAdminAcknowledged = true;
                        }
                        return session;
                    }),
                });

                dispatch([new NotificationActions.Success('Submission(s) Acknowledged')]);
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.AcknowledgeInfluencerNotificationFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.SetCurrentTabIndex)
    setCurrentTabIndex(ctx: StateContext<StateModel>, action: ManageEventActions.SetCurrentTabIndex): void {
        ctx.patchState({
            currentTabIndex: action.index,
        });
        localStorage.setItem('manageEvents.currentTabIndex', JSON.stringify(action.index));
    }

    @Action(ManageEventActions.LoadLink)
    loadLink(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageEventActions.LoadLink,
    ): Observable<any> | void {
        const isNew = action.value === 'new';
        const links = this.store.selectSnapshot(ManageEventsState.getLinks());
        const copyLink = this.store.selectSnapshot(ManageEventsState.getCopyLink());
        const model = isNew ? copyLink || getDefaultEventLinkModel() : links.find(l => l.rowKey === action.value) || null;

        if (!model) {
            return;
        }

        patchState({
            editLink: null,
            copyLink: null,
            linkSessions: [],
            linkSubmissions: [],
            linkRegistrants: [],
            linkPayments: [],
            participants: [],
        });

        if (!isNew) {
            const rowKey: string = model.rowKey as string;
            // default
            return dispatch([
                new ManageEventActions.LoadLinkSessions(rowKey),
                new ManageEventActions.LoadLinkRegistrants(rowKey),
                new ManageEventActions.LoadLinkSubmissions(rowKey),
                new ManageEventActions.LoadParticipants(rowKey),
                new ManageEventActions.LoadLinkPayments(rowKey),
                new ManageEventActions.UpdateSessionCount(rowKey),
                new ManageEventActions.LoadSubmissionMessagesForLink(model),
                new ManageTemplateActions.LoadPreambleTemplateForLink(rowKey),
            ]).pipe(
                withLatestFrom(this.store.select(ManageEventsState.getFormSubmissionMessages())),
                tap(([_, submissionMessages]) => {
                    model.submissionMessages = submissionMessages;

                    patchState({
                        editLink: model,
                    });
                }),
            );
        }

        patchState({
            editLink: model,
        });
    }

    @Action(ManageEventActions.CopyLink)
    copyLink(
        { patchState, dispatch }: StateContext<StateModel>,
        action: ManageEventActions.CopyLink,
    ): Observable<any> | void {
        const { link } = action;

        if (!isNullOrEmpty(link.password)) {
            link.password = generatePassword();
        }

        if (!isNullOrEmpty(link.influencerPassword)) {
            link.influencerPassword = generatePassword();
        }

        const copyLink = {
            ...link,
            rowKey: null,
            isDeleted: false,
            activationDate: null,
            createdDate: new Date(),
            status: LinkStatus.Active,
            activeSubmissionCount: 0,
            submissionMessages: [] as FormSubmissionMessage[],
            pages: link.pages.map(page => ({
                ...page,
                children: page.children.map(child => {
                    switch (child.sectionType) {
                        case FormSectionType.MeetingRegistration:
                        case FormSectionType.WebinarRegistration:
                            return {
                                ...child,
                                webinarId: null,
                                webinarTemplateId: null,
                                webinarTopic: null,
                                webinarAgenda: null,
                                webinarUniqueId: null,
                                meetingId: null,
                                meetingTemplateId: null,
                                meetingTopic: null,
                                meetingAgenda: null,
                                meetingUniqueId: null,
                            };
                    }

                    return child;
                }),
            })),
        };

        if (link.submissionMessages?.length > 0) {
            return dispatch(new ManageEventActions.LoadSubmissionMessagesForLink(action.link)).pipe(
                withLatestFrom(this.store.select(ManageEventsState.getFormSubmissionMessages())),
                tap(([_, submissionMessages]) => {
                    copyLink.submissionMessages = submissionMessages;

                    patchState({
                        copyLink,
                    });
                }),
                mergeMap(() => dispatch(new Navigate(['/home/event-links/new']))),
            );
        }

        patchState({
            copyLink,
        });

        return dispatch(new Navigate(['/home/event-links/new']));
    }

    @Action(ManageEventActions.EnsureLoadLinks)
    ensureLoadLinks({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedLinks } = getState();

        if (!hasLoadedLinks) {
            return dispatch([new ManageEventActions.LoadLinks()]);
        }

        return of(void 0);
    }

    @Action(ManageEventActions.EnsureLoadArchivedLinks)
    ensureArchivedLoad({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedArchivedLinks } = getState();

        if (!hasLoadedArchivedLinks) {
            return dispatch([new ManageEventActions.SearchArchivedLinks()]);
        }
    }

    @Action(ManageEventActions.EnsureLoadTemplates)
    ensureLoadTemplates({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedTemplates } = getState();

        if (!hasLoadedTemplates) {
            return dispatch([new ManageEventActions.LoadFormTemplates()]);
        }
    }

    @Action(ManageEventActions.LoadArchivedLink)
    loadArchivedLink(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageEventActions.LoadArchivedLink,
    ): Observable<any> | void {
        patchState({ isLoadingArchivedLinks: true });

        return this.manageEventsService.getArchivedLink(action.rowKey).pipe(
            mergeMap(archivedLink => {
                patchState({
                    archivedLink,
                });

                return dispatch(new ManageEventActions.LoadSubmissionMessagesForLink(archivedLink));
            }),
            mergeMap(() => dispatch(new ManageEventActions.LoadArchivedLinkSuccess())),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadArchivedLinkFailure(err))),
            finalize(() => patchState({ isLoadingArchivedLinks: false })),
        );
    }

    @Action(ManageEventActions.LoadFormTemplates)
    loadFormTemplates({ dispatch, patchState, getState }: StateContext<StateModel>): Observable<any> | void {
        const { isLoadingTemplates } = getState();

        if (isLoadingTemplates) {
            return this.actions.pipe(ofActionDispatched(ManageEventActions.LoadFormTemplatesDone), take(1));
        }

        patchState({ isLoadingTemplates: true });

        return this.manageEventsService.getAllTemplates().pipe(
            tap(templates => {
                patchState({
                    templates,
                });
                dispatch(new ManageEventActions.LoadFormTemplatesSuccess());
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadFormTemplatesFailure(err))),
            finalize(() => {
                patchState({ isLoadingTemplates: false, hasLoadedTemplates: true });
                dispatch([new ManageEventActions.LoadFormTemplatesDone()]);
            }),
        );
    }

    @Action(ManageEventActions.LoadLinks)
    loadLinks({ getState, patchState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { isLoadingLinks } = getState();

        if (isLoadingLinks) {
            return this.actions.pipe(ofActionDispatched(ManageEventActions.LoadLinksDone), take(1));
        }

        patchState({ isLoadingLinks: true });

        return this.manageEventsService.getAllLinks().pipe(
            tap(links => {
                patchState({
                    links,
                });
                dispatch(new ManageEventActions.LoadLinksSuccess());
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadLinksFailure(err))),
            finalize(() => {
                patchState({ isLoadingLinks: false, hasLoadedLinks: true });
                dispatch(new ManageEventActions.LoadLinksDone());
            }),
        );
    }

    @Action(ManageEventActions.LoadLinkSessions)
    loadLinkSessions(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageEventActions.LoadLinkSessions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageEventsState.getEditModel());
        if ((!model || !model.rowKey || model.rowKey === 'new') && (!rowKey || rowKey === 'new')) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageEventsService.getAllLinkSessions((model?.rowKey || rowKey) as string).pipe(
            tap(linkSessions => ctx.patchState({ linkSessions })),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadLinkSubmissionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageEventActions.LoadLinkRegistrants)
    loadLinkRegistrants(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageEventActions.LoadLinkRegistrants,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageEventsState.getEditModel());
        if ((!model || !model.rowKey || model.rowKey === 'new') && (!rowKey || rowKey === 'new')) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageEventsService.getLinkRegistrants((model?.rowKey || rowKey) as string).pipe(
            tap(linkRegistrants => ctx.patchState({ linkRegistrants })),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadLinkRegistrantsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageEventActions.LoadGuestRegistrations)
    loadGuestRegistrations(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageEventActions.LoadGuestRegistrations,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageEventsState.getEditModel());
        if ((!model || !model.rowKey || model.rowKey === 'new') && (!rowKey || rowKey === 'new')) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageEventsService.getGuestRegistrations((model?.rowKey || rowKey) as string).pipe(
            tap(guestRegistrations => ctx.patchState({ guestRegistrations })),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadGuestRegistrationsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageEventActions.LoadArchivedLinkSessions)
    loadArchivedLinkSessions(
        ctx: StateContext<StateModel>,
        action: ManageEventActions.LoadArchivedLinkSessions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageEventsState.getArchivedLink());
        const rowKey = action.rowKey || model?.rowKey;

        if (!rowKey) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.blobService.downloadBlobBlock(`archived-events/${rowKey}.json`, BlobContainer.Private).pipe(
            transformResponseToText(),
            handleNotFoundError('{}'),
            tap(content => {
                const result = JSON.parse(content) as any;
                const archivedContent = convertObjectKeysToCamelCase(result);
                ctx.patchState({ archivedContent });
            }),
            catchError(err => ctx.dispatch(new ManageEventActions.LoadArchivedLinkSessionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageEventActions.LoadLinkSubmissions)
    loadLinkSubmissions(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageEventActions.LoadLinkSubmissions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageEventsState.getEditModel());
        if (((!model || !model.rowKey) && !rowKey) || rowKey === 'new') {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageEventsService.getAllLinkSubmissions((model?.rowKey || rowKey) as string).pipe(
            tap(linkSubmissions => ctx.patchState({ linkSubmissions })),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadLinkSessionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageEventActions.LoadLinkPayments)
    loadLinkPayments(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageEventActions.LoadLinkPayments,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageEventsState.getEditModel());
        if (((!model || !model.rowKey) && !rowKey) || rowKey === 'new') {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageEventsService.getAllLinkPayments((model?.rowKey || rowKey) as string).pipe(
            tap(linkPayments => ctx.patchState({ linkPayments })),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadLinkPaymentsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    getLinkFromForm(): any {
        const model = this.store.selectSnapshot(ManageEventsState.getForm()).model as EditEventLinkModel;

        const { timeZone } = model.linkExpiration;
        // const timeZone = getTimeZoneByIanaTimeZone(ianaTimeZone);
        const submissionMessages = (model.submissionMessages || []).map((message: FormSubmissionMessage) => {
            return {
                rowKey: message.rowKey,
                name: message.name,
            };
        });

        const pages = model.pages.reduce((acc: FormSection[], current: any) => {
            if (current.data) {
                const page = {
                    ...current.data,
                    children: current.data.children.reduce((acc: FormSection[], current: any) => {
                        const section = {
                            ...current.data,
                            id: current.id,
                            sectionType: current.sectionType,
                        };

                        if (
                            section.type === FormQuestionType.ShortAnswer ||
                            section.type === FormQuestionType.LongAnswer ||
                            section.type === FormQuestionType.Date ||
                            section.type === FormQuestionType.Time ||
                            section.type === FormQuestionType.DateTime
                        ) {
                            section.options = [];
                        }

                        if (section.sectionType !== FormSectionType.Media) {
                            section.mediaAssetId = null;
                        }

                        acc.push(section);

                        return acc;
                    }, []),
                };

                acc.push(page);
            } else {
                acc.push(current);
            }

            return acc;
        }, []) as any[];

        return {
            ...model,
            ...model.linkExpiration,
            pages,
            timeZone: timeZone || DEFAULT_TIME_ZONE,
            submissionMessages,
            videoSettings: {
                showWatermark: model.showWatermark,
                elapsedTimeInSeconds: 0,
                isLiveStream: false,
                startTime: VideoOption.None,
                autoplay: false,
                enableWaitingRoom: false,
                waitingRoomOffsetInSeconds: 0,
                showControls: true,
                showPlayButton: true,
                showFullscreenButton: true,
                showProgress: true,
                allowScrubbing: true,
                showPlaybackSpeed: true,
                resumePlayback: false,
                allowListeners: true,
                countDownAssetId: null,
            },
        };
    }

    @Action(ManageEventActions.Save)
    save({ patchState, dispatch }: StateContext<StateModel>): Observable<void> {
        patchState({
            isSaving: true,
            errors: null,
        });

        const model = this.getLinkFromForm();
        const preambleTemplate = model.preambleTemplate;
        const preambleTemplateId = model.preambleTemplateId;
        const links = [...this.store.selectSnapshot(ManageEventsState.getLinks())];
        const ix = links.findIndex(n => n.rowKey === model.rowKey);

        model.preambleTemplate = null;

        return this.manageEventsService.saveLink(model).pipe(
            mergeMap(({ data: link, meta }) => {
                if (ix !== -1) {
                    links[ix] = link;
                } else {
                    links.push(link);
                }

                patchState({
                    links,
                    copyLink: null,
                });

                const actions: unknown[] = [new ManageEventActions.SaveFormSubmissionMessages(link.rowKey as string)];
                const successActions: unknown[] = [
                    new Navigate(['/home/event-links']),
                    new ManageEventActions.UpdateSessionCount(link.rowKey as string),
                    new ManageEventActions.UpdateLinkSummary(),
                ];

                if (preambleTemplateId === 'custom') {
                    actions.push(
                        new ManageTemplateActions.SavePreambleForLink(
                            preambleTemplate?.content || '',
                            link.rowKey as string,
                        ),
                    );
                }

                if (meta && isArray(meta.saveWarnings)) {
                    successActions.push(
                        new NotificationActions.Warning(
                            `Link has been successfully saved. Following warnings occurred:`,
                            meta.saveWarnings.map((w: { errorCode: ErrorCodes; message: string }) => {
                                switch (w.errorCode) {
                                    case ErrorCodes.ZoomWebinarUpdateFailed:
                                        return `Zoom update failed for ${w.message}`;
                                }

                                return w.message;
                            }),
                            true,
                        ),
                    );
                } else {
                    successActions.push(new NotificationActions.Success('Link has been successfully saved'));
                }

                return dispatch(actions).pipe(mergeMap(() => dispatch(successActions)));
            }),
            catchError(err => dispatch(new ManageEventActions.SaveFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.SaveFormSubmissionMessages)
    saveFormSubmissionMessages(
        { dispatch, patchState }: StateContext<StateModel>,
        { linkId }: ManageEventActions.SaveFormSubmissionMessages,
    ) {
        const model = this.store.selectSnapshot(ManageEventsState.getForm()).model as EditEventLinkModel;
        const submissionMessages = model.submissionMessages || [];

        if (submissionMessages.length === 0) {
            return of(void 0);
        }

        const actions = submissionMessages.map(m => {
            const id = `events/${linkId}/${m.rowKey}.html`;
            return this.blobService.uploadBlobBlock(id, m.content, 'text/html', BlobContainer.Private);
        });

        return forkJoin(actions);
    }

    @Action(ManageEventActions.ConfirmArchiveLinks)
    confirmArchiveLinks(ctx: StateContext<StateModel>, action: ManageEventActions.ConfirmArchiveLinks) {
        return this.matDialog
            .open(ArchiveContentLinkConfirmationDialogComponent, {
                disableClose: true,
                data: action.links,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageEventActions.ArchiveLinks(action.links));
                    }
                }),
            );
    }

    @Action(ManageEventActions.AcknowledgeSubmissions)
    acknowledgeSubmissions(
        { patchState }: StateContext<StateModel>,
        { linkId, submissionRowKeys }: ManageEventActions.AcknowledgeSubmissions,
    ) {
        patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageEventsState.getEditModel()) as EventLinkModel;
        const rowKey = (link?.rowKey || linkId) as string;

        return this.manageEventsService.acknowledgeSubmissions(submissionRowKeys, rowKey).pipe(
            tap(summaries => {
                const submissions = this.store.selectSnapshot(ManageEventsState.getLinkSubmissions());
                const linkSessions = this.store.selectSnapshot(ManageEventsState.getLinkSessions());

                patchState({
                    linkSubmissions: submissions.map(submission => {
                        if (submissionRowKeys.includes(submission.rowKey)) {
                            submission.isRead = true;
                        }

                        return submission;
                    }),
                    linkSessions: linkSessions.map(s => {
                        const summary = summaries.find(d => d.rowKey == s.rowKey);
                        if (summary) {
                            return summary;
                        }

                        return s;
                    }),
                });

                this.store.dispatch(new ManageEventActions.LoadLinks());
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.ArchiveLinksFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(CoreActions.Refresh)
    autoRefresh({ dispatch }: StateContext<StateModel>) {
        const route = this.store.selectSnapshot(RouterState.selectCurrentRoute());
        if (!route) return;

        const url = route.url?.map(s => s.path).join('/') || '';

        if (url.includes('event-links')) {
            dispatch([new ManageEventActions.LoadLinks()]);
        }
    }

    @Action(ManageEventActions.ArchiveLinks)
    archiveLinks(ctx: StateContext<StateModel>, action: ManageEventActions.ArchiveLinks) {
        return this.manageEventsService.archiveLinks(action.links).pipe(
            mergeMap(result => {
                const links = this.store.selectSnapshot(ManageEventsState.getLinks()).map(link => ({ ...link }));
                const linksToArchive = links.filter(link => result.linksToRemove.includes(link.rowKey as string));
                const linksToArchiveIds = linksToArchive.map(link => link.rowKey);

                linksToArchive.forEach(link => (link.isDeleted = true));

                ctx.patchState({
                    links: links.filter(link => !linksToArchiveIds.includes(link.rowKey)),
                });

                const linkId = this.store.selectSnapshot(RouterState.selectRouteParam('linkId'));

                if (linkId) {
                    this.store.dispatch(new Navigate(['/home/event-links']));
                }

                return this.store.dispatch(new NotificationActions.Success('Link has been successfully archived'));
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.ArchiveLinksFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.ConfirmDeleteLinks)
    confirmDeleteLink(ctx: StateContext<StateModel>, action: ManageEventActions.ConfirmDeleteLinks) {
        return this.matDialog
            .open(DeleteShareableLinkConfirmationDialogComponent, {
                disableClose: true,
                data: action.links,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageEventActions.DeleteLinks(action.links));
                    }
                }),
            );
    }

    @Action(ManageEventActions.DeleteLinks)
    deleteLinks(ctx: StateContext<StateModel>, action: ManageEventActions.DeleteLinks) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageEventsService.deleteLinks(action.links).pipe(
            mergeMap(result => {
                return this.store.dispatch([
                    new ManageEventActions.SearchArchivedLinks(), // force refresh of archived links
                    new NotificationActions.Success('Link has been successfully deleted'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.DeleteLinksFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.ConfirmMergeSession)
    confirmMergeSessions(ctx: StateContext<StateModel>, action: ManageEventActions.ConfirmMergeSession) {
        const allSessions = this.store.selectSnapshot(ManageEventsState.getLinkSessions());
        const sessions: LinkSessionSummary[] = [];

        action.rowKeys.forEach(rowKey => {
            const session = allSessions.find(s => s.rowKey === rowKey);
            if (session) {
                sessions.push(session);
            }
        });

        return this.matDialog
            .open(MergeLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    linkType: LinkType.Event,
                    sessions,
                },
            })
            .afterClosed()
            .pipe(
                tap((primary: LinkSessionSummary) => {
                    if (primary) {
                        this.store.dispatch(new ManageEventActions.MergeSessions(primary.rowKey, action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageEventActions.MergeSessions)
    mergeSessions(ctx: StateContext<StateModel>, action: ManageEventActions.MergeSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageEventsState.getEditModel()) as EventLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageEventsService.mergeSessions(rowKey, action.primaryRowKey, action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageEventActions.LoadLinkSessions(rowKey),
                    new ManageEventActions.LoadLinkRegistrants(rowKey),
                    new ManageEventActions.LoadLinkSubmissions(rowKey),
                    new ManageEventActions.LoadLinkPayments(rowKey),
                    new ManageEventActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submissions Merged Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.MergeSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.SyncParticipants)
    syncParticipants({ patchState, dispatch }: StateContext<StateModel>, action: ManageEventActions.SyncParticipants) {
        const model = this.store.selectSnapshot(ManageEventsState.getEditModel());

        if (!model || !model.rowKey || model.rowKey === 'new') {
            return;
        }

        patchState({
            isLoadingParticipants: true,
            errors: null,
        });

        return this.manageEventsService.syncParticipants(model?.rowKey as string).pipe(
            mergeMap(() => {
                return dispatch(new ManageEventActions.LoadParticipants());
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.SyncParticipantsFailure(err))),
            finalize(() =>
                patchState({
                    isLoadingParticipants: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.LoadParticipants)
    loadParticipants(ctx: StateContext<StateModel>, { rowKey }: ManageEventActions.LoadParticipants) {
        const model = this.store.selectSnapshot(ManageEventsState.getEditModel());

        if ((!model || !model.rowKey || model.rowKey === 'new') && (!rowKey || rowKey === 'new')) {
            return;
        }

        ctx.patchState({
            isLoadingParticipants: true,
            errors: null,
        });

        return this.manageEventsService.getParticipants((model?.rowKey || rowKey) as string).pipe(
            tap(participants => {
                ctx.patchState({
                    participants,
                });
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.LoadParticipantsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isLoadingParticipants: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.ConfirmDeleteSession)
    confirmDeleteSession(ctx: StateContext<StateModel>, action: ManageEventActions.ConfirmDeleteSession) {
        return this.matDialog
            .open(DeleteLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: action.rowKeys,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageEventActions.DeleteSessions(action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageEventActions.DeleteSessions)
    deleteSession(ctx: StateContext<StateModel>, action: ManageEventActions.DeleteSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageEventsState.getEditModel()) as EventLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageEventsService.deleteSessions(action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageEventActions.LoadLinkSessions(rowKey),
                    new ManageEventActions.LoadLinkRegistrants(rowKey),
                    new ManageEventActions.LoadLinkSubmissions(rowKey),
                    new ManageEventActions.UpdateLinkSummary(),
                    new ManageEventActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Deleted Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.DeleteSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.ActivateSession)
    activateSession(ctx: StateContext<StateModel>, action: ManageEventActions.ActivateSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageEventsState.getEditModel()) as EventLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageEventsService.activateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageEventActions.LoadLinkSessions(rowKey),
                    new ManageEventActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission Activated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.ActivateSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.DeactivateSession)
    deactivateSession(ctx: StateContext<StateModel>, action: ManageEventActions.DeactivateSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageEventsState.getEditModel()) as EventLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageEventsService.deactivateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageEventActions.LoadLinkSessions(rowKey),
                    new ManageEventActions.LoadLinkRegistrants(rowKey),
                    new ManageEventActions.LoadLinkSubmissions(rowKey),
                    new ManageEventActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission Deactivated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.DeactivateSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.SyncActiveSessions)
    syncActiveSessions(ctx: StateContext<StateModel>, action: ManageEventActions.SyncActiveSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageEventsService.syncActiveSessions(action.rowKey).pipe(
            tap(result => {
                const links = this.store.selectSnapshot(ManageEventsState.getLinks()).concat();
                const ix = links.findIndex(l => l.rowKey === action.rowKey);
                const link = links[ix];

                links[ix] = {
                    ...link,
                    lateRegistrationCount: result.lateRegistrationCount,
                    activeSubmissionCount: result.activeSubmissionCount,
                    approvalCount: result.approvalCount,
                    sessionLimit: result.sessionLimit,
                };

                ctx.patchState({
                    links,
                });
            }),
            mergeMap(link =>
                this.store.dispatch([
                    new NotificationActions.Success('Active Submissions Synchronized'),
                    new UpdateFormValue({
                        value: { sessionLimit: link.sessionLimit, activeSubmissionCount: link.activeSubmissionCount },
                        path: 'manageEvents.form',
                    }),
                ]),
            ),
            catchError(err => this.store.dispatch(new ManageEventActions.SyncActiveSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.UpdateSessionCount)
    updateSessionCount(ctx: StateContext<StateModel>, action: ManageEventActions.UpdateSessionCount) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageEventsService.updateLinkCounts(action.rowKey).pipe(
            tap(result => {
                const links = [...this.store.selectSnapshot(ManageEventsState.getLinks())];
                const ix = links.findIndex(l => l.rowKey === action.rowKey);
                const link = links[ix];

                links[ix] = {
                    ...link,
                    lateRegistrationCount: result.lateRegistrationCount,
                    activeSubmissionCount: result.activeSubmissionCount,
                    approvalCount: result.approvalCount,
                    resubmissionCount: result.resubmissionCount,
                    formSubmissionCount: result.formSubmissionCount,
                };

                ctx.patchState({
                    links,
                });
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.UpdateSessionCountFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.CopyLinkToClipboard)
    copyLinkToClipboard(ctx: StateContext<StateModel>, action: ManageEventActions.CopyLinkToClipboard): void {
        const model = action.link;
        const url = formatLinkUrl(model, this.env);
        const details = [`Shareable Link: ${url}`];

        if (model.isPasswordRequired) {
            details.push('');
            details.push(`Password: ${model.password}`);
        }

        switch (model.linkExpiration) {
            case LinkExpiration.Timer:
                switch (model.timerUnit) {
                    case TimerUnit.Months:
                        details.push(`Timer length after activation (${model.timer}m)`);
                        break;
                    case TimerUnit.Weeks:
                        details.push(`Timer length after activation (${model.timer}w)`);
                        break;
                    case TimerUnit.Days:
                        details.push(`Timer length after activation (${model.timer}d)`);
                        break;
                    case TimerUnit.Hours:
                        details.push(`Timer length after activation (${model.timer}h)`);
                        break;
                    case TimerUnit.Minutes:
                        details.push(`Timer length after activation (${model.timer}min)`);
                        break;
                    default:
                }
                if (model.timerEndDate) {
                    details.push('');
                    details.push(`Expires ${this.formatDate(model.timerEndDate, action.timezone)}`);
                }
                break;
            case LinkExpiration.Date:
                if (model.startDate) {
                    details.push(`Starts ${this.formatDate(model.startDate, action.timezone)}`);
                }
                if (model.endDate) {
                    details.push(`Expires ${this.formatDate(model.endDate, action.timezone)}`);
                }
                break;
            default:
        }

        if (model.sessionLimit) {
            details.push('');
            details.push(`Active Device & Browser Limit (${model.sessionLimit})`);
        }

        if (model.registrationEndDate) {
            details.push('');
            details.push(`Registration Cut-off ${this.formatDate(model.registrationEndDate, action.timezone)}`);
        }

        if (model.registrationMode === RegistrationMode.Onetime) {
            details.push('');
            details.push(`Link is configured for a one-time access`);
        }

        if (action.includeInfluencer) {
            details.push('');
            details.push(`*Influencer Password: ${model.influencerPassword}*`);
        }

        this.store.dispatch(
            new CoreActions.CopyToClipboard({
                text: details.join('\r\n'),
                message: 'Link copied to clipboard',
            }),
        );
    }

    @Action(ManageEventActions.OpenLink)
    openLink(ctx: StateContext<ManageEventsState>, action: ManageEventActions.OpenLink): Observable<any> | void {
        const url = formatLinkUrl(action.link, this.env);

        if (action.link.isPasswordRequired) {
            return this.store
                .dispatch(
                    new CoreActions.CopyToClipboard({
                        text: action.link.password,
                    }),
                )
                .pipe(tap(() => window.open(url, `_blank`)));
        }

        window.open(url, `_blank`);
    }

    @Action(ManageEventActions.ApproveSessions)
    approveSessions(ctx: StateContext<StateModel>, action: ManageEventActions.ApproveSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageEventsState.getEditModel()) as EventLinkModel;
        const rowKey = link?.rowKey || action.linkId;

        return this.manageEventsService.approveSession(rowKey, action.rowKeys).pipe(
            mergeMap(() => {
                const actions = [
                    new ManageEventActions.LoadLinkSessions(rowKey),
                    new ManageEventActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Approved'),
                ];

                if (link && link.hasRegistration && link.registrationEndDate) {
                    const endDate = new Date(link.registrationEndDate);
                    const now = new Date();

                    if (now > endDate) {
                        actions.push(new ManageEventActions.SyncActiveSessions(rowKey));
                    }
                }

                return this.store.dispatch(actions);
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.ApproveSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.UploadFile)
    uploadFile(ctx: StateContext<StateModel>, { fileId, getFile }: ManageEventActions.UploadFile) {
        const file = getFile();
        const { name, type } = file;
        const id = `events/${fileId}/${name}`;

        return this.blobService.uploadFile(id, file, type, BlobContainer.Private);
    }

    @Action(ManageEventActions.CompleteSubmission)
    completeSubmission(
        { patchState, dispatch }: StateContext<StateModel>,
        { submission }: ManageEventActions.CompleteSubmission,
    ): Observable<any> | void {
        patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageEventsService.completeSubmission(submission.rowKey).pipe(
            tap(() => {
                const submissions = this.store.selectSnapshot(ManageEventsState.getLinkSubmissions());

                patchState({
                    linkSubmissions: submissions.map(s => {
                        if (submission.rowKey === s.rowKey) {
                            submission.isComplete = true;
                        }

                        return submission;
                    }),
                });

                this.store.dispatch([
                    new NotificationActions.Success('Submission Completed'),
                    new ManageEventActions.LoadLinkSubmissions(submission.linkId),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.CompleteSubmissionFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageEventActions.SearchArchivedLinks, { cancelUncompleted: true }) // cancel if another search goes through
    searchArchivedLinks(
        { dispatch, patchState, getState }: StateContext<StateModel>,
        action: ManageEventActions.SearchArchivedLinks,
    ) {
        const { searchArchivedLinksOptions: options } = getState();
        const form = this.store.selectSnapshot(ManageEventsState.getSearchArchivedLinksForm());
        const formModel = form.model as SearchOptionsModel;
        const model = action.model || {
            ...formModel,
            limit: options?.limit || 0,
            total: options?.total || null,
            offset: options?.offset || 0,
        };

        if (model.total === undefined) {
            model.total = options?.total;
        }

        patchState({ isSearching: true });

        return this.manageEventsService.searchArchivedLinks(model).pipe(
            tap(queryResult => {
                patchState({
                    searchArchivedLinksQueryResult: queryResult,
                    searchArchivedLinksOptions: {
                        filter: '',
                        tags: [],
                        limit: queryResult.limit,
                        total: queryResult.total,
                        offset: queryResult.offset,
                    },
                });
            }),
            catchError(err => this.store.dispatch(new ManageEventActions.SearchArchivedLinksFailure(err))),
            finalize(() => patchState({ isSearching: false, hasLoadedArchivedLinks: true })),
        );
    }

    @Action(ManageEventActions.LoadSubmissionMessagesForLink)
    loadSubmissionMessagesForLink(
        { getState, patchState }: StateContext<StateModel>,
        { link }: ManageEventActions.LoadSubmissionMessagesForLink,
    ) {
        patchState({
            formSubmissionMessages: [],
        });

        if (!link || !link.submissionMessages?.length) {
            return;
        }

        const { rowKey } = link;

        const actions = link.submissionMessages.reduce((acc, current) => {
            const name = `events/${rowKey}/${current.rowKey}.html`;
            acc.push(
                this.blobService.downloadBlobBlock(name).pipe(
                    transformResponseToText(),
                    handleNotFoundError(),
                    map(content => {
                        return {
                            ...current,
                            content,
                        };
                    }),
                ),
            );

            return acc;
        }, [] as Observable<any>[]);

        return forkJoin(actions).pipe(
            tap(submissionMessages => {
                patchState({
                    formSubmissionMessages: submissionMessages,
                });
            }),
        );
    }

    @Action(ManageEventActions.ValidationError)
    validationError(ctx: StateContext<StateModel>, action: ManageEventActions.ValidationError) {
        this.snack.openFromComponent(ValidationSummaryComponent, {
            data: action.error,
        });
    }

    @Action([
        ManageEventActions.ActivateSessionFailure,
        ManageEventActions.LoadLinksFailure,
        ManageEventActions.SaveFailure,
        ManageEventActions.DeleteLinksFailure,
        ManageEventActions.ArchiveLinksFailure,
        ManageEventActions.DeleteSessionFailure,
        ManageEventActions.MergeSessionsFailure,
        ManageEventActions.ApproveSessionsFailure,
        ManageEventActions.LoadLinkSessionsFailure,
        ManageEventActions.DeactivateSessionFailure,
        ManageEventActions.SyncActiveSessionsFailure,
        ManageEventActions.UpdateSessionCountFailure,
        ManageEventActions.LoadArchivedLinkSessionsFailure,
    ])
    handleFailures(ctx: StateContext<StateModel>, action: ManageEventFailureTypes): Observable<any> | void {
        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof ManageEventActions.LoadLinksFailure:
                console.log('Error loading event links', action.error);
                return this.store.dispatch([
                    new InsightActions.TrackException({
                        exception: action.error,
                        severityLevel: SeverityLevel.Error,
                        properties: { source: 'ManageEventActions.LoadLinksFailure' },
                    }),
                    new NotificationActions.Error('Unexpected Error Loading Event Links', action.error),
                ]);
            case action instanceof ManageEventActions.SaveFailure:
                return this.store.dispatch(new ManageEventActions.ValidationError(action.error));
            case action instanceof ManageEventActions.DeleteLinksFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Deleting Link(s)', action.error));
            case action instanceof ManageEventActions.ArchiveLinksFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Archiving Link(s)', action.error),
                );
            case action instanceof ManageEventActions.DeactivateSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deactivating Session(s)', action.error),
                );
            case action instanceof ManageEventActions.DeleteSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deleting Link Session(s)', action.error),
                );
            case action instanceof ManageEventActions.SyncActiveSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Syncing Link Sessions', action.error),
                );
            case action instanceof ManageEventActions.UpdateSessionCountFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Updating Session Counts', action.error),
                );
            case action instanceof ManageEventActions.LoadArchivedLinkSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Link Sessions', action.error),
                );
            case action instanceof ManageEventActions.LoadLinkSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Link Sessions', action.error),
                );
            case action instanceof ManageEventActions.MergeSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Merging Link Sessions', action.error),
                );
            case action instanceof ManageEventActions.ApproveSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Approving Link Session(s)', action.error),
                );
            default:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error', action.error));
        }
    }

    getOffset(timezone: Timezone, date: Date): number {
        if (timezone.isdst && isDst(date)) {
            return timezone.offset + (timezone.offset < 0 ? -1 : 1);
        }

        return timezone.offset;
    }

    getUtcDateString(date: string | Date, timeZone: Timezone): string {
        if (isString(date)) {
            return `${date.replace(' ', 'T')}${parseTimeZoneFromOffset(
                this.getOffset(timeZone, new Date(date.replace(' ', 'T'))),
            )}`;
        }

        if (isDate(date)) {
            return `${toDateTimeString(date)}${parseTimeZoneFromOffset(this.getOffset(timeZone, date))}`;
        }

        throw new Error('Invalid type');
    }

    getUtcDate(date: string | Date, timeZone: Timezone): Date {
        return new Date(this.getUtcDateString(date, timeZone));
    }

    private formatDate(date: Date, timeZone: Timezone): string {
        return formatDatetimeWithTimeZone(date, timeZone);
    }
}

type ManageEventFailureTypes =
    | ManageEventActions.ActivateSessionFailure
    | ManageEventActions.LoadLinksFailure
    | ManageEventActions.SaveFailure
    | ManageEventActions.DeleteLinksFailure
    | ManageEventActions.ArchiveLinksFailure
    | ManageEventActions.DeleteSessionFailure
    | ManageEventActions.MergeSessionsFailure
    | ManageEventActions.ApproveSessionsFailure
    | ManageEventActions.LoadLinkSessionsFailure
    | ManageEventActions.DeactivateSessionFailure
    | ManageEventActions.SyncActiveSessionsFailure
    | ManageEventActions.UpdateSessionCountFailure
    | ManageEventActions.LoadArchivedLinkSessionsFailure;

function getDefaultEventLinkModel() {
    return {
        rowKey: null,

        allowInfluencerToNotifyAdmin: false,
        showWatermark: true,
        nodeIds: [],
        questions: [],
        videoSettings: {
            allowListeners: false,
            autoplay: false,
            elapsedTimeInSeconds: 0,
            enableWaitingRoom: false,
            waitingRoomOffsetInSeconds: 60,
            showWatermark: true,
            showBackgroundWatermark: true,
            showControls: true,
            showPlayButton: true,
            showFullscreenButton: true,
            showProgress: true,
            allowScrubbing: true,
            showPlaybackSpeed: false,
            resumePlayback: false,
            startTime: VideoOption.None,
            countDownAssetId: '',
            isLiveStream: false,
        },
        password: generatePassword(),
        influencerPassword: '',
        isDeleted: false,
        url: '',
        requestedBy: '',
        activationDate: null,
        status: LinkStatus.Active,
        timer: null,
        timerUnit: TimerUnit.Hours,
        isNameRequired: true,
        isPasswordRequired: false,

        allowResubmission: false,
        notifyOnUpdates: false,
        notifyOnSubmission: true,

        timerEndDate: null,
        sessionLimit: null,
        startDate: null,
        endDate: null,
        type: LinkType.Shareable,
        requiresApproval: false,
        requiresSubmission: true,
        isGuidedSequence: false,
        pages: [
            {
                id: randomId(6),
                children: [],
                description: '',
                sectionType: FormSectionType.Page,
                title: 'Untitled Page 1',
                backgroundColour: null,
                fontColour: null,
                goToPage: '',
                isEnabled: true,
            },
        ],

        hasRegistration: false,
        displayWatermark: true,
        linkExpiration: LinkExpiration.None,
        linkInactivityMode: LinkInactivityMode.Default,
        lastActivityDate: null,
        tags: [],

        createdDate: new Date(),
        registrationMode: RegistrationMode.Default,
        timeZone: DEFAULT_TIME_ZONE,
        registrationEndDate: null,
        timestamp: null,
        archivedDate: null,
        linkExpirationDate: null,
        supportMessage: ``,

        incompleteCount: 0,
        completeCount: 0,
        lateRegistrationCount: 0,
        activeSubmissionCount: 0,
        resubmissionCount: 0,
        approvalCount: 0,
        formSubmissionCount: 0,
        requiresAttentionCount: 0,
        isPreambleRequired: true,
        preambleTemplateId: null,
        preambleTemplate: null,
        checklistTemplateIds: [],
        hasAdminAcknowledged: true,

        submissionMessages: [
            {
                rowKey: randomId(6),
                name: 'Default',
                content: `<p>Your submission has been completed.</p> <p>Thank you for your time.</p>`,
                sasUri: '',
                hasLoaded: false,
            },
        ],
        archivedBy: null,
        archivedReason: null,
        autoArchive: true,
        isExpired: false,
        isActive: true,
        displayRegistrationCountdown: false,
        allowUsersToRequestMoreTime: false,
    } as EventLinkModel;
}

import { CdkDragDrop, CdkDragMove, CdkDragSortEvent, moveItemInArray, CdkDropList, CdkDrag, CdkDragPreview, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TaskModel } from '@app/data/models';

import { TaskNotesDialogComponent } from '../../dialogs/task-notes/task-notes-dialog.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatIcon } from '@angular/material/icon';
import { FocusDirective } from '../../../../../shared/src/lib/directives/focus.directive';
import { MatAnchor } from '@angular/material/button';
import { AnyPipe } from '../../../../../shared/src/lib/pipes/any.pipe';

@Component({
    selector: 'admin-tasks',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./tasks.component.html`,
    styleUrls: [`./tasks.component.scss`],
    standalone: true,
    imports: [
        FlexModule,
        NgIf,
        CdkDropList,
        NgFor,
        CdkDrag,
        CdkDragPreview,
        CdkDragPlaceholder,
        ExtendedModule,
        NgClass,
        MatIcon,
        FocusDirective,
        MatAnchor,
        AnyPipe,
    ],
})
export class TasksComponent implements OnDestroy, OnChanges {
    private isAlive$: Subject<void> = new Subject();
    private clickTimeout: any;
    private clickCount = 0;

    activeParent = null;

    @Input() tasks: TaskModel[] | null;

    @Output() readonly save: EventEmitter<TaskModel[]> = new EventEmitter();

    constructor(private matDialog: MatDialog, private changeDetectorRef: ChangeDetectorRef) {}

    onToggleCompletion(task: TaskModel, ix: number) {
        task.isComplete = !task.isComplete;
        if (!task.isSubtask) {
            this.toggleChildren(task);
        } else {
            this.toggleParent(task);
        }

        this.save.emit(this.tasks as TaskModel[]);
    }

    toggleParent(child: TaskModel) {
        let parent = null;
        const tasks = this.tasks as TaskModel[];
        let x = tasks.indexOf(child) - 1;
        for (; x >= 0; x--) {
            const task = tasks[x];

            if (!task.isSubtask) {
                parent = task;
                break;
            }
        }

        this.updateParent(parent);
    }

    hasSubtasks(index: number) {
        return (
            this.tasks &&
            this.tasks?.length > 0 &&
            !this.tasks[index].isSubtask &&
            index + 1 < this.tasks?.length &&
            this.tasks[index + 1].isSubtask === true
        );
    }

    completedSubtasks(index: number) {
        let count = 0;
        const len = this.tasks?.length || 0;
        const tasks = this.tasks as TaskModel[];
        for (let x = index + 1; x < len; x++) {
            const task = tasks[x];

            if (task.isSubtask && task.isComplete) {
                count++;
            } else if (!task.isSubtask) {
                return count;
            }
        }

        return count;
    }

    totalSubtasks(index: number) {
        const len = this.tasks?.length || 0;
        const tasks = this.tasks as TaskModel[];
        for (let x = index + 1; x < len; x++) {
            const task = tasks[x];

            if (!task.isSubtask) {
                return x - index - 1;
            }
        }

        return len - index;
    }

    onShowNotes(task: TaskModel) {
        this.onCancelEdit();

        this.matDialog
            .open(TaskNotesDialogComponent, {
                closeOnNavigation: false,
                height: '500px',
                data: task.notes,
            })
            .afterClosed()
            .pipe(
                tap(notes => {
                    if (notes !== undefined) {
                        task.notes = notes;
                        this.save.emit(this.tasks as TaskModel[]);
                    }
                }),
            )
            .subscribe();
    }

    toggleChildren(parent: TaskModel) {
        const tasks = this.tasks as TaskModel[];
        const len = tasks.length || 0;
        let x = tasks.indexOf(parent) + 1;
        for (; x < len; x++) {
            const task = tasks[x];

            if (task.isSubtask) {
                task.isComplete = parent.isComplete;
            } else {
                break;
            }
        }
    }

    updateParent(parent: TaskModel | null) {
        if (parent) {
            const children: TaskModel[] = [];
            const tasks = this.tasks as TaskModel[];
            const len = tasks.length || 0;
            let x = tasks.indexOf(parent) + 1;
            for (; x < len; x++) {
                const task = tasks[x];

                if (task.isSubtask) {
                    children.push(task);
                } else {
                    break;
                }
            }

            if (children.length > 0) {
                parent.isComplete = children.every(child => child.isComplete);
            }
        }
    }

    onDragStarted() {
        this.onCancelEdit();
    }

    onCancelEdit() {
        this.clickTimeout = null;
        this.clickCount = 0;
        this.tasks?.forEach(task => (task.isEditing = false));
    }

    onEditTask(task: TaskModel) {
        this.onCancelEdit();
        const tasks = this.tasks as TaskModel[];
        const ix = tasks.indexOf(task) || 0;
        tasks[ix].isEditing = true;
    }

    onHandleClick(task: TaskModel) {
        this.clickCount++;

        if (!this.clickTimeout) {
            this.clickTimeout = setTimeout(() => {
                // single or double?

                if (this.clickCount === 1) {
                    this.onEditTask(task);
                } else {
                    this.onShowNotes(task);
                }

                this.clickCount = 0;
                this.clickTimeout = null;
                this.changeDetectorRef.markForCheck();
            }, 320);
        }
    }

    onUpdateTask(task: TaskModel, s: string, ix: number) {
        const title = s.trim();
        this.onCancelEdit();

        task.title = title;

        if (title.length === 0) {
            this.tasks?.splice(ix, 1);
        }

        this.save.emit(this.tasks as TaskModel[]);
    }

    onAdd(title: string) {
        if ((title || '').trim().length === 0) {
            return;
        }

        this.tasks?.splice(0, 0, {
            title,
            level: 0,
            notes: '',
            isEditing: false,
            isComplete: false,
            isSubtask: false,
        });

        this.save.emit(this.tasks as TaskModel[]);
    }

    areAllCompleted() {
        return this.tasks?.every(n => n.isComplete);
    }

    areAnyCompleted() {
        return this.tasks?.some(n => n.isComplete);
    }

    onCompleteAllTasks(value: boolean) {
        this.tasks?.forEach(t => (t.isComplete = value));
        this.save.emit(this.tasks as TaskModel[]);
    }

    onClearCompleted() {
        this.tasks = this.tasks?.filter(t => t.isComplete !== true) as TaskModel[];
        this.save.emit(this.tasks as TaskModel[]);
    }

    onDelete(index: number) {
        this.tasks?.splice(index, 1);
        this.save.emit(this.tasks as TaskModel[]);
    }

    onReorderList(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.tasks as TaskModel[], event.previousIndex, event.currentIndex);

        this.tasks?.filter(p => !p.isSubtask).forEach(task => this.updateParent(task));

        this.save.emit(this.tasks as TaskModel[]);
    }

    onMoved(event: CdkDragMove<TaskModel>, task: TaskModel) {
        if (event.source.data) {
            event.source.data.isSubtask = event.distance.x > 50;
            task.isSubtask = event.distance.x > 50;
        }
    }

    onSorted(event: CdkDragSortEvent) {
        // console.log('Sorted', event);
        // const tasks = [...this.tasks];
        // moveItemInArray(tasks, event.previousIndex, event.currentIndex);
        // this.activeParent = null;
        // if (event.currentIndex > 0) {
        //     this.activeParent = tasks[event.currentIndex - 1];
        // }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['tasks']) {
            this.tasks = this.tasks?.map(n => ({ ...n })) || []; // clone
        }
    }

    ngOnDestroy() {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}

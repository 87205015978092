<h2 matDialogTitle>Session Extension</h2>
<mat-dialog-content>
    <div class="form">
        <div [formGroup]="form">
            <mat-form-field appearance="outline">
                <mat-label>Amount in Days</mat-label>
                <input matInput type="number" formControlName="days" />
                <mat-hint>How many days to extend the user session?</mat-hint>
                <mat-error *ngIf="ctrls['days'].invalid">
                    <ui-error-display [error]="ctrls['days'].errors"></ui-error-display>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="form.value.days">Extend</button>
</mat-dialog-actions>

<mat-action-list>
  @for (submission of submissions; track submission) {
    <mat-list-item
      class="session-group"
      [ngClass]="{ active: submission.isActive }"
      (click)="onSessionGroupSelected(submission)"
      >@if (submission.isSubmitted) {
      <div matListItemAvatar class="avatar">
        <mat-icon class="no-location">check_circle</mat-icon>
      </div>
    }
    <time matListItemLine class="small mute">
      {{ submission.createdDate | formatDate }}
    </time>
    @if (submission.isSubmitted) {
      <time matListItemLine class="small mute">
        {{ submission.submissionDate | formatDate }}
      </time>
    }
  </mat-list-item>
}
</mat-action-list>

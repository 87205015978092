import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

import { FormPage, FormSection, PaymentModel, ScheduledEvent, Submission } from '@app/data/models';
import { FormSectionType } from '@app/shared/enums';
import { Store } from '@ngxs/store';
import { FormSectionModel, FormSectionComponent } from '../form-section/form-section.component';
import { NgTemplateOutlet } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatDivider } from '@angular/material/divider';
import { FormSectionTextComponent } from '../form-section-text/form-section-text.component';
import { FormSectionQuestionComponent } from '../form-section-question/form-section-question.component';
import { FormSectionDownloadComponent } from '../form-section-download/form-section-download.component';
import { FormSectionMediaComponent } from '../form-section-media/form-section-media.component';
import { FormSectionEventComponent } from '../form-section-event/form-section-event.component';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';
import { CalendlyEventComponent } from '../calendly-event/calendly-event.component';
import { AnyPipe } from '../../../../../shared/src/lib/pipes/any.pipe';

type Page = FormSectionModel & { children: FormSection[] };

@Component({
    selector: 'ui-view-form-submission',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './view-form-submission.component.html',
    styleUrls: ['./view-form-submission.component.scss'],
    standalone: true,
    imports: [
    FlexModule,
    MatDivider,
    FormSectionComponent,
    FormSectionTextComponent,
    FormSectionQuestionComponent,
    FormSectionDownloadComponent,
    FormSectionMediaComponent,
    FormSectionEventComponent,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    NgTemplateOutlet,
    CalendlyEventComponent,
    AnyPipe
],
})
export class ViewFormSubmissionComponent implements OnChanges {
    sections: Page[] = [];
    FormSectionType: typeof FormSectionType = FormSectionType;

    @Input() showComplete = false;
    @Input() submission: Submission | null = null;
    @Input() payments: PaymentModel[] | null = [];
    @Input() pages: FormPage[] | null = [];
    @Input() scheduledEvents: ScheduledEvent[] | null = [];

    @Output() readonly completeSubmission: EventEmitter<Submission> = new EventEmitter();
    @Output() readonly loadScheduledEvents: EventEmitter<string[]> = new EventEmitter();

    constructor(public cd: ChangeDetectorRef, private store: Store) {}

    getPaymentsForSection(section: FormSection) {
        return this.payments?.filter(p => p.productCode === section.productCode) || null;
    }

    update() {
        if (!this.pages || !this.submission) {
            return;
        }

        const allowedSections = [
            FormSectionType.Text,
            FormSectionType.Booking,
            FormSectionType.Question,
            FormSectionType.Event,
            FormSectionType.File,
        ];
        this.sections = [];
        this.sections = this.pages.reduce((acc, page) => {
            const parent: Page = { ...page, children: [] };
            let hasSubmittedResults = false;

            page.children.forEach(child => {
                const value = this.submission?.isSubmitted
                    ? this.submission?.submittedResults.find(x => x.id === child.id)?.value
                    : this.submission?.results.find(x => x.id === child.id)?.value;

                if (!hasSubmittedResults) {
                    hasSubmittedResults = value !== undefined;
                }

                if (value || allowedSections.includes(child.sectionType)) {
                    parent.children.push({
                        id: child.id,
                        title: child.title,
                        description: child.description,
                        type: child.type,
                        hint: child.hint,
                        isRequired: child.isRequired,
                        showPlaybackSpeed: child.showPlaybackSpeed,
                        allowScrubbing: child.allowScrubbing,
                        resumePlayback: child.resumePlayback,
                        mediaAssetId: child.mediaAssetId,
                        maxLabel: child.maxLabel,
                        minLabel: child.minLabel,
                        fileId: child.fileId,
                        fileName: child.fileName,
                        options: child.options,
                        matrix: child.matrix,
                        showOther: child.showOther,
                        futureDatesOnly: child.futureDatesOnly,
                        isEnabled: child.isEnabled,
                        sectionType: child.sectionType,

                        paymentProvider: child.paymentProvider,
                        amount: child.amount,
                        productCode: child.productCode,
                        productName: child.productName,
                        bookingUrl: child.bookingUrl,
                        hideSections: child.hideSections,
                        value,

                        webinarId: child.webinarId,
                        webinarUniqueId: child.webinarUniqueId,
                        webinarTopic: child.webinarTopic,
                        webinarAgenda: child.webinarAgenda,
                        webinarTemplateId: child.webinarTemplateId,

                        meetingId: child.meetingId,
                        meetingUniqueId: child.meetingUniqueId,
                        meetingTopic: child.meetingTopic,
                        meetingAgenda: child.meetingAgenda,
                        meetingTemplateId: child.meetingTemplateId,

                        allowGuestRegistration: child.allowGuestRegistration,
                        showGuestRegistrationJoinLink: child.showGuestRegistrationJoinLink,
                        showGuestRegistrationCopy: child.showGuestRegistrationCopy,
                        showJoinUrl: child.showJoinUrl,
                        showJoinButton: child.showJoinButton,
                        showCopyLinkButton: child.showCopyLinkButton,
                    });
                }
            });

            if (hasSubmittedResults) {
                acc.push(parent);
            }

            return acc;
        }, [] as Page[]).filter(x => x.children.length > 0);
    }

    hasCalendlyResult(section: FormSection) {
        try {
            const model = JSON.parse(section.value as string);
            const uri = model.payload?.event?.uri;

            return uri ? true : false;
        } catch (error) {}

        return false;
    }

    onLoadScheduledEvent(section: FormSection) {
        const uri = JSON.parse(section.value as string).payload?.event?.uri;
        const id = uri?.split('/').pop();

        this.loadScheduledEvents.emit([id]);
    }

    getScheduledEvent(section: FormSection): ScheduledEvent | null {
        const model = JSON.parse(section.value as string);
        const eventUri = model.payload?.event?.uri;

        return this.scheduledEvents?.find(x => x.uri === eventUri) || null;
    }

    onCompleteSubmission() {
        this.completeSubmission.emit(this.submission as Submission);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['submission'] || changes['pages']) {
            this.update();
        }
    }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { Store } from '@ngxs/store';

import { ManageLinksActions, ManageTemplateActions } from '../actions';
import { TabIndex as ShareableLinksTabIndex } from '../containers/shareable-links-list-container/shareable-links-list-container.component';
import { ManageLinksState } from '../state/manage-links.state';

export const ShareableLinksResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    const currentTabIndex = store.selectSnapshot(ManageLinksState.currentTabIndex());
    const archivedSearchResults = store.selectSnapshot(ManageLinksState.getSearchArchivedLinksResults());
    const actions = [new CoreActions.UpdateGlobalLoading(true), new ManageLinksActions.EnsureLoadTags()];

    if (currentTabIndex === ShareableLinksTabIndex.ArchivedLinks && !archivedSearchResults) {
        actions.push(new ManageLinksActions.SearchArchivedLinks());
    } else if (currentTabIndex === ShareableLinksTabIndex.Templates) {
        actions.push(new ManageTemplateActions.EnsureLoadLinkTemplates());
    } else if (currentTabIndex === ShareableLinksTabIndex.Links) {
        actions.push(new ManageLinksActions.EnsureLoadLinks());
        actions.push(new ManageTemplateActions.EnsureLoadLinkTemplates());
    }

    return store.dispatch(actions).pipe(
        take(1),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};

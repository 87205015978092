import { Injector, Type } from '@angular/core';

import { Node as TiptapNode, NodeConfig, nodeInputRule } from '@tiptap/core';
import { AngularNodeViewComponent, AngularNodeViewRenderer } from 'ngx-tiptap';

import { Environment } from '../models';
import { APP_ENVIRONMENT } from '../tokens';
import { migrateToStorageUrl } from '../util';

/**
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */
const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        image: {
            /**
             * Add an image
             */
            setImageSize: (options: { size: 'small' | 'medium' | 'large' }) => ReturnType;
        };
    }
}

export const createImageExtension = (injector: Injector, component: Type<AngularNodeViewComponent> | null = null) => {
    const env = injector.get(APP_ENVIRONMENT) as Environment;

    const config: Partial<NodeConfig<any, any>> = {
        name: 'image',
        inline: true,
        group: 'inline',
        selectable: true,
        draggable: true,
        defaultOptions: {
            sizes: ['small', 'medium', 'large'],
        },

        addAttributes: () => ({
            src: {},
            alt: { default: null },
            title: { default: null },
            size: { default: 'small', rendered: false },
            file: {
                default: null,
                rendered: false,
            }
        }),

        parseHTML: () => [
            {
                tag: 'img[src]',
                getAttrs: dom => {
                    if (typeof dom === 'string') return {};
                    const element = dom as HTMLImageElement;
                    let size = 'small';

                    if (element.classList.contains('custom-image-medium')) {
                        size = 'medium';
                    } else if (element.classList.contains('custom-image-large')) {
                        size = 'large';
                    }

                    const result = {
                        size,
                        src: migrateToStorageUrl(element.getAttribute('src') as string, env, 'image'),
                        title: element.getAttribute('title'),
                        alt: element.getAttribute('alt'),
                    };

                    return result;
                },
            },
        ],
        renderHTML: ({ node, HTMLAttributes }) => {
            const size = node.attrs['size'];
            HTMLAttributes['class'] = 'custom-image-' + size;

            console.log('node.attrs', node.attrs);
            return ['img', HTMLAttributes];
        },

        addCommands() {
            return {
                setImageSize:
                    (options: { size: string }) =>
                    ({ tr, dispatch, commands, chain, view, state }) => {
                        return chain().updateAttributes(this.name, { size: options.size }).run();
                    },
            };
        },
        addInputRules() {
            return [
                nodeInputRule({
                    find: IMAGE_INPUT_REGEX,
                    type: this.type,
                    getAttributes: match => {
                        const [, alt, src, title, size] = match;
                        return {
                            src,
                            alt,
                            title,
                            size,
                        };
                    },
                }),
            ];
        },
    };

    if (component) {
        config.addNodeView = () => AngularNodeViewRenderer(component, { injector });
    }

    return TiptapNode.create(config);
};

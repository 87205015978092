import { LinkSearchResultModel, SearchOptionsModel, ShareableLinkModel, ShareableLinkTemplateModel } from '@app/data/models';
import { Timezone } from 'timezones.json';

const ns = '[Links]';
export class Save {
    static readonly type = `${ns} Save`;
}

export class SaveFailure {
    static readonly type = `${ns} Save Failure`;
    constructor(public error: any) {}
}

export class Edit {
    static readonly type = `${ns} Edit`;
}

export class LoadLinks {
    static readonly type = `${ns} Load Links`;
}
export class LoadLinksDone {
    static readonly type = `${ns} Load Links Done`;
}
export class LoadLinksSuccess {
    static readonly type = `${ns} Load Links Success`;
}

export class LoadLinksFailure {
    static readonly type = `${ns} Load Links Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLinksDone {
    static readonly type = `${ns} Load Archived Links Done`;
}

export class LoadArchivedLinksFailure {
    static readonly type = `${ns} Load Archived Links Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLink {
    static readonly type = `${ns} Load Archived Link`;
    constructor(public rowKey: string) {}
}
export class LoadArchivedLinkSuccess {
    static readonly type = `${ns} Load Archived Link Success`;
}
export class LoadArchivedLinkFailure {
    static readonly type = `${ns} Load Archived Link Failure`;
    constructor(public error: any) {}
}

export class EnsureLoadLinks {
    static readonly type = `${ns} Ensure Load`;
}
export class EnsureLoadTags {
    static readonly type = `${ns} Ensure Load Tags`;
}

export class LoadLinkSessions {
    static readonly type = `${ns} Load Link Sessions`;
    constructor(public rowKey?: string) {}
}

export class LoadLinkSessionsFailure {
    static readonly type = `${ns} Load Link Sessions Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLinkSessions {
    static readonly type = `${ns} Load Archived Link Sessions`;
    constructor(public rowKey?: string | null) {}
}

export class LoadArchivedLinkSessionsFailure {
    static readonly type = `${ns} Load Archived Link Sessions Failure`;
    constructor(public error: any) {}
}

export class LoadGuidedSequenceSummariesForLink {
    static readonly type = `${ns} Load Guided Sequence Summaries For Link`;
    constructor(public rowKey?: string) {}
}

export class LoadGuidedSequenceSummariesForLinkFailure {
    static readonly type = `${ns} Load Guided Sequence Summaries For Link Failure`;
    constructor(public err: any) {}
}

export class Error {
    static readonly type = `${ns} Error`;
    constructor(public error: any, public source: any) {}
}

export class ActivateSession {
    static readonly type = `${ns} Activate Session`;
    constructor(public rowKey: string) {}
}

export class ActivateSessionFailure {
    static readonly type = `${ns} Activate Session Failure`;
    constructor(public error: any) {}
}

export class DeactivateSession {
    static readonly type = `${ns} Deactivate Session`;
    constructor(public rowKey: string) {}
}

export class DeactivateSessionFailure {
    static readonly type = `${ns} Deactivate Session Failure`;
    constructor(public error: any) {}
}

export class UpdateSessionCount {
    static readonly type = `${ns} Update Session Count`;
    constructor(public rowKey: string) {}
}

export class UpdateSessionCountFailure {
    static readonly type = `${ns} Update Session Count Failure`;
    constructor(public error: any) {}
}

export class ConfirmMergeSession {
    static readonly type = `${ns} Confirm Merge Sessions`;
    constructor(public rowKeys: string[]) {}
}

export class MergeSessions {
    static readonly type = `${ns} Merge Sessions`;
    constructor(public primaryRowKey: string, public rowKeys: string[]) {}
}

export class MergeSessionsFailure {
    static readonly type = `${ns} Merge Sessions Failure`;
    constructor(public error: any) {}
}

export class ConfirmDeleteSession {
    static readonly type = `${ns} Confirm Delete Session`;
    constructor(public rowKeys: string[]) {}
}

export class DeleteSession {
    static readonly type = `${ns} Delete Session`;
    constructor(public rowKeys: string[]) {}
}

export class DeleteSessionFailure {
    static readonly type = `${ns} Delete Session Failure`;
    constructor(public error: any) {}
}

export class ApproveSessions {
    static readonly type = `${ns} Approve Sessions`;
    constructor(public linkId: string, public rowKeys: string[]) {}
}
export class ApproveSearchResultSessions {
    static readonly type = `${ns} Approve Search Result Sessions`;
    constructor(public rowKeys: LinkSearchResultModel[]) {}
}

export class ApproveSessionsFailure {
    static readonly type = `${ns} Approve Sessions Failure`;
    constructor(public error: any) {}
}

export class SyncActiveSessions {
    static readonly type = `${ns} Sync Active Sessions`;
    constructor(public rowKey: string) {}
}

export class SyncActiveSessionsFailure {
    static readonly type = `${ns} Sync Active Sessions Failure`;
    constructor(public error: any) {}
}

export class ArchiveLinks {
    static readonly type = `${ns} Archive Link`;
    constructor(public links: ShareableLinkModel[]) {}
}

export class ArchiveLinksFailure {
    static readonly type = `${ns} Archive Link Failure`;
    constructor(public error: any) {}
}

export class ConfirmArchiveLinks {
    static readonly type = `${ns} Confirm Archive Link`;
    constructor(public links: ShareableLinkModel[]) {}
}

export class ConfirmDeleteLinks {
    static readonly type = `${ns} Confirm Delete Link`;
    constructor(public links: ShareableLinkModel[]) {}
}

export class DeleteLinks {
    static readonly type = `${ns} Delete Link`;
    constructor(public links: ShareableLinkModel[]) {}
}

export class DeleteLinksFailure {
    static readonly type = `${ns} Delete Link Failure`;
    constructor(public error: any) {}
}

export class CopyLink {
    static readonly type = `${ns} Copy Link`;
    constructor(public link: ShareableLinkModel) {}
}

export class CopyLinkToClipboard {
    static readonly type = `${ns} Copy Link to Clipboard`;
    constructor(public link: ShareableLinkModel, public timeZone: Timezone, public includeInfluencer: boolean = false) {}
}

export class OpenLink {
    static readonly type = `${ns} Open Link`;
    constructor(public link: ShareableLinkModel) {}
}

export class SetCurrentTabIndex {
    static readonly type = `${ns} Set Current Tab Index`;
    constructor(public index: number) {}
}

export class SearchLinksByName {
    static readonly type = `${ns} Search Links By Name`;
}

export class SearchLinksByNameFailure {
    static readonly type = `${ns} Search Links By Name Failure`;
    constructor(public error: any) {}
}

export class SearchArchivedLinks {
    static readonly type = `${ns} Search Archived Links`;
    constructor(public model?: SearchOptionsModel) {}
}

export class SearchArchivedLinksFailure {
    static readonly type = `${ns} Search Archived Links Failure`;
    constructor(public error: any) {}
}

export class ShowExtendSessionDialog {
    static readonly type = `${ns} Show Extend Session Dialog`;
    constructor(public rowKeys: string[]) {}
}

export class ExtendSessions {
    static readonly type = `${ns} Extend Sessions`;
    constructor(public rowKeys: string[], public days: number) {}
}

export class ExtendSessionsFailure {
    static readonly type = `${ns} Extend Sessions Failure`;
    constructor(public error: any) {}
}

export class ShowExtendLinkDialog {
    static readonly type = `${ns} Show Extend Link Dialog`;
    constructor(public links: ShareableLinkModel[]) {}
}

export class ViewLink {
    static readonly type = `${ns} View Link`;
    constructor(public model: ShareableLinkModel) {}
}

export class ViewArchivedLink {
    static readonly type = `${ns} View Archived Link`;
    constructor(public model: ShareableLinkModel) {}
}

export class LoadTags {
    static readonly type = `${ns} Load Tags`;
}

export class LoadTagsFailure {
    static readonly type = `${ns} Load Tags Failure`;
    constructor(public error: any) {}
}

export class LoadLinkSummary {
    static readonly type = `${ns} Load Link Summary`;
}

export class LoadLinkSummaryFailure {
    static readonly type = `${ns} Load Link Summary Failure`;
    constructor(public error: any) {}
}

export class LoadChecklist {
    static readonly type = `${ns} Load Checklist`;
    constructor(public rowKey?: string, public retry = true) {}
}

export class LoadChecklistSuccess {
    static readonly type = `${ns} Load Checklist Success`;
}

export class LoadChecklistFailure {
    static readonly type = `${ns} Load Checklist Failure`;
    constructor(public error: any) {}
}

export class ExtendLinkExpirationDate {
    static readonly type = `${ns} Extend Link Expiration Date`;
    constructor(public links: ShareableLinkModel[], public days: number) {}
}

export class ExtendLinkExpirationDateFailure {
    static readonly type = `${ns} Extend Link Expiration Date Failure`;
    constructor(public error: any) {}
}

export class SaveChecklist {
    static readonly type = `${ns} Save Checklist`;
    constructor(public checklist: { rowKey: string; content: string }, public rowKey?: string) {}
}

export class SaveChecklistFailure {
    static readonly type = `${ns} Save Checklist Failure`;
    constructor(public error: any) {}
}

export class LoadLinkPayments {
    static readonly type = `${ns} Load Link Payments`;
    constructor(public rowKey?: string) {}
}

export class LoadLinkPaymentsFailure {
    static readonly type = `${ns} Load Link Payments Failure`;
    constructor(public err: any) {}
}

export class CopyShareableLinkTemplate {
    static readonly type = `${ns} Create Link From Template`;
    constructor(public template: ShareableLinkTemplateModel | null) {}
}

export class LoadLink {
    static readonly type = `${ns} Load Link`;
    constructor(public value: string) {}
}

export class UpdateEditLinkModel {
    static readonly type = `${ns} Update Edit Link Model`;
}

<div class="toolbar" [ngClass]="{ 'no-border': !showBorder }">
    <button [disabled]="readonly" (click)="editor.chain().toggleBold().focus().run()" mat-icon-button matTooltip="Bold">
        <mat-icon>format_bold</mat-icon>
    </button>
    <button [disabled]="readonly" (click)="editor.chain().toggleItalic().focus().run()" mat-icon-button matTooltip="Italic">
        <mat-icon>format_italic</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="editor.chain().toggleUnderline().focus().run()"
        mat-icon-button
        matTooltip="Underline"
    >
        <mat-icon>format_underlined</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="editor.chain().toggleStrike().focus().run()"
        mat-icon-button
        matTooltip="Strike-through"
    >
        <mat-icon>strikethrough_s</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="editor.chain().setHorizontalRule().focus().run()"
        mat-icon-button
        matTooltip="Horizontal Rule"
    >
        <mat-icon>horizontal_rule</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="editor.chain().toggleHighlight().focus().run()"
        mat-icon-button
        matTooltip="Highlight"
    >
        <mat-icon>border_color</mat-icon>
    </button>

    <!-- <button class="svg-button" (click)="editor.chain().toggleHeading({ level: 1 }).focus().run()" mat-icon-button>
        <svg viewBox="0 0 18 18">
            <path
                class="fill"
                d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm6.06787,9.209H14.98975V7.59863a.54085.54085,0,0,0-.605-.60547h-.62744a1.01119,1.01119,0,0,0-.748.29688L11.645,8.56641a.5435.5435,0,0,0-.022.8584l.28613.30762a.53861.53861,0,0,0,.84717.0332l.09912-.08789a1.2137,1.2137,0,0,0,.2417-.35254h.02246s-.01123.30859-.01123.60547V13.209H12.041a.54085.54085,0,0,0-.605.60547v.43945a.54085.54085,0,0,0,.605.60547h4.02686a.54085.54085,0,0,0,.605-.60547v-.43945A.54085.54085,0,0,0,16.06787,13.209Z"
            ></path>
        </svg>
    </button>
    <button class="svg-button" (click)="editor.chain().toggleHeading({ level: 2 }).focus().run()" mat-icon-button>
        <svg viewBox="0 0 18 18">
            <path
                class="fill"
                d="M16.73975,13.81445v.43945a.54085.54085,0,0,1-.605.60547H11.855a.58392.58392,0,0,1-.64893-.60547V14.0127c0-2.90527,3.39941-3.42187,3.39941-4.55469a.77675.77675,0,0,0-.84717-.78125,1.17684,1.17684,0,0,0-.83594.38477c-.2749.26367-.561.374-.85791.13184l-.4292-.34082c-.30811-.24219-.38525-.51758-.1543-.81445a2.97155,2.97155,0,0,1,2.45361-1.17676,2.45393,2.45393,0,0,1,2.68408,2.40918c0,2.45312-3.1792,2.92676-3.27832,3.93848h2.79443A.54085.54085,0,0,1,16.73975,13.81445ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"
            ></path>
        </svg>
    </button> -->
    <button
        [disabled]="readonly"
        (click)="editor.chain().toggleOrderedList().focus().run()"
        mat-icon-button
        matTooltip="Add Numbered List"
    >
        <mat-icon>format_list_numbered</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="editor.chain().toggleBulletList().focus().run()"
        mat-icon-button
        matTooltip="Add List"
    >
        <mat-icon>format_list_bulleted</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="editor.chain().sinkListItem('listItem').focus().run()"
        mat-icon-button
        matTooltip="Increase Indent"
    >
        <mat-icon>format_indent_increase</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="editor.chain().liftListItem('listItem').focus().run()"
        mat-icon-button
        matTooltip="Decrease Indent"
    >
        <mat-icon>format_indent_decrease</mat-icon>
    </button>

    <button [disabled]="readonly" (click)="setTextAlign('left')" mat-icon-button matTooltip="Align Left">
        <mat-icon>format_align_left</mat-icon>
    </button>

    <button [disabled]="readonly" (click)="setTextAlign('center')" mat-icon-button matTooltip="Align Centre">
        <mat-icon>format_align_center</mat-icon>
    </button>
    <button [disabled]="readonly" (click)="setTextAlign('right')" mat-icon-button matTooltip="Align Right">
        <mat-icon>format_align_right</mat-icon>
    </button>
    <button [disabled]="readonly" (click)="setTextAlign('justify')" mat-icon-button matTooltip="Align Justify">
        <mat-icon>format_align_justify</mat-icon>
    </button>

    <mat-form-field appearance="outline">
        <mat-label>Font Size</mat-label>
        <mat-select [disabled]="readonly" [value]="currentFontSize" (selectionChange)="onFontSizeChange($event)">
            <mat-option value="">Default</mat-option>
            <mat-option *ngFor="let option of fontSizes" [value]="option.size">{{ option.label }}</mat-option>
            <!-- <mat-option value="1.5rem"><span class="md">Medium</span></mat-option>
            <mat-option value="2rem"><span class="lg">Large</span></mat-option> -->
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Font Family</mat-label>
        <mat-select [disabled]="readonly" [value]="fontFamily" (selectionChange)="onFontFamilyChange($event)">
            <mat-option value="">Normal</mat-option>
            <mat-option [value]="font.value" *ngFor="let font of fonts">
                <span [style.fontFamily]="font.value">{{ font.name }}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button
        mat-icon-button
        [disabled]="readonly"
        (click)="isFontColourOpen = !isFontColourOpen"
        matTooltip="Change Font Colour"
        cdkOverlayOrigin
        #fontColour="cdkOverlayOrigin"
    >
        <mat-icon>format_color_text</mat-icon>
    </button>
    <button
        mat-icon-button
        [disabled]="readonly"
        (click)="isFontForegroundOpen = !isFontForegroundOpen"
        cdkOverlayOrigin
        #fontForeground="cdkOverlayOrigin"
        matTooltip="Change Foreground Colour"
    >
        <mat-icon>format_color_fill</mat-icon>
    </button>
    <!-- <button [disabled]="readonly" (click)="stillImagePicker.click()" mat-icon-button matTooltip="Add Image">
        <mat-icon>image</mat-icon>
    </button>
    <input type="file" #stillImagePicker (change)="onImageSelect($event)" class="hidden" [attr.accept]="stillImageAccept" /> -->
    <button [disabled]="readonly" (click)="filePicker.click()" mat-icon-button matTooltip="Add File">
        <mat-icon>download</mat-icon>
    </button>
    <input type="file" #filePicker (change)="onFileSelect($event)" class="hidden" />
    <button [disabled]="readonly" (click)="onUpdateLink()" mat-icon-button matTooltip="Add Link">
        <mat-icon>link</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="this.editor.chain().unsetAllMarks().run()"
        mat-icon-button
        matTooltip="Clear formatting"
    >
        <mat-icon>clear_all</mat-icon>
    </button>
    <button
        [disabled]="readonly"
        (click)="editor.chain().focus().toggleTaskList().run()"
        mat-icon-button
        matTooltip="Add Checklist"
    >
        <mat-icon>checklist</mat-icon>
    </button>

    <button
        *ngIf="allowInlineMediaAsset"
        [disabled]="readonly"
        (click)="setInlineMediaAsset()"
        mat-icon-button
        matTooltip="Add Media Asset"
    >
        <mat-icon>movie</mat-icon>
    </button>

    <button
        *ngIf="allowZoomLink"
        [disabled]="readonly"
        (click)="editor.chain().insertZoomJoinLink({ showJoinUrl: true }).focus().run()"
        mat-icon-button
        matTooltip="Add Zoom Link"
    >
        <mat-icon>open_in_new</mat-icon>
    </button>
    <button
        *ngIf="allowGuestLink"
        [disabled]="readonly"
        (click)="editor.chain().insertGuestLink({ showJoinUrl: true }).focus().run()"
        mat-icon-button
        matTooltip="Add Guest Link Registration"
    >
        <mat-icon class="zoom-icon" svgIcon="zoom_logo"></mat-icon>
    </button>
</div>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOrigin]="fontColour"
    [cdkConnectedOverlayOpen]="isFontColourOpen"
    (backdropClick)="isFontColourOpen = false"
>
    <span class="picker-options" tabindex="-1">
        <span
            *ngFor="let colour of colours"
            tabindex="0"
            role="button"
            class="picker-colour"
            (click)="setFontColour(colour)"
            [ngStyle]="{ 'background-color': colour }"
        ></span>
    </span>
</ng-template>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOrigin]="fontForeground"
    [cdkConnectedOverlayOpen]="isFontForegroundOpen"
    (backdropClick)="isFontForegroundOpen = false"
>
    <span class="picker-options" tabindex="-1">
        <span
            *ngFor="let colour of colours"
            tabindex="0"
            role="button"
            class="picker-colour"
            (click)="setForegroundColour(colour)"
            [ngStyle]="{ 'background-color': colour }"
        ></span>
    </span>
</ng-template>

<ng-template
    #imageSize
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayOpen]="isImageSizeOpen"
>
    <div class="image-toolbar">
        <button (click)="editor.chain().focus().setImageSize({ size: 'small' }).run()" mat-icon-button>S</button>
        <button (click)="editor.chain().focus().setImageSize({ size: 'medium' }).run()" mat-icon-button>M</button>
        <button (click)="editor.chain().focus().setImageSize({ size: 'large' }).run()" mat-icon-button>L</button>
    </div>
</ng-template>

<ng-template
    #videoSize
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayOpen]="isVideoSizeOpen"
>
    <div class="image-toolbar">
        <button (click)="editor.chain().focus().setVideoSize({ size: 'small' }).run()" mat-icon-button>S</button>
        <button (click)="editor.chain().focus().setVideoSize({ size: 'medium' }).run()" mat-icon-button>M</button>
        <button (click)="editor.chain().focus().setVideoSize({ size: 'large' }).run()" mat-icon-button>L</button>
    </div>
</ng-template>

<ng-template
    #audioDisplay
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayOpen]="isAudioDisplayOpen"
>
    <div class="image-toolbar">
        <button (click)="editor.chain().focus().setAudioDisplay({ display: 'default' }).run()" mat-raised-button>
            Default
        </button>
        <button (click)="editor.chain().focus().setAudioDisplay({ display: 'inline' }).run()" mat-raised-button>
            Inline
        </button>
    </div>
</ng-template>

<ng-template
    #zoomLinkMenu
    cdkConnectedOverlay
    cdkConnectedOverlayLockPosition
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="isZoomLinkMenuOpen"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    (backdropClick)="isZoomLinkMenuOpen = false"
>
    <div class="zoom-toolbar">
        <div class="title">Zoom Link Configuration</div>
        <div class="instructions">Configure how the Zoom link will appear for influencers</div>
        <button (click)="editor.chain().focus().updateZoomJoinLink({ showJoinButton: true }).run()" mat-raised-button>
            Show Join Button
        </button>
        <button (click)="editor.chain().focus().updateZoomJoinLink({ showJoinUrl: true }).run()" mat-raised-button>
            Show Join Url
        </button>
        <button
            (click)="editor.chain().focus().updateZoomJoinLink({ showCopyPasswordButton: true }).run()"
            mat-raised-button
        >
            Show Copy Passcode Button
        </button>
        <mat-form-field appearance="outline">
            <mat-label>Zoom Meeting</mat-label>
            <mat-select
                [value]="currentZoomMeetingId"
                (selectionChange)="editor.chain().focus().updateZoomJoinLink({ zoomId: $event.value }).run()"
            >
                <mat-option *ngFor="let meeting of zoomMeetings" [value]="meeting.id">{{ meeting.topic }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

<ng-template
    #guestLinkMenu
    cdkConnectedOverlay
    cdkConnectedOverlayLockPosition
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="isGuestLinkMenuOpen"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    (backdropClick)="isGuestLinkMenuOpen = false"
>
    <div class="zoom-toolbar">
        <div class="title">Guest Link Registration</div>
        <div class="instructions">Select the Zoom meeting for guests. Influencers may send this link to their guests to register for the selected webinar/meeting</div>
        <mat-form-field appearance="outline">
            <mat-label>Zoom Meeting</mat-label>
            <mat-select
                [value]="currentGuestMeetingId"
                (selectionChange)="editor.chain().focus().updateGuestLink({ zoomId: $event.value }).run()"
            >
                <mat-option *ngFor="let meeting of zoomMeetings" [value]="meeting.id">{{ meeting.topic }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

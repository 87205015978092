<mat-card>
    <!-- <mat-card-title *ngIf="showTitle">{{ title }}</mat-card-title> -->
    <mat-card-content>
        <ui-rich-text-view [content]="model?.title"></ui-rich-text-view>
        @if (payments && payments | any) {
        <mat-list>
            @for (payment of payments; track payment) {
            <mat-list-item>
                <mat-icon [ngClass]="isPaymentComplete(payment) ? 'complete' : null" matListItemIcon>{{
                    isPaymentComplete(payment) ? 'credit_score' : 'payment'
                }}</mat-icon>
                <div matListItemTitle>{{ payment.productName }} ({{ payment.productCode }})</div>
                <div matListItemLine>{{ payment.amount | currency : payment.merchantCurrency }}</div>
                <div matListItemLine>{{ payment.status | paymentStatus }}</div>
                @if (isPaymentComplete(payment)) {
                <div matListItemLine>Sold To: {{ payment.payerName }} ({{ payment.payerEmail }})</div>
                } @if (isPaymentRefunded(payment) || isPaymentComplete(payment)) {
                <ng-container>
                    <div matListItemLine>Txn: {{ payment.transactionId }}</div>
                    <div matListItemLine>
                        <small>{{ payment.paymentDate | formatDate }}</small>
                    </div>
                </ng-container>
                } @if (payment.paymentMethod === PaymentMethod.Stripe && isPaymentPending(payment)) {
                <button
                    mat-raised-button
                    matListItemLine
                    [disabled]="isDisabled"
                    color="primary"
                    (click)="onRetryPayment(payment)"
                >
                    Continue Payment<mat-icon>open_in_new</mat-icon>
                </button>
                }
            </mat-list-item>
            <mat-divider></mat-divider>
            } @if (shouldDisplayPending) {
            <mat-list-item>
                <mat-icon matListItemIcon></mat-icon>
                <div matListItemTitle>Loading. Please wait</div>
                <div matListItemLine>This may take up to several seconds.</div>
                <div class="progress" matListItemLine>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </mat-list-item>
            }
        </mat-list>
        @if (hasSuccessMessage && isTransactionComplete()) {
        <ui-rich-text-view [content]="message"></ui-rich-text-view>
        } @if ( !isTransactionPending() && !isTransactionComplete() && model?.paymentProvider === PaymentProvider.Paypal ) {
        <ng-container [ngTemplateOutlet]="makePayment"> </ng-container>
        } } @else {
        <button mat-raised-button [disabled]="isDisabled" color="primary" (click)="onPurchaseTicket()">Pay Now</button>
        }
    </mat-card-content>
    <mat-card-actions align="end">
        @if (isTransactionComplete() && showLink) {
        <button mat-raised-button [disabled]="isJoiningMeeting" color="primary" (click)="onJoinMeeting()">
            Join Zoom Meeting<mat-icon>video_call</mat-icon>
        </button>
        }
    </mat-card-actions>
</mat-card>
<ng-template #makePayment>
    <button mat-raised-button [disabled]="isDisabled" color="primary" (click)="onPurchaseTicket()">Pay Now</button>
</ng-template>


import { IAction } from '@app/shared/models';

const ns = '[Admin]';

export class ExecuteAction {
    static readonly type = `${ns} Execute Action`;
    constructor(public action: string) {}
}
export class ExecuteActionFailure {
    static readonly type = `${ns} Execute Action Failure`;
    constructor(public action: string, public error: any) {}
}

export class LoadSettings {
    static readonly type = `${ns} Load Settings`;
}
export class LoadSettingsFailure {
    static readonly type = `${ns} Load Settings Failure`;
    constructor(public error: any) {}
}

export class AddErrors {
    static readonly type = `${ns} Add Errors`;
    constructor(public action: IAction, public error: any) {}
}

export class ClearErrors {
    static readonly type = `${ns} Clear Errors`;
    constructor(public actions?: IAction | IAction[]) {}
}

export class ShowAdminActions {
    static readonly type = `${ns} Show Admin Actions`;
}

export class PurgeCdn {
    static readonly type = `${ns} Purge CDN`;
    constructor(public model: { url?: string | null; keys?: string[] | null }, public delay = 0) {}
}

<mat-card [ngClass]="[bgColour ? 'colour-bg-' + bgColour : '', fontColour ? 'colour-' + fontColour : '']">
  <mat-card-header>
    @if (showTitle) {
      <mat-card-title>{{ title }}</mat-card-title>
    }
  </mat-card-header>
  <mat-card-content>
    <ui-rich-text-view [content]="description"></ui-rich-text-view>
  </mat-card-content>
</mat-card>

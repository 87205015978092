import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { DropMediaDialogComponent } from '../dialogs/drop-media/drop-media-dialog.component';
import { DropMediaModel } from '@app/data/models';
import { MediaNodeType } from '@app/shared/enums';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

export type MediaAssetOptions = {
    HTMLAttributes: Record<string, any>;
};

export const createDropMediaExtension = (injector: Injector, isPublic: (() => boolean) | boolean = false) => {
    return Extension.create({
        addProseMirrorPlugins() {
            return [
                new Plugin({
                    key: new PluginKey('dropMedia'),
                    props: {
                        handleDrop(view, event, slice, moved) {
                            const files = Array.from(event?.dataTransfer?.files || []);
                            const hasFiles = files.length > 0;

                            if (!hasFiles) {
                                return false;
                            }

                            const mediaTypes = [
                                'audio/mp3',
                                'audio/mpeg',
                                'audio/ogg',
                                'audio/wav',
                                'audio/webm',
                                'audio/midi',
                                'audio/x-midi',
                                'audio/x-wav',
                                'audio/x-aiff',
                                'audio/x-pn-realaudio',

                                'video/mp4',
                                'video/mpeg',
                                'video/quicktime',
                                'video/webm',
                                'video/3gpp',
                                'video/3gpp2',
                                'video/x-msvideo',
                                'video/x-ms-wmv',
                                'video/x-flv',
                                'video/x-matroska',
                                'video/x-ms-asf',
                                'video/x-ms-wmv',
                                'video/x-msvideo',
                                'video/x-flv',
                                'video/x-matroska',
                                'video/x-ms-asf',

                                'image/jpg',
                                'image/jpeg',
                                'image/png',
                                'image/gif',
                                'image/webp',
                                'image/svg+xml',
                            ];
                            const mediaFiles = files.filter(file => mediaTypes.includes(file.type));

                            if (mediaFiles.length === 0) {
                                return false;
                            }

                            // Prevent default behavior
                            event.preventDefault();

                            const dialog = injector.get(MatDialog);
                            const dialogRef = dialog.open(DropMediaDialogComponent, {
                                data: {
                                    mediaFiles,
                                    isPublic: typeof isPublic === 'function' ? isPublic() : isPublic === true,
                                },
                                closeOnNavigation: true,
                                disableClose: true,
                            });

                            dialogRef.afterClosed().subscribe((result: DropMediaModel[] | null) => {
                                if (result) {
                                    result.forEach(file => {
                                        let node: ProseMirrorNode | null = null;

                                        switch (file.nodeType) {
                                            case MediaNodeType.Audio:
                                                {
                                                    // create a node
                                                    node = view.state.schema.nodes['inlineAudio'].create({
                                                        file,
                                                        src: null,
                                                        isPublic: typeof isPublic === 'function' ? isPublic() : isPublic === true
                                                    });
                                                }
                                                break;
                                            case MediaNodeType.Image:
                                                {
                                                    // create a node
                                                    node = view.state.schema.nodes['image'].create({
                                                        file,
                                                        src: null,
                                                        isPublic: typeof isPublic === 'function' ? isPublic() : isPublic === true
                                                    });
                                                }
                                                break;
                                            case MediaNodeType.Video:
                                                {
                                                    // create a node
                                                    node = view.state.schema.nodes['video'].create({
                                                        file,
                                                        id: file.id,
                                                        src: null,
                                                        isPublic: typeof isPublic === 'function' ? isPublic() : isPublic === true
                                                    });
                                                }
                                                break;
                                            case MediaNodeType.MediaAsset:
                                                {
                                                    // create a node
                                                    node = view.state.schema.nodes['mediaAsset'].create({
                                                        mediaAssetId: null,
                                                        mediaFile: file,
                                                    });
                                                }
                                                break;
                                        }

                                        if (node) {
                                            // insert the node at the current position
                                            const position = view.state.selection.$from.pos;
                                            view.dispatch(view.state.tr.insert(position, node));
                                            view.dispatch(view.state.tr.insertText(' ', position + 1));
                                        }
                                    });
                                }
                            });

                            return true;
                        },
                        handlePaste(view, event, slice) {
                            const items = Array.from(event.clipboardData?.items || []);

                            items.forEach(item => {
                                const image = item.getAsFile();
                                if (item.type.indexOf('image') === 0) {
                                    event.preventDefault();

                                    const node = view.state.schema.nodes['image'].create({
                                        file: {
                                            id: performance.now().toString(),
                                            fileSize: image?.size || 0,
                                            isAudio: false,
                                            isVideo: false,
                                            isImage: true,
                                            file: image,
                                        },
                                        src: null,
                                        title: image?.name,
                                    });

                                    const transaction = view.state.tr.replaceSelectionWith(node);
                                    view.dispatch(transaction);
                                }
                                // else {
                                //     const reader = new FileReader();
                                //     reader.onload = readerEvent => {
                                //         const node = schema.nodes['image'].create({
                                //             src: readerEvent.target?.result,
                                //         });
                                //         const transaction = view.state.tr.replaceSelectionWith(node);
                                //         view.dispatch(transaction);
                                //     };
                                //     if (!image) return;
                                //     reader.readAsDataURL(image);
                                // }
                            });

                            return false;
                        },
                    },
                }),
            ];
        },
    });
};

import { Pipe, PipeTransform } from '@angular/core';

import { isAdmin } from '@app/shared/util';

import { CurrentUser } from '@app/data/models';

@Pipe({
    name: 'isAdmin',
    standalone: true,
})
export class IsAdminPipe implements PipeTransform {
    transform(value: CurrentUser | null | undefined): boolean {
        return isAdmin(value?.role);
    }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DeleteNodeResult, Node } from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { isString } from '@app/shared/util';

@Injectable({
    providedIn: 'root',
})
export class ManageNodesService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) { }

    deleteNode(node: Node, includeChildren: boolean, includeMediaAsset: boolean): Observable<DeleteNodeResult> {
        return this.http
            .post<ServerResult<DeleteNodeResult>>(`${this.env.serverUrl}/manage/nodes/delete`, {
                includeMediaAsset,
                includeChildren,
                id: node.rowKey,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveNode(model: Node): Observable<Node[]> {
        return this.http
            .post<ServerResult<Node[]>>(`${this.env.serverUrl}/manage/nodes`, {
                ...model,
                content: isString(model.content) ? model.content : null,
                binary: isString(model.binary) ? model.binary : null,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    updateNodeDuration(rowKey: string): Observable<string> {
        return this.http
            .post<ServerResult<string>>(`${this.env.serverUrl}/manage/node/duration`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}

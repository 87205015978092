<button mat-raised-button (click)="onExport()"><mat-icon>image</mat-icon>Export</button>
<div>
    <mat-list>
        @for(item of filteredSessions; track item.rowKey){
        <mat-list-item (click)="onSelectItem(item)" class="item" matRipple>
            {{ item.name }}
            @for(registrant of item.registrantsInfo; track $index){
            <mat-icon
                class="webinar-icon"
                [ngClass]="{
                    'has-registered': registrant.hasRegistered,
                    'has-joined': registrant.hasJoined
                }"
                (mouseover)="showTooltip(registrant, origin)"
                (mouseout)="isTooltipOpen = false"
                cdkOverlayOrigin
                #origin="cdkOverlayOrigin"
                svgIcon="zoom_logo"
            ></mat-icon>
            }
        </mat-list-item>
        }
    </mat-list>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayOrigin]="registrantOrigin"
    [cdkConnectedOverlayOpen]="isTooltipOpen"
>
    <ui-registrant-details [model]="model" [zoomMeetings]="zoomMeetings"></ui-registrant-details>
</ng-template>

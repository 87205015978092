import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IntakeSubmissionViewModel } from "@app/data/models";

import { FlexModule } from "@angular/flex-layout/flex";
import { AnyPipe } from "../../../../../shared/src/lib/pipes/any.pipe";
import { SubmissionDatePipe } from "../../../../../shared/src/lib/pipes/submission-data.pipe";
import { FormatDatePipe } from "../../../../../shared/src/lib/pipes/format-date.pipe";

@Component({
    selector: 'ui-session-intake-submission',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './session-intake-submission.component.html',
    styleUrls: ['./session-intake-submission.component.scss'],
    standalone: true,
    imports: [
    FlexModule,
    AnyPipe,
    SubmissionDatePipe,
    FormatDatePipe
],
})
export class SessionIntakeSubmissionComponent {
    @Input() intakeForms: IntakeSubmissionViewModel[];
}
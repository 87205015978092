import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, ValidationErrors, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';

import { Subject, takeUntil, tap } from 'rxjs';

import { DEFAULT_TIME_ZONE, formatDate, formatDatetime, formatTime, isDate, isString, isValidDate } from '@app/shared/util';
import { Timezone } from 'timezones.json';

import timezones from '../../timezones';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormField, MatLabel, MatHint, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { ErrorDisplayComponent } from '../../../../../ui/src/lib/components/error-display/error-display.component';
import { provideNativeDateAdapter } from '@angular/material/core';

@Component({
    selector: 'admin-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: DateTimePickerComponent,
        },
        provideNativeDateAdapter(),
    ],
    standalone: true,
    imports: [
        FlexModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatHint,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        NgIf,
        MatIconButton,
        MatIcon,
        NgxMaterialTimepickerModule,
        MatMenuTrigger,
        ExtendedModule,
        NgClass,
        MatError,
        ErrorDisplayComponent,
        MatMenu,
        NgFor,
        MatMenuItem,
    ],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnDestroy, OnInit, OnChanges {
    DEFAULT_TIME_ZONE = DEFAULT_TIME_ZONE;
    isAlive$: Subject<void> = new Subject();

    disabled = false;
    touched = false;
    data: Date | string | null | undefined = null;
    timeZones: Timezone[] = timezones;

    get showTime(): boolean {
        return this.type === 'time' || this.type === 'datetime';
    }

    get datetime(): AbstractControl {
        return this.form.get('datetime') as AbstractControl;
    }

    get hasValidValue(): boolean {
        return this.datetime.value && this.datetime.valid;
    }

    get valueAsDate(): Date | null {
        return this.hasValidValue ? new Date(this.datetime.value) : null;
    }

    get valueAsTime(): string {
        return this.hasValidValue
            ? (this.valueAsDate as Date).toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
              })
            : '';
    }

    @Input() minDate: Date | null = null;
    @Input() maxDate: Date | null = null;
    @Input() defaultTime = '00:00';
    @Input() timeZone = DEFAULT_TIME_ZONE;
    @Input() type: 'date' | 'time' | 'datetime' = 'datetime';
    @Input() label = '';
    @Input() placeholder = '';
    @Input() hint = '';
    @Input() showTimeZone = false;
    @Input() errors: ValidationErrors | null = {};

    @Output() readonly timeZoneChange = new EventEmitter<Timezone>();

    form = this.fb.group({
        datetime: ['', [(ctrl: AbstractControl) => this.validateDateTime(ctrl)]],
        date: [''],
        time: [this.defaultTime],
        timeZone: [this.timeZone],
    });

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.form
            .get('datetime')
            ?.valueChanges.pipe(
                takeUntil(this.isAlive$),
                tap(value => {
                    this.onDateTimeChange(value || '');
                }),
            )
            .subscribe();
    }

    validateDateTime(ctrl: AbstractControl): { [key: string]: any } | null {
        if (!ctrl.value) {
            return null;
        }

        if (isDate(ctrl.value)) {
            return null;
        }

        if (isString(ctrl.value) && isValidDate(new Date(ctrl.value))) {
            return null;
        }

        return { invalidDateTime: 'Invalid date' };
    }

    onTouched = () => {};

    onChange = (value: any) => {};

    writeValue(obj: any): void {
        this.data = obj;

        // update the form
        if (isString(obj)) {
            this.data = new Date(obj);
        } else if (isDate(obj) && isValidDate(obj)) {
            this.data = obj;
        }

        if (isDate(this.data)) {
            this.form.patchValue({
                datetime: this.formatDatetime(this.data),
                date: formatDate(this.data, this.form.get('timeZone')?.value as string),
                time: formatTime(this.data, this.form.get('timeZone')?.value as string),
            } as any);
        } else {
            this.form.patchValue(
                {
                    datetime: null,
                    date: null,
                    time: null,
                },
                { emitEvent: false },
            );
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;

        if (this.disabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    resolveValue(): Date | null {
        const date = this.form.get('date')?.value || null;
        const time = this.form.get('time')?.value || this.defaultTime;
        const timeZone = this.form.get('timeZone')?.value || DEFAULT_TIME_ZONE;

        if (!date) {
            return null;
        }

        const offset = new Intl.DateTimeFormat('en-US', {
            timeZone,
            timeZoneName: 'longOffset' as any,
        })
            .format(new Date(`${date} ${time}`))
            .split(' ')[1];

        return new Date(`${date} ${time} ${offset}`.trim());
    }

    onDateTimeChange(value: string): void {
        const date = new Date(value);

        if (isValidDate(date)) {
            this.form.get('date')?.setValue(formatDate(date), { emitEvent: false });
            this.form.get('time')?.setValue(formatTime(date), { emitEvent: false });

            this.onUpdateValue(this.resolveValue());
            this.onTouched();
        } else {
            this.form.get('date')?.setValue(null, { emitEvent: false });
            this.form.get('time')?.setValue(null, { emitEvent: false });

            this.onUpdateValue(null);
            this.onTouched();
        }
    }

    onDateChange(ev: MatDatepickerInputEvent<any>): void {
        const date = formatDate(ev.value);
        const time = this.form.get('time')?.value || this.defaultTime;

        this.form.get('date')?.setValue(date);
        this.form.get('datetime')?.setValue(formatDatetime(new Date(`${date} ${time}`)), { emitEvent: false });

        this.onUpdateValue(this.resolveValue());
        this.onTouched();
    }

    onTimeChange(time: string): void {
        const date = this.form.get('date')?.value || formatDate(new Date());

        this.form.get('time')?.setValue(time);
        this.form.get('datetime')?.setValue(formatDatetime(new Date(`${date} ${time}`)), { emitEvent: false });

        this.onUpdateValue(this.resolveValue());
        this.onTouched();
    }

    onTimeZoneChange(timeZone: Timezone): void {
        this.form.get('timeZone')?.setValue(timeZone.utc[0]);
        this.onUpdateValue(this.resolveValue());
        this.timeZoneChange.emit(timeZone);
        this.onTouched();
    }

    onUpdateValue(value: Date | string | null | undefined) {
        this.data = null;

        if (isString(value)) {
            this.data = new Date(value);
        } else if (isDate(value) && isValidDate(value)) {
            this.data = value;
        }

        this.onChange(this.data);
    }

    formatDatetime(date: Date): string {
        if (this.type === 'time') {
            return formatTime(date, this.form.get('timeZone')?.value as string);
        }

        if (this.type === 'date') {
            return formatDate(date, this.form.get('timeZone')?.value as string);
        }

        return date.toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: this.form.get('timeZone')?.value as string,
            hour12: true,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['timeZone']) {
            if (changes['timeZone'].currentValue !== changes['timeZone'].previousValue) {
                if (changes['timeZone'].isFirstChange()) {
                    this.form.get('timeZone')?.setValue(this.timeZone, { emitEvent: false });
                } else {
                    const timeZone = this.timeZones.find(tz => tz.utc[0] === (this.timeZone || DEFAULT_TIME_ZONE));

                    if (timeZone) {
                        this.form.get('timeZone')?.setValue(timeZone.utc[0]);
                        this.onUpdateValue(this.resolveValue());
                        this.onTouched();
                    }
                }
            }
        }

        if (changes['errors']) {
            if (changes['errors'].currentValue !== changes['errors'].previousValue) {
                const parentErrors = this.errors || {};
                const localErrors = this.form.get('datetime')?.errors || {};
                const errors = { ...parentErrors, ...localErrors };

                if (Object.keys(errors).length > 0) {
                    this.form.get('datetime')?.setErrors(errors);
                } else {
                    this.form.get('datetime')?.setErrors(null);
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}

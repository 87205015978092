<mat-card [ngClass]="{ readonly: readonly }">
  <!-- <mat-card-title *ngIf="showTitle">{{ title }}</mat-card-title> -->
  <mat-card-content>
    <ui-rich-text-view [content]="model?.description"></ui-rich-text-view>
    <div el #parent="el" class="video-player">
      <shared-media-asset-player
        [token]="token"
        [videoConfiguration]="configuration"
        [controlBarConfiguration]="controlBarConfiguration"
        (playerEvent)="onPlayerEvent($event)"
        >
      </shared-media-asset-player>
    </div>
    @if (isRequired && !hasWatched) {
      <div class="error">This media must be watched fully to complete the form</div>
    }
  </mat-card-content>
</mat-card>

<h1 mat-dialog-title>Create meeting from zoom template</h1>
<mat-dialog-content>
    <admin-create-meeting-from-template
        [model]="model$ | async"
        [templates]="templates$ | async"
        (refresh)="onRefreshTemplates()"
    ></admin-create-meeting-from-template>
    <ui-error-display [error]="errors$ | async"></ui-error-display>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Close</button>
    <button
        mat-raised-button
        color="primary"
        [disabled]="(isValid$ | async) === false || (isSaving$ | async) === true"
        (click)="onCreateMeetingFromTemplate()"
    >
        <mat-spinner *ngIf="(isSaving$ | async) === true" matPrefix diameter="20" strokeWidth="4"></mat-spinner>
        Create
    </button>
</mat-dialog-actions>

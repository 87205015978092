import { Injector, Type } from '@angular/core';
import { mergeAttributes, Node as TiptapNode, NodeConfig } from '@tiptap/core';
import { AngularNodeViewComponent, AngularNodeViewRenderer } from 'ngx-tiptap';
import { migrateToStorageUrl } from '../util';
import { APP_ENVIRONMENT } from '../tokens';
import { Environment } from '../models';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        video: {
            setVideoSize: (options: { size: 'small' | 'medium' | 'large' }) => ReturnType;
        };
    }
}

export const createVideoExtension = (injector: Injector, component: Type<AngularNodeViewComponent> | null = null) => {
    const env = injector.get(APP_ENVIRONMENT) as Environment;

    const config: Partial<NodeConfig<any, any>> = {
        name: 'video', // unique name for the Node
        inline: false,
        group: 'block', // belongs to the 'block' group of extensions
        selectable: true, // so we can select the video
        draggable: true, // so we can drag the video
        atom: true, // is a single unit

        addOptions() {
            return {
                sizes: ['small', 'medium', 'large'],
                HTMLAttributes: {
                    id: null,
                    class: null,
                    preload: 'none',
                    file: null,
                    src: null,
                    size: null,
                    style: null,
                },
            };
        },

        addStorage() {
            return {
                file: null,
            };
        },

        addAttributes() {
            return {
                id: {
                    default: null,
                },
                src: {
                    default: null,
                },
                controls: {
                    default: 'controls',
                },
                class: {
                    default: null,
                },
                size: {
                    default: 'medium',
                },
                controlsList: {
                    default: 'nodownload',
                },
                preload: {
                    default: 'none',
                },
                file: {
                    default: null,
                    rendered: false,
                },
            };
        },

        parseHTML() {
            return [
                {
                    tag: 'div[data-upload-video] video',
                    getAttrs: dom => {
                        if (typeof dom === 'string') return {};
                        const element = dom as HTMLVideoElement;
                        let size = 'small';

                        if (element.classList.contains('custom-image-medium')) {
                            size = 'medium';
                        } else if (element.classList.contains('custom-image-large')) {
                            size = 'large';
                        }

                        const result = {
                            size,
                            src: migrateToStorageUrl(element.getAttribute('src') as string, env, 'video'),
                            title: element.getAttribute('title'),
                        };

                        return result;
                    },
                },
            ];
        },

        renderHTML({ HTMLAttributes }) {
            return [
                'div',
                { 'data-upload-video': '', style: HTMLAttributes['style'] },
                [
                    'video',
                    mergeAttributes(this.options.HTMLAttributes, {
                        ...HTMLAttributes,
                        class: `custom-video-${HTMLAttributes['size']}`,
                    }),
                ],
            ];
        },

        addCommands() {
            return {
                setVideoSize:
                    (options: { size: string }) =>
                    ({ tr, dispatch, commands, chain, view, state }) => {
                        return chain().updateAttributes(this.name, { size: options.size }).run();
                    },
            };
        },

        addNodeView() {
            return ({ HTMLAttributes, node, editor, getPos }) => {
                const divWrapper = document.createElement('div');
                const videoEl = document.createElement('video');

                Object.entries(HTMLAttributes).forEach(([key, value]) => {
                    if (key === 'class') {
                        videoEl.classList.add(value as string);
                        return;
                    }

                    videoEl.setAttribute(key, value as string);
                });

                divWrapper.attributes.setNamedItem(document.createAttribute('data-upload-video'));
                divWrapper.contentEditable = 'false';

                divWrapper.addEventListener('contextmenu', event => {
                    event.preventDefault();
                    return false;
                });

                divWrapper.appendChild(videoEl);

                return {
                    dom: divWrapper,
                };
            };
        },
    };

    if (component) {
        config.addNodeView = () => AngularNodeViewRenderer(component, { injector });
    }

    return TiptapNode.create(config);
};

import {
    ApplicationRef,
    EmbeddedViewRef,
    inject,
    Inject,
    Injectable,
    createComponent,
    EnvironmentInjector,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterState } from '@angular/router';

import { interval, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { SECONDS_30 } from '@app/shared/constants';
import { ScreenOrientation, ScreenProfile } from '@app/shared/enums';
import { Environment } from '@app/shared/models';
import { APP_AUTOUPDATE, APP_ENVIRONMENT } from '@app/shared/tokens';
import { WatermarkComponent } from '@app/shared/components/watermark/watermark.component';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Actions, createSelector, NgxsOnInit, ofAction, State, StateContext, Store } from '@ngxs/store';
import { Idle } from 'idle.ts/src/js/idle';

import { CoreActions, InsightActions, NotificationActions } from '../actions';
import { AppSettings } from '../models';
import { ApplicationInsightsService } from '../services/application-insights.service';
import { ClipboardService } from '../services/clipboard.service';
import { MatchMediaService } from '../services/match-media.service';
import { AuthState } from './auth.state';
import html2canvas from 'html2canvas';
import { base64ToBlob } from '@app/shared/util';

export interface StateModel {
    isAutoUpdateEnabled: boolean;
    isLoading: boolean;
    isSideNavOpen: boolean;
    screenProfile: ScreenProfile;
    screenOrientation: ScreenOrientation;
    settings: AppSettings | null;
    lastActivity: Date | null;
    isConnected: boolean;
    watermarkUri: string | null;
}

@State<StateModel>({
    name: 'core',
    defaults: {
        isAutoUpdateEnabled: true,
        isLoading: true,
        isSideNavOpen: false,
        screenProfile: ScreenProfile.Unknown,
        screenOrientation: ScreenOrientation.Unknown,
        settings: null,
        lastActivity: null,
        isConnected: true,
        watermarkUri: null,
    },
})
@Injectable({
    providedIn: 'root',
})
export class CoreState implements NgxsOnInit {
    private idle: Idle | null = null;

    static isLoading() {
        return createSelector([CoreState], (state: StateModel) => state.isLoading);
    }

    static isSideNavOpen() {
        return createSelector([CoreState], (state: StateModel) => state.isSideNavOpen);
    }

    static getScreenOrientation() {
        return createSelector([CoreState], (state: StateModel) => state.screenOrientation);
    }

    static getScreenProfile() {
        return createSelector([CoreState], (state: StateModel) => state.screenProfile);
    }

    static getSettings() {
        return createSelector([CoreState], (state: StateModel) => state.settings);
    }

    static isAutoUpdateEnabled() {
        return createSelector([CoreState], (state: StateModel) => state.isAutoUpdateEnabled);
    }

    static getLastActivity() {
        return createSelector([CoreState], (state: StateModel) => state.lastActivity);
    }

    static isConnected() {
        return createSelector([CoreState], (state: StateModel) => state.isConnected);
    }

    static getWatermarkUri() {
        return createSelector([CoreState], (state: StateModel) => state.watermarkUri);
    }

    appRef = inject(ApplicationRef);
    environmentInjector = inject(EnvironmentInjector);

    constructor(
        private actions: Actions,
        private store: Store,
        private clipboardService: ClipboardService,
        private matchMediaService: MatchMediaService,
        private insights: ApplicationInsightsService,
        private titleService: Title,
        private router: Router,
        @Inject(APP_ENVIRONMENT) private env: Environment,
        @Inject(APP_AUTOUPDATE) private autoUpdate: boolean,
    ) {}

    ngxsOnInit({ patchState, dispatch }: StateContext<StateModel>) {
        this.idle = new Idle({
            activityReportInSec: this.env.activityReportInSec,
            onReportUserIsIdle: () => {
                dispatch(new CoreActions.UserIsIdle());
            },
            onReportUserIsActive: () => {
                dispatch(new CoreActions.UserIsActive());
            },
        });

        this.matchMediaService.orientation$
            .pipe(tap(orientation => this.store.dispatch(new CoreActions.UpdateOrientation(orientation))))
            .subscribe();

        this.matchMediaService.screen$
            .pipe(tap(screenProfile => this.store.dispatch(new CoreActions.UpdateProfile(screenProfile))))
            .subscribe();

        patchState({
            isAutoUpdateEnabled: this.autoUpdate,
        });

        dispatch(new CoreActions.StartAutoRefresh());

        this.actions
            .pipe(
                ofAction(CoreActions.UserIsIdle, CoreActions.UserIsActive),
                distinctUntilChanged((pre, curr) => (pre.constructor as any).type === (curr.constructor as any).type),
                withLatestFrom(this.store.select(CoreState.getLastActivity())),
                switchMap(([action, lastActivity]) => {
                    if (action instanceof CoreActions.UserIsIdle) {
                        patchState({ lastActivity: new Date() });
                        return of('');
                    }

                    if (lastActivity) {
                        const now = new Date();
                        const diff = now.getTime() - lastActivity.getTime();
                        const diffInSec = Math.round(diff / 1000);

                        if (diffInSec > this.env.inactivityTimeoutInSec) {
                            patchState({ lastActivity: null });
                            return dispatch(new CoreActions.SessionActive());
                        }
                    }

                    patchState({ lastActivity: null });
                    return of('');
                }),
            )
            .subscribe();
    }

    @Action(CoreActions.UpdateGlobalLoading)
    updateGlobalLoading({ patchState }: StateContext<StateModel>, { isLoading }: CoreActions.UpdateGlobalLoading) {
        patchState({ isLoading });
    }

    @Action(CoreActions.UpdateConnectionStatus)
    updateConnectionStatus({ patchState }: StateContext<StateModel>, { isConnected }: CoreActions.UpdateConnectionStatus) {
        patchState({ isConnected });
    }

    @Action(CoreActions.ToggleSideNavigation)
    toggleSideNavigation({ patchState }: StateContext<StateModel>) {
        const isOpen = this.store.selectSnapshot(CoreState.isSideNavOpen());
        patchState({ isSideNavOpen: !isOpen });
    }

    @Action(CoreActions.UpdateSideNavigation)
    updateSideNavigation({ patchState }: StateContext<StateModel>, { isOpen }: CoreActions.UpdateSideNavigation) {
        patchState({ isSideNavOpen: isOpen });
    }

    @Action(CoreActions.CopyToClipboard)
    copyToClipBoard(ctx: StateContext<StateModel>, { model }: CoreActions.CopyToClipboard) {
        return this.clipboardService.copyToClipboard(model.text || model.items).pipe(
            mergeMap(result => {
                if (result) {
                    if (model.message) {
                        return this.store.dispatch(new NotificationActions.Info(model.message));
                    }

                    return of('');
                }

                return this.store.dispatch(new NotificationActions.Error('Error copying to clipboard'));
            }),
        );
    }

    @Action(CoreActions.UpdateOrientation)
    updateOrientation(ctx: StateContext<StateModel>, { screenOrientation }: CoreActions.UpdateOrientation) {
        ctx.patchState({ screenOrientation });
    }

    @Action(CoreActions.UpdateProfile)
    updateProfile(ctx: StateContext<StateModel>, { screenProfile }: CoreActions.UpdateProfile) {
        ctx.patchState({ screenProfile });
    }

    @Action(CoreActions.SetPageTitle)
    setPageTitle(ctx: StateContext<StateModel>, action: CoreActions.SetPageTitle) {
        const title = action.title
            ? action.title
            : [...new Set(this.getTitle(this.router.routerState, this.router.routerState.root))].join(' | ');

        this.titleService.setTitle(title);
    }

    @Action(RouterNavigation)
    navigation({ dispatch }: StateContext<StateModel>) {
        dispatch([new CoreActions.UpdateSideNavigation(false), new CoreActions.SetPageTitle(null)]);
    }

    @Action(CoreActions.ReportUserActivity)
    reportUserActivity({ dispatch }: StateContext<StateModel>) {
        this.idle?.onUserActivity();
    }

    @Action(CoreActions.StartAutoRefresh)
    startAutoRefresh({ dispatch }: StateContext<StateModel>) {
        return interval(SECONDS_30).pipe(
            startWith(null),
            withLatestFrom(
                this.store.select(CoreState.isAutoUpdateEnabled()),
                this.store.select(AuthState.isAuthenticated()),
            ),
            map(([, autoUpdate, isAuthenticated]) => autoUpdate && isAuthenticated === true),
            tap(autoRefresh => {
                if (autoRefresh) {
                    dispatch(new CoreActions.Refresh());
                }
            }),
        );
    }

    @Action(InsightActions.SetAuthenticationContext)
    setAuthenticationContext(ctx: StateContext<StateModel>, action: InsightActions.SetAuthenticationContext) {
        this.insights.setAuthenticationContext(action.id);
    }

    @Action(InsightActions.ClearAuthenticationContext)
    clearAuthenticationContext() {
        this.insights.clearAuthenticationContext();
    }

    @Action(InsightActions.TrackException)
    trackException(ctx: StateContext<StateModel>, action: InsightActions.TrackException) {
        this.insights.trackException(action.ex);
    }

    @Action(InsightActions.TrackMetric)
    trackMetric(ctx: StateContext<StateModel>, action: InsightActions.TrackMetric) {
        this.insights.trackMetric(action.event, action.props);
    }

    @Action(InsightActions.TrackEvent)
    trackEvent(ctx: StateContext<StateModel>, action: InsightActions.TrackEvent) {
        this.insights.trackEvent(action.event, action.props);
    }

    @Action(InsightActions.TrackPageView)
    trackPageView(ctx: StateContext<StateModel>, action: InsightActions.TrackPageView) {
        if (action.event) {
            this.insights.trackPageView(action.event);
        }
    }

    @Action(InsightActions.TrackDependency)
    trackDependency(ctx: StateContext<StateModel>, action: InsightActions.TrackDependency) {
        this.insights.trackDependency(action.event);
    }

    @Action(CoreActions.MarkUserActivity)
    markUserActivity() {
        this.idle?.onUserActivity();
    }

    @Action(CoreActions.ComputeWatermark)
    computeWatermark({ patchState }: StateContext<StateModel>, { text }: CoreActions.ComputeWatermark) {
        const componentRef = createComponent(WatermarkComponent, { environmentInjector: this.environmentInjector });

        componentRef.setInput('watermark', text);
        componentRef.changeDetectorRef.detectChanges();
        this.appRef.attachView(componentRef.hostView);

        const domEl = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        document.body.appendChild(domEl);

        return html2canvas(domEl.firstChild as HTMLElement, { backgroundColor: 'transparent' })
            .then(canvas => {
                if (canvas) {
                    const contentDataURL = canvas.toDataURL('image/png');
                    const header = 'data:image/png;base64,';

                    const watermarkBlob = base64ToBlob(contentDataURL.substring(header.length), 'image/png');
                    const blobUri = URL.createObjectURL(watermarkBlob);

                    patchState({ watermarkUri: blobUri });
                }
            })
            .finally(() => {
                this.appRef.detachView(componentRef.hostView);
                componentRef.destroy();
            });
    }

    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
            data.push(parent.snapshot.data['title']);
        }

        if (state && parent) {
            data.push(...this.getTitle(state, (state as any).firstChild(parent)));
        }

        return data;
    }
}

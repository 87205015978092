import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { combineLatest, Observable, of } from 'rxjs';
import { filter, finalize, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { ChecklistLinkModel } from '@app/data/models';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';

import { ManageChecklistActions, ManageTemplateActions } from '../actions';
import { ManageChecklistsState,  } from '../state/manage-checklists.state';
import { ManageTemplatesState } from '../state/manage-templates.state';

export const EditChecklistLinkResolver: ResolveFn<Observable<boolean | Observable<void>>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
    actions: Actions = inject(Actions),
): Observable<boolean | Observable<void>> => {
    const { linkId } = route.params;

    store.dispatch([
        new CoreActions.UpdateGlobalLoading(true),
        new ManageChecklistActions.EnsureLoadLinks(),
        new ManageTemplateActions.EnsureLoadChecklistTemplates(),
        new ManageTemplateActions.EnsureLoadPreambleTemplates(),
    ]);

    return combineLatest({
        hasLinksLoaded: store.select(ManageChecklistsState.hasLoadedLinks()),
        hasChecklistTemplatesLoaded: store.select(ManageTemplatesState.hasLoadedChecklistTemplates()),
    }).pipe(
        filter(result => result.hasChecklistTemplatesLoaded && result.hasLinksLoaded),
        take(1),
        withLatestFrom(store.select(ManageChecklistsState.getLinks())),
        mergeMap(([, links]) => {
            if (linkId !== 'new') {
                const link = links.find(l => l.rowKey === linkId) as ChecklistLinkModel;

                if (link.checklistTemplateIds.length > 0) {
                    store.dispatch([
                        ...link.checklistTemplateIds.map(id => new ManageChecklistActions.LoadChecklist(`${linkId}.${id}`)),
                        new ManageChecklistActions.UpdateSessionCount(linkId),
                    ]);

                    return actions.pipe(
                        ofActionCompleted(
                            ManageChecklistActions.LoadChecklistSuccess,
                            ManageChecklistActions.LoadChecklistFailure,
                        ),
                        take(link.checklistTemplateIds.length),
                        map(() => store.dispatch(new ManageChecklistActions.LoadLinkSessions(linkId))),
                    );
                }
            }

            return of(true);
        }),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};

import { ChangeDetectionStrategy, Component, Inject, Injector, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { createDropMediaExtension } from '@app/admin/tiptap/drop-media';
import { APP_TIPTAP_EDITOR_CONFIG } from '@app/shared/tokens';
import { Editor, EditorOptions } from '@tiptap/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { RichTextEditorToolbarComponent } from '../../components/rich-text-editor-toolbar/rich-text-editor-toolbar.component';
import { NgxTiptapModule } from 'ngx-tiptap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <mat-dialog-content>
            <admin-rich-text-editor-toolbar [editor]="editor"></admin-rich-text-editor-toolbar>
            <div class="tiptap-container">
                <div tiptap [editor]="editor" [(ngModel)]="content"></div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" (click)="onSave()">Save</button>
        </mat-dialog-actions>
    `,
    styles: [
        `
            :host {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            mat-dialog-content {
                flex: 1 1;
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            admin-rich-text-editor-toolbar {
                flex: 0 0;
            }

            .tiptap-container {
                flex: 1 1;
                overflow: auto;
            }
        `,
    ],
    standalone: true,
    imports: [
        CdkScrollable,
        MatDialogContent,
        RichTextEditorToolbarComponent,
        NgxTiptapModule,
        ReactiveFormsModule,
        FormsModule,
        MatDialogActions,
        MatButton,
    ],
})
export class TaskNotesDialogComponent implements OnDestroy {
    editor: Editor;
    content: string;

    constructor(
        @Inject(APP_TIPTAP_EDITOR_CONFIG) config: EditorOptions,
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<TaskNotesDialogComponent>,
        injector: Injector
    ) {
        this.editor = new Editor({
            ...config,
            extensions: config.extensions.concat([createDropMediaExtension(injector)]),
            editable: true,
        });
        this.content = data;
    }

    onSave() {
        this.dialogRef.close(this.content);
    }

    ngOnDestroy() {
        this.editor.destroy();
    }
}

<div class="header" fxLayout="row" fxLayoutAlign="end center">
    <mat-spinner *ngIf="(isSaving$ | async) === true" diameter="24"></mat-spinner>
    <button mat-icon-button (click)="isExpanded = !isExpanded">
        <mat-icon>{{ isExpanded ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>
    <button mat-icon-button color="warn" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="content">
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">sticky_note_2</mat-icon>
            Notes
        </ng-template>
        <section
            class="notes"
            fxLayout="row nowrap"
            fxLayoutAlign="start stretch"
            [@detailExpand]="isExpanded ? 'expanded' : 'collapsed'"
        >
            <admin-notes-list
                [notes]="notes$ | async"
                [isLoading]="isLoadingNotes$ | async"
                (add)="onAdd()"
                (delete)="onDelete($event)"
                (pin)="onPin($event)"
                (selectionChange)="onSelectionChange($event)"
                fxFlex="0 0 350px"
            ></admin-notes-list>
            <admin-notes
                *ngIf="model$ | async"
                fxFlex="1 1 auto"
                [nodes]="nodes$ | async"
                [model]="model$ | async"
                (save)="onSave($event)"
                (nodeSelected)="onNodeSelected($event)"
            ></admin-notes>
        </section>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">task</mat-icon>
            Tasks
        </ng-template>
        <section fxLayout="row nowrap" fxLayoutAlign="start stretch" [@detailExpand]="isExpanded ? 'expanded' : 'collapsed'">
            <admin-tasks fxFlex="1 1 auto" (save)="onSaveTasks($event)" [tasks]="tasks$ | async"></admin-tasks>
        </section>
    </mat-tab>
</mat-tab-group>

import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, finalize, mergeMap, take, tap } from 'rxjs/operators';

import { CoreActions, NotificationActions } from '@app/data/actions';
import {
    ArchivedChecklistLinkContentModel,
    ChecklistLinkModel,
    ChecklistTemplate,
    EditChecklistLinkModel,
    FormState,
    LinkSessionSummary,
    PaymentModel,
    PreambleTemplate,
    QueryResult,
    SearchOptionsModel,
    Submission,
} from '@app/data/models';
import { handleNotFoundError, transformResponseToText } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { RouterState } from '@app/data/state/router.state';
import {
    BlobContainer,
    LinkExpiration,
    LinkInactivityMode,
    LinkStatus,
    LinkType,
    RegistrationMode,
    TimerUnit,
} from '@app/shared/enums';
import { Environment, isErrorModel } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import {
    convertObjectKeysToCamelCase,
    DEFAULT_TIME_ZONE,
    formatDatetimeWithTimeZone,
    formatLinkUrl,
    generatePassword,
} from '@app/shared/util';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Actions, createSelector, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';
import { Timezone } from 'timezones.json';

import { ManageChecklistActions } from '../actions';
import {
    ArchiveContentLinkConfirmationDialogComponent,
} from '../dialogs/archive-content-link-confirmation/archive-content-link-confirmation-dialog.component';
import {
    DeleteLinkSessionsConfirmationDialogComponent,
} from '../dialogs/delete-link-session-confirmation/delete-link-session-confirmation-dialog.component';
import {
    DeleteShareableLinkConfirmationDialogComponent,
} from '../dialogs/delete-shareable-link-confirmation/delete-shareable-link-confirmation-dialog.component';
import {
    MergeLinkSessionsConfirmationDialogComponent,
} from '../dialogs/merge-link-sessions-confirmation/merge-link-sessions-confirmation-dialog.component';
import { ManageChecklistLinksService } from '../services/manage-checklist-links.service';
import { ManageTemplatesState } from './manage-templates.state';

interface StateModel {
    checklistContent: Record<string, string | null | undefined>;

    searchArchivedLinksOptions: SearchOptionsModel | null;
    searchArchivedLinksQueryResult: QueryResult<ChecklistLinkModel> | null;

    template: string | null;

    tags: string[];

    isLoadingPreamble: boolean;
    isSavingPreamble: boolean;
    isSavingChecklist: boolean;
    isLoadingChecklist: boolean;
    isSearching: boolean;

    hasLoadedLinks: boolean;
    hasLoadedArchivedLinks: boolean;
    hasLoadedTemplates: boolean;

    isLoadingLinks: boolean;
    isLoadingTemplates: boolean;
    isSaving: boolean;

    errors: any;
    links: ChecklistLinkModel[];
    archivedContent: ArchivedChecklistLinkContentModel | null;
    archivedLinks: ChecklistLinkModel[];
    archivedLink: ChecklistLinkModel | null;
    linkSessions: LinkSessionSummary[];
    linkPayments: PaymentModel[];
    linkSubmissions: Submission[];
    copyLink: ChecklistLinkModel | ChecklistTemplate | null;
    currentTabIndex: number;
    form: FormState<EditChecklistLinkModel>;
    filters: {
        model: any;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
    templateFilters: FormState<Record<string, string>>;
    archivedFilters: {
        model: any;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
    sessionFilters: {
        model: any;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
    searchArchivedLinks: {
        model: SearchOptionsModel | null;
        status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' | '';
        dirty: boolean;
        errors: any;
    };
}

@State<StateModel>({
    name: 'manageChecklists',
    defaults: {
        checklistContent: {},

        isSearching: false,
        searchArchivedLinksQueryResult: null,
        searchArchivedLinksOptions: { filter: '', tags: [], total: null, limit: 25, offset: 0 },
        tags: [],

        template: null,
        isLoadingChecklist: false,
        isSavingChecklist: false,
        isLoadingPreamble: false,
        isSavingPreamble: false,
        hasLoadedLinks: false,
        hasLoadedArchivedLinks: false,
        isLoadingLinks: false,
        isLoadingTemplates: false,
        hasLoadedTemplates: false,
        isSaving: false,
        errors: null,
        links: [],
        archivedContent: null,
        archivedLinks: [],
        archivedLink: null,
        linkSessions: [],
        linkPayments: [],
        linkSubmissions: [],
        copyLink: null,
        currentTabIndex: 0,
        form: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        filters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        templateFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        archivedFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        sessionFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        searchArchivedLinks: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class ManageChecklistsState {
    readonly format: string = 'MMM DD YYYY @ hh:mmA';

    static isLoadingPreamble() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.isLoadingPreamble);
    }

    static isSavingPreamble() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.isSavingPreamble);
    }

    static hasLoadedLinks() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.hasLoadedLinks);
    }

    static hasLoadedChecklistTemplates() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.hasLoadedTemplates);
    }

    static hasLoadedArchivedLinks() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.hasLoadedArchivedLinks);
    }

    static isLoading() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.isLoadingLinks);
    }

    static currentTabIndex() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.currentTabIndex);
    }

    static isNewLink() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId === 'new');
    }

    static getModel() {
        return createSelector(
            [
                ManageChecklistsState.getLinks(),
                ManageChecklistsState.getCopyLink(),
                ManageChecklistsState.getLinkId(),
                ManageChecklistsState.isNewLink(),
                ManageTemplatesState.getChecklistTemplates(),
            ],
            (
                links: ChecklistLinkModel[],
                copy: ChecklistLinkModel,
                linkId: string,
                isNew: boolean,
                templates: ChecklistTemplate[],
            ) => {
                if (isNew) {
                    if (copy) {
                        return {
                            ...copy,
                            checklists: {
                                ...(copy.checklistTemplateIds || []).reduce((acc, current) => {
                                    const template = templates.find(t => t.rowKey === current) || null;

                                    if (template) {
                                        acc[template.rowKey as string] = template.content;
                                    }

                                    return acc;
                                }, {} as Record<string, string>),
                            },
                            rowKey: null,
                            isDeleted: false,
                            activationDate: null,
                            createdDate: new Date(),
                            status: LinkStatus.Active,
                            activeSubmissionCount: 0,
                        };
                    }
                    return {
                        rowKey: null,
                        questions: [],
                        password: generatePassword(),
                        isDeleted: false,
                        url: '',
                        requestedBy: '',
                        activationDate: null,
                        status: LinkStatus.Active,
                        timer: null,
                        timerUnit: TimerUnit.Hours,
                        isNameRequired: true,
                        isPasswordRequired: false,

                        timerEndDate: null,
                        sessionLimit: null,
                        startDate: null,
                        endDate: null,
                        type: LinkType.Checklist,
                        requiresApproval: false,

                        hasRegistration: false,
                        displayWatermark: true,
                        linkExpiration: LinkExpiration.None,
                        linkInactivityMode: LinkInactivityMode.Default,
                        lastActivityDate: null,
                        tags: [],

                        createdDate: new Date(),
                        registrationMode: RegistrationMode.Default,
                        timeZone: DEFAULT_TIME_ZONE,
                        registrationEndDate: null,
                        timestamp: null,
                        archivedDate: null,
                        linkExpirationDate: null,

                        lateRegistrationCount: 0,
                        activeSubmissionCount: 0,
                        approvalCount: 0,

                        isPreambleRequired: true,
                        preambleTemplateId: null,
                        checklistTemplateIds: [],

                        requestedByVisitorId: null,
                        hasAdminAcknowledged: true,
                        autoArchive: true,
                        isExpired: false,
                        isActive: true,
                        displayRegistrationCountdown: false,
                        allowUsersToRequestMoreTime: false,
                    } as ChecklistLinkModel;
                }

                const link = links.find(l => l.rowKey === linkId) || null;

                if (link) {
                    return { ...link };
                }

                return null;
            },
        );
    }

    static getChecklistContentForLink() {
        return createSelector(
            [
                ManageChecklistsState.getLinkId(),
                ManageChecklistsState.getChecklistContent(),
                ManageTemplatesState.getChecklistTemplates(),
            ],
            (
                linkId: string,
                checklistContent: Record<string, string | null | undefined>,
                templates: ChecklistTemplate[],
            ) => {
                return templates.reduce((acc, current) => {
                    const rowKey = current.rowKey as string;
                    const content = checklistContent[`${linkId}.${rowKey}`] || current.content;

                    acc.push({ ...current, content });

                    return acc;
                }, [] as ChecklistTemplate[]);
            },
        );
    }

    static getChecklistContentForArchivedLink() {
        return createSelector(
            [
                ManageChecklistsState.getLinkId(),
                ManageChecklistsState.getChecklistContent(),
                ManageChecklistsState.getArchivedContent(),
            ],
            (
                linkId: string,
                checklistContent: Record<string, string | null | undefined>,
                archivedContent: ArchivedChecklistLinkContentModel,
            ) => {
                return archivedContent.checklistTemplates.reduce((acc, current) => {
                    const rowKey = current.rowKey as string;
                    const content = checklistContent[`${linkId}.${rowKey}`] || current.content;

                    acc.push({ ...current, content });

                    return acc;
                }, [] as ChecklistTemplate[]);
            },
        );
    }

    static getPreambleContentForLink() {
        return createSelector([ManageTemplatesState.getPreambleTemplates()], (templates: PreambleTemplate[]) => {
            return templates
                .reduce((acc, current) => {
                    const rowKey = current.rowKey as string;

                    if (!current.isGlobal || rowKey === 'preamble-checklists') {
                        acc.push(current);
                    }

                    return acc;
                }, [] as PreambleTemplate[])
                .sort((a, b) => {
                    if (a.isGlobal && !b.isGlobal) {
                        return -1;
                    } else if (!a.isGlobal && b.isGlobal) {
                        return 1;
                    }

                    return a.name.localeCompare(b.name);
                });
        });
    }

    static getErrors() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.errors);
    }

    static getLinks() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.links);
    }

    static getCopyLink() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.copyLink);
    }

    static getArchivedContent() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.archivedContent);
    }

    static getArchivedLinkSessions() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.archivedContent?.sessions || []);
    }

    static getLinkSessions() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.linkSessions);
    }

    static getLinkSubmissions() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.linkSubmissions);
    }

    static getLinkPayments() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.linkPayments);
    }

    static isSaving() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.isSaving);
    }

    static isValid() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.form.status === 'VALID');
    }

    static getForm() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.form);
    }

    static getTags() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.tags);
    }

    static getSearchArchivedLinksForm() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.searchArchivedLinks);
    }

    static getSearchArchivedLinksSearchOptions() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.searchArchivedLinksOptions);
    }

    static isSearching() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.isSearching);
    }

    static getSearchArchivedLinksResults() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.searchArchivedLinksQueryResult);
    }

    static getTemplate() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.template);
    }

    static getArchivedLink() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.archivedLink);
    }

    static getLinkId() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId);
    }

    static getChecklistContent() {
        return createSelector([ManageChecklistsState], (state: StateModel) => state.checklistContent);
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private manageChecklistsService: ManageChecklistLinksService,
        private blobService: BlobService,
        private store: Store,
        private actions: Actions,
        private matDialog: MatDialog,
    ) {}

    @Action(UpdateFormValue)
    updateFormValue({ patchState, getState }: StateContext<StateModel>, action: UpdateFormValue): void {
        if (action.payload.path === 'manageChecklists.filters') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }

        if (action.payload.path === 'manageChecklists.searchArchivedLinks') {
            const options = getState().searchArchivedLinksOptions;
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
            patchState({ searchArchivedLinksOptions: { ...options, ...action.payload.value } });
        }

        if (action.payload.path === 'manageChecklists.sessionFilters') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }
    }

    @Action(ManageChecklistActions.SetCurrentTabIndex)
    setCurrentTabIndex(ctx: StateContext<StateModel>, action: ManageChecklistActions.SetCurrentTabIndex): void {
        ctx.patchState({
            currentTabIndex: action.index,
        });
        localStorage.setItem('manageChecklists.currentTabIndex', JSON.stringify(action.index));
    }

    @Action(ManageChecklistActions.CopyLink)
    copyLink(ctx: StateContext<StateModel>, action: ManageChecklistActions.CopyLink): void {
        ctx.patchState({
            copyLink: action.link,
        });
    }

    @Action(ManageChecklistActions.EnsureLoadLinks)
    ensureLoad({ getState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedLinks } = getState();

        if (!hasLoadedLinks) {
            return dispatch([new ManageChecklistActions.LoadLinks()]);
        }

        return of(void 0);
    }

    @Action(ManageChecklistActions.EnsureLoadArchivedLinks)
    ensureArchivedLoad(
        { getState, dispatch }: StateContext<StateModel>,
        action: ManageChecklistActions.EnsureLoadArchivedLinks,
    ): Observable<any> | void {
        const { hasLoadedArchivedLinks } = getState();

        if (!hasLoadedArchivedLinks) {
            return dispatch([new ManageChecklistActions.SearchArchivedLinks()]);
        }
    }

    @Action(ManageChecklistActions.LoadArchivedLink)
    loadArchivedLink(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageChecklistActions.LoadArchivedLink,
    ): Observable<any> | void {
        patchState({ archivedLink: null });

        return this.manageChecklistsService.getArchivedLink(action.rowKey).pipe(
            tap(archivedLink => {
                patchState({
                    archivedLink,
                });
                dispatch(new ManageChecklistActions.LoadArchivedLinkSuccess());
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.LoadArchivedLinkFailure(err))),
        );
    }

    @Action(ManageChecklistActions.LoadLinks)
    load({ getState, patchState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const { isLoadingLinks } = getState();

        if (isLoadingLinks) {
            return this.actions.pipe(ofActionDispatched(ManageChecklistActions.LoadLinksDone), take(1));
        }

        patchState({ isLoadingLinks: true });

        return this.manageChecklistsService.getAllLinks().pipe(
            tap(links => {
                patchState({
                    links,
                });
                dispatch(new ManageChecklistActions.LoadLinksSuccess());
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.LoadLinksFailure(err))),
            finalize(() => {
                patchState({ isLoadingLinks: false, hasLoadedLinks: true });
                dispatch(new ManageChecklistActions.LoadLinksDone());
            }),
        );
    }

    @Action(ManageChecklistActions.LoadLinkSessions)
    loadLinkSessions(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageChecklistActions.LoadLinkSessions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageChecklistsState.getModel());
        if ((!model || !model.rowKey || model.rowKey === 'new') && (!rowKey || rowKey === 'new')) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageChecklistsService.getAllLinkSessions((model?.rowKey || rowKey) as string).pipe(
            tap(linkSessions => ctx.patchState({ linkSessions })),
            catchError(err => this.store.dispatch(new ManageChecklistActions.LoadLinkSubmissionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageChecklistActions.LoadArchivedLinkSessions)
    loadArchivedLinkSessions(
        ctx: StateContext<StateModel>,
        action: ManageChecklistActions.LoadArchivedLinkSessions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageChecklistsState.getArchivedLink());
        const rowKey = action.rowKey || model?.rowKey;

        if (!rowKey) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.blobService.downloadBlobBlock(`archived-checklists/${rowKey}.json`, BlobContainer.Private).pipe(
            transformResponseToText(),
            handleNotFoundError('{}'),
            tap(content => {
                const result = JSON.parse(content) as any;
                const archivedContent = convertObjectKeysToCamelCase(result);
                ctx.patchState({ archivedContent });
            }),
            catchError(err => ctx.dispatch(new ManageChecklistActions.LoadArchivedLinkSessionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageChecklistActions.LoadLinkSubmissions)
    loadLinkSubmissions(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageChecklistActions.LoadLinkSubmissions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageChecklistsState.getModel());
        if (((!model || !model.rowKey) && !rowKey) || rowKey === 'new') {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageChecklistsService.getAllLinkSubmissions((model?.rowKey || rowKey) as string).pipe(
            tap(linkSubmissions => ctx.patchState({ linkSubmissions })),
            catchError(err => this.store.dispatch(new ManageChecklistActions.LoadLinkSessionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    getLinkFromForm(): any {
        const model = this.store.selectSnapshot(ManageChecklistsState.getForm()).model as EditChecklistLinkModel;

        const { timeZone } = model.linkExpiration;
        // const timeZone = getTimeZoneByIanaTimeZone(ianaTimeZone);

        return {
            ...model,
            ...model.linkExpiration,
            timeZone: timeZone || DEFAULT_TIME_ZONE,
        };
    }

    @Action(ManageChecklistActions.Save)
    save({ dispatch, patchState }: StateContext<StateModel>): Observable<void> {
        patchState({
            isSaving: true,
            errors: null,
        });

        const model = this.getLinkFromForm() as EditChecklistLinkModel;
        const links = [...this.store.selectSnapshot(ManageChecklistsState.getLinks())];
        const ix = links.findIndex(n => n.rowKey === model.rowKey);
        const checklists = model.checklistTemplateIds.reduce((acc, rowKey) => {
            const content = model.checklists?.[rowKey];

            if (content) {
                acc.push({
                    rowKey,
                    content,
                });
            }

            return acc;
        }, [] as { rowKey: string; content: string }[]);

        model.checklists = null;

        return this.manageChecklistsService.saveLink({ ...model, ...model.linkExpiration }).pipe(
            mergeMap(link => {
                if (ix !== -1) {
                    links[ix] = link;
                } else {
                    links.push(link);
                }

                patchState({
                    links,
                    copyLink: null,
                });

                if (!checklists.length) {
                    return dispatch([
                        new Navigate(['/home/checklist-links']),
                        new ManageChecklistActions.UpdateSessionCount(link.rowKey as string),
                        new ManageChecklistActions.UpdateLinkSummary(),
                        new NotificationActions.Success('Link has been successfully saved'),
                    ]);
                }

                return forkJoin(
                    checklists.map(checklist =>
                        dispatch(new ManageChecklistActions.SaveChecklist(checklist, link.rowKey as string)),
                    ),
                ).pipe(
                    mergeMap(() => {
                        return dispatch([
                            new Navigate(['/home/checklist-links']),
                            new ManageChecklistActions.UpdateSessionCount(link.rowKey as string),
                            new ManageChecklistActions.UpdateLinkSummary(),
                            new NotificationActions.Success('Link has been successfully saved'),
                        ]);
                    }),
                    finalize(() => patchState({ isSavingChecklist: false })),
                );
            }),
            catchError(err => dispatch(new ManageChecklistActions.SaveFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.SaveChecklist)
    saveChecklist(
        { patchState }: StateContext<StateModel>,
        action: ManageChecklistActions.SaveChecklist,
    ): Observable<any> | void {
        const rowKey = `${action.rowKey}.${action.checklist.rowKey}`;

        patchState({
            isSavingChecklist: true,
            errors: null,
        });

        return this.blobService.uploadBlobBlock(
            `checklists/${rowKey}.html`,
            action.checklist.content,
            'text/plain',
            BlobContainer.Private,
        );
    }

    @Action(ManageChecklistActions.LoadChecklist)
    loadChecklist(
        { patchState, getState, dispatch }: StateContext<StateModel>,
        action: ManageChecklistActions.LoadChecklist,
    ) {
        patchState({
            isLoadingChecklist: true,
        });

        const linkId = action.rowKey || this.store.selectSnapshot(ManageChecklistsState.getLinkId());

        return this.blobService.downloadBlobBlock(`checklists/${linkId}.html`, BlobContainer.Private).pipe(
            transformResponseToText(),
            handleNotFoundError('<p></p>'),
            mergeMap(template => {
                const checklistContent = getState().checklistContent;
                patchState({
                    checklistContent: {
                        ...checklistContent,
                        [linkId]: template,
                    },
                });
                return dispatch(new ManageChecklistActions.LoadChecklistSuccess());
            }),
            catchError(err => dispatch(new ManageChecklistActions.LoadChecklistFailure(err))),
            finalize(() => patchState({ isLoadingChecklist: false })),
        );
    }

    @Action(ManageChecklistActions.ConfirmArchiveLinks)
    confirmArchiveLinks(ctx: StateContext<StateModel>, action: ManageChecklistActions.ConfirmArchiveLinks) {
        return this.matDialog
            .open(ArchiveContentLinkConfirmationDialogComponent, {
                disableClose: true,
                data: action.links,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageChecklistActions.ArchiveLinks(action.links));
                    }
                }),
            );
    }

    @Action(CoreActions.Refresh)
    autoRefresh({ dispatch }: StateContext<StateModel>) {
        const route = this.store.selectSnapshot(RouterState.selectCurrentRoute());
        if (!route) return;

        const url = route.url?.map(s => s.path).join('/') || '';

        if (url.includes('checklist-links')) {
            dispatch([new ManageChecklistActions.LoadLinks()]);
        }
    }

    @Action(ManageChecklistActions.ArchiveLinks)
    archiveLinks(ctx: StateContext<StateModel>, action: ManageChecklistActions.ArchiveLinks) {
        return this.manageChecklistsService.archiveLinks(action.links).pipe(
            mergeMap(result => {
                const links = this.store.selectSnapshot(ManageChecklistsState.getLinks()).map(link => ({ ...link }));
                const linksToArchive = links.filter(link => result.linksToRemove.includes(link.rowKey as string));
                const linksToArchiveIds = linksToArchive.map(link => link.rowKey);

                linksToArchive.forEach(link => (link.isDeleted = true));

                ctx.patchState({
                    links: links.filter(link => !linksToArchiveIds.includes(link.rowKey)),
                });

                const linkId = this.store.selectSnapshot(RouterState.selectRouteParam('linkId'));

                if (linkId) {
                    this.store.dispatch(new Navigate(['/home/checklist-links']));
                }

                return this.store.dispatch(new NotificationActions.Success('Link has been successfully archived'));
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.ArchiveLinksFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.ConfirmDeleteLinks)
    confirmDeleteLink(ctx: StateContext<StateModel>, action: ManageChecklistActions.ConfirmDeleteLinks) {
        return this.matDialog
            .open(DeleteShareableLinkConfirmationDialogComponent, {
                disableClose: true,
                data: action.links,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageChecklistActions.DeleteLinks(action.links));
                    }
                }),
            );
    }

    @Action(ManageChecklistActions.DeleteLinks)
    deleteLinks(ctx: StateContext<StateModel>, action: ManageChecklistActions.DeleteLinks) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageChecklistsService.deleteLinks(action.links).pipe(
            mergeMap(result => {
                return this.store.dispatch([
                    new ManageChecklistActions.SearchArchivedLinks(), // force refresh of archived links
                    new NotificationActions.Success('Link has been successfully deleted'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.DeleteLinksFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.ConfirmMergeSession)
    confirmMergeSessions(ctx: StateContext<StateModel>, action: ManageChecklistActions.ConfirmMergeSession) {
        const allSessions = this.store.selectSnapshot(ManageChecklistsState.getLinkSessions());
        const sessions: LinkSessionSummary[] = [];

        action.rowKeys.forEach(rowKey => {
            const session = allSessions.find(s => s.rowKey === rowKey);
            if (session) {
                sessions.push(session);
            }
        });

        return this.matDialog
            .open(MergeLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    linkType: LinkType.Checklist,
                    sessions,
                },
            })
            .afterClosed()
            .pipe(
                tap((primary: LinkSessionSummary) => {
                    if (primary) {
                        this.store.dispatch(new ManageChecklistActions.MergeSessions(primary.rowKey, action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageChecklistActions.MergeSessions)
    mergeSessions(ctx: StateContext<StateModel>, action: ManageChecklistActions.MergeSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageChecklistsState.getModel()) as ChecklistLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageChecklistsService.mergeSessions(rowKey, action.primaryRowKey, action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageChecklistActions.LoadLinkSessions(),
                    new ManageChecklistActions.LoadLinkSubmissions(),
                    new ManageChecklistActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submissions Merged Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.MergeSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.ConfirmDeleteSession)
    confirmDeleteSession(ctx: StateContext<StateModel>, action: ManageChecklistActions.ConfirmDeleteSession) {
        return this.matDialog
            .open(DeleteLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: action.rowKeys,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageChecklistActions.DeleteSessions(action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageChecklistActions.DeleteSessions)
    deleteSession(ctx: StateContext<StateModel>, action: ManageChecklistActions.DeleteSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageChecklistsState.getModel()) as ChecklistLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageChecklistsService.deleteSessions(action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageChecklistActions.LoadLinkSessions(),
                    new ManageChecklistActions.LoadLinkSubmissions(),
                    new ManageChecklistActions.UpdateLinkSummary(),
                    new ManageChecklistActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Deleted Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.DeleteSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.ActivateSession)
    activateSession(ctx: StateContext<StateModel>, action: ManageChecklistActions.ActivateSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageChecklistsState.getModel()) as ChecklistLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageChecklistsService.activateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageChecklistActions.LoadLinkSessions(),
                    new ManageChecklistActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission Activated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.ActivateSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.DeactivateSession)
    deactivateSession(ctx: StateContext<StateModel>, action: ManageChecklistActions.DeactivateSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageChecklistsState.getModel()) as ChecklistLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageChecklistsService.deactivateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageChecklistActions.LoadLinkSessions(),
                    new ManageChecklistActions.LoadLinkSubmissions(),
                    new ManageChecklistActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission Deactivated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.DeactivateSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.SyncActiveSessions)
    syncActiveSessions(ctx: StateContext<StateModel>, action: ManageChecklistActions.SyncActiveSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageChecklistsService.syncActiveSessions(action.rowKey).pipe(
            tap(result => {
                const links = this.store.selectSnapshot(ManageChecklistsState.getLinks()).concat();
                const ix = links.findIndex(l => l.rowKey === action.rowKey);
                const link = links[ix];

                links[ix] = {
                    ...link,
                    lateRegistrationCount: result.lateRegistrationCount,
                    activeSubmissionCount: result.activeSubmissionCount,
                    approvalCount: result.approvalCount,
                    sessionLimit: result.sessionLimit,
                };

                ctx.patchState({
                    links,
                });
            }),
            mergeMap(link =>
                this.store.dispatch([
                    new NotificationActions.Success('Active Submissions Synchronized'),
                    new UpdateFormValue({
                        value: { sessionLimit: link.sessionLimit, activeSubmissionCount: link.activeSubmissionCount },
                        path: 'manageChecklists.form',
                    }),
                ]),
            ),
            catchError(err => this.store.dispatch(new ManageChecklistActions.SyncActiveSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.UpdateSessionCount)
    updateSessionCount(ctx: StateContext<StateModel>, action: ManageChecklistActions.UpdateSessionCount) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageChecklistsService.updateLinkCounts(action.rowKey).pipe(
            tap(result => {
                const links = [...this.store.selectSnapshot(ManageChecklistsState.getLinks())];
                const ix = links.findIndex(l => l.rowKey === action.rowKey);
                const link = links[ix];

                links[ix] = {
                    ...link,
                    lateRegistrationCount: result.lateRegistrationCount,
                    activeSubmissionCount: result.activeSubmissionCount,
                    approvalCount: result.approvalCount,
                };

                ctx.patchState({
                    links,
                });
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.UpdateSessionCountFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.CopyLinkToClipboard)
    copyLinkToClipboard(ctx: StateContext<StateModel>, action: ManageChecklistActions.CopyLinkToClipboard): void {
        const model = action.link;
        const url = formatLinkUrl(model, this.env);
        const details = [`Shareable Link: ${url}`];

        if (model.isPasswordRequired) {
            details.push('');
            details.push(`Password: ${model.password}`);
        }

        switch (model.linkExpiration) {
            case LinkExpiration.Timer:
                {
                    const w1 = model.requiresApproval ? 'approval' : 'activation';
                    switch (model.timerUnit) {
                        case TimerUnit.Months:
                            details.push(`Timer length after ${w1} (${model.timer}m)`);
                            break;
                        case TimerUnit.Weeks:
                            details.push(`Timer length after ${w1} (${model.timer}w)`);
                            break;
                        case TimerUnit.Days:
                            details.push(`Timer length after ${w1} (${model.timer}d)`);
                            break;
                        case TimerUnit.Hours:
                            details.push(`Timer length after ${w1} (${model.timer}h)`);
                            break;
                        case TimerUnit.Minutes:
                            details.push(`Timer length after ${w1} (${model.timer}min)`);
                            break;
                        default:
                    }

                    if (model.timerEndDate) {
                        details.push(`Expires ${this.formatDate(model.timerEndDate, action.timeZone)}`);
                    }
                }
                break;
            case LinkExpiration.Date:
                if (model.startDate) {
                    details.push(`Starts ${this.formatDate(model.startDate, action.timeZone)}`);
                }

                if (model.endDate) {
                    details.push(`Expires ${this.formatDate(model.endDate, action.timeZone)}`);
                }
                break;
        }

        if (model.sessionLimit) {
            details.push('');
            details.push(`Active Device & Browser Limit (${model.sessionLimit})`);
        }

        if (model.registrationEndDate) {
            details.push('');
            details.push(`Registration Cut-off ${this.formatDate(model.registrationEndDate, action.timeZone)}`);
        }

        if (model.registrationMode === RegistrationMode.Onetime) {
            details.push('');
            details.push(`Link is configured for a one-time access`);
        }

        this.store.dispatch(
            new CoreActions.CopyToClipboard({
                text: details.join('\r\n'),
                message: 'Link copied to clipboard',
            }),
        );
    }

    @Action(ManageChecklistActions.OpenLink)
    openLink(ctx: StateContext<ManageChecklistsState>, action: ManageChecklistActions.OpenLink): Observable<any> | void {
        const url = formatLinkUrl(action.link, this.env);

        if (action.link.isPasswordRequired) {
            return this.store
                .dispatch(
                    new CoreActions.CopyToClipboard({
                        text: action.link.password,
                    }),
                )
                .pipe(tap(() => window.open(url, `_blank`)));
        }

        window.open(url, `_blank`);
    }

    @Action(ManageChecklistActions.ApproveSessions)
    approveSessions(ctx: StateContext<StateModel>, action: ManageChecklistActions.ApproveSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageChecklistsState.getModel()) as ChecklistLinkModel;
        const rowKey = link?.rowKey || action.linkId;

        return this.manageChecklistsService.approveSession(rowKey, action.rowKeys).pipe(
            mergeMap(() => {
                const actions = [
                    new ManageChecklistActions.LoadLinkSessions(),
                    new ManageChecklistActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Approved'),
                ];

                if (link && link.hasRegistration && link.registrationEndDate) {
                    const endDate = new Date(link.registrationEndDate);
                    const now = new Date();

                    if (now > endDate) {
                        actions.push(new ManageChecklistActions.SyncActiveSessions(rowKey));
                    }
                }

                return this.store.dispatch(actions);
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.ApproveSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageChecklistActions.SearchArchivedLinks, { cancelUncompleted: true }) // cancel if another search goes through
    searchArchivedLinks(
        { dispatch, patchState, getState }: StateContext<StateModel>,
        action: ManageChecklistActions.SearchArchivedLinks,
    ) {
        const { searchArchivedLinksOptions: options } = getState();
        const form = this.store.selectSnapshot(ManageChecklistsState.getSearchArchivedLinksForm());
        const formModel = form.model as SearchOptionsModel;
        const model = action.model || {
            ...formModel,
            limit: options?.limit || 0,
            total: options?.total || null,
            offset: options?.offset || 0,
        };

        if (model.total === undefined) {
            model.total = options?.total;
        }

        patchState({ isSearching: true });

        return this.manageChecklistsService.searchArchivedLinks(model).pipe(
            tap(queryResult => {
                patchState({
                    searchArchivedLinksQueryResult: queryResult,
                    searchArchivedLinksOptions: {
                        filter: '',
                        tags: [],
                        limit: queryResult.limit,
                        total: queryResult.total,
                        offset: queryResult.offset,
                    },
                });
            }),
            catchError(err => this.store.dispatch(new ManageChecklistActions.SearchArchivedLinksFailure(err))),
            finalize(() => patchState({ isSearching: false, hasLoadedArchivedLinks: true })),
        );
    }

    @Action([
        ManageChecklistActions.ActivateSessionFailure,
        ManageChecklistActions.LoadLinksFailure,
        ManageChecklistActions.SaveFailure,
        ManageChecklistActions.DeleteLinksFailure,
        ManageChecklistActions.ArchiveLinksFailure,
        ManageChecklistActions.DeleteSessionFailure,
        ManageChecklistActions.MergeSessionsFailure,
        ManageChecklistActions.ApproveSessionsFailure,
        ManageChecklistActions.LoadLinkSessionsFailure,
        ManageChecklistActions.DeactivateSessionFailure,
        ManageChecklistActions.SyncActiveSessionsFailure,
        ManageChecklistActions.UpdateSessionCountFailure,
        ManageChecklistActions.LoadArchivedLinkSessionsFailure,
        ManageChecklistActions.LoadChecklistFailure,
    ])
    handleFailures(ctx: StateContext<StateModel>, action: CheckListErrorTypes): Observable<any> | void {
        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof ManageChecklistActions.LoadLinksFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Loading Links', action.error));
            case action instanceof ManageChecklistActions.SaveFailure:
                // ctx.patchState({ errors: action.error });
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Saving Link', action.error));
            case action instanceof ManageChecklistActions.DeleteLinksFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Deleting Link(s)', action.error));
            case action instanceof ManageChecklistActions.ArchiveLinksFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Archiving Link(s)', action.error),
                );
            case action instanceof ManageChecklistActions.DeactivateSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deactivating Session(s)', action.error),
                );
            case action instanceof ManageChecklistActions.DeleteSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deleting Link Session(s)', action.error),
                );
            case action instanceof ManageChecklistActions.SyncActiveSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Syncing Link Sessions', action.error),
                );
            case action instanceof ManageChecklistActions.UpdateSessionCountFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Updating Session Counts', action.error),
                );
            case action instanceof ManageChecklistActions.LoadArchivedLinkSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Link Sessions', action.error),
                );
            case action instanceof ManageChecklistActions.LoadLinkSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Link Sessions', action.error),
                );
            case action instanceof ManageChecklistActions.MergeSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Merging Link Sessions', action.error),
                );
            case action instanceof ManageChecklistActions.ApproveSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Approving Link Session(s)', action.error),
                );
            case action instanceof ManageChecklistActions.LoadChecklistFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Checklist', action.error),
                );
            default:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error', action.error));
        }
    }

    private formatDate(date: Date, timeZone: Timezone): string {
        return formatDatetimeWithTimeZone(date, timeZone);
    }
}

type CheckListErrorTypes =
    | ManageChecklistActions.ActivateSessionFailure
    | ManageChecklistActions.LoadLinksFailure
    | ManageChecklistActions.SaveFailure
    | ManageChecklistActions.DeleteLinksFailure
    | ManageChecklistActions.ArchiveLinksFailure
    | ManageChecklistActions.DeleteSessionFailure
    | ManageChecklistActions.MergeSessionsFailure
    | ManageChecklistActions.ApproveSessionsFailure
    | ManageChecklistActions.LoadLinkSessionsFailure
    | ManageChecklistActions.DeactivateSessionFailure
    | ManageChecklistActions.SyncActiveSessionsFailure
    | ManageChecklistActions.UpdateSessionCountFailure
    | ManageChecklistActions.LoadArchivedLinkSessionsFailure
    | ManageChecklistActions.LoadChecklistFailure;

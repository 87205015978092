<section>
    <admin-media-asset-player
        *ngIf="!encodingJob"
        [src]="mediaAssetSrc"
        [token]="token"

    >
    </admin-media-asset-player>

    <admin-encoding-status *ngIf="encodingJob" [encodingJob]="encodingJob"></admin-encoding-status>
</section>

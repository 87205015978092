import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { catchError, delay, filter, finalize, mergeMap, take, tap } from 'rxjs/operators';

import { NotificationActions } from '@app/data/actions';
import { AppSettings } from '@app/data/models';
import { Environment, IAction, isErrorModel } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { isArray, isFunction } from '@app/shared/util';
import { Action, createSelector, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';

import { AdminActions } from '../actions';
import { AdminActionsDialogComponent } from '../dialogs/admin-actions/admin-actions-dialog.component';
import { AdminService } from '../services/admin.service';
import { AuthState } from '@app/data/state/auth.state';

export interface StateModel {
    isReady: boolean;
    isAuthenticating: boolean;
    isLoading: boolean | null;
    settings: AppSettings | null;
    errors: { [key: string]: any };
}

@State<StateModel>({
    name: 'admin',
    defaults: {
        isReady: false,
        isAuthenticating: false,
        isLoading: null,
        settings: null,
        errors: {},
    },
})
@Injectable({
    providedIn: 'root',
})
export class AdminState implements NgxsOnInit {
    static getErrors() {
        return createSelector([AdminState], (state: StateModel) => state.errors);
    }

    static isLoading() {
        return createSelector([AdminState], (state: StateModel) => state.isLoading);
    }

    static getSettings() {
        return createSelector([AdminState], (state: StateModel) => state.settings);
    }

    static isReady() {
        return createSelector([AdminState], (state: StateModel) => state.isReady);
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private store: Store,
        private adminService: AdminService,
        private dialog: MatDialog,
    ) {}

    ngxsOnInit({ dispatch, patchState }: StateContext<any>) {
        this.store
            .select(AuthState.isAuthenticated())
            .pipe(
                filter(isAuthenticated => isAuthenticated),
                take(1),
                mergeMap(() => dispatch(new AdminActions.LoadSettings())),
            )
            .subscribe();
    }

    @Action(AdminActions.ExecuteAction)
    executeAction({ patchState, dispatch }: StateContext<StateModel>, { action }: AdminActions.ExecuteAction) {
        patchState({ isLoading: true });

        this.store.dispatch(new AdminActions.ClearErrors(AdminActions.ExecuteActionFailure));

        return this.adminService.execute(action).pipe(
            mergeMap(() => this.store.dispatch(new NotificationActions.Success('Action complete'))),
            catchError(error => this.store.dispatch(new AdminActions.ExecuteActionFailure(action, error))),
            finalize(() => patchState({ isLoading: false })),
        );
    }

    @Action(AdminActions.LoadSettings)
    loadSettings({ patchState }: StateContext<StateModel>) {
        return this.adminService.getSettings().pipe(
            tap(settings => {
                patchState({ settings });

                this.env.blobStorageUrl = settings.storageSasUri;
                this.env.fpjsApiToken = settings.fpjsApiToken;
                this.env.sourceStorageSasUri = settings.sourceStorageSasUri;
                this.env.destinationStorageSasUri = settings.destinationStorageSasUri;
            }),
        );
    }

    @Action(AdminActions.ShowAdminActions)
    showAdminActions() {
        this.dialog.open(AdminActionsDialogComponent);
    }

    @Action(AdminActions.PurgeCdn)
    purgeCdn({ patchState }: StateContext<StateModel>, action: AdminActions.PurgeCdn) {
        return of(null).pipe(
            delay(action.delay),
            mergeMap(() => this.adminService.purgeCdn(action.model).pipe(catchError(error => of(null)))),
        );
    }

    @Action([AdminActions.ExecuteActionFailure])
    handleFailure(ctx: StateContext<StateModel>, action: { action: string; error: any }): void | Observable<void> {
        const error = action.error;

        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof AdminActions.ExecuteActionFailure:
                return this.store.dispatch([
                    new AdminActions.AddErrors(AdminActions.ExecuteActionFailure, action.error),
                    new NotificationActions.Error(`Error executing action '${action.action}'`, error),
                ]);
        }
    }

    @Action(AdminActions.ClearErrors)
    clearErrors(ctx: StateContext<StateModel>, { actions }: AdminActions.ClearErrors) {
        let errors = { ...this.store.selectSnapshot(AdminState.getErrors()) };

        if (isArray<IAction>(actions)) {
            actions.forEach(act => {
                errors[act.type] = null;
            });
        } else if (isFunction(actions)) {
            errors[actions.type] = null;
        } else {
            errors = {};
        }

        ctx.patchState({ errors });
    }

    @Action(AdminActions.AddErrors)
    addErrors(ctx: StateContext<StateModel>, { action, error }: AdminActions.AddErrors) {
        const errors = { ...this.store.selectSnapshot(AdminState.getErrors()) };

        errors[action.type] = error;

        ctx.patchState({ errors });
    }
}

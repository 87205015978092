import { Extension } from '@tiptap/core';
import { Node } from 'prosemirror-model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { LOADING_SPINNER } from '../util';

export type ProgressFn = (event: ProgressEvent, isComplete: boolean) => void;
export type UploadFn = (file: File, progressFn?: ProgressFn) => Promise<string>;

export function findNodePosition(doc: Node, target: Node) {
    let result = -1;
    doc.descendants((node, pos) => {
        if (target === node) {
            result = pos;
            return false;
        }

        return true;
    });
    return result;
}

export const dropImagePlugin = (upload: UploadFn) => {
    return Extension.create({
        addProseMirrorPlugins() {
            return [
                new Plugin({
                    key: new PluginKey('dropImage'),
                    props: {
                        handlePaste(view, event, slice) {
                            const items = Array.from(event.clipboardData?.items || []);
                            const { schema } = view.state;

                            items.forEach(item => {
                                const image = item.getAsFile();
                                if (item.type.indexOf('image') === 0) {
                                    event.preventDefault();

                                    if (upload && image) {
                                        const node = schema.nodes['image'].create({
                                            src: LOADING_SPINNER,
                                            file: image,
                                        });

                                        view.dispatch(view.state.tr.replaceSelectionWith(node));

                                        upload(image).then(src => {
                                            const from = findNodePosition(view.state.doc, node);
                                            view.dispatch(view.state.tr.setNodeAttribute(from, 'src', src));
                                        });
                                    }
                                } else {
                                    const reader = new FileReader();
                                    reader.onload = readerEvent => {
                                        const node = schema.nodes['image'].create({
                                            src: readerEvent.target?.result,
                                        });
                                        const transaction = view.state.tr.replaceSelectionWith(node);
                                        view.dispatch(transaction);
                                    };
                                    if (!image) return;
                                    reader.readAsDataURL(image);
                                }
                            });

                            return false;
                        },

                    },
                }),
            ];
        },
    });
};

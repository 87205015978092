import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Input } from '@angular/core';

import { TimeoutError } from 'rxjs';

import { ErrorCodes } from '../../../../../shared/src/lib/enums';
import { ErrorModel, isErrorModel } from '../../../../../shared/src/lib/models';
import {
    getHttpErrorMessage,
    getMediaErrorMessage,
    isArray,
    isError,
    isHttpError,
    isMediaError,
    isObject,
    isString,
} from '../../../../../shared/src/lib/util';

@Component({
    selector: 'ui-error-display',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./error-display.component.html`,
    styleUrls: ['./error-display.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class ErrorDisplayComponent {
    error = input<ErrorModel<any> | any>(null);
    messages = input<{ [key: string]: string } | null>(null);
    showIcons = input<boolean | null>(true);

    message = computed(() => {
        return this.getMessage(this.error()).shift() || null;
    });

    validationResults = computed(() => {
        const error = this.error();
        return isErrorModel(error) ? error.validationResults : [];
    });

    isValidationError = computed(() => {
        const error = this.error();
        return isErrorModel(error) && error.errorCode === ErrorCodes.ValidationError;
    });

    hasError = computed(() => {
        const message = this.message() || '';
        return message !== '';
    });

    isEnumerable = computed(() => {
        return isArray(this.error());
    });

    getMessage(value: unknown): string[] {
        let messages = [];
        if (value === null || value === undefined) {
            return [];
        }

        if (isArray(value)) {
            messages = value.reduce((acc: string[], curr: any) => {
                acc = acc.concat(this.getMessage(curr));
                return acc;
            }, []) as string[];
        } else if (isErrorModel(value)) {
            messages = [this.getMessageMapping(ErrorCodes[value.errorCode]) || value.message];
        } else if (isHttpError(value)) {
            messages = [getHttpErrorMessage(value)];
        } else if (isError(value)) {
            messages = [value.message];
        } else if (isMediaError(value)) {
            messages = [getMediaErrorMessage(value)];
        } else if (isString(value)) {
            messages = [value || ''];
        } else if (isObject(value)) {
            switch (true) {
                case value instanceof TimeoutError:
                    messages = ['Timeout occured while uploading content'];
            }
            messages = this.getErrorObjectMessage(value as any); // recursive??
        } else {
            messages = [JSON.stringify(value || '')];
        }

        return messages.filter(s => s && s.length > 0);
    }

    getMessageMapping(key: string): string | null {
        const messages = this.messages();
        return messages && messages[key];
    }

    getErrorObjectMessage(value: { [key: string]: { [key: string]: boolean } }): string[] {
        const keys = Object.keys(value || {});
        let messages: string[] = [];
        for (const key of keys) {
            switch (key) {
                case 'required':
                    messages = messages.concat([this.getMessageMapping(key) || 'You must enter a value.']);
                    break;
                case 'email':
                    messages = messages.concat([this.getMessageMapping(key) || 'Invalid email.']);
                    break;
                case 'pattern':
                    messages = messages.concat([this.getMessageMapping(key) || 'Invalid value.']);
                    break;
                case 'minlength':
                    messages = messages.concat([
                        this.getMessageMapping(key) || `Minimum length is ${value[key]['requiredLength']}.`,
                    ]);
                    break;
                case 'maxlength':
                    messages = messages.concat([
                        this.getMessageMapping(key) || `Maximum length is ${value[key]['requiredLength']}.`,
                    ]);
                    break;
                case 'min':
                    messages = messages.concat([this.getMessageMapping(key) || `Minimum value is ${value[key]['min']}.`]);
                    break;
                case 'max':
                    messages = messages.concat([this.getMessageMapping(key) || `Maximum value is ${value[key]['max']}.`]);
                    break;
                default:
                    messages = messages.concat(this.getMessage(value[key]));
                    break;
            }
        }

        return messages;
    }
}

<form [formGroup]="form" ngxsForm="zoom.createWebinarForm" [ngxsFormClearOnDestroy]="true">
    <div fxLayout="column">
        <div class="field" fxFlex>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Zoom Webinar Template</mat-label>
                <mat-select formControlName="templateId">
                    <mat-option *ngFor="let template of filteredTemplates" [value]="template.id">
                        {{ template.name }}
                    </mat-option>
                </mat-select>
                <button matSuffix mat-icon-button (click)="onRefresh()">
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-error *ngIf="ctrls['templateId'].invalid">
                    <ui-error-display [error]="ctrls['templateId'].errors"></ui-error-display>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="field" fxFlex>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Meeting Name</mat-label>
                <input matInput formControlName="topic" />
                <mat-error *ngIf="ctrls['topic'].invalid">
                    <ui-error-display [error]="ctrls['topic'].errors"></ui-error-display>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="field" fxFlex>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Agenda</mat-label>
                <textarea matInput formControlName="agenda"></textarea>
                <mat-error *ngIf="ctrls['agenda'].invalid">
                    <ui-error-display [error]="ctrls['agenda'].errors"></ui-error-display>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="field" fxFlex>
            <mat-form-field appearance="outline">
                <mat-label>Password (Not Required)</mat-label>
                <input matInput formControlName="password" />
                <mat-error *ngIf="ctrls['password'].invalid">
                    <ui-error-display [error]="ctrls['password'].errors"></ui-error-display>
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" class="field">
            <admin-date-time-picker
                label="Start Date"
                placeholder="Set Start Date"
                hint="When does this webinar start?"
                fxFlex="1 1 50%"
                [showTimeZone]="true"
                [errors]="ctrls['startDate'].errors"
                formControlName="startDate"
                (timeZoneChange)="onTimeZoneChange($event)"
            ></admin-date-time-picker>
            <div fxFlex class="time-summary" fxLayout="column">
                <small *ngIf="isDifferentTimeZone"> <b>Local Time:</b> {{ ctrls['startDate'].value | formatDate }} </small>
                <small>
                    <b>Webinar Time:</b>
                    {{ ctrls['startDate'].value | formatDate : ctrls['timeZone'].value }}
                </small>
            </div>
        </div>
        <div class="field" fxFlex>
            <mat-form-field appearance="outline">
                <mat-label>Duration</mat-label>
                <mat-hint>Duration in minutes</mat-hint>
                <input matInput type="number" min="1" formControlName="duration" />
                <mat-error *ngIf="ctrls['duration'].invalid">
                    <ui-error-display [error]="ctrls['duration'].errors"></ui-error-display>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>

import { Injector, Type } from '@angular/core';

import { mergeAttributes, Node as TipTapNode } from '@tiptap/core';
import { AngularNodeViewComponent, AngularNodeViewRenderer } from 'ngx-tiptap';

export type MediaAssetOptions = {
    HTMLAttributes: Record<string, any>;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        mediaAsset: {
            /**
             * Add an image
             */
            setInlineMediaAsset: (options: { mediaAssetId: string | null }) => ReturnType;
        };
    }
}

export const createMediaAssetExtension = (injector: Injector, component: Type<AngularNodeViewComponent>) => {
    //https://prosemirror.net/docs/ref/#model.NodeSpec.atom

    return TipTapNode.create<MediaAssetOptions>({
        name: 'mediaAsset',
        inline: false,
        group: 'block', // belongs to the 'block' group of extensions
        selectable: true, // so we can select the video
        draggable: true, // so we can drag the video
        atom: true, // is a single unit

        addOptions() {
            return {
                HTMLAttributes: {
                    id: null,
                    class: null,
                    style: null,
                },
            };
        },

        addAttributes() {
            return {
                mediaAssetId: {
                    default: null,
                    parseHTML: element => element.getAttribute('data-mediaassetid') || element.getAttribute('mediaassetid'),
                    renderHTML: attributes => {
                        if (!attributes['mediaAssetId']) {
                            return {};
                        }

                        return {
                            mediaAssetId: attributes['mediaAssetId'],
                        };
                    },
                },
                mediaFile: {
                    rendered: false,
                    default: null,
                },
            };
        },

        parseHTML() {
            return [
                {
                    tag: 'media-asset',
                },
            ];
        },

        renderHTML({ node, HTMLAttributes }) {
            return ['media-asset', mergeAttributes(HTMLAttributes)];
        },

        addNodeView() {
            return AngularNodeViewRenderer(component, { injector });
        },

        addCommands() {
            return {
                setInlineMediaAsset:
                    ({ mediaAssetId }) =>
                    ({ tr, dispatch, editor, commands }) => {
                        // get the current range
                        const { view } = this.editor;
                        const node = view.state.schema.nodes['mediaAsset'].create({
                            mediaAssetId,
                            mediaFile: null,
                        });

                        tr.replaceSelectionWith(node, false);
                        tr.insertText(' ', tr.selection.from + 1);

                        return true;
                    },
            };
        },
    });
};

import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, ElementRef, inject, input } from '@angular/core';

@Component({
    selector: 'shared-watermark',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<div [ngStyle]="{ height: height(), left: '10000px', top: top(), bottom: bottom() }" class="wm">
        {{ text() }}
    </div> `,
    styleUrl: './watermark.component.scss',
    standalone: true,
    imports: [NgStyle]
})
export class WatermarkComponent {
    max = input<number | null>(1500);
    watermark = input<string | null>('');

    text = computed(() => {
        const arr = [];
        const s = this.watermark() || '';
        const max = this.max() || 0;

        for (let x = 0; x < max; x++) {
            arr.push(s);
        }

        return arr.join(' ');
    });

    hypotenuse = computed(() => {
        const a = window.innerHeight;
        const b = window.innerWidth;
        const c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

        return c;
    });

    heightDelta = computed(() => {
        const a = window.innerHeight;
        const b = this.hypotenuse();

        return b - a;
    });

    height = computed(() => {
        const a = window.innerHeight;
        const delta = this.heightDelta();

        return `${a + delta}px`;
    });

    top = computed(() => {
        const delta = this.heightDelta();

        return `-${delta / 2}px`;
    });

    bottom = computed(() => {
        const delta = this.heightDelta();

        return `-${delta / 2}px`;
    });

    cd = inject(ChangeDetectorRef);
    el = inject(ElementRef);
}

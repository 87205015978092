import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { filter, finalize, mergeMap, take } from 'rxjs/operators';

import { CoreActions } from '@app/data/actions';
import { UpdateForm } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';

import { ManageTemplateActions } from '../actions';
import { ManageTemplatesState } from '../state/manage-templates.state';

export const EditFormLinkTemplateResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    const { linkId } = route.params;

    store.dispatch([
        new CoreActions.UpdateGlobalLoading(true),
        new ManageTemplateActions.EnsureLoadFormLinkTemplates(),
        new ManageTemplateActions.EnsureLoadPreambleTemplates(),
    ]);

    return combineLatest({
        shareableLinkTemplates: store.select(ManageTemplatesState.hasLoadedFormLinkTemplates()).pipe(
            filter(loaded => loaded),
            take(1),
        ),
        preambleTemplates: store.select(ManageTemplatesState.hasLoadedPreambleTemplates()).pipe(
            filter(loaded => loaded),
            take(1),
        ),
    }).pipe(
        take(1),
        mergeMap(() => {
            const links = store.selectSnapshot(ManageTemplatesState.getFormLinkTemplates());
            const link = links.find(l => l.rowKey === linkId);

            const actions: any[] = [
                new UpdateForm({
                    dirty: false,
                    errors: null,
                    status: '',
                    path: 'manageTemplates.formTemplateHeader',
                    value: {
                        templateName: link?.templateName || '',
                        isTemplatePublic: link?.isTemplatePublic || false,
                        canRequestLink: link?.canRequestLink || false,
                    },
                }),
            ];

            if (linkId !== 'new') {
                actions.push(
                    new ManageTemplateActions.LoadPreambleTemplateForLink(linkId),
                    new ManageTemplateActions.LoadSubmissionMessagesForLink(linkId),
                );
            } else {
                actions.push(new ManageTemplateActions.SetCustomPreamble(''));
            }

            return store.dispatch(actions);
        }),
        finalize(() => store.dispatch(new CoreActions.UpdateGlobalLoading(false))),
    );
};

import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRouteSnapshot } from '@angular/router';

import { forkJoin, Observable } from 'rxjs';
import { catchError, finalize, mergeMap, take, tap } from 'rxjs/operators';

import { MediaPlayerActions, NotificationActions } from '@app/data/actions';
import { FormState, Node } from '@app/data/models';
import { handleNotFoundError, transformResponseToText } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { ContentService } from '@app/data/services/content.service';
import { NodeService } from '@app/data/services/node.service';
import { RouterState } from '@app/data/state/router.state';
import { MiniPlayerContainerComponent } from '@app/shared/containers';
import { Layout, MiniPlayerState, NodeType } from '@app/shared/enums';
import { isErrorModel } from '@app/shared/models';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { HomeActions } from '../actions';
import { MediaAssetMiniPlayerComponent } from '../components/media-asset-mini-player/media-asset-mini-player.component';

export interface StateModel {
    toastRefId: number | null;
    previewMediaAssetId: string | null;
    miniPlayerState: MiniPlayerState;
    isLoadingNodes: boolean;
    hasLoadedNodes: boolean;
    theatreMode: boolean;
    nodes: Node[];
    favourites: string[];
    expandedNodeIds: string[];
    isLoading: boolean;
    isSaving: boolean;
    isDownloading: boolean;
    layout: Layout;
    path: string | null;

    nodeFilters: FormState<{ filter: string | null; nodeFilters: NodeType[] }>;
    mediaAssetFilters: FormState<{ filter: string | null; displayedColumns: string[] }>;
}

@State<StateModel>({
    name: 'home',
    defaults: {
        previewMediaAssetId: null,
        toastRefId: null,
        miniPlayerState: MiniPlayerState.Hidden,
        isLoadingNodes: false,
        hasLoadedNodes: false,
        theatreMode: false,
        nodes: [],
        favourites: [],
        expandedNodeIds: [],
        isLoading: false,
        isSaving: false,
        isDownloading: false,
        layout: Layout.Tree,
        path: null,

        nodeFilters: {
            model: {
                filter: null,
                nodeFilters: [],
            },
            status: '',
            dirty: false,
            errors: null,
        },

        mediaAssetFilters: {
            model: {
                filter: null,
                displayedColumns: [],
            },
            status: '',
            dirty: false,
            errors: null,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class HomeState {
    miniPlayerRef: MatBottomSheetRef<MiniPlayerContainerComponent> | null = null;

    static hasLoadedNodes() {
        return createSelector([HomeState], (model: StateModel) => model.hasLoadedNodes);
    }

    static getPreviewMediaAssetId() {
        return createSelector([HomeState], (model: StateModel) => model.previewMediaAssetId);
    }

    static getMiniPlayerState() {
        return createSelector([HomeState], (model: StateModel) => model.miniPlayerState);
    }

    static isTheatreMode() {
        return createSelector([HomeState], (model: StateModel) => model.theatreMode);
    }

    static getNodes() {
        return createSelector([HomeState], (model: StateModel) => model.nodes);
    }

    static getFavourites() {
        return createSelector([HomeState], (model: StateModel) => model.favourites);
    }

    static getCurrentNode(root?: ActivatedRouteSnapshot) {
        return createSelector(
            [HomeState.getNodes(), RouterState.selectRouteParam('nodeId', root)],
            (nodes: Node[], nodeId: string) => {
                if (nodes && nodes.length > 0 && nodeId) {
                    const node = nodes.find(n => n.rowKey === nodeId) || null;
                    return node;
                }

                return null;
            },
        );
    }

    static selectExpandedNodeIds() {
        return createSelector([HomeState], (model: StateModel) => model.expandedNodeIds);
    }

    static selectLayout() {
        return createSelector([HomeState], (model: StateModel) => model.layout);
    }

    static isLoading() {
        return createSelector([HomeState], (model: StateModel) => model.isLoading);
    }

    static selectPath() {
        return createSelector([HomeState], (model: StateModel) => model.path);
    }

    static isFavouriteNode() {
        return createSelector(
            [HomeState.getCurrentNode(), HomeState.getFavourites()],
            (node: Node, favourites: string[]) => node && favourites.includes(node.rowKey as string),
        );
    }

    static isDownloading() {
        return createSelector([HomeState], (model: StateModel) => model.isDownloading);
    }

    constructor(
        private store: Store,
        private sheet: MatBottomSheet,
        private nodeService: NodeService,
        private contentService: ContentService,
        private blobService: BlobService,
        private toastr: ToastrService,
    ) {}

    @Action(UpdateFormValue)
    updateFormValue({ patchState, getState }: StateContext<StateModel>, action: UpdateFormValue): void {
        if (action.payload.path === 'home.nodeFilters') {
            localStorage.setItem(action.payload.path, JSON.stringify(action.payload.value));
        }
    }

    @Action(HomeActions.PreviewMediaAsset)
    previewMediaAsset(
        { patchState, getState, dispatch }: StateContext<StateModel>,
        { mediaAssetId }: HomeActions.PreviewMediaAsset,
    ) {
        const { toastRefId } = getState();
        let toast: ActiveToast<MediaAssetMiniPlayerComponent> | null = null;

        if (!toastRefId) {
            toast = this.toastr.success('', '', {
                toastComponent: MediaAssetMiniPlayerComponent,
                disableTimeOut: true,
                positionClass: 'toast-bottom-right',
            });

            toast.onHidden
                .pipe(
                    take(1),
                    tap(() => {
                        patchState({ toastRefId: null });
                    }),
                )
                .subscribe();

            patchState({ toastRefId: toast.toastId });

            const instance = toast.toastRef.componentInstance as MediaAssetMiniPlayerComponent;
            instance.toastRef = toast.toastRef;
        }

        patchState({ previewMediaAssetId: mediaAssetId });
    }

    @Action(MediaPlayerActions.SetMiniPlayerVisibility)
    setMiniPlayerVisibility(
        { patchState }: StateContext<StateModel>,
        { state }: MediaPlayerActions.SetMiniPlayerVisibility,
    ) {
        if (state === MiniPlayerState.Hidden) {
            this.sheet.dismiss();
            return;
        }

        if (!this.miniPlayerRef && (state === MiniPlayerState.Full || state === MiniPlayerState.Mini)) {
            this.miniPlayerRef = this.sheet.open(MiniPlayerContainerComponent, {
                hasBackdrop: false,
                data: { state },
                // backdropClass: 'cdk-overlay-transparent-backdrop',
            });
            this.miniPlayerRef
                .afterDismissed()
                .pipe(
                    take(1),
                    tap(() => {
                        this.miniPlayerRef = null;
                        patchState({ miniPlayerState: MiniPlayerState.Hidden });
                    }),
                )
                .subscribe();

            this.miniPlayerRef.instance.state = state;
        }
    }

    @Action(HomeActions.EnsureLoadNodes)
    ensureLoadNodes({ patchState, getState }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedNodes, isLoadingNodes } = getState();

        if (hasLoadedNodes || isLoadingNodes) {
            return;
        }

        patchState({ isLoadingNodes: true });

        return this.store.dispatch(new HomeActions.LoadNodes());
    }

    @Action(HomeActions.LoadNode)
    loadNode(ctx: StateContext<StateModel>, action: HomeActions.LoadNode) {
        ctx.patchState({
            isLoading: true,
        });

        return this.nodeService.getNodeById(action.id).pipe(
            mergeMap(node => {
                return this.store.dispatch([new HomeActions.UpdateNodes([node])]);
            }),
            catchError(error => this.store.dispatch(new HomeActions.LoadNodeFailure(error))),
            finalize(() => ctx.patchState({ isLoading: false })),
        );
    }

    @Action(HomeActions.LoadNodes)
    loadNodes({ patchState }: StateContext<StateModel>) {
        patchState({
            isLoading: true,
        });

        return this.nodeService.getNodesForCurrentUser().pipe(
            mergeMap(nodes => {
                patchState({ nodes });
                return this.store.dispatch([new HomeActions.LoadNodesSuccess(), new HomeActions.LoadFavourites()]);
            }),
            catchError(error => this.store.dispatch(new HomeActions.LoadNodesFailure(error))),
            finalize(() => patchState({ isLoading: false, isLoadingNodes: false, hasLoadedNodes: true })),
        );
    }

    @Action(HomeActions.LoadNodeContent)
    loadNodeContent(ctx: StateContext<StateModel>, action: HomeActions.LoadNodeContent): Observable<any> | void {
        ctx.patchState({
            isLoading: true,
        });

        const nodes = action.nodes
            .filter(n => !n.isLoading)
            .map((n, ix) => {
                return { ...n, isLoading: true, isLoaded: false };
            });

        if (nodes.length === 0) {
            // nothing to fetch
            return;
        }

        return this.store.dispatch(new HomeActions.UpdateNodes(nodes)).pipe(
            mergeMap(() => {
                return forkJoin(
                    nodes.map(n => {
                        return this.blobService
                            .downloadBlobBlock(`content/${n.rowKey}/${n.rowKey}.html`)
                            .pipe(transformResponseToText(), handleNotFoundError());
                    }),
                ).pipe(
                    mergeMap((content: string[]) => {
                        return this.store.dispatch(
                            new HomeActions.UpdateNodes(
                                nodes.map((n, ix) => {
                                    return {
                                        ...n,
                                        content: content[ix],
                                        isLoading: false,
                                        isLoaded: true,
                                    };
                                }),
                            ),
                        );
                    }),
                    finalize(() => console.log('done')),
                );
            }),
        );
    }

    @Action(HomeActions.UpdatePath)
    updatePath(ctx: StateContext<StateModel>, { path }: HomeActions.UpdatePath) {
        ctx.patchState({ path });
        localStorage.setItem('path', JSON.stringify(path));
    }

    @Action(HomeActions.UpdateNodes)
    updateNodes(ctx: StateContext<StateModel>, action: HomeActions.UpdateNodes) {
        const state = ctx.getState();

        const nodes = state.nodes.map(n => ({ ...n }));

        action.nodes.forEach(node => {
            const ix = nodes.findIndex(n => n.rowKey === node.rowKey);

            if (ix !== -1) {
                nodes[ix] = node;
            } else {
                nodes.push(node);
            }
        });

        ctx.patchState({
            nodes,
        });
    }

    @Action(HomeActions.RemoveNodes)
    removeNodes(ctx: StateContext<StateModel>, { nodeIds }: HomeActions.RemoveNodes) {
        const state = ctx.getState();

        const nodes = state.nodes.filter(n => !nodeIds.includes(n.rowKey as string)).map(n => ({ ...n }));

        ctx.patchState({
            nodes,
        });
    }

    @Action(HomeActions.LoadFavourites)
    loadFavourites(ctx: StateContext<StateModel>, action: HomeActions.LoadFavourites) {
        ctx.patchState({
            isLoading: true,
        });

        return this.nodeService.getFavourites().pipe(
            tap(favourites => ctx.patchState({ favourites })),
            catchError(error => this.store.dispatch(new HomeActions.LoadFavouritesFailure(error))),
            finalize(() => ctx.patchState({ isLoading: false })),
        );
    }

    @Action(HomeActions.AddToFavourites)
    addToFavourites(ctx: StateContext<StateModel>, action: HomeActions.AddToFavourites) {
        ctx.patchState({
            isSaving: true,
        });

        return this.nodeService.addNodeToFavourites(action.node.rowKey as string).pipe(
            tap(() => {
                const { favourites } = ctx.getState();
                ctx.patchState({ favourites: [...favourites, action.node.rowKey as string] });
            }),
            catchError(error => this.store.dispatch(new HomeActions.AddToFavouritesFailure(error))),
            finalize(() => ctx.patchState({ isSaving: false })),
        );
    }

    @Action(HomeActions.RemoveFromFavourites)
    removeFromFavourites(ctx: StateContext<StateModel>, action: HomeActions.RemoveFromFavourites) {
        ctx.patchState({
            isSaving: true,
        });

        return this.nodeService.removeNodeFromFavourites(action.node.rowKey as string).pipe(
            tap(() => {
                const { favourites } = ctx.getState();
                ctx.patchState({ favourites: favourites.filter(n => n !== action.node.rowKey) });
            }),
            catchError(error => this.store.dispatch(new HomeActions.RemoveFromFavouritesFailure(error))),
            finalize(() => ctx.patchState({ isSaving: false })),
        );
    }

    @Action(HomeActions.SetTheatreMode)
    setTheatreMode(ctx: StateContext<StateModel>, { value }: HomeActions.SetTheatreMode) {
        ctx.patchState({ theatreMode: value });
    }

    @Action(HomeActions.ToggleTheatreMode)
    toggleTheatreMode(ctx: StateContext<StateModel>, action: HomeActions.ToggleTheatreMode) {
        const theatreMode = !this.store.selectSnapshot(HomeState.isTheatreMode());

        ctx.patchState({ theatreMode });
    }

    @Action(HomeActions.UpdateExpandedNodeIds)
    updateExpandedNodeIds(ctx: StateContext<StateModel>, action: HomeActions.UpdateExpandedNodeIds) {
        ctx.patchState({ expandedNodeIds: action.nodeIds });
    }

    @Action(HomeActions.ExpandNode)
    expandNode(ctx: StateContext<StateModel>, { nodeId }: HomeActions.ExpandNode) {
        const expandedNodeIds = [...this.store.selectSnapshot(HomeState.selectExpandedNodeIds())];
        expandedNodeIds.push(nodeId);
        localStorage.setItem('expandedNodeIds', JSON.stringify(expandedNodeIds));
        ctx.patchState({ expandedNodeIds });
    }

    @Action(HomeActions.CollapseNode)
    collapseNode(ctx: StateContext<StateModel>, { nodeId }: HomeActions.CollapseNode) {
        const expandedNodeIds = this.store.selectSnapshot(HomeState.selectExpandedNodeIds()).filter(n => n !== nodeId);
        localStorage.setItem('expandedNodeIds', JSON.stringify(expandedNodeIds));
        ctx.patchState({ expandedNodeIds });
    }

    @Action(HomeActions.UpdateLayout)
    updateLayout(ctx: StateContext<StateModel>, { layout }: HomeActions.UpdateLayout) {
        localStorage.setItem('layout', JSON.stringify(layout));
        ctx.patchState({ layout });
    }

    @Action(HomeActions.ToggleLayout)
    toggleLayout(ctx: StateContext<StateModel>, action: HomeActions.ToggleLayout) {
        const current = this.store.selectSnapshot(HomeState.selectLayout());
        const layout = current === Layout.Tree ? Layout.List : Layout.Tree;
        localStorage.setItem('layout', JSON.stringify(layout));
        ctx.patchState({ layout });
    }

    @Action(HomeActions.DownloadNodeBinary)
    downloadNodeBinary(ctx: StateContext<StateModel>, { node }: HomeActions.DownloadNodeBinary): Observable<any> | void {
        const isDownloading = this.store.selectSnapshot(HomeState.isDownloading());

        if (isDownloading) {
            return;
        }

        ctx.patchState({ isDownloading: true });
        const link = document.createElement('a');
        let uri: string | null = null;

        return this.contentService.getBinary(node.rowKey as string).pipe(
            tap(response => {
                const blob = new Blob([response.body as BlobPart], { type: 'application/octet-binary' });
                uri = URL.createObjectURL(blob);
                link.setAttribute('href', uri);
                link.setAttribute('download', `${node.binary}`);
                document.body.appendChild(link);
                link.click();
            }),
            catchError(error => this.store.dispatch(new HomeActions.DownloadNodeBinaryFailure(node, error))),
            finalize(() => {
                URL.revokeObjectURL(uri as string);
                document.body.removeChild(link);
                ctx.patchState({ isDownloading: false });
            }),
        );
    }

    @Action([
        HomeActions.DownloadNodeBinaryFailure,
        // HomeActions.LoadIndexFailure,
        HomeActions.LoadNodesFailure,
        HomeActions.LoadFavouritesFailure,
        HomeActions.LoadNodeContentFailure,
        HomeActions.AddToFavouritesFailure,
        HomeActions.RemoveFromFavouritesFailure,
    ])
    handleFailure(ctx: StateContext<StateModel>, action: HomeFailureTypes) {
        const error = action.error;
        let message = 'Unexpected error encountered';

        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof HomeActions.AddToFavouritesFailure:
                message = 'Unexpected error encountered while adding to favourites';
                break;
            case action instanceof HomeActions.RemoveFromFavouritesFailure:
                message = 'Unexpected error encountered while removing from favourites';
                break;
            case action instanceof HomeActions.LoadNodeContentFailure:
                message = 'Unexpected error encountered while loading node content';
                break;
            // case action instanceof HomeActions.LoadIndexFailure:
            //     message = 'Unexpected error encountered while loading search index';
            //     break;
            case action instanceof HomeActions.DownloadNodeBinaryFailure:
                message = 'Unexpected error encountered while downloading';
                break;
            case action instanceof HomeActions.LoadNodesFailure:
                message = 'Unexpected error encountered while loading nodes';
                break;
            case action instanceof HomeActions.LoadFavouritesFailure:
                message = 'Unexpected error encountered while loading favourites';
                break;
        }

        return this.store.dispatch([new NotificationActions.Error(message, error)]);
    }
}

type HomeFailureTypes = HomeActions.DownloadNodeBinaryFailure |
    // HomeActions.LoadIndexFailure,
    HomeActions.LoadNodesFailure |
    HomeActions.LoadFavouritesFailure |
    HomeActions.LoadNodeContentFailure |
    HomeActions.AddToFavouritesFailure |
    HomeActions.RemoveFromFavouritesFailure;
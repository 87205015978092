<mat-card [ngClass]="{ readonly: readonly }">
  <mat-card-header>
    @if (showTitle) {
      <mat-card-title>{{ title }}</mat-card-title>
    }
  </mat-card-header>
  <mat-card-content>
    <ui-rich-text-view [content]="model?.description"></ui-rich-text-view>

    <div>
      <button mat-raised-button [disabled]="isDownloading" color="primary" (click)="onDownload().subscribe()">
        @if (!isDownloading) {
          <mat-icon>get_app</mat-icon>
        }
        @if (isDownloading) {
          <mat-spinner diameter="15"></mat-spinner>
        }
        Download
      </button>
    </div>
    @if (isRequired && !hasDownloaded) {
      <div class="error">This file must be downloaded to complete the form</div>
    }
  </mat-card-content>
</mat-card>

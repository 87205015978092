import { ChangeDetectionStrategy, Component, computed, input, OnChanges } from '@angular/core';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatList, MatListItem, MatListItemTitle } from '@angular/material/list';

import { Participant, RegistrantInfo } from '@app/data/models';

import { ElapsedTimePipe } from '../../../../../shared/src/lib/pipes/elapsed-time.pipe';
import { FormatDatePipe } from '../../../../../shared/src/lib/pipes/format-date.pipe';

@Component({
    selector: 'ui-registrant-details',
    templateUrl: './registrant-details.component.html',
    styleUrls: ['./registrant-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatCard,
        MatCardContent,
        MatCardTitle,
        MatList,
        MatListItem,
        MatListItemTitle,

        ElapsedTimePipe,
        FormatDatePipe,
    ],
})
export class RegistrantDetailsComponent implements OnChanges {
    readonly model = input<RegistrantInfo | null>(null);
    readonly zoomMeetings = input<{ id: number; topic: string }[]>([]);

    topic = computed(() => {
        const model = this.model();
        if (model && model.topic) {
            return model.topic;
        }

        const meeting = this.zoomMeetings().find(x => x.id === model?.meetingId || x.id === model?.webinarId);

        return meeting?.topic || '';
    });

    dataSource: Participant[] = [];
    displayedColumns = [
        // 'userName',
        'email',
        'device',
        'joinTime',
        'leaveTime',
        'durationInSeconds',
        'audioQuality',
        'videoQuality',
        'leaveReason',
    ];

    update() {
        const model = this.model();
        if (model) {
            this.dataSource = [...model.participants];
        }
    }

    ngOnChanges(): void {
        this.update();
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import { NodeColours, NodeType } from '../enums';
import { isNullOrUndefined } from '../util';

@Pipe({
    name: 'nodeColour',
    standalone: true,
})
export class NodeColourPipe implements PipeTransform {
    transform(value: { type: NodeType; colour: string | number | NodeColours }): string {
        if (value && value.type === NodeType.Node && !isNullOrUndefined(value.colour)) {
            switch (~~value.colour) {
                case NodeColours.Colour1:
                    return 'node-colour-1';
                case NodeColours.Colour2:
                    return 'node-colour-2';
                case NodeColours.Colour3:
                    return 'node-colour-3';
                case NodeColours.Colour4:
                    return 'node-colour-4';
                case NodeColours.Colour5:
                    return 'node-colour-5';
                case NodeColours.Colour6:
                    return 'node-colour-6';
                case NodeColours.Colour7:
                    return 'node-colour-7';
                case NodeColours.Colour8:
                    return 'node-colour-8';
                case NodeColours.Colour9:
                    return 'node-colour-9';
                case NodeColours.Colour10:
                    return 'node-colour-10';
                case NodeColours.Colour11:
                    return 'node-colour-11';
                case NodeColours.Colour12:
                    return 'node-colour-12';
            }
        }

        return '';
    }
}

@let registrant = model();
<mat-card>
    <mat-card-content>
        <mat-card-title>{{ topic() }}</mat-card-title>
        @if(registrant){
        <mat-list dense role="list">
            <mat-list-item role="listitem">Email: {{ registrant.email }}</mat-list-item>
            <mat-list-item role="listitem"
                >Join Time: {{ registrant.joinTime ? (registrant.joinTime | formatDate) : '--' }}</mat-list-item
            >
            @if(!registrant.isInSession){
            <mat-list-item role="listitem">
                <span matListItemTitle
                    >Leave Time: {{ registrant.leaveTime ? (registrant.leaveTime | formatDate) : '--' }}</span
                >
            </mat-list-item>
            }
            <mat-list-item role="listitem">Total Duration: {{ registrant.durationInSeconds | elapsedTime }}</mat-list-item>
        </mat-list>
        }
    </mat-card-content>
</mat-card>

import { Node } from '@app/data/models';
import { Layout } from '@app/shared/enums';

const ns = '[Home]';

export class EnsureLoadNodes {
    static readonly type = `${ns} Ensure Load Nodes`;
}

export class LoadNodes {
    static readonly type = `${ns} Load Nodes`;
}

export class LoadNodesSuccess {
    static readonly type = `${ns} Load Nodes Success`;
}
export class LoadNodesFailure {
    static readonly type = `${ns} Load Nodes Failure`;
    constructor(public error: any) {}
}

export class LoadNode {
    static readonly type = `${ns} Load Node`;
    constructor(public id: string) {}
}

export class LoadNodeSuccess {
    static readonly type = `${ns} Load Node Success`;
}
export class LoadNodeFailure {
    static readonly type = `${ns} Load Node Failure`;
    constructor(public error: any) {}
}
export class LoadFavourites {
    static readonly type = `${ns} Load Favourites`;
}
export class LoadFavouritesFailure {
    static readonly type = `${ns} Load Favourites Failure`;
    constructor(public error: any) {}
}
export class LoadNodeContent {
    static readonly type = `${ns} Load Node Content`;
    constructor(public nodes: Node[]) {}
}
export class LoadNodeContentFailure {
    static readonly type = `${ns} Load Node Content Failure`;
    constructor(public error: any) {}
}

export class UpdateNodes {
    static readonly type = `${ns} Update Nodes`;
    constructor(public nodes: Node[]) {}
}

export class RemoveNodes {
    static readonly type = `${ns} Remove Nodes`;
    constructor(public nodeIds: string[]) {}
}

export class SetTheatreMode {
    static readonly type = `${ns} Set Theatre Mode`;
    constructor(public value: boolean) {}
}
export class ToggleTheatreMode {
    static readonly type = `${ns} Toggle Theatre Mode`;
}
export class AddToFavourites {
    static readonly type = `${ns} Add to favourites`;
    constructor(public node: Node) {}
}
export class AddToFavouritesFailure {
    static readonly type = `${ns} Add To Favourites Failure`;
    constructor(public error: any) {}
}
export class RemoveFromFavourites {
    static readonly type = `${ns} Remove from favourites`;
    constructor(public node: Node) {}
}
export class RemoveFromFavouritesFailure {
    static readonly type = `${ns} Remove from favourites Failure`;
    constructor(public error: any) {}
}

export class UpdatePath {
    static readonly type = `${ns} Update Path`;
    constructor(public path: string | null) {}
}

export class ExpandNode {
    static readonly type = `${ns} Expand Node`;
    constructor(public nodeId: string) {}
}

export class CollapseNode {
    static readonly type = `${ns} Collapse Node`;
    constructor(public nodeId: string) {}
}

export class UpdateExpandedNodeIds {
    static readonly type = `${ns} Update Expanded Node Ids`;
    constructor(public nodeIds: string[]) {}
}

export class UpdateLayout {
    static readonly type = `${ns} Update Layout`;
    constructor(public layout: Layout) {}
}

export class ToggleLayout {
    static readonly type = `${ns} Toggle Layout`;
}

export class DownloadNodeBinary {
    static readonly type = `${ns} Download Node Binary`;
    constructor(public node: Node) {}
}

export class DownloadNodeBinaryFailure {
    static readonly type = `${ns} Download Node Binary Failure`;
    constructor(public node: Node, public error: any) {}
}

export class PreviewMediaAsset {
    static readonly type = `${ns} Preview Media Asset`;
    constructor(public mediaAssetId: string) {}
}
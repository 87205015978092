import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';

import { NotificationActions } from '@app/data/actions';
import { CurrentUser, NoteModel, TaskModel } from '@app/data/models';
import { handleNotFoundError, transformResponseToText } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { AuthState } from '@app/data/state/auth.state';
import { BlobContainer } from '@app/shared/enums';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';

import { NoteActions, SearchActions } from '../actions';
import { NotesContainerComponent } from '../containers/notes-container/notes-container.component';
import { DeleteNoteConfirmationDialogComponent } from '../dialogs/delete-note-confirmation/delete-note-confirmation-dialog.component';
import { NoteService } from '../services/note.service';

enum VisualState {
    Closed,
    Open,
}

export interface StateModel {
    visualState: VisualState | null;
    isSaving: boolean;
    hasLoaded: boolean;
    isLoading: boolean;
    isLoadingNotes: boolean;
    errors: any;
    note: NoteModel | null;
    notes: NoteModel[];
    tasks: TaskModel[];
}

@State<StateModel>({
    name: 'notes',
    defaults: {
        visualState: null,
        isSaving: false,
        hasLoaded: false,
        isLoading: false,
        isLoadingNotes: false,
        errors: null,
        note: null,
        notes: [],
        tasks: [],
    },
})
@Injectable({
    providedIn: 'root',
})
export class NotesState {
    static hasLoaded() {
        return createSelector([NotesState], (state: StateModel) => state.hasLoaded);
    }
    static isLoading() {
        return createSelector([NotesState], (state: StateModel) => state.isLoading);
    }
    static isLoadingNotes() {
        return createSelector([NotesState], (state: StateModel) => state.isLoadingNotes);
    }
    static isSaving() {
        return createSelector([NotesState], (state: StateModel) => state.isSaving);
    }
    static getState() {
        return createSelector([NotesState], (state: StateModel) => state.visualState);
    }

    static getNote() {
        return createSelector([NotesState], (state: StateModel) => state.note);
    }

    static getNotes() {
        return createSelector([NotesState], (state: StateModel) => state.notes);
    }

    static getTasks() {
        return createSelector([NotesState], (state: StateModel) => state.tasks);
    }

    constructor(
        private noteService: NoteService,
        private blobService: BlobService,
        private bottomSheet: MatBottomSheet,
        private matDialog: MatDialog,
        private store: Store,
    ) {}

    @Action(NoteActions.EnsureLoad)
    ensureLoad(ctx: StateContext<StateModel>, action: NoteActions.EnsureLoad): Observable<any> {
        const hasLoaded = this.store.selectSnapshot(NotesState.hasLoaded());

        if (!hasLoaded) {
            ctx.patchState({
                hasLoaded: true,
                isLoading: true,
                errors: null,
            });

            return this.store.dispatch([new NoteActions.LoadNotes(), new NoteActions.LoadTasks()]);
        }

        return EMPTY;
    }

    @Action(NoteActions.LoadNotes)
    loadNotes(ctx: StateContext<StateModel>, action: NoteActions.LoadNotes): Observable<any> {
        ctx.patchState({ isLoadingNotes: true });

        return this.noteService.getNotesForUser().pipe(
            tap(notes => ctx.patchState({ notes: this.sortNotes(notes) })),
            catchError(error => this.store.dispatch(new NoteActions.Error(error))),
            finalize(() => ctx.patchState({ isLoadingNotes: false })),
        );
    }

    @Action(NoteActions.LoadTasks)
    loadTasks({ dispatch, patchState }: StateContext<StateModel>, action: NoteActions.LoadTasks): Observable<any> {
        patchState({ isLoading: true });

        const user = this.store.selectSnapshot(AuthState.getCurrentUser());
        const url = `users/${user?.rowKey}/tasks.json`;

        return this.blobService.downloadBlobBlock(url, BlobContainer.Private).pipe(
            transformResponseToText(),
            handleNotFoundError(),
            tap(content => {
                try {
                    const tasks = JSON.parse(content);
                    patchState({ tasks });
                } catch {
                    // do nothing
                }
            }),
            catchError(err => dispatch(new NoteActions.Error(err))),
            finalize(() => patchState({ isLoading: false })),
        );
    }

    @Action(NoteActions.AddNote)
    addNote({ patchState, dispatch }: StateContext<StateModel>, action: NoteActions.AddNote) {
        patchState({ isSaving: true });

        const notes = [...this.store.selectSnapshot(NotesState.getNotes())];
        const title = `Note ${notes.length + 1}`;
        const note = {
            title,
            rowKey: null,
            createdDate: new Date(),
            content: '',
            isFavourite: false,
            isLoaded: false,
            nodeIds: action.node ? [action.node.rowKey] : [],
            sasUri: '',
        } as NoteModel;

        return this.noteService.saveNote(note).pipe(
            tap(note => patchState({ notes: [note].concat(notes) })),
            tap(note => dispatch([new NoteActions.AddNoteSuccess(), new NoteActions.SelectNote(note)])),
            catchError(error => this.store.dispatch(new NoteActions.AddNoteFailure(error))),
            finalize(() => patchState({ isSaving: false })),
        );
    }

    @Action(NoteActions.ShowNote)
    showNote({ patchState, dispatch }: StateContext<StateModel>, action: NoteActions.ShowNote) {
        dispatch([new NoteActions.Show(), new NoteActions.SelectNote(action.note)]);
    }

    @Action(NoteActions.SelectNote)
    selectNote(
        { patchState, dispatch }: StateContext<StateModel>,
        { note }: NoteActions.SelectNote,
    ): Observable<any> | void {
        patchState({
            isLoading: true,
        });

        if (!note) {
            patchState({ isLoading: false, note: null });
            return;
        }

        if (!note.isLoaded) {
            const user = this.store.selectSnapshot(AuthState.getCurrentUser());
            const url = `users/${user?.rowKey}/notes/${note.rowKey}.html`;
            return this.blobService.downloadBlobBlock(url, BlobContainer.Private).pipe(
                transformResponseToText(),
                handleNotFoundError(),
                tap(content => {
                    patchState({
                        note: {
                            ...note,
                            content,
                            isLoaded: true,
                        },
                    });

                    return dispatch(
                        new NoteActions.UpdateNote({
                            ...note,
                            content,
                            isLoaded: true,
                        }),
                    );
                }),
                catchError(err => dispatch(new NoteActions.Error(err))),
                finalize(() => patchState({ isLoading: false })),
            );
        }

        patchState({
            isLoading: false,
            note: {
                ...note,
                isLoaded: true,
            },
        });

        return dispatch(
            new NoteActions.UpdateNote({
                ...note,
                isLoaded: true,
            }),
        );
    }

    @Action(NoteActions.ConfirmDeleteNote)
    confirmDeleteNote({ patchState, dispatch }: StateContext<StateModel>, action: NoteActions.ConfirmDeleteNote) {
        return this.matDialog
            .open(DeleteNoteConfirmationDialogComponent, {
                disableClose: true,
                data: action.note,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new NoteActions.DeleteNote(action.note));
                    }
                }),
            );
    }

    @Action(NoteActions.PinNote)
    pinNote({ patchState, dispatch }: StateContext<StateModel>, action: NoteActions.PinNote): Observable<any> | void {
        const isSaving = this.store.selectSnapshot(NotesState.isSaving());

        if (isSaving) {
            return;
        }

        patchState({
            isSaving: true,
            errors: null,
        });

        const notes = this.store.selectSnapshot(NotesState.getNotes());
        const note = { ...notes.find(n => n.rowKey === action.note.rowKey), ...action.note };
        note.isFavourite = !note.isFavourite;

        return this.noteService.saveNote({ ...note, content: '' }).pipe(
            mergeMap(note => {
                return dispatch(new NoteActions.UpdateNote({ ...note }));
            }),
            catchError(err => this.store.dispatch(new NoteActions.SaveNoteFailure(err))),
            finalize(() => patchState({ isSaving: false })),
        );
    }

    @Action(NoteActions.SaveNote)
    saveNote({ patchState, dispatch }: StateContext<StateModel>, action: NoteActions.SaveNote): Observable<any> | void {
        const isSaving = this.store.selectSnapshot(NotesState.isSaving());

        if (isSaving) {
            return;
        }

        patchState({
            isSaving: true,
            errors: null,
        });

        const notes = this.store.selectSnapshot(NotesState.getNotes());
        const note = { ...notes.find(n => n.rowKey === action.model.model.rowKey), ...action.model.model };

        const actions = [];

        if (action.model.saveContent) {
            const user = this.store.selectSnapshot(AuthState.getCurrentUser()) as CurrentUser;
            const url = `users/${user.rowKey}/notes/${note.rowKey}.html`;

            actions.push(this.blobService.uploadBlobBlock(url, note.content, 'text/html', BlobContainer.Private));
        }

        if (action.model.saveTitle) {
            actions.push(
                this.noteService.saveNote({ ...note, content: '' }).pipe(
                    mergeMap(note => {
                        return dispatch(new NoteActions.UpdateNote({ ...note }));
                    }),
                    catchError(err => this.store.dispatch(new NoteActions.SaveNoteFailure(err))),
                    finalize(() => patchState({ isSaving: false })),
                ),
            );
        }

        if (actions.length === 0) {
            return;
        }

        return dispatch(actions).pipe(
            mergeMap(() => dispatch(new SearchActions.UpdateIndex())),
            catchError(err => this.store.dispatch(new NoteActions.SaveNoteFailure(err))),
            finalize(() => patchState({ isSaving: false })),
        );
    }

    @Action(NoteActions.SaveTasks)
    saveTasks({ patchState, dispatch }: StateContext<StateModel>, action: NoteActions.SaveTasks) {
        const isSaving = this.store.selectSnapshot(NotesState.isSaving());

        if (isSaving) {
            return;
        }

        patchState({
            isSaving: true,
            errors: null,
        });

        const user = this.store.selectSnapshot(AuthState.getCurrentUser()) as CurrentUser;

        return this.blobService
            .uploadBlobBlock(
                `users/${user.rowKey}/tasks.json`,
                JSON.stringify(action.tasks),
                'application/json',
                BlobContainer.Private,
            )
            .pipe(finalize(() => patchState({ isSaving: false })));
    }

    @Action(NoteActions.UpdateNote)
    updateNote({ patchState }: StateContext<StateModel>, action: NoteActions.UpdateNote) {
        const { note } = action;
        const notes = [...this.store.selectSnapshot(NotesState.getNotes())];
        const ix = notes.findIndex(n => n.rowKey === note.rowKey);

        notes[ix] = { ...notes[ix], ...note };

        patchState({ notes: this.sortNotes(notes) }); // update the model
    }

    @Action(NoteActions.DeleteNote)
    deleteNote({ dispatch, patchState }: StateContext<StateModel>, action: NoteActions.DeleteNote) {
        patchState({
            isSaving: true,
            errors: null,
        });

        return this.noteService.deleteNotes([action.note]).pipe(
            tap(result => {
                const notes = this.sortNotes(
                    this.store
                        .selectSnapshot(NotesState.getNotes())
                        .filter(note => !result.notesToRemove.includes(note.rowKey as string)),
                );

                const note = this.store.selectSnapshot(NotesState.getNote());

                patchState({ notes, note: note && result.notesToRemove.includes(note.rowKey as string) ? null : note });

                this.store.dispatch([
                    new NotificationActions.Success('Note has been successfully deleted'),
                    new SearchActions.UpdateIndex(),
                ]);
            }),
            catchError(err => this.store.dispatch(new NoteActions.DeleteNoteFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action([
        NoteActions.Error,
        NoteActions.DeleteNoteFailure,
        NoteActions.AddNoteFailure,
        NoteActions.SaveNoteFailure,
        NoteActions.LoadNotesFailure,
        NoteActions.LoadTasksFailure,
        NoteActions.SaveTasksFailure,
    ])
    handleFailure({ dispatch, patchState }: StateContext<StateModel>, action: { error: any }): Observable<any> | void {
        patchState({ errors: action.error });
    }

    @Action(NoteActions.Show)
    show(ctx: StateContext<StateModel>) {
        ctx.patchState({
            visualState: VisualState.Open,
        });

        this.bottomSheet.open(NotesContainerComponent, {
            panelClass: 'notes-host',
            closeOnNavigation: false,
            disableClose: true,
            hasBackdrop: false,
        });
    }

    @Action(NoteActions.Hide)
    hide(ctx: StateContext<StateModel>) {
        ctx.patchState({
            visualState: VisualState.Closed,
        });

        this.bottomSheet.dismiss();
    }

    @Action(NoteActions.Toggle)
    toggle(ctx: StateContext<StateModel>) {
        const visualState = this.store.selectSnapshot(NotesState.getState());

        if (visualState === VisualState.Open) {
            this.store.dispatch(new NoteActions.Hide());
        } else {
            this.store.dispatch(new NoteActions.Show());
        }
    }

    sortNotes(notes: NoteModel[]): NoteModel[] {
        return notes.sort((a, b) => {
            if (a.isFavourite && !b.isFavourite) {
                return -1;
            } else if (b.isFavourite && !a.isFavourite) {
                return 1;
            }

            return a.title.localeCompare(b.title);
        });
    }
}

import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[src]',
    standalone: true,
})
export class SrcUnloadDirective implements AfterViewInit {
    constructor(public element: ElementRef<HTMLImageElement>) {}

    ngAfterViewInit(): void {
        this.element.nativeElement.addEventListener('load', () => {
            URL.revokeObjectURL(this.element.nativeElement.src);
        });
    }
}

import {
    ChecklistLinkTemplateModel,
    ChecklistTemplate,
    FormLinkTemplateModel,
    FormSubmissionMessage,
    PreambleTemplate,
    ShareableLinkTemplateModel,
} from '@app/data/models';
import { LinkType } from '@app/shared/enums';

const ns = '[Manage Templates]';

export class EnsureLoadPreambleTemplates {
    static readonly type = `${ns} Ensure Load Preamble Templates`;
}

export class LoadPreambleTemplates {
    static readonly type = `${ns} Load Preamble Templates`;
}

export class LoadPreambleTemplatesFailure {
    static readonly type = `${ns} Load Preamble Templates Failure`;
    constructor(public error: any) {}
}

export class EnsureLoadChecklistTemplates {
    static readonly type = `${ns} Ensure Load Checklist Templates`;
}

export class LoadChecklistTemplates {
    static readonly type = `${ns} Load Checklist Templates`;
}

export class LoadChecklistTemplatesFailure {
    static readonly type = `${ns} Load Checklist Templates Failure`;
    constructor(public error: any) {}
}

export class EnsureLoadChecklistLinkTemplates {
    static readonly type = `${ns} Ensure Load Checklist Link Templates`;
}

export class LoadChecklistLinkTemplates {
    static readonly type = `${ns} Load Checklist Link Templates`;
}

export class LoadChecklistLinkTemplatesFailure {
    static readonly type = `${ns} Load Checklist Link Templates Failure`;
    constructor(public error: any) {}
}

export class SaveChecklistTemplate {
    static readonly type = `${ns} Save Checklist Template`;
}

export class SaveChecklistTemplateFailure {
    static readonly type = `${ns} Save Checklist Template Failure`;
    constructor(public error: any) {}
}

export class SavePreambleTemplate {
    static readonly type = `${ns} Save Preamble Template`;
}

export class SavePreambleTemplateFailure {
    static readonly type = `${ns} Save Preamble Template Failure`;
    constructor(public error: any) {}
}

export class EnsureLoadLinkTemplates {
    static readonly type = `${ns} Ensure Load Link Templates`;
}

export class LoadShareableLinkTemplates {
    static readonly type = `${ns} Load Link Templates`;
}

export class LoadShareableLinkTemplatesFailure {
    static readonly type = `${ns} Load Link Templates Failure`;
    constructor(public error: any) {}
}

export class EnsureLoadFormLinkTemplates {
    static readonly type = `${ns} Ensure Load Form Link Templates`;
}

export class LoadFormLinkTemplates {
    static readonly type = `${ns} Load Form Link Templates`;
}

export class LoadFormLinkTemplatesFailure {
    static readonly type = `${ns} Load Form Link Templates Failure`;
    constructor(public error: any) {}
}

export class SaveChecklist {
    static readonly type = `${ns} Save Checklist`;
    constructor(public content: string, public rowKey?: string, public isTemplate = false) {}
}

export class SaveChecklistFailure {
    static readonly type = `${ns} Save Checklist Failure`;
    constructor(public error: any) {}
}

export class SavePreamble {
    static readonly type = `${ns} Save Preamble`;
    constructor(public content: string, public rowKey: string) {}
}

export class SavePreambleFailure {
    static readonly type = `${ns} Save Preamble Failure`;
    constructor(public error: any) {}
}

export class DeleteChecklistTemplate {
    static readonly type = `${ns} Delete Checklist Template`;
}

export class DeleteChecklistTemplateFailure {
    static readonly type = `${ns} Delete Checklist Template Failure`;
    constructor(public error: any) {}
}

export class LoadChecklistTemplate {
    static readonly type = `${ns} Load Checklist Template`;
}

export class SaveShareableLinkTemplate {
    static readonly type = `${ns} Save Shareable Link Template`;
}

export class SaveShareableLinkTemplateFailure {
    static readonly type = `${ns} Save Shareable Link Template Failure`;
    constructor(public error: any) {}
}

export class ConfirmDeleteTemplates {
    static readonly type = `${ns} Confirm Delete Templates`;
    constructor(
        public type: LinkType,
        public templates:
            | ChecklistTemplate[]
            | ShareableLinkTemplateModel[]
            | FormLinkTemplateModel[]
            | ChecklistLinkTemplateModel[]
            | PreambleTemplate[],
    ) {}
}

export class DeleteTemplates {
    static readonly type = `${ns} Delete Templates`;
    constructor(public type: LinkType, public rowKeys: string[]) {}
}

export class DeleteTemplatesFailure {
    static readonly type = `${ns} Delete Templates Failure`;
    constructor(public error: any) {}
}

export class LoadChecklist {
    static readonly type = `${ns} Load Checklist`;
    constructor(public rowKey?: string | null, public isTemplate = false, public retry = true) {}
}

export class LoadChecklistSuccess {
    static readonly type = `${ns} Load Checklist Success`;
    constructor(public content: string) { }
}

export class LoadChecklistFailure {
    static readonly type = `${ns} Load Checklist Failure`;
    constructor(public error: any) {}
}

export class CopyLink {
    static readonly type = `${ns} Copy Link`;
    constructor(public link: PreambleTemplate | ShareableLinkTemplateModel | FormLinkTemplateModel | ChecklistLinkTemplateModel | ChecklistTemplate | null, public isTemplate = false) {}
}

export class SaveFormLinkTemplate {
    static readonly type = `${ns} Save Form Link Template`;
}

export class SaveFormLinkTemplateFailure {
    static readonly type = `${ns} Save Form Link Template Failure`;
    constructor(public error: any) {}
}

export class SaveChecklistLinkTemplate {
    static readonly type = `${ns} Save Checklist Link Template`;
}

export class SaveChecklistLinkTemplateFailure {
    static readonly type = `${ns} Save Checklist Link Template Failure`;
    constructor(public error: any) {}
}

export class LoadPreamble {
    static readonly type = `${ns} Load Preamble`;
    constructor(public rowKey?: string | null, public retry = true) {}
}

export class LoadPreambleSuccess {
    static readonly type = `${ns} Load Preamble Success`;
    constructor(public content: string) { }
}

export class LoadPreambleFailure {
    static readonly type = `${ns} Load Preamble Failure`;
    constructor(public error: any) {}
}

export class LoadPreambleTemplateForLink {
    static readonly type = `${ns} Load Preamble Template For Link`;
    constructor(public rowKey?: string | null, public retry = true) {}
}

export class LoadPreambleTemplateForLinkSuccess {
    static readonly type = `${ns} Load Preamble Template For Link Success`;
    constructor(public content: string) { }
}

export class LoadPreambleTemplateForLinkFailure {
    static readonly type = `${ns} Load Preamble Template For Link Failure`;
    constructor(public error: any) {}
}

export class SavePreambleForLink {
    static readonly type = `${ns} Save Preamble For Link`;
    constructor(public content: string, public rowKey?: string) {}
}

export class SavePreambleForLinkFailure {
    static readonly type = `${ns} Save Preamble For Link Failure`;
    constructor(public error: any) {}
}

export class SetCustomPreamble {
    static readonly type = `${ns} Set Custom Preamble`;
    constructor(public content: string) {}
}

export class SaveFormSubmissionMessageForTemplate{
    static readonly type = `${ns} Save Form Submission Message For Template`;
    constructor(public rowKey: string, public submissionMessages: FormSubmissionMessage[]) {}
}

export class LoadSubmissionMessagesForLink {
    static readonly type = `${ns} Load Submission Messages For Link`;
    constructor(public rowKey: string) {}
}
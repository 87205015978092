@if (currentPage) {
  <ui-form-section class="section" [model]="currentPage"></ui-form-section>
  @for (child of currentPage.children; track child; let index = $index) {
    @switch (child.sectionType) {
      @case (FormSectionType.Text) {
        <ui-form-section-text
          [uiPreview]="showPreview"
          class="section"
          [model]="child"
        ></ui-form-section-text>
      }
      @case (FormSectionType.Media) {
        <ui-form-section-media
          [uiPreview]="showPreview"
          class="section"
          [token]="token"
          [model]="child"
          [videoSettings]="getVideoSettings(child)"
        ></ui-form-section-media>
      }
      @case (FormSectionType.Question) {
        <ui-form-section-question
          [uiPreview]="showPreview"
          class="section"
          [model]="child"
          (valueChanged)="onValuedChanged(child, $event)"
        ></ui-form-section-question>
      }
      @case (FormSectionType.File) {
        <ui-form-section-download
          [uiPreview]="showPreview"
          class="section"
          [model]="child"
        ></ui-form-section-download>
      }
      @case (FormSectionType.Event) {
        <ui-form-section-event
          [uiPreview]="showPreview"
          class="section"
          [model]="child"
        ></ui-form-section-event>
      }
      @case (FormSectionType.WebinarRegistration) {
        <ui-form-section-webinar
          [uiPreview]="showPreview"
          class="section"
          [registrant]="registrant"
          [model]="child"
          (registerForWebinar)="onRegisterForWebinar()"
        ></ui-form-section-webinar>
      }
      @case (FormSectionType.MeetingRegistration) {
        <ui-form-section-meeting
          [uiPreview]="showPreview"
          class="section"
          [registrant]="registrant"
          [model]="child"
          (registerForMeeting)="onRegisterForWebinar()"
        ></ui-form-section-meeting>
      }
    }
  }
}

<section class="actions">
  @if (isBackVisible) {
    <button mat-raised-button [disabled]="!isBackEnabled" (click)="onBack()">Back</button>
  }
  @if (isNextVisible) {
    <button mat-raised-button [disabled]="!isNextEnabled" color="primary" (click)="onNext()">
      Next
    </button>
  }
  @if (isSubmitVisible) {
    <button mat-raised-button [disabled]="!isSubmitEnabled" color="primary" (click)="onSubmit()">
      Submit
    </button>
  }
</section>
